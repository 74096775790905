import {
	Badge,
	Button, Card, Col, Form, Jumbotron, Row
} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {BiSearchAlt} from "react-icons/bi";
import {BsLockFill} from "react-icons/bs";
import {GoDiffAdded} from "react-icons/go";
import {useHistory} from "react-router-dom";

import {MarketplaceService} from "../../../../services";
import {useAppContext} from "../../../../soajs/libs/contextLib";

import {NotificationManager} from "../../../../soajs/urac/components";
import EditButton from "../../../buttons/Edit";
import ViewButton from "../../../buttons/View";
import AutoPagination from "../../../../lib/AutoPagination";
import JsonModal from "../../../../soajs/urac/components/JsonModal";


const marketplaceService = MarketplaceService.getService();

export default function Recipe() {
	const {t} = useTranslation(["common", "soajs"]);
	const {ability} = useAppContext();
	
	const [recipes, setRecipes] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [criteria, setCriteria] = useState({
		"keywords": ""
	});
	const [pagination, setPagination] = useState({
		"totalItems": 1, "maxSize": 1, "itemsPerPage": 1
	});
	
	const history = useHistory();
	
	const [modalOpt, setModalOpt] = useState({"show": false, "json": {}, "mode": "view", "title": ""});
	const modalOptShow = (json, title) => setModalOpt({
		"show": true,
		"json": json,
		"mode": "view",
		"title": title || ""
	});
	
	function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}
	
	async function handleSearch(event) {
		event.preventDefault();
		try {
			let c = {"limit": 10, "start": (currentPage - 1) * 10};
			if (criteria) {
				if (criteria.keywords && criteria.keywords !== "") {
					c.keywords = criteria.keywords;
				}
			}
			const _recipes = await marketplaceService.getRecipes(c);
			setPagination(
				{
					"totalItems": _recipes.count,
					"maxSize": 10,
					"itemsPerPage": _recipes.limit
				}
			);
			setRecipes(_recipes.records);
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				let c = {"limit": 10, "start": (currentPage - 1) * 10};
				const _recipes = await marketplaceService.getRecipes(c);
				if (isSubscribed && _recipes && _recipes.records) {
					setRecipes(_recipes.records);
					setPagination(
						{
							"totalItems": _recipes.count,
							"maxSize": 10,
							"itemsPerPage": _recipes.limit
						}
					);
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad().catch();
		return () => (isSubscribed = false);
	}, [currentPage]);
	
	return (
		<>
			<JsonModal
				modalOpt={modalOpt}
				setModalOpt={setModalOpt}
			/>
			<Jumbotron className="p-3 mb-2">
				<Row>
					<Col lg={4}>
						<h5 className="font-weight-bold m-0 float-left">{t("soajs:titles.env_cat_recipe")}</h5>
						{ability.can('market_recipe', 'add') &&
						<Button variant="success" className="float-right" size="sm"
						        onClick={() => {
							        history.push("/catalogs/recipe/add");
						        }}
						>
							<GoDiffAdded/> {t("soajs:buttons.Add")}
						</Button>}
					</Col>
					<Col>
						<Form onSubmit={handleSearch}>
							<Row>
								<Col xs={9} md={9}>
									<Form.Control
										id="keywords"
										size="sm"
										autoFocus
										value={criteria.keywords}
										onChange={handleCriteriaChange}
									/>
								</Col>
								<Col xs={3} md={3}>
									<Button className="float-right" variant="dark" size="sm" onClick={handleSearch}>
										<BiSearchAlt/> {t("soajs:buttons.Search")}
									</Button>
								</Col>
							</Row>
						
						</Form>
					</Col>
				</Row>
			</Jumbotron>
			{recipes.map((oo, index) => (
				<Card key={index} className="m-1 p-1" bg="light">
					<Card.Body className="p-2">
						<Row>
							<Col>
								{oo.name} - <Badge variant="dark" className="mr-2">V {oo.v}</Badge>
								{oo.locked && <BsLockFill className="text-danger"/>}
							</Col>
							<Col>
								<Badge variant="secondary">{oo.type}</Badge> <Badge variant="info">{oo.subtype}</Badge>
								<span className="float-right small">
								
								{ability.can('market_recipe', 'edit') &&
								<EditButton
									disabled={oo.locked}
									className="ml-3"
									onClick={() => {
										history.push("/catalogs/recipe/edit/" + oo._id);
									}}
								/>}
								
								<ViewButton
									className="ml-3"
									onClick={() => {
										
										modalOptShow(oo.recipe, oo.name);
									}}
								/>
						</span>
							</Col>
						</Row>
						<Row className="small text-muted">
							<Col>
								{oo.description}
							</Col>
						</Row>
					</Card.Body>
				</Card>
			))}
			{recipes.length > 0 ? (
				<>
					<div className="float-right mt-3">
						<AutoPagination
							currentPage={currentPage}
							totalItems={pagination.totalItems}
							itemsPerPage={pagination.itemsPerPage}
							maxSize={pagination.maxSize}
							onClick={(p) => {
								setCurrentPage(p);
							}}
						/>
					</div>
					<div className="clearfix"></div>
				</>
			) : null}
		</>
	);
}