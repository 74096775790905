import {
	ListGroup,
	ListGroupItem,
	Row,
	Col,
	Alert,
	Form,
	Accordion,
	Card,
	Button,
	Jumbotron,
	Tab, Tabs, useAccordionToggle
} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {NotificationManager} from "../../../../soajs/urac/components";
import {ConsoleService, MultitenantService} from "../../../../services";
import {SoaanalyticsService} from "../../../../services";
import {BiSearchAlt} from "react-icons/bi";
import AutoPagination from "../../../../lib/AutoPagination";
import AddButton from "../../../buttons/Add";
import DeleteButton from "../../../buttons/Delete";
import getACL from "../../../Settings/containers/Packages/lib/scopeAclLib";
import {FcCollapse, FcExpand} from "react-icons/fc";

const consoleService = ConsoleService.getService();
const multitenantService = MultitenantService.getService();
const soaanalyticsService = SoaanalyticsService.getService();
const initFields = null;

const initCriteria = {
	"type": "service",
	"includeSOAJS": false,
	"serviceName": "",
	"serviceGroup": "",
	"serviceVersion": "",
	"route": ""
};

const methodVariant = {
	"get": "primary",
	"post": "success",
	"delete": "danger",
	"put": "warning",
	"patch": "info",
	"head": "secondary",
	"other": "light"
};

function CustomToggle({children, eventKey, handleClick}) {
	const decoratedOnClick = useAccordionToggle(eventKey, () => {
		handleClick();
	});
	return (
		<div className="float-left" type="button" onClick={decoratedOnClick}>
			{children}
		</div>
	);
}

function getMethodVariant(method) {
	if (method && methodVariant[method]) {
		return methodVariant[method];
	} else {
		return methodVariant["other"];
	}
}

function getApiAccess(api) {
	if (api.hasOwnProperty("access")) {
		return api.access;
	} else {
		return "inherit";
	}
}

function isApiAdded(fieldsPackage, selectedEnv, service, version, method, api) {
	if (fieldsPackage &&
		fieldsPackage.acl &&
		fieldsPackage.acl[selectedEnv]) {
		if (fieldsPackage.acl[selectedEnv][service] &&
			fieldsPackage.acl[selectedEnv][service][version]) {
			if (fieldsPackage.acl[selectedEnv][service][version][method] &&
				fieldsPackage.acl[selectedEnv][service][version][method].apis &&
				fieldsPackage.acl[selectedEnv][service][version][method].apis[api]) {
				
				return true;
			}
		}
	}
	return false;
}

async function onLoadApi(setFieldsApi, setPagination, currentPage, isSubscribed, criteria) {
	try {
		let c = {
			"limit": 6,
			"start": (currentPage - 1) * 6,
			"type": criteria.type,
			"includeSOAJS": criteria.includeSOAJS,
			"keywords": {
				"serviceName": criteria.serviceName,
				"serviceGroup": criteria.serviceGroup,
				"serviceVersion": criteria.serviceVersion,
				"route": criteria.route
			}
		};
		const response = await soaanalyticsService.getApis(c);
		if (isSubscribed) {
			if (response && response.records) {
				setFieldsApi(response.records);
				setPagination(
					{
						"totalItems": response.count,
						"maxSize": 10,
						"itemsPerPage": response.limit
					}
				);
			}
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

async function onLoad(setFields, setFieldsPackage, setFieldsEnvironments, setFSelectedEnv, id, code, isSubscribed) {
	try {
		const response = await multitenantService.getProduct(id);
		const _environments = await consoleService.getEnvironments();
		if (_environments && isSubscribed) {
			let envs = [];
			for (let i = 0; i < _environments.length; i++) {
				envs.push(_environments[i].code.toLowerCase());
			}
			setFieldsEnvironments(envs);
			setFSelectedEnv(envs[0] || null);
		}
		if (isSubscribed && response) {
			setFields(response);
			if (response.packages && response.packages.length > 0) {
				let pack = response.packages.filter((p) => {
					return p.code === code;
				})
				if (pack) {
					pack = pack[0];
					let envs = Object.keys(pack.acl)
					// setFieldsEnvironments(envs);
					// setFSelectedEnv(envs[0] || null);
					let convertedPack = {
						"acl": {},
						"code": pack.code,
						"name": pack.name,
						"aclTypeByEnv": pack.aclTypeByEnv || {}
					};
					for (let i = 0; i < envs.length; i++) {
						if (pack.aclTypeByEnv && pack.aclTypeByEnv[envs[i]] && pack.aclTypeByEnv[envs[i]] === "granular") {
							convertedPack.acl[envs[i]] = pack.acl[envs[i]];
						} else if (response.scope) {
							Object.keys(pack.acl[envs[i]]).map((service, index) => {
								let ACL = {};
								for (let j = 0; j < pack.acl[envs[i]][service].length; j++) {
									if (pack.acl[envs[i]][service][j].version) {
										let version = pack.acl[envs[i]][service][j].version;
										if (response.scope.acl[envs[i]][service][version]) {
											ACL[version] = getACL(response.scope.acl[envs[i]][service][version], pack.acl[envs[i]][service][j]);
											
										}
									}
								}
								if (!convertedPack.acl[envs[i]]) {
									convertedPack.acl[envs[i]] = {};
								}
								convertedPack.acl[envs[i]][service] = ACL;
								convertedPack.aclTypeByEnv[envs[i]] = "granular";
								return true;
							});
						}
					}
					setFieldsPackage(convertedPack);
				}
			}
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function PackageAcl() {
	const {t} = useTranslation(["common", "soajs"]);
	const [activeKey, setActiveKey] = useState(0);
	const [fields, setFields] = useState(initFields);
	const [fieldsPackage, setFieldsPackage] = useState({});
	const [fieldsEnvironments, setFieldsEnvironments] = useState([]);
	const [selectedEnv, setFSelectedEnv] = useState(null);
	
	const [fieldsApi, setFieldsApi] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [criteria, setCriteria] = useState(initCriteria);
	const [pagination, setPagination] = useState({
		"totalItems": 1, "maxSize": 1, "itemsPerPage": 1
	});
	
	async function reLoadApi(page) {
		await onLoadApi(setFieldsApi, setPagination, page || currentPage, true, criteria)
	}
	
	function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}
	
	async function handleSearch(event) {
		event.preventDefault();
		setCurrentPage(1);
		await reLoadApi(1);
	}
	
	const {id} = useParams();
	const {code} = useParams();
	
	function handleEnvChange(event) {
		setFSelectedEnv(event.target.value);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		if (id) {
			onLoad(setFields, setFieldsPackage, setFieldsEnvironments, setFSelectedEnv, id, code, isSubscribed).catch();
		}
		onLoadApi(setFieldsApi, setPagination, 1, isSubscribed, initCriteria).catch();
		return () => (isSubscribed = false);
	}, [id, code]);
	
	function updateService(event, selectedEnv, service, version) {
		if (event.target.id === "apisPermission") {
			let obj = fieldsPackage;
			if (event.target.value === "restricted") {
				obj.acl[selectedEnv][service][version].apisPermission = event.target.value;
			} else {
				delete obj.acl[selectedEnv][service][version].apisPermission;
			}
			setFieldsPackage({...obj});
		} else if (event.target.id === "access") {
			let obj = fieldsPackage;
			obj.acl[selectedEnv][service][version].access = event.target.value;
			setFieldsPackage({...obj});
		}
	}
	
	function updateAPI(event, selectedEnv, service, version, method, api) {
		if (event.target.id === "access") {
			let obj = fieldsPackage;
			if (event.target.value === "true") {
				obj.acl[selectedEnv][service][version][method].apis[api].access = true;
			} else if (event.target.value === "false") {
				obj.acl[selectedEnv][service][version][method].apis[api].access = false;
			} else {
				delete obj.acl[selectedEnv][service][version][method].apis[api].access;
			}
			setFieldsPackage({...obj});
		}
	}
	
	function addAPI(service, version, method, api, group) {
		if (!fieldsPackage.acl) {
			fieldsPackage.acl = {};
		}
		if (!fieldsPackage.acl[selectedEnv]) {
			fieldsPackage.acl[selectedEnv] = {};
		}
		if (!fieldsPackage.acl[selectedEnv][service]) {
			fieldsPackage.acl[selectedEnv][service] = {};
		}
		if (!fieldsPackage.acl[selectedEnv][service][version]) {
			fieldsPackage.acl[selectedEnv][service][version] = {
				"access": true,
				"apisPermission": "restricted"
			};
		} else {
			fieldsPackage.acl[selectedEnv][service][version].apisPermission = "restricted";
		}
		if (!fieldsPackage.acl[selectedEnv][service][version][method]) {
			fieldsPackage.acl[selectedEnv][service][version][method] = {};
		}
		if (!fieldsPackage.acl[selectedEnv][service][version][method].apis) {
			fieldsPackage.acl[selectedEnv][service][version][method].apis = {};
		}
		if (!fieldsPackage.acl[selectedEnv][service][version][method].apis[api]) {
			fieldsPackage.acl[selectedEnv][service][version][method].apis[api] = {
				"access": true,
				"group": group
			};
		}
		fieldsPackage.aclTypeByEnv[selectedEnv] = "granular";
		setFieldsPackage({...fieldsPackage});
	}
	
	function removeAPI(service, version, method, api) {
		if (fieldsPackage.acl &&
			fieldsPackage.acl[selectedEnv] &&
			fieldsPackage.acl[selectedEnv][service] &&
			fieldsPackage.acl[selectedEnv][service][version] &&
			fieldsPackage.acl[selectedEnv][service][version][method] &&
			fieldsPackage.acl[selectedEnv][service][version][method].apis &&
			fieldsPackage.acl[selectedEnv][service][version][method].apis[api]
		) {
			delete fieldsPackage.acl[selectedEnv][service][version][method].apis[api];
			setFieldsPackage({...fieldsPackage});
		}
	}
	
	async function handleSubmit() {
		try {
			await multitenantService.updatePackageAcl({
				"id": fields._id,
				"code": fieldsPackage.code,
				"env": selectedEnv,
				"type": fieldsPackage.aclTypeByEnv[selectedEnv],
				"acl": fieldsPackage.acl[selectedEnv]
			});
			NotificationManager.success(t("soajs:messages.updatePackageAcl"));
		} catch
			(e) {
			NotificationManager.error(e.message);
		}
	}
	
	return (
		id && fields &&
		<>
			<ListGroup variant="flush">
				<ListGroupItem>
					<Row>
						<Col>
							<span className="font-weight-bold">{t("soajs:fields.ProductCode")}:</span> {fields.code}
						</Col>
						<Col>
							<span className="font-weight-bold">{t("soajs:fields.ProductName")}:</span> {fields.name}
						</Col>
					</Row>
				</ListGroupItem>
				<ListGroupItem>
					<Row>
						<Col>
							<span
								className="font-weight-bold">{t("soajs:fields.PackageCode")}:</span> {fieldsPackage.code}
						</Col>
						<Col>
							<span
								className="font-weight-bold">{t("soajs:fields.PackageName")}:</span> {fieldsPackage.name}
						</Col>
					</Row>
				</ListGroupItem>
			</ListGroup>
			<hr/>
			
			<Alert variant="info">
				<Form.Control
					as="select"
					id="env"
					value={selectedEnv || ""}
					onChange={handleEnvChange}
				>
					{fieldsEnvironments.map((env, index) => (
						<option key={index} value={env}>{t("soajs:titles.environment")}: {env}</option>
					))}
				</Form.Control>
			</Alert>
			<hr/>
			
			{selectedEnv &&
			<>
				
				<Accordion activeKey={activeKey}>
					<CustomToggle
						eventKey={1}
						handleClick={() => {
							if (activeKey === 1) {
								setActiveKey(null);
							} else {
								setActiveKey(1);
							}
						}}
					>
						{activeKey === 1 ? <FcExpand className="mr-1"/> :
							<FcCollapse className="mr-1"/>} <span
						className="small font-weight-bold text-secondary">Click here to Find more APIs and Add them to the package ACL</span>
					</CustomToggle>
					<div className="clearfix"></div>
					<Accordion.Collapse eventKey={1}>
						<Jumbotron className="p-3">
							<Form onSubmit={handleSearch}>
								<Row className="small">
									<Col xs={4}>
										<Form.Control
											placeholder={t("soajs:fields.ServiceName")}
											id="serviceName"
											size="sm"
											autoFocus
											value={criteria.serviceName}
											onChange={handleCriteriaChange}
										/>
									</Col>
									<Col xs={4}>
										<Form.Control
											placeholder={t("soajs:fields.ServiceVersion")}
											id="serviceVersion"
											size="sm"
											autoFocus
											value={criteria.serviceVersion}
											onChange={handleCriteriaChange}
										/>
									</Col>
									<Col xs={4}>
										<Form.Control
											placeholder={t("soajs:fields.ServiceGroup")}
											id="serviceGroup"
											size="sm"
											autoFocus
											value={criteria.serviceGroup}
											onChange={handleCriteriaChange}
										/>
									</Col>
								</Row>
								<Row className="small mt-4">
									<Col xs={6} md={7}>
										<Form.Control
											placeholder={t("soajs:fields.Route")}
											id="route"
											size="sm"
											autoFocus
											value={criteria.route}
											onChange={handleCriteriaChange}
										/>
									</Col>
									<Col xs={3}>
										<Form.Check
											id="includeSOAJS"
											label="Include SOAJS APIs"
											defaultChecked={criteria.includeSOAJS}
											onChange={handleCriteriaChange}
										/>
									</Col>
									<Col xs={3} md={2}>
										<Button className="float-right" variant="dark" size="sm"
										        onClick={handleSearch}>
											<BiSearchAlt/> {t("soajs:buttons.Search")}
										</Button>
									</Col>
								</Row>
							</Form>
							<hr/>
							{fieldsApi.map((api, index) => (
								api.method && api.route &&
								<Alert className="m-1 p-2" key={index} variant={getMethodVariant(api.method)}>
									<Row>
										<Col>
											<AddButton
												disabled={isApiAdded(fieldsPackage, selectedEnv, api.serviceName, api.v, api.method, api.route)}
												className="float-left pl-1 pr-1 pt-0 pb-0 mr-2"
												onClick={() => {
													addAPI(api.serviceName, api.v, api.method, api.route, api.apiGroup);
												}}
											/>
											<Button size="sm" className="mr-2 pl-1 pr-1 pt-0 pb-0 float-left"
											        variant={getMethodVariant(api.method)}><span
												className="small">{api.method.toUpperCase()}</span></Button>
											<span
												className="small float-left font-weight-bold mr-1">{api.serviceName}</span>
											<span
												className="small float-left mr-2 font-weight-bold text-success"> v{api.v} </span>
											<span className="small float-left">{api.route}</span>
											<span className="small float-right text-info">{api.group}</span>
										</Col>
									</Row>
								</Alert>
							))}
							<div className="float-right mt-3">
								<AutoPagination
									className="mb-0"
									currentPage={currentPage}
									totalItems={pagination.totalItems}
									itemsPerPage={pagination.itemsPerPage}
									maxSize={pagination.maxSize}
									onClick={(p) => {
										setCurrentPage(p);
										reLoadApi(p);
									}}
								/>
							</div>
							<div className="clearfix"></div>
						</Jumbotron>
					</Accordion.Collapse>
				</Accordion>
				<hr/>
				
				{fieldsPackage && fieldsPackage.acl && fieldsPackage.acl[selectedEnv] &&
				<>
					<Alert variant="warning">
						<h6 className="float-left text-dark font-weight-bold mt-3">
							If you change the ACL, make sure you update for each environment
						</h6>
						<Button
							className="mt-2 float-right"
							variant="success"
							onClick={handleSubmit}
						>
							{t("soajs:buttons.Update")} ACL for [<span
							className="text-dark font-weight-bold">{selectedEnv}</span>]
						</Button>
						<div className="clearfix"></div>
					</Alert>
					<Accordion defaultActiveKey={1}>
						{Object.keys(fieldsPackage.acl[selectedEnv]).map((service, index) => (
							<Card key={index + 1}>
								<Card.Header className="pt-1 pb-1 pr-2 pl-2">
									<Accordion.Toggle className="p-0" as={Button} variant="link"
									                  eventKey={index + 1}>
										{service}
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey={index + 1}>
									<Card.Body>
										
										<Tabs defaultActiveKey="1"
										      style={{borderBottom: "1px solid #dee2e6"}}
										>
											{Object.keys(fieldsPackage.acl[selectedEnv][service]).map((version, index) => (
												<Tab
													key={index + 1}
													eventKey={index + 1}
													title={t("soajs:fields.Version") + " " + version}
													tabClassName="small"
												>
													<ListGroup variant="flush" className="small mt-3">
														<ListGroupItem>
															<Row>
																<Col>
																	<Form.Label className="font-weight-bold mr-3 mt-1">Service's
																		APIs
																		default access
																		is: </Form.Label>
																</Col>
																<Col>
																	<Form.Control
																		size="sm"
																		as="select"
																		id="access"
																		value={fieldsPackage.acl[selectedEnv][service][version].access || false}
																		onChange={(event) => {
																			updateService(event, selectedEnv, service, version);
																		}}
																	>
																		<option value={true}>Private - requires logged
																			in
																			user
																		</option>
																		<option value={false}>Public - anyone can access
																		</option>
																	</Form.Control>
																</Col>
															</Row>
														</ListGroupItem>
														<ListGroupItem>
															<Row>
																<Col>
																	<Form.Label className="font-weight-bold mr-3 mt-1">Access
																		is
																		restricted to the selected
																		APIs: </Form.Label>
																</Col>
																<Col>
																	<Form.Control
																		size="sm"
																		as="select"
																		id="apisPermission"
																		value={fieldsPackage.acl[selectedEnv][service][version].apisPermission || "notRestricted"}
																		onChange={(event) => {
																			updateService(event, selectedEnv, service, version);
																		}}
																	>
																		<option value="restricted">Yes - only the
																			selected
																			APIs are
																			accessible
																		</option>
																		<option value="notRestricted">No - all APIs are
																			accessible
																		</option>
																	</Form.Control>
																</Col>
															</Row>
														</ListGroupItem>
													</ListGroup>
													<hr/>
													
													{(fieldsPackage.acl[selectedEnv][service][version].apisPermission &&
														fieldsPackage.acl[selectedEnv][service][version].apisPermission === "restricted") &&
													Object.keys(methodVariant).map((method, index) => (
														fieldsPackage.acl[selectedEnv][service][version][method] && fieldsPackage.acl[selectedEnv][service][version][method].apis &&
														Object.keys(fieldsPackage.acl[selectedEnv][service][version][method].apis).map((api, index) => (
															<Alert
																className="m-1 p-2"
																key={index}
																variant={getMethodVariant(method)}>
																<Row>
																	<Col className="small">
																		<DeleteButton
																			className="float-left pl-1 pr-1 pt-0 pb-0 mr-2"
																			onClick={() => {
																				removeAPI(service, version, method, api);
																			}}
																		/>
																		<Button size="sm"
																		        className="mr-2 pl-1 pr-1 pt-0 pb-0 float-left"
																		        variant={getMethodVariant(method)}><span
																			className="small">{method.toUpperCase()}</span></Button>
																		
																		<span className="float-left mt-1">{api}</span>
																	</Col>
																	<Col className="small" xs={3} sm={3} md={2}>
																		<Form.Control
																			style={{
																				"fontSize": "80%"
																			}}
																			size="sm"
																			as="select"
																			id="access"
																			value={getApiAccess(fieldsPackage.acl[selectedEnv][service][version][method].apis[api])}
																			onChange={(event) => {
																				updateAPI(event, selectedEnv, service, version, method, api);
																			}}
																		>
																			<option value="true">Private</option>
																			<option value="false">Public</option>
																			<option
																				value="inherit">Inherit from service
																			</option>
																		</Form.Control>
																	</Col>
																</Row>
															</Alert>
														))
													))}
												
												</Tab>
											))}
										</Tabs>
									
									
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						))}
					</Accordion>
				</>
				}
			</>
			}
		</>
	);
}