import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {useLocation, useParams} from "react-router-dom";

import {useAppContext} from "../../libs/contextLib";

import {NotificationManager} from '../components';
import {AbilitiesService, LocalStorageService, OauthService, UracService} from "../services";
import {useTranslation} from "react-i18next";

const localStorageService = LocalStorageService.getService();
const oauthService = OauthService.getService();
const uracService = UracService.getService();
const abilitiesService = AbilitiesService.getService();

export default function ThirdPartyLogin() {
	const {t} = useTranslation(["common", "urac"]);
	const {strategy} = useParams();
	const thirdPartyParams = useLocation().search;
	const {userHasAuthenticated, setFirstName, ability} = useAppContext();
	const [validating, setValidating] = useState(false);
	const [confirmationMsg, setConfirmationMsg] = useState(strategy + t("urac:msg.thirdPartyLoginConfirm"));
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				await oauthService.validateThirdPartyLogin(strategy, thirdPartyParams);
				await uracService.getUser();
				await abilitiesService.createUserAbilities(ability);
				if (isSubscribed) {
					setFirstName(localStorageService.getFirstname());
					userHasAuthenticated(true);
				}
			} catch (e) {
				if (isSubscribed) {
					setConfirmationMsg(strategy + t("urac:msg.thirdPartyLoginError"));
				}
				NotificationManager.error(e.message);
			}
		}
		
		setValidating(true);
		
		onLoad();
		return () => isSubscribed = false;
	}, [strategy, setFirstName, userHasAuthenticated, thirdPartyParams, ability, t]);
	
	
	function validatingForm() {
		return (
			<Form>
				<Form.Text className="pb-3" muted>
					{strategy} : {t("urac:msg.thirdPartyLoginProcess")}
				</Form.Text>
			</Form>
		);
	}
	
	function renderConfirmationForm() {
		return (
			<Form>
				<Form.Text className="pb-3" muted>
					{confirmationMsg}
				</Form.Text>
			</Form>
		);
	}
	
	return (
		<div className="URAC">
			{!validating ? validatingForm() : renderConfirmationForm()}
		</div>
	);
}