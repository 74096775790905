import {Accordion, Button, Card, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";

import {GoDiffAdded} from "react-icons/go";

import {NotificationManager} from "../../../../soajs/urac/components";
import {MultitenantService} from "../../../../services";
import DeleteButton from "../../../buttons/Delete";
import EditButton from "../../../buttons/Edit";
import {useTranslation} from "react-i18next";
import ViewButton from "../../../buttons/View";
import AclButton from "../../../buttons/Acl";
import {useAppContext} from "../../../../soajs/libs/contextLib";

import DeleteModal from "./DeleteModal";
import AddModal from "./AddModal";
import UpdateModal from "./UpdateModal";
import AddPackageModal from "./AddPackageModal";
import UpdatePackageModal from "./UpdatePackageModal";
import InfoModal from "../../../Settings/containers/Packages/InfoModal/InfoModal";
import {useHistory} from "react-router-dom";

async function onLoad(setFields, isSubscribed) {
	try {
		const response = await multitenantService.getProducts();
		if (isSubscribed && response) {
			setFields(response);
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

const multitenantService = MultitenantService.getService();

export default function Products() {
	const {ability} = useAppContext();
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState([]);
	
	const history = useHistory();
	
	const [modalDeleteOpt, setModalDeleteOpt] = useState({"show": false, item: null});
	const modalDeleteOptShow = (item) => setModalDeleteOpt({"show": true, item: item});
	const [modalUpdateProductOpt, setModalUpdateProductOpt] = useState({"show": false, item: null});
	const modalUpdateProductOptShow = (item) => setModalUpdateProductOpt({"show": true, item: item});
	const [modalAddProductOpt, setModalAddProductOpt] = useState({"show": false, item: null});
	const modalAddProductOptShow = (item) => setModalAddProductOpt({"show": true, item: item});
	const [modalUpdatePackageOpt, setModalUpdatePackageOpt] = useState({"show": false, item: null});
	const modalUpdatePackageOptShow = (item) => setModalUpdatePackageOpt({"show": true, item: item});
	const [modalAddPackageOpt, setModalAddPackageOpt] = useState({"show": false, item: null});
	const modalAddPackageOptShow = (item) => setModalAddPackageOpt({"show": true, item: item});
	
	const [modalInfoOpt, setModalInfoOpt] = useState({"show": false, opts: null, scope: null});
	const modalInfoOptShow = (pack, scope) => setModalInfoOpt({"show": true, opts: pack, scope: scope});
	
	async function reLoad() {
		await onLoad(setFields, true);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		onLoad(setFields, isSubscribed).catch();
		return () => (isSubscribed = false);
	}, []);
	
	return (
		<>
			<DeleteModal
				modalOpt={modalDeleteOpt}
				setModalOpt={setModalDeleteOpt}
				handleAction={reLoad}
			/>
			<UpdateModal
				modalOpt={modalUpdateProductOpt}
				setModalOpt={setModalUpdateProductOpt}
				handleAction={reLoad}
			/>
			<AddModal
				modalOpt={modalAddProductOpt}
				setModalOpt={setModalAddProductOpt}
				handleAction={reLoad}
			/>
			<UpdatePackageModal
				modalOpt={modalUpdatePackageOpt}
				setModalOpt={setModalUpdatePackageOpt}
				handleAction={reLoad}
			/>
			<AddPackageModal
				modalOpt={modalAddPackageOpt}
				setModalOpt={setModalAddPackageOpt}
				handleAction={reLoad}
			/>
			<InfoModal
				modalOpt={modalInfoOpt}
				setModalOpt={setModalInfoOpt}
			/>
			<div className="mb-1">
				<h5 className="float-left font-weight-bold">Available products</h5>
				
				{ability.can('product', 'add') &&
				<Button
					className="float-right mb-1"
					variant="success"
					size="sm"
					onClick={() => {
						modalAddProductOptShow({});
					}}
				><GoDiffAdded/> {t("soajs:buttons.Add")}</Button>}
				
				<span className="clearfix"></span>
			</div>
			{fields.map((rec, index) => (
				<Accordion className="mb-2" key={index + 1} defaultActiveKey={1}>
					<Card>
						<Card.Header style={{"backgroundColor": "#DEF1F7"}}>
							<Accordion.Toggle className="p-0" as={Button} variant="link"
							                  eventKey={index + 1}>
								<span className="font-weight-bold">{rec.name}</span> [{rec.code}]
							</Accordion.Toggle>
							
							{ability.can('product', 'delete') &&
							<DeleteButton
								className="float-right ml-1"
								onClick={() => {
									modalDeleteOptShow({
										"type": "product",
										"id": rec._id,
										"name": rec.name
									});
								}}
							/>}
							
							{ability.can('product', 'edit') &&
							<EditButton
								className="float-right ml-1"
								onClick={() => {
									modalUpdateProductOptShow({
										"id": rec._id,
										"code": rec.code,
										"name": rec.name || "",
										"description": rec.description || ""
									});
								}}
							/>}
						
						</Card.Header>
						<Accordion.Collapse eventKey={index + 1}>
							<Card.Body>
								<span className="small">{rec.description}</span>
								<hr className="mt-4"/>
								<div className="mb-1">
									<h6 className="float-left font-weight-bold">Available packages</h6>
									
									{ability.can('package', 'add') &&
									<Button
										className="float-right mb-1"
										variant="success"
										size="sm"
										onClick={() => {
											modalAddPackageOptShow({
												"id": rec._id
											});
										}}
									><GoDiffAdded/> {t("soajs:buttons.Add")}</Button>}
									
									<span className="clearfix"></span>
								</div>
								<Table responsive striped hover size="sm" className="small">
									<thead className="text-light bg-dark">
									<tr>
										<th>{t("soajs:buttons.Actions")}</th>
										<th>{t("soajs:fields.Code")}</th>
										<th>{t("soajs:fields.Name")}</th>
										<th>{t("soajs:fields.Description")}</th>
									</tr>
									</thead>
									<tbody>
									{rec.packages.map((pack, index) => (
										<tr key={index}>
											<td nowrap="true">
												<ViewButton
													className="mr-1"
													onClick={() => {
														pack.turnOffAbilityView = true;
														modalInfoOptShow(pack, rec.scope);
													}}
												/>
												{ability.can('package', 'delete') &&
												<DeleteButton
													className="mr-1"
													onClick={() => {
														modalDeleteOptShow({
															"type": "package",
															"id": rec._id,
															"code": pack.code,
															"name": pack.name
														});
													}}
												/>
												}
												{ability.can('package', 'edit') &&
												<EditButton
													className="mr-1"
													onClick={() => {
														modalUpdatePackageOptShow({
															"id": rec._id,
															"code": pack.code,
															"name": pack.name || "",
															"description": pack.description || "",
															"_TTL": pack._TTL ? pack._TTL / 3600000 : 168,
														});
													}}
												/>
												}
												{ability.can('package', 'acl') &&
												<AclButton
													className="mr-1"
													onClick={() => {
														history.push("/multitenancy/products/" + rec._id + "/acl/" + pack.code);
													}}
												/>
												}
											</td>
											<td>{pack.code}</td>
											<td nowrap="true">{pack.name}</td>
											<td>{pack.description}</td>
										</tr>
									))}
									</tbody>
								</Table>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				</Accordion>
			))}
		</>
	);
}