import {Accordion, Alert, Button, Card, CardDeck, Col, Form, Jumbotron, Row, useAccordionToggle} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {BiSearchAlt} from "react-icons/bi";
import {useTranslation} from "react-i18next";
import _ from "lodash";

import {NotificationManager} from "../../../../soajs/urac/components";
import {SoaanalyticsService} from "../../../../services";
import AutoPagination from "../../../../lib/AutoPagination";
import {FcCollapse, FcExpand} from "react-icons/fc";

const initCriteria = {
	"type": "service",
	"includeSOAJS": false,
	"serviceName": "",
	"serviceGroup": "",
	"serviceVersion": "",
	"route": "",
	"tags": [],
	"programs": [],
	"attributes": {}
};

const methodVariant = {
	"get": "primary",
	"post": "success",
	"delete": "danger",
	"put": "warning",
	"patch": "info",
	"head": "secondary",
	"other": "light"
};
const soaanalyticsService = SoaanalyticsService.getService();

function getMethodVariant(method) {
	if (method && methodVariant[method]) {
		return methodVariant[method];
	} else {
		return methodVariant["other"];
	}
}

function CustomToggle({children, eventKey, handleClick}) {
	const decoratedOnClick = useAccordionToggle(eventKey, () => {
		handleClick();
	});
	return (
		<div className="float-left" type="button" onClick={decoratedOnClick}>
			{children}
		</div>
	);
}

async function onLoad(setFields, setPagination, currentPage, setFacets, isSubscribed, criteria) {
	try {
		let c = {
			"limit": 25,
			"start": (currentPage - 1) * 25,
			"type": criteria.type,
			"includeSOAJS": criteria.includeSOAJS,
			"keywords": {
				"serviceName": criteria.serviceName,
				"route": criteria.route,
				"serviceGroup": criteria.serviceGroup,
				"serviceVersion": criteria.serviceVersion
			},
			"tags": criteria.tags,
			"programs": criteria.programs,
			"attributes": criteria.attributes
		};
		const response = await soaanalyticsService.getDashboardApis(c);
		if (isSubscribed) {
			if (response && response.records && Array.isArray(response.records) && response.records.length > 0) {
				setFields(response.records);
				setPagination(
					{
						"totalItems": response.count,
						"maxSize": 10,
						"itemsPerPage": c.limit
					}
				);
				setFacets({
					"tags": response.tags || [], "programs": response.programs || [], "attributes": response.attributes || {}
				});
			}
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function ApiCatalog() {
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState([]);
	const [activeKey, setActiveKey] = useState(0);
	const [facets, setFacets] = useState({"tags": [], "programs": [], "attributes": {}});
	const [currentPage, setCurrentPage] = useState(1);
	const [criteria, setCriteria] = useState(initCriteria);
	const [pagination, setPagination] = useState({
		"totalItems": 1, "maxSize": 1, "itemsPerPage": 1
	});
	
	function onChangeProgram(checked, program) {
		let obj = criteria;
		if (checked) {
			obj.programs.push(program);
		} else {
			_.pullAll(obj.programs, [program]);
		}
		setCriteria({...obj});
	}
	
	function onChangeTag(checked, tag) {
		let obj = criteria;
		if (checked) {
			obj.tags.push(tag);
		} else {
			_.pullAll(obj.tags, [tag]);
		}
		setCriteria({...obj});
	}
	
	function onChangeAttribute(checked, attribute, tag) {
		let obj = criteria;
		if (checked) {
			if (!obj.attributes[attribute]) {
				obj.attributes[attribute] = [];
			}
			obj.attributes[attribute].push(tag);
		} else {
			if (obj.attributes[attribute]) {
				_.pullAll(obj.attributes[attribute], [tag]);
				if (obj.attributes[attribute].length === 0) {
					delete obj.attributes[attribute];
				}
			}
		}
		setCriteria({...obj});
	}
	
	function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}
	
	async function reLoad(page) {
		await onLoad(setFields, setPagination, page || currentPage, setFacets, true, criteria)
	}
	
	async function handleSearch(event) {
		event.preventDefault();
		setCurrentPage(1);
		await onLoad(setFields, setPagination, 1, setFacets, true, criteria);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		onLoad(setFields, setPagination, 1, setFacets, isSubscribed, initCriteria);
		return () => (isSubscribed = false);
	}, []);
	
	return (
		<>
			<Jumbotron className="p-3">
				<Form onSubmit={handleSearch}>
					<Row className="small">
						<Col xs={3}>
							<Form.Control
								placeholder={t("soajs:fields.ServiceName")}
								id="serviceName"
								size="sm"
								autoFocus
								value={criteria.serviceName}
								onChange={handleCriteriaChange}
							/>
						</Col>
						<Col xs={2}>
							<Form.Control
								placeholder={t("soajs:fields.ServiceVersion")}
								id="serviceVersion"
								size="sm"
								autoFocus
								value={criteria.serviceVersion}
								onChange={handleCriteriaChange}
							/>
						</Col>
						<Col xs={2}>
							<Form.Control
								placeholder={t("soajs:fields.ServiceGroup")}
								id="serviceGroup"
								size="sm"
								autoFocus
								value={criteria.serviceGroup}
								onChange={handleCriteriaChange}
							/>
						</Col>
						<Col xs={3}>
							<Form.Control
								placeholder={t("soajs:fields.Route")}
								id="route"
								size="sm"
								autoFocus
								value={criteria.route}
								onChange={handleCriteriaChange}
							/>
						</Col>
						<Col xs={2} className="mt-2">
							<Form.Check
								id="includeSOAJS"
								label="Include SOAJS APIs"
								defaultChecked={criteria.includeSOAJS}
								onChange={handleCriteriaChange}
							/>
						</Col>
					</Row>
					<hr/>
					
					<Row>
						<Col>
							<Accordion activeKey={activeKey}>
								<CustomToggle
									eventKey={1}
									handleClick={() => {
										if (activeKey === 1) {
											setActiveKey(null);
										} else {
											setActiveKey(1);
										}
									}}
								>
									{activeKey === 1 ? <FcExpand className="mr-1"/> :
										<FcCollapse className="mr-1"/>} <span
									className="small">Advanced Filters</span>
								</CustomToggle>
								
								<Button className="float-right" variant="dark" size="sm" onClick={handleSearch}>
									<BiSearchAlt/> {t("soajs:buttons.Search")}
								</Button>
								<span className="clearfix"></span>
								<Accordion.Collapse eventKey={1}>
									<>
										<hr className="mb-1"/>
										<Row className="mt-1 small">
											<Col xs={1}>
												Programs
											</Col>
											<Col>
												<CardDeck style={{"maxHeight": "3rem", "overflowY": "auto"}}>
													{facets.programs.map((program, index) => (
														<Card key={index} className="m-1 p-1" bg="light"
														      style={{"minWidth": '11rem', "maxWidth": '11rem'}}>
															<Card.Body className="p-1">
																<Form.Check key={index + 1} type="checkbox"
																            label={program}
																            defaultChecked={criteria.programs.includes(program)}
																            onChange={(event) => {
																	            onChangeProgram(event.target.checked, program)
																            }}
																/>
															</Card.Body>
														</Card>
													))}
												</CardDeck>
											</Col>
										</Row>
										<hr className="mt-1 mb-1"/>
										<Row className="mt-1 small">
											<Col xs={1}>
												Tags
											</Col>
											<Col>
												<CardDeck style={{"maxHeight": "6rem", "overflowY": "auto"}}>
													{facets.tags.map((tag, index) => (
														<Card key={index} className="m-1 p-1" bg="light"
														      style={{"minWidth": '11rem', "maxWidth": '11rem'}}>
															<Card.Body className="p-1">
																<Form.Check key={index + 1} type="checkbox"
																            label={tag}
																            defaultChecked={criteria.tags.includes(tag)}
																            onChange={(event) => {
																	            onChangeTag(event.target.checked, tag)
																            }}
																/>
															</Card.Body>
														</Card>
													))}
												</CardDeck>
											</Col>
										</Row>
										<hr className="mt-1 mb-1"/>
										<Row className="mt-1 small">
											<Col xs={1}>
												Attributes
											</Col>
											<Col>
												{Object.keys(facets.attributes).map((attribute, index) => (
													<div key={index}>
														<Row key={index} className="mt-2">
															<Col xs={1}>
																{attribute}
															</Col>
															<Col>
																<CardDeck
																	style={{"maxHeight": "7rem", "overflowY": "auto"}}>
																	{facets.attributes[attribute].map((tag, index) => (
																		<Card key={index} className="m-1 p-1" bg="light"
																		      style={{
																			      "minWidth": '11rem',
																			      "maxWidth": '11rem'
																		      }}>
																			<Card.Body className="p-1">
																				<Form.Check key={index + 1}
																				            type="checkbox"
																				            label={tag}
																				            defaultChecked={criteria.attributes[attribute] && criteria.attributes[attribute].includes(tag)}
																				            onChange={(event) => {
																					            onChangeAttribute(event.target.checked, attribute, tag)
																				            }}
																				/>
																			</Card.Body>
																		</Card>
																	))}
																</CardDeck>
															</Col>
														</Row>
														<hr className="mt-1 mb-1"/>
													</div>
												))}
											</Col>
										</Row>
									</>
								</Accordion.Collapse>
							</Accordion>
						</Col>
					</Row>
				</Form>
			</Jumbotron>
			
			{
				fields.length > 0 ? (
					<>
						{fields.map((api, index) => (
							api.method && api.route &&
							<Alert className="m-1 p-2" key={index} variant={getMethodVariant(api.method)}>
								<Row>
									<Col>
										<Button size="sm" className="mr-2 pl-1 pr-1 pt-0 pb-0 float-left"
										        variant={getMethodVariant(api.method)}><span
											className="small">{api.method.toUpperCase()}</span></Button>
										
										<span
											className="small float-left font-weight-bold mr-1">{api.serviceName}</span>
										<span
											className="small float-left mr-2 font-weight-bold text-success"> v{api.v} </span>
										<span className="small float-left">{api.route}</span>
										<span className="small float-right text-info">{api.group}</span>
									</Col>
								</Row>
								<Row className="mt-2">
									<Col>
									<span className="small font-italic float-left ml-3">
									{api.label}
								</span>
									</Col>
								</Row>
							</Alert>
						))}
						<div className="float-right mt-3">
							<AutoPagination
								currentPage={currentPage}
								totalItems={pagination.totalItems}
								itemsPerPage={pagination.itemsPerPage}
								maxSize={pagination.maxSize}
								onClick={(p) => {
									setCurrentPage(p);
									reLoad(p);
								}}
							/>
						</div>
						<div className="clearfix"></div>
					</>
				) : null
			}
		</>
	);
}