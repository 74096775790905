import {Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {MultitenantService} from "../../../../services";
import {JsonEditor, NotificationManager} from "../../../../soajs/urac/components";

import _config from "../../../../config"
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../../../soajs/libs/contextLib";

const multitenantService = MultitenantService.getService();

async function onLoad(setFields, isSubscribed) {
	try {
		
		const response = await multitenantService.getMyConsoleTenant();
		if (isSubscribed && response) {
			if (response.applications && response.applications[0] && response.applications[0].keys && response.applications[0].keys[0])
				setFields({
					"key": response.applications[0].keys[0].key,
					"appId": response.applications[0].appId,
					"id": response._id,
					"envCode": _config.consoleEnvironment,
					"config": response.applications[0].keys[0].config[_config.consoleEnvironment] || {}
				});
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function Configuration() {
	const {ability} = useAppContext();
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState({
		"key": null,
		"appId": null,
		"id": null,
		"envCode": null,
		"config": {}
	});
	
	function JsonEditor_onChange(json) {
		fields.config = json;
	}
	
	async function handleSubmit() {
		try {
			await multitenantService.updateTenantConsoleApplicationKey(fields);
			NotificationManager.success(t("soajs:messages.consoleConfigurationUpdate"));
		} catch
			(e) {
			NotificationManager.error(e.message);
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		onLoad(setFields, isSubscribed);
		return () => (isSubscribed = false);
	}, []);
	return (
		<>
			<h5>
				{t("soajs:titles.consoleConfiguration")}
			</h5>
			<small className="text-muted">Make sure you know what your are doing or ask SOAJS Team for support!</small>
			<div className="mt-3" style={{"height": "700px"}}>
				<JsonEditor
					mode={"code"}
					modes={["code", "tree"]}
					json={fields.config || {}}
					onChange={JsonEditor_onChange}
				/>
			</div>
			<Button
				className="float-right"
				variant="success"
				onClick={handleSubmit}
				disabled={!ability.can('tenant_console_application_key', 'configure')}
			>
				{t("soajs:buttons.Update")}
			</Button>
			<span className="clearfix"></span>
		</>
	);
}