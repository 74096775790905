import {Button, Form, Modal} from "react-bootstrap";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {JsonEditor, NotificationManager} from "../../../../../soajs/urac/components";
import {MultitenantService} from "../../../../../services";
import deepSet from "../../../../../soajs/libs/deepSetLib";

const multitenantService = MultitenantService.getService();

export default function AddExtKeyModal({modalOpt, setModalOpt, handleAction, environments}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, item: null});
	
	const [fields, setFields] = useState({
		"id": "",
		"appId": "",
		"key": "",
		"label": "",
		"env": "",
		"expDate": null,
		"device": {},
		"geo": {}
	});
	
	function validateForm() {
		return (
			fields.label.length > 0 &&
			fields.env.length > 0
		);
	}
	
	function JsonEditor_onChange_device(json) {
		fields.device = json;
	}
	
	function JsonEditor_onChange_geo(json) {
		fields.geo = json;
	}
	
	function handleExpDateChange(m) {
		let obj = fields;
		if (m) {
			//deepSet(obj, "expDate", m.getFullYear() + "-" + (m.getMonth() + 1) + "-" + m.getDate());
			deepSet(obj, "expDate", m.toISOString());
		} else {
			deepSet(obj, "expDate", null);
		}
		setFields({...obj});
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		let obj = fields;
		deepSet(obj, event.target.id, value);
		setFields({...obj});
	}
	
	async function handleSubmit() {
		try {
			fields.id = modalOpt.item.id;
			fields.appId = modalOpt.item.appId;
			fields.key = modalOpt.item.key;
			await multitenantService.addTenantConsoleExtKey(fields);
			NotificationManager.success(t("soajs:messages.tenantExtKeyAdd"));
			handleAction();
			handleClose();
		} catch
			(e) {
			NotificationManager.error(e.message);
		}
	}
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.tenantExtKeyAdd")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Label")}</Form.Label>
						<Form.Control
							id="label"
							size="sm"
							value={fields.label}
							onChange={handleFieldChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Env")}</Form.Label>
						<Form.Control
							as="select"
							id="env"
							size="sm"
							defaultValue={fields.env}
							onChange={handleFieldChange}
						>
							<option value="">---</option>
							{environments.map((rec, index) => (
								<option key={index} value={rec.code}>{rec.code}</option>
							))}
						</Form.Control>
					</Form.Group>
					<Form.Group>
						<Form.Label className="mr-5">{t("soajs:fields.ExpDate")}</Form.Label>
						<DatePicker
							selected={(fields.expDate && fields.expDate !== "") ? new Date(fields.expDate) : null}
							onChange={handleExpDateChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Device")}</Form.Label><JsonEditor
						mode={"code"}
						json={fields.device || {}}
						onChange={JsonEditor_onChange_device}
					/>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Geo")}</Form.Label><JsonEditor
						mode={"code"}
						json={fields.geo || {}}
						onChange={JsonEditor_onChange_geo}
					/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button
					variant="success"
					onClick={handleSubmit}
					disabled={!validateForm()}
				>
					{t("soajs:buttons.Add")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}