import {Switch} from "react-router-dom";
import React from "react";
import {AuthenticatedRoute} from "../../../../soajs/helpers";

import Rbac from "./Rbac/Rbac";
import Nodes from "./Nodes/Nodes";
import Workloads from "./Workloads/Workloads";
import Services from "./Services/Services";
import Storage from "./Storage/Storage";
import Configuration from "./Configuration/Configuration";


const rootPath = "/environments";

export default function Routes({environment}) {
	return (
		<Switch>
			
			<AuthenticatedRoute path={rootPath + "/manage/:env/cluster/nodes"}>
				<Nodes
					environment={environment}
				/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/manage/:env/cluster/workloads"}>
				<Workloads
					environment={environment}
				/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/manage/:env/cluster/services"}>
				<Services
					environment={environment}
				/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/manage/:env/cluster/storage"}>
				<Storage
					environment={environment}
				/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/manage/:env/cluster/configuration"}>
				<Configuration
					environment={environment}
				/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/manage/:env/cluster/rbac"}>
				<Rbac
					environment={environment}
				/>
			</AuthenticatedRoute>
		
		</Switch>
	)
}