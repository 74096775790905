
import {ResponsivePie} from '@nivo/pie'

const myColors = {
	"get": "#b8daff",
	"post": "#c3e6cb",
	"put": "#ffeeba",
	"delete": "#f5c6cb",
	"patch": "#bee5eb",
	"head": "#d6d8db",
	"other": "#c6c8ca" //"#fdfdfe"
};

function myColor(datum) {
	return myColors[datum.id];
}

export default function MethodsChart({data /* see data tab */}) {
	
	return (
		<ResponsivePie
			data={data}
			margin={{top: 40, right: 80, bottom: 80, left: 80}}
			padAngle={0.7}
			cornerRadius={3}
			colors={(datum) => myColor(datum)}
			borderWidth={1}
			borderColor={{from: 'color', modifiers: [['darker', 0.3]]}}
			radialLabelsSkipAngle={10}
			radialLabelsTextColor="#000000"
			radialLabelsLinkColor={{from: 'color'}}
			sliceLabel="value"
			sliceLabelsSkipAngle={10}
			arcLinkLabelsSkipAngle={10}
			sliceLabelsTextColor="#000000"
			legends={[
				{
					anchor: 'bottom',
					direction: 'row',
					justify: false,
					translateX: 0,
					translateY: 56,
					itemsSpacing: 0,
					itemWidth: 60,
					itemHeight: 18,
					itemTextColor: '#999',
					itemDirection: 'left-to-right',
					itemOpacity: 1,
					symbolSize: 15,
					symbolShape: 'circle',
					effects: [
						{
							on: 'hover',
							style: {
								itemTextColor: '#000'
							}
						}
					]
				}
			]}
		/>
	)
}