import React from "react";
import Template from "../Template/Template";
import {Alert} from "react-bootstrap";
import ContainerDetails from "../common/ContainerDetails";

function getDeployConfiguration(deployment, v, env) {
	if (deployment && deployment[env]) {
		for (let i = 0; i < deployment[env].length; i++) {
			if (deployment[env][i].version === v) {
				return deployment[env][i];
			}
		}
	}
	return null;
}

export default function Daemon({environment}) {
	function Details({version, item, active, deactivate, reloadItem}) {
		return (
			<>
				{environment.deployer && environment.deployer.type === "container" &&
				<ContainerDetails
					reloadItem={reloadItem}
					version={version}
					itemName={item.name}
					itemType={item.type}
					itemSettings={item.settings || null}
					itemSrc={item.src || null}
					env={environment.code.toLowerCase()}
					active={active}
					deactivate={deactivate}
					deployConfiguration={getDeployConfiguration(item.deploy, version.version, environment.code.toLowerCase())}
				/>}
			</>
		);
	}
	
	return (
		environment &&
		<>
			{environment.deployer && environment.deployer.type === "container" ? (
				<Template
					type={"daemon"}
					Children={Details}
				/>
			) : (
				<Alert variant="info" className="mt-4 mb-4">
					This environment is of type <span className="text-success">manual</span>, <span
					className="font-weight-bold">Daemon Catalog</span> is only available
					for environments of type <span className="text-info">container</span>
				</Alert>
			)}
		</>
	);
}