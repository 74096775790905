import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";
import {GoSync} from "react-icons/go";

export default function Synchronise({...props}) {
	const {t} = useTranslation(["common", "soajs"]);
	return (
		<OverlayTrigger
			placement="top"
			overlay={
				<Tooltip id={`tooltip-top}`}>
					{t("soajs:buttons.Sync")}
				</Tooltip>
			}
		>
			
			<Button size="sm" variant="info" {...props}>
				<GoSync/>
			</Button>
		</OverlayTrigger>
	);
}