import {Button, Modal, Form} from "react-bootstrap";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";

import {ConsoleService, InfraService} from "../../../../services";
import {NotificationManager} from "../../../../soajs/urac/components";
import {useAppContext} from "../../../../soajs/libs/contextLib";

const consoleService = ConsoleService.getService();
const infraService = InfraService.getService();

export default function NamespaceModal({modalOpt, setModalOpt, handleAction, environment, settings}) {
	const {t} = useTranslation(["common", "soajs"]);
	const {ability} = useAppContext();
	const [fields, setFields] = useState({
		"id": "",
		"namespace": "",
		"code": "",
		"type": "existing"
	});
	const [namespaces, setNamespaces] = useState([]);
	
	const handleClose = () => setModalOpt({"show": false});
	
	async function handleSubmit() {
		try {
			await consoleService.updateEnvironmentNamespace(fields);
			NotificationManager.success(t("soajs:messages.envNamespace"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setFields({
			...fields,
			[event.target.id]: value
		});
	}
	
	function validateForm() {
		return (
			fields.namespace.length > 0
		);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				if (environment) {
					const response = await infraService.getNamespaces(environment.code);
					if (isSubscribed && response && response.items && response.items.length > 0) {
						setNamespaces(response.items);
					}
					if (isSubscribed && settings) {
						setFields({
							"id": settings.container.kubernetes.id,
							"code": environment.code,
							"namespace": settings.container.kubernetes.namespace,
							"type": "existing"
						});
					}
				} else {
					if (isSubscribed) {
						setNamespaces([]);
					}
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad()
		return () => (isSubscribed = false);
	}, [environment, settings]);
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false}>
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.envNamespace")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{fields &&
				<Form onSubmit={(event) => {
					event.preventDefault();
				}}>
					<Form.Row className="mb-2">
						<Form.Label>{t("soajs:fields.NamespaceSelection")}</Form.Label>
						<Form.Control as="select" id="type"
						              defaultValue={fields.type}
						              onChange={handleFieldChange}
						>
							<option value="existing">Choose exiting namespace</option>
							<option value="new">Create new namespace</option>
						</Form.Control>
					</Form.Row>
					{fields.type === "existing" &&
					<Form.Row className="mb-2">
						<Form.Label>{t("soajs:fields.ExistingNamespace")}</Form.Label>
						<Form.Control as="select" id="namespace"
						              defaultValue={fields.namespace}
						              onChange={handleFieldChange}
						>
							{namespaces.map((ns, index) => (
								<option key={index} value={ns.metadata.name}>{ns.metadata.name}</option>
							))}
						</Form.Control>
					</Form.Row>
					}
					{fields.type === "new" &&
					<Form.Row className="mb-2">
						<Form.Label>{t("soajs:fields.CreateNamespace")}</Form.Label>
						<Form.Control
							id="namespace"
							size="sm"
							value={fields.namespace}
							onChange={handleFieldChange}
						/>
					</Form.Row>
					}
				</Form>
				}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				{ability.can('env', 'update') &&
				<Button variant="success"
				        disabled={!validateForm()}
				        onClick={handleSubmit}
				>
					{t("soajs:buttons.Update")}
				</Button>
				}
			</Modal.Footer>
		</Modal>
	);
}