import {Button, Form, Modal} from "react-bootstrap";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {JsonEditor} from "../../../../../../soajs/urac/components";


export default function JsonModal({modalOpt, setModalOpt}) {
	const {t} = useTranslation(["common", "soajs"]);
	const [selectedPod, setSelectedPod] = useState(0);
	
	const handleClose = () => setModalOpt({"show": false, "data": [], "mode": "view", "title": ""});
	
	function handleSelectedPod(event) {
		setSelectedPod(event.target.value);
	}
	
	return (
		modalOpt &&
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="xl">
			<Modal.Header closeButton className="bg-secondary text-white">
				<Modal.Title>{modalOpt.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Control
					as="select"
					id="pod"
					defaultValue={selectedPod}
					onChange={handleSelectedPod}
				>
					{modalOpt.data.map((rec, i) => (
						<option key={i} value={i}>{rec.id}</option>
					))}
				</Form.Control>
				<hr/>
				{selectedPod >= 0 && modalOpt && modalOpt.data && modalOpt.data[selectedPod] &&
				<JsonEditor
					mode={modalOpt.mode || "view"}
					modes={["view", "preview"]}
					json={modalOpt.data[selectedPod].response || {}}
				/>
				}
			</Modal.Body>
			<Modal.Footer className="bg-light">
				<Button variant="info" onClick={handleClose}>
					{t("soajs:buttons.Ok")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}