import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "../../../../soajs/libs/contextLib";
import {Button, Card, CardDeck, Form, Jumbotron} from "react-bootstrap";
import _ from "lodash";
import {NotificationManager} from "../../../../soajs/urac/components";
import {ConsoleService, MarketplaceService} from "../../../../services";

const consoleService = ConsoleService.getService();
const marketplaceService = MarketplaceService.getService();

function isEnvSelected(settings, value) {
	return (
		settings &&
		settings.environments &&
		settings.environments.value &&
		settings.environments.value.includes(value)
	);
}

export default function ConfigureModalEnvironments({modalOpt, handleAction, handleClose}) {
	const {t} = useTranslation(["common", "soajs"]);
	const {ability} = useAppContext();
	const [environments, setEnvironments] = useState([]);
	const [fields, setFields] = useState({
		"environments": [],
		"type": "whitelist"
	});
	
	function validateForm() {
		let valid = true;
		
		return (
			valid
		);
	}
	
	function whitelistBlacklist(checked, what) {
		let obj = fields;
		obj.type = what;
		setFields({...obj});
	}
	
	function onChangeEnvironment(checked, code) {
		let obj = fields;
		if (checked) {
			obj.environments.push(code);
		} else {
			_.pullAll(obj.environments, [code]);
		}
		setFields({...obj});
	}
	
	async function handleSubmit(event) {
		event.preventDefault();
		try {
			
			await marketplaceService.updateSettingsEnvironments({
				"id": modalOpt.item._id,
				"environments": fields.environments,
				"type": fields.type,
				"subType": (modalOpt.item.configuration ? modalOpt.item.configuration.subType || null : null)
			});
			NotificationManager.success(t("soajs:messages.environmentsItemSuccess"));
			
			handleAction()
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				if (modalOpt.item && modalOpt.item.settings && modalOpt.item.settings.environments) {
					setFields({
						"environments": modalOpt.item.settings.environments.value,
						"type": modalOpt.item.settings.environments.type
					});
				}
				const _environments = await consoleService.getEnvironments();
				
				if (isSubscribed) {
					setEnvironments(_environments);
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad().catch();
		return () => (isSubscribed = false);
	}, [modalOpt]);
	
	return (
		<>
			<Jumbotron className="p-4 m-0 bg-light border-dark small">
				<fieldset>
					<Form.Check
						type="radio"
						label="whitelist"
						name="environmentsType"
						id="environmentsType1"
						defaultChecked={fields.type === "whitelist"}
						onChange={(event) => {
							whitelistBlacklist(event.target.checked, "whitelist")
						}}
					/>
					<Form.Check
						type="radio"
						label="blacklist"
						name="environmentsType"
						id="environmentsType2"
						defaultChecked={fields.type === "blacklist"}
						onChange={(event) => {
							whitelistBlacklist(event.target.checked, "blacklist")
						}}
					/>
				</fieldset>
			</Jumbotron>
			<CardDeck className="ml-2">
				{environments.map((oo, index) => (
					<Card key={index} className="m-1 p-1" bg="light"
					      style={{"minWidth": '11rem', "maxWidth": '11rem'}}>
						<Card.Body className="p-2">
							<Form.Check className="small" key={index + 1} type="checkbox"
							            defaultChecked={isEnvSelected(modalOpt.item.settings, oo.code)}
							            label={oo.code}
							            onChange={(event) => {
								            onChangeEnvironment(event.target.checked, oo.code)
							            }}
							/>
						</Card.Body>
					</Card>
				))}
			</CardDeck>
			<hr/>
			<div>
				<Button className="ml-1 float-right" variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Close")}
				</Button>
				
				{ability.can('market_item', 'settings_environments') && (modalOpt.item.configuration ? modalOpt.item.configuration.subType || null : null) !== "soajs" &&
				<Button className="ml-1 float-right" variant="success"
				        disabled={!validateForm()}
				        onClick={handleSubmit}
				>
					{t("soajs:buttons.Save")} - {t("soajs:fields.Environments")}
				</Button>}
				{ability.can('market_item', 'settings_environments') && (modalOpt.item.configuration ? modalOpt.item.configuration.subType || null : null) === "soajs" &&
				<Button className="ml-1 float-right" variant="success"
				        disabled={!validateForm()}
				        onClick={handleSubmit}
				>
					{t("soajs:buttons.Save")} - {t("soajs:fields.Environments")}
				</Button>}
			</div>
		</>
	);
}