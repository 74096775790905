const config = {
	"consoleEnvironment": "dashboard",
	"badTokenMessages": [
		"The access token provided has expired.", "The access token provided is invalid."
	],
	"alreadyInvited": [
		"User is already in the tenant tenancy.", "User has already been invited."
	],
	"noToken": [
		"/oauth/token"
	]
};

export default config;