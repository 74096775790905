import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";

import {AuthenticatedRoute} from "../../soajs/helpers";

import Users from "./containers/Users/Users"
import Products from "./containers/Products/Products"
import PackageAcl from "./containers/Products/PackageAcl"
import MainTenant from "./containers/tenants/Main/Main"
import SubTenant from "./containers/tenants/Sub/Sub"

const rootPath = "/multitenancy";
const defaultPath = "/products";

export default function Routes() {
	return (
		<Switch>
			<AuthenticatedRoute path={rootPath + "/products/:id/acl/:code"}>
				<PackageAcl/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/products"}>
				<Products/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/tenants/main"}>
				<MainTenant/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/tenants/sub"}>
				<SubTenant/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/users"}>
				<Users/>
			</AuthenticatedRoute>
			<Route
				render={() => {
					return (<Redirect to={rootPath + defaultPath}/>)
				}}/>
		</Switch>
	)
}