import React, {useState} from "react";
import Form from "react-bootstrap/Form";

import {useFormFields} from "../../libs/hooksLib";
import {LoaderButton} from "../../helpers";

import {NotificationManager} from '../components';
import {UracService} from "../services";
import {useTranslation} from "react-i18next";

const uracService = UracService.getService();

export default function ForgotPassword() {
	const {t} = useTranslation(["common", "urac"]);
	const [fields, handleFieldChange] = useFormFields({
		username: ""
	});
	const [isLoading, setIsLoading] = useState(false);
	
	function validateForm() {
		return (
			fields.username.length > 0
		);
	}
	
	async function handleSubmit(event) {
		event.preventDefault();
		
		setIsLoading(true);
		
		try {
			await uracService.forgotPassword(fields.username);
			NotificationManager.success(t("urac:msg.forgotPasswordSuccess"));
		} catch (e) {
			NotificationManager.error(e.message);
		}
		
		setIsLoading(false);
	}
	
	return (
		<div className="URAC">
			<Form onSubmit={handleSubmit}>
				<Form.Group controlId="username">
					<Form.Label>{t("urac:Email or Username")}</Form.Label>
					<Form.Control
						autoFocus
						type="username"
						value={fields.password}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				
				<Form.Text className="pb-3" muted>
					{t("urac:msg.forgotPassword")}
				</Form.Text>
				<LoaderButton
					block
					type="submit"
					isLoading={isLoading}
					disabled={!validateForm()}
				>
					{t("urac:button.Request")}
				</LoaderButton>
			</Form>
		</div>
	);
}