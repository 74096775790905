import React from "react";

import itemModel from "./model/itemModel.json";

import RecipeRW from "./RecipeRW";

export default function AddRecipe() {
	
	
	return (
		<>
			<RecipeRW
				starterFields={itemModel}
				mode={"add"}
			/>
		</>
	);
}