import {Button, Modal, Form, Col} from "react-bootstrap";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";

import {UracService} from "../../../../soajs/urac/services";
import {NotificationManager} from "../../../../soajs/urac/components";
import _ from "lodash";

const uracService = UracService.getService();
const fieldsInit = {
	"id": "",
	"username": "",
	"email": "",
	"firstName": "",
	"lastName": "",
	"status": ""
};

export default function EditModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState(fieldsInit);
	const [groupsFields, setGroupsFields] = useState([]);
	const [groups, setGroups] = useState(null);
	
	const handleClose = () => setModalOpt({"show": false, opts: null});
	
	async function handleSubmit() {
		try {
			await uracService.updateUser(
				{
					"id": modalOpt.opts._id,
					"username": fields.username,
					"email": fields.email,
					"firstName": fields.firstName,
					"lastName": fields.lastName,
					"status": fields.status,
					"groups": groups //|| modalOpt.opts.groups || null
				});
			NotificationManager.success(t("soajs:messages.editUser"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	function onChangeGroup(checked, code) {
		if (checked) {
			let g = groups
			if (!g) {
				g = [];
			}
			g.push(code);
			setGroups(g)
		} else {
			let g = groups
			if (g) {
				_.pull(g, code);
				setGroups(g);
			}
		}
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setFields({
			...fields,
			[event.target.id]: value
		});
	}
	
	function validateForm() {
		return (
			fields.email.length > 0 &&
			fields.username.length > 4 &&
			fields.firstName.length > 0 &&
			fields.lastName.length > 0
		);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				if (modalOpt.opts) {
					const _groups = await uracService.getGroups();
					let _g = null;
					if (_groups) {
						_g = [];
						for (let i = 0; i < _groups.length; i++) {
							let _item = {
								"code": _groups[i].code,
								"name": _groups[i].name,
								"selected": false
							};
							if (modalOpt.opts.groups && modalOpt.opts.groups.includes(_item.code)) {
								_item.selected = true;
							}
							_g.push(_item);
						}
						if (isSubscribed) {
							setGroupsFields(_g);
							if (modalOpt.opts.groups && Array.isArray(modalOpt.opts.groups)) {
								setGroups([...modalOpt.opts.groups]);
							}
						}
					}
					if (isSubscribed) {
						setFields({
							"id": modalOpt.opts._id,
							"username": modalOpt.opts.username,
							"email": modalOpt.opts.email,
							"firstName": modalOpt.opts.firstName,
							"lastName": modalOpt.opts.lastName,
							"status": modalOpt.opts.status
						});
					}
				} else {
					if (isSubscribed) {
						setGroupsFields([]);
						setGroups(null);
						setFields(fieldsInit);
					}
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad()
		return () => (isSubscribed = false);
	}, [modalOpt]);
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false}>
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.editUser")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{fields &&
				<Form onSubmit={(event) => {
					event.preventDefault();
				}}>
					<Form.Row className="mb-2">
						<Form.Label column="sm" sm={3}>{t("soajs:fields.Username")}</Form.Label>
						<Col sm={9}>
							<Form.Control
								id="username"
								size="sm"
								value={fields.username}
								onChange={handleFieldChange}
							/>
						</Col>
					</Form.Row>
					<Form.Row className="mb-2">
						<Form.Label column="sm" sm={3}>{t("soajs:fields.Email")}</Form.Label>
						<Col sm={9}>
							<Form.Control
								id="email"
								size="sm"
								value={fields.email}
								onChange={handleFieldChange}
							/>
						</Col>
					</Form.Row>
					<Form.Row className="mb-2">
						<Form.Label column="sm" sm={3}>{t("soajs:fields.FirstName")}</Form.Label>
						<Col sm={9}>
							<Form.Control
								id="firstName"
								size="sm"
								value={fields.firstName}
								onChange={handleFieldChange}
							/>
						</Col>
					</Form.Row>
					<Form.Row className="mb-2">
						<Form.Label column="sm" sm={3}>{t("soajs:fields.LastName")}</Form.Label>
						<Col sm={9}>
							<Form.Control
								id="lastName"
								size="sm"
								value={fields.lastName}
								onChange={handleFieldChange}
							/>
						</Col>
					</Form.Row>
					<Form.Row className="mb-2">
						<Form.Label column="sm" sm={3}>{t("soajs:fields.Status")}</Form.Label>
						<Col sm={9}>
							<Form.Control as="select" id="status"
							              defaultValue={fields.status}
							              onChange={handleFieldChange}
							>
								<option value="active">active</option>
								<option value="inactive">inactive</option>
								<option value="pendingNew">pendingNew</option>
								<option value="pendingJoin">pendingJoin</option>
							</Form.Control>
						</Col>
					</Form.Row>
				</Form>
				}
				<hr/>
				{groupsFields.map((group, index) => (
					<Form.Check key={index + 1} type="switch"
					            label={group.code + " - " + group.name}
					            id={group.code}
					            defaultChecked={group.selected}
					            onChange={(event) => {
						            onChangeGroup(event.target.checked, group.code)
					            }}
					/>
				))}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button variant="success"
				        disabled={!validateForm()}
				        onClick={handleSubmit}
				>
					{t("soajs:buttons.Update")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}