import {Button, ListGroup, ListGroupItem, Modal} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";

function getDate(timestamp) {
	let dateStr = "";
	if (timestamp) {
		let date = new Date(timestamp);
		dateStr = dateStr +
			date.getDate() +
			"/" + (date.getMonth() + 1) +
			"/" + date.getFullYear();
	}
	return dateStr;
}

export default function InfoModal({modalOpt, setModalOpt}) {
	const {t} = useTranslation(["common", "soajs", "organization"]);
	
	const handleClose = () => setModalOpt({"show": false, opts: null});
	
	return (
		modalOpt.opts &&
		<Modal show={modalOpt.show} onHide={handleClose} animation={false}>
			<Modal.Header closeButton className="bg-secondary text-white">
				<Modal.Title>{t("soajs:titles.userInfo")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ListGroup variant="flush">
					<ListGroupItem>
						<span className="font-weight-bold">
						{t("soajs:fields.Username")}:</span> {modalOpt.opts.username}
					</ListGroupItem>
					<ListGroupItem>
						<span className="font-weight-bold">
						{t("soajs:fields.LastLogin")}:</span> {getDate(modalOpt.opts.lastLogin)}
					</ListGroupItem>
					<ListGroupItem>
						<span className="font-weight-bold">
						{t("soajs:fields.JoinedOn")}:</span> {getDate(modalOpt.opts.ts)}
					</ListGroupItem>
					<ListGroupItem>
						<span className="font-weight-bold">
							{t("soajs:fields.FirstName")}:</span> {modalOpt.opts.firstName}
					</ListGroupItem>
					<ListGroupItem>
						<span className="font-weight-bold">
							{t("soajs:fields.LastName")}:</span> {modalOpt.opts.lastName}
					</ListGroupItem>
					<ListGroupItem>
						<span className="font-weight-bold">
						{t("soajs:fields.Email")}:</span> {modalOpt.opts.email}
					</ListGroupItem>
					<ListGroupItem>
						<span className="font-weight-bold">
						{t("soajs:fields.Groups")}:</span> {modalOpt.opts.groups && modalOpt.opts.groups.join(", ")}
					</ListGroupItem>
					<ListGroupItem>
						<span className="font-weight-bold">
						{t("soajs:fields.Status")}:</span> {modalOpt.opts.status}
					</ListGroupItem>
				</ListGroup>
			</Modal.Body>
			<Modal.Footer className="bg-light">
				<Button variant="info" onClick={handleClose}>
					{t("soajs:buttons.Ok")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}