function getSourcesFromConfiguration(configuration) {
	let response = {};
	if (configuration.src) {
		if (configuration.src.tag) {
			response.tag = configuration.src.tag;
		}
		if (configuration.src.branch) {
			response.branch = configuration.src.branch;
		}
		if (configuration.src.commit) {
			response.commit = configuration.src.commit;
		}
	}
	if (configuration.recipe) {
		if (configuration.recipe.image && configuration.recipe.image.tag) {
			response.imageTag = configuration.recipe.image.tag;
		}
		if (configuration.recipe.image && configuration.recipe.image.prefix) {
			response.imagePrefix = configuration.recipe.image.prefix;
		}
		if (configuration.recipe.image && configuration.recipe.image.name) {
			response.imageName = configuration.recipe.image.name;
		}
		if (configuration.recipe.image && configuration.recipe.image.registrySecret) {
			response.imageRegistrySecret = configuration.recipe.image.registrySecret;
		}
	}
	return response;
}

function getSourcesFromContainer(template) {
	let response = {};
	let container = template.spec.containers[0];
	response.imageTag = container.image.substr(container.image.indexOf(":") + 1, container.image.length);
	if (container.image.indexOf("/")) {
		response.imagePrefix = container.image.substr(0, container.image.indexOf("/"));
		response.imageName = container.image.substr(container.image.indexOf("/") + 1, container.image.indexOf(":") - (container.image.indexOf("/") + 1));
	} else {
		response.imageName = container.image.substr(0, container.image.indexOf(":"));
	}
	if (container.env) {
		container.env.map((rec, i) => {
			if (rec.name === "SOAJS_GIT_BRANCH") {
				if (template.metadata.labels["service.tag"] && template.metadata.labels["service.tag"] === rec.value) {
					response.tag = rec.value;
				} else {
					response.branch = rec.value;
				}
			}
			if (rec.name === "SOAJS_GIT_COMMIT") {
				response.commit = rec.value;
			}
			return true;
		});
	}
	return response;
}

export {
	getSourcesFromConfiguration,
	getSourcesFromContainer
};

export default function getTemplate(inspect, kind) {
	let template = null;
	if (kind === "Deployment") {
		if (inspect.deployments &&
			inspect.deployments.items &&
			inspect.deployments.items[0]) {
			if (inspect.deployments.items[0].spec &&
				inspect.deployments.items[0].spec.template) {
				template = inspect.deployments.items[0].spec.template;
			}
		}
	}
	if (kind === "DaemonSet") {
		if (inspect.daemonsets &&
			inspect.daemonsets.items &&
			inspect.daemonsets.items[0]) {
			if (inspect.daemonsets.items[0].spec &&
				inspect.daemonsets.items[0].spec.template) {
				template = inspect.daemonsets.items[0].spec.template;
			}
		}
	}
	if (kind === "CronJob") {
		if (inspect.cronjobs &&
			inspect.cronjobs.items &&
			inspect.cronjobs.items[0]) {
			if (inspect.cronjobs.items[0].spec &&
				inspect.cronjobs.items[0].jobTemplate &&
				inspect.cronjobs.items[0].jobTemplate.spec &&
				inspect.cronjobs.items[0].jobTemplate.spec.template) {
				template = inspect.cronjobs.items[0].jobTemplate.spec.template;
			}
		}
	}
	return template;
}