import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";

import {
	Accordion,
	Card,
	CardDeck,
	Jumbotron,
	ListGroup,
	ListGroupItem,
	useAccordionToggle,
	DropdownButton,
	Dropdown,
	Button,
	Tabs,
	Tab
} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FcCollapse, FcExpand} from "react-icons/fc";
import ReactMarkdown from "react-markdown";
import SwaggerUI from './SwaggerUI';

import {NotificationManager} from "../../../../soajs/urac/components";
import {MarketplaceService} from "../../../../services";

const marketplaceService = MarketplaceService.getService();

function Swagger({item, ver}) {
	let spec = null;
	for (let i = 0; i < item.versions.length; i++) {
		if (item.versions[i].version === ver) {
			if (item.versions[i].swagger) {
				spec = item.versions[i].swagger;
				break;
			}
		}
	}
	try {
		if (spec) {
			spec = JSON.parse(spec);
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
	return (
		<SwaggerUI spec={spec || {}} ver={ver}/>
	);
}

function Readme({item, ver}) {
	let documentation = null;
	for (let i = 0; i < item.versions.length; i++) {
		if (item.versions[i].version === ver) {
			if (item.versions[i].documentation && item.versions[i].documentation.readme) {
				documentation = item.versions[i].documentation.readme;
				break;
			}
		}
	}
	return (
		<>
			<ReactMarkdown children={documentation}/>
		</>
	);
}

function Release({item, ver}) {
	let documentation = null;
	for (let i = 0; i < item.versions.length; i++) {
		if (item.versions[i].version === ver) {
			if (item.versions[i].documentation && item.versions[i].documentation.release) {
				documentation = item.versions[i].documentation.release;
				break;
			}
		}
	}
	return (
		<>
			<ReactMarkdown children={documentation}/>
		</>
	);
}

export default function Detail() {
	const {t} = useTranslation(["common", "soajs"]);
	const [activeKey, setActiveKey] = useState(1);
	const [item, setItem] = useState(null);
	const {type} = useParams();
	const {name} = useParams();
	const {ver} = useParams();
	
	const history = useHistory();
	
	function CustomToggle({children, eventKey, handleClick}) {
		const decoratedOnClick = useAccordionToggle(eventKey, () => {
			handleClick();
		});
		return (
			<div className="float-left" type="button" onClick={decoratedOnClick}>
				{children}
			</div>
		);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				if (!item || item.name !== name) {
					const response = await marketplaceService.getItem(name, type);
					if (isSubscribed) {
						setItem(response);
					}
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad();
		return () => (isSubscribed = false);
	}, [item, name, type]);
	
	return (
		<>
			{item &&
			<>
				<Jumbotron className="p-4 mb-3">
						<span
							className="font-weight-bold">{item.name}</span> {(item.configuration && item.configuration.port) ? [item.configuration.port] : ""}
					- <small>{item.configuration.group}</small>
					<hr/>
					{item.description}
				</Jumbotron>
				
				<Accordion activeKey={activeKey}>
					<Card key={1}>
						<Card.Header>
							<CustomToggle
								as={Card.Header}
								eventKey={1}
								handleClick={() => {
									if (activeKey === 1) {
										setActiveKey(null);
									} else {
										setActiveKey(1);
									}
								}}
							>
								{activeKey === 1 ? <FcExpand className="mr-1"/> :
									<FcCollapse className="mr-1"/>}
								{t("soajs:catalogs.metadata.title")}
							</CustomToggle>
						</Card.Header>
						<Accordion.Collapse eventKey={1}>
							<Card.Body>
								{item.metadata &&
								<ListGroup variant="flush">
									<ListGroupItem><span
										className="font-weight-bold">{t("soajs:catalogs.metadata.program")}:</span> {item.metadata.program}
									</ListGroupItem>
									<ListGroupItem><span
										className="font-weight-bold">{t("soajs:catalogs.metadata.tags")}:</span> [{item.metadata.tags ? item.metadata.tags.join(", ") : ""}]
									</ListGroupItem>
									<ListGroupItem><span
										className="font-weight-bold">{t("soajs:catalogs.metadata.attributes")}</span>
										{item.metadata.attributes &&
										<CardDeck>
											{Object.keys(item.metadata.attributes).map((_O, _I) => (
												<Card key={_I} className="text-white mt-2 mb-2 ml-2 mr-2" bg="info"
												      style={{"minWidth": '20rem', "maxWidth": '20rem'}}>
													<Card.Body className="p-2"><span
														className="font-weight-bold">{_O}:</span> [{item.metadata.attributes[_O].join(", ")}]</Card.Body>
												</Card>))}
										</CardDeck>
										}
									</ListGroupItem>
								</ListGroup>
								}
							</Card.Body>
						</Accordion.Collapse>
					</Card>
					{type === "service" &&
					<Card key={2}>
						<Card.Header>
							<CustomToggle
								as={Card.Header}
								eventKey={2}
								handleClick={() => {
									if (activeKey === 2) {
										setActiveKey(null);
									} else {
										setActiveKey(2);
									}
								}}
							>
								{activeKey === 2 ? <FcExpand className="mr-1"/> :
									<FcCollapse className="mr-1"/>}
								{t("soajs:catalogs.configuration.title")}
							</CustomToggle>
						</Card.Header>
						<Accordion.Collapse eventKey={2}>
							<Card.Body>
								<ListGroup variant="flush">
									<ListGroupItem><span
										className="font-weight-bold">{t("soajs:catalogs.configuration.subType")}:</span> {item.configuration.subType}
									</ListGroupItem>
									<ListGroupItem><span
										className="font-weight-bold">{t("soajs:catalogs.configuration.requestTimeout")}:</span> {item.configuration.requestTimeout}
									</ListGroupItem>
									<ListGroupItem><span
										className="font-weight-bold">{t("soajs:catalogs.configuration.requestTimeoutRenewal")}:</span> {item.configuration.requestTimeoutRenewal}
									</ListGroupItem>
								</ListGroup>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
					}
				</Accordion>
				
				<hr/>
				
				{item.versions &&
				<div className="bg-light p-2">
					<div className="mt-3">
						<DropdownButton size="sm" id="dropdown-item-button"
						                title={ver ? "Version " + ver : "Select a version:"}
						                variant="info" className="float-right">
							{item.versions.map((_ver, index) => (
								<Dropdown.Item as="button" key={index} active={ver && ver === _ver.version}
								               onClick={() => {
									               let uri = window.location.pathname;
									               uri = uri.substr(0, uri.indexOf("/" + name) + name.length + 1);
									               history.push(uri + "/" + _ver.version);
								               }}>
									<small>
										version {_ver.version}
									</small>
								</Dropdown.Item>
							))}
						</DropdownButton>
						<div className="clearfix"></div>
					</div>
					{ver &&
					<Tabs defaultActiveKey="documentation" id="uncontrolled-tab">
						<Tab eventKey="documentation" title={t("soajs:titles.Documentation")}>
							<Accordion defaultActiveKey="0" className="mt-3 ml-3 mr-3">
								<Card>
									<Card.Header>
										<Accordion.Toggle as={Button} variant="link" eventKey="0">
											Readme
										</Accordion.Toggle>
									</Card.Header>
									<Accordion.Collapse eventKey="0">
										<Card.Body>
											<Readme item={item} ver={ver}/>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
								<Card>
									<Card.Header>
										<Accordion.Toggle as={Button} variant="link" eventKey="1">
											Release
										</Accordion.Toggle>
									</Card.Header>
									<Accordion.Collapse eventKey="1">
										<Card.Body>
											<Release item={item} ver={ver}/>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							</Accordion>
						</Tab>
						
						{item.type === "service" &&
						<Tab eventKey="swagger" title={t("soajs:titles.Swagger")}>
							<Swagger item={item} ver={ver}/>
						</Tab>
						}
					</Tabs>
					}
				</div>
				}
			</>
			}
		</>
	);
}