import React, {useState} from "react";
import Form from "react-bootstrap/Form";

import {useFormFields} from "../../libs/hooksLib";
import {LoaderButton} from "../../helpers";

import {NotificationManager} from '../components';
import {UracService} from "../services";
import {useTranslation} from "react-i18next";

const uracService = UracService.getService();

export default function ChangeEmail() {
	const {t} = useTranslation(["common", "urac"]);
	const [fields, handleFieldChange] = useFormFields({
		newEmail: "",
		confirmNewEmail: "",
	});
	const [isLoading, setIsLoading] = useState(false);
	
	function validateForm() {
		return (
			fields.newEmail.length > 0 &&
			fields.newEmail === fields.confirmNewEmail
		);
	}
	
	async function handleSubmit(event) {
		event.preventDefault();
		
		setIsLoading(true);
		
		try {
			await uracService.changeEmail(fields.newEmail);
			NotificationManager.success(t("urac:msg.changeEmailSuccess"));
		} catch (e) {
			NotificationManager.error(e.message);
		}
		
		setIsLoading(false);
	}
	
	return (
		<div className="URAC">
			<Form onSubmit={handleSubmit}>
				<Form.Group controlId="newEmail">
					<Form.Label>{t("urac:New Email")}</Form.Label>
					<Form.Control
						autoFocus
						type="email"
						value={fields.newEmail}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Form.Group controlId="confirmNewEmail">
					<Form.Label>{t("urac:Confirm New Email")}</Form.Label>
					<Form.Control
						autoFocus
						type="email"
						value={fields.confirmNewEmail}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Form.Text className="pb-3" muted>
					{t("urac:msg.changeEmail")}
				</Form.Text>
				<LoaderButton
					block
					type="submit"
					isLoading={isLoading}
					disabled={!validateForm()}
				>
					{t("urac:button.Change")}
				</LoaderButton>
			</Form>
		</div>
	);
}