import React, {useEffect, useState} from "react";
import {Table, Button, Form, Row, Col, Jumbotron} from "react-bootstrap";

import {useTranslation} from "react-i18next";
import {GoDiffAdded} from "react-icons/go";
import {BiSearchAlt} from "react-icons/bi";

import AutoPagination from "../../../../lib/AutoPagination";
import {NotificationManager} from "../../../../soajs/urac/components";
import {UracService} from "../../../../soajs/urac/services";
import {useAppContext} from "../../../../soajs/libs/contextLib";

import DeleteButton from "../../../buttons/Delete";
import ViewButton from "../../../buttons/View";
import EditButton from "../../../buttons/Edit";

import DeleteModal from "./DeleteModal"
import InfoModal from "./InfoModal"
import EditModal from "./EditModal"
import AddModal from "./AddModal"

const uracService = UracService.getService();

async function onLoad(setFields, isSubscribed, setPagination, currentPage, criteria) {
	try {
		let c = {"limit": 50, "start": (currentPage - 1) * 50};
		if (criteria) {
			if (criteria.keywords && criteria.keywords !== "") {
				c.keywords = criteria.keywords;
			}
		}
		const users = await uracService.getUsers(null, null, c);
		const usersCount = await uracService.getUsersCount(null, null, c);
		if (users && Array.isArray(users) && users.length > 0) {
			if (isSubscribed) {
				setFields(users);
				setPagination(
					{
						"totalItems": usersCount,
						"maxSize": 10,
						"itemsPerPage": c.limit
					}
				);
			}
		} else {
			if (isSubscribed) {
				setFields(null);
			}
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function Users() {
	const {ability} = useAppContext();
	const {t} = useTranslation(["common", "soajs", "organization"]);
	const [fields, setFields] = useState([]);
	const [modalInfoOpt, setModalInfoOpt] = useState({"show": false, opts: null});
	const modalInfoOptShow = (opt) => setModalInfoOpt({"show": true, opts: opt});
	const [modalDeleteOpt, setModalDeleteOpt] = useState({"show": false, opts: null});
	const modalDeleteOptShow = (opt) => setModalDeleteOpt({"show": true, opts: opt});
	const [modalEditOpt, setModalEditOpt] = useState({"show": false, opts: null});
	const modalEditOptShow = (opt) => setModalEditOpt({"show": true, opts: opt});
	const [modalAddOpt, setModalAddOpt] = useState({"show": false, opts: null});
	const modalAddOptShow = (opt) => setModalAddOpt({"show": true, opts: opt});
	
	const [currentPage, setCurrentPage] = useState(1);
	const [criteria, setCriteria] = useState({
		"keywords": ""
	});
	const [pagination, setPagination] = useState({
		"totalItems": 1, "maxSize": 1, "itemsPerPage": 1
	});
	
	async function handleSearch(event) {
		event.preventDefault();
		setCurrentPage(1);
		await onLoad(setFields, true, setPagination, 1, criteria);
	}
	
	function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}
	
	async function reLoad(page) {
		await onLoad(setFields, true, setPagination, page || currentPage, criteria)
	}
	
	useEffect(() => {
		let isSubscribed = true;
		onLoad(setFields, isSubscribed, setPagination, 1, null);
		return () => (isSubscribed = false);
	}, []);
	
	return (
		fields && (
			<>
				<AddModal
					modalOpt={modalAddOpt}
					setModalOpt={setModalAddOpt}
					handleAction={reLoad}
				/>
				<EditModal
					modalOpt={modalEditOpt}
					setModalOpt={setModalEditOpt}
					handleAction={reLoad}
				/>
				<DeleteModal
					modalOpt={modalDeleteOpt}
					setModalOpt={setModalDeleteOpt}
					handleAction={reLoad}
				/>
				<InfoModal
					modalOpt={modalInfoOpt}
					setModalOpt={setModalInfoOpt}
				/>
				<div>
					<h4 className="float-left">{t("soajs:titles.users")}</h4>
					{ability.can('user', 'add') &&
					<Button variant="success" className="float-right" size="sm"
					        onClick={()=>modalAddOptShow(null)}
					>
						<GoDiffAdded/> {t("soajs:buttons.Add")}
					</Button>}
					<span className="clearfix"></span>
				</div>
				<hr/>
				<Jumbotron className="p-3">
					<Form onSubmit={handleSearch}>
						<Row>
							<Col xs={9} md={10}>
								<Form.Control
									id="keywords"
									size="sm"
									autoFocus
									value={criteria.keywords}
									onChange={handleCriteriaChange}
								/>
							</Col>
							<Col xs={3} md={2}>
								<Button className="float-right" variant="dark" size="sm" onClick={handleSearch}>
									<BiSearchAlt/> {t("soajs:buttons.Search")}
								</Button>
							</Col>
						</Row>
					</Form>
				</Jumbotron>
				<Table responsive striped hover size="sm">
					<thead className="text-light bg-dark">
					<tr>
						<th>{t("soajs:buttons.Actions")}</th>
						<th>{t("soajs:fields.FirstName")}</th>
						<th>{t("soajs:fields.LastName")}</th>
						<th>{t("soajs:fields.Email")}</th>
						<th>{t("soajs:fields.Groups")}</th>
						<th>{t("soajs:fields.Status")}</th>
					</tr>
					</thead>
					<tbody>
					{fields.map((user, index) => (
						<tr key={index}>
							<td nowrap="true">
								<ViewButton
									className="mr-1"
									onClick={() => {
										modalInfoOptShow(user);
									}}
								/>
								{ability.can('user', 'delete') &&
								<DeleteButton
									className="mr-1"
									onClick={() => {
										modalDeleteOptShow(user);
									}}/>
								}
								{ability.can('user', 'edit') &&
								<EditButton
									className="mr-1"
									onClick={() => {
										modalEditOptShow(user);
									}}/>
								}
							</td>
							<td>{user.firstName}</td>
							<td>{user.lastName}</td>
							<td>{user.email}</td>
							<td>{user.groups && user.groups.join(", ")}</td>
							<td>{user.status}</td>
						</tr>
					))}
					</tbody>
				</Table>
				{fields.length > 0 ? (
					<>
						<div className="float-right mt-3">
							<AutoPagination
								currentPage={currentPage}
								totalItems={pagination.totalItems}
								itemsPerPage={pagination.itemsPerPage}
								maxSize={pagination.maxSize}
								onClick={(p) => {
									setCurrentPage(p);
									reLoad(p);
								}}
							/>
						</div>
						<div className="clearfix"></div>
					</>
				) : null}
			</>
		)
	);
}