import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import _ from "lodash";

import {
	Accordion,
	Button,
	Card,
	useAccordionToggle,
	Tabs,
	Tab,
	CardDeck,
	Form,
	Col,
	Table,
	Jumbotron, ListGroupItem, ListGroup, Modal
} from "react-bootstrap";
import {FcCollapse, FcExpand} from "react-icons/fc";
import {BiSearchAlt} from "react-icons/bi";

import AutoPagination from "../../../../lib/AutoPagination"
import {NotificationManager} from "../../../../soajs/urac/components";
import {RepositoriesService} from "../../../../services";
import {useAppContext} from "../../../../soajs/libs/contextLib";

import SynchroniseButton from "../../../buttons/Synchronise";
import TurnOffButton from "../../../buttons/TurnOff";
import TurnOnButton from "../../../buttons/TurnOn";
import DeleteButton from "../../../buttons/Delete";

const repositoriesService = RepositoriesService.getService();

function Branches({branches, t, ability, synBranch, turnOffBranch, turnOnBranch}) {
	let show = false;
	if (branches && Array.isArray(branches) && branches.length > 0) {
		show = true;
	}
	return (
		show &&
		<div className="mt-5">
			<CardDeck>
				{branches.map((_O, _I) => (
					_O.active ?
						<Card key={_I} className="mb-3" border="success"
						      style={{"minWidth": '21rem', "maxWidth": '21rem'}}>
							<Card.Body>
								<small>
									{_O.name}
								</small>
								{ability.can('repo_branch', 'turnOff') &&
								<TurnOffButton
									className="float-right ml-3"
									onClick={() => {
										turnOffBranch(_O.name);
									}}/>
								}
								{ability.can('repo_branch', 'synchronize') &&
								<SynchroniseButton
									className="float-right ml-3"
									onClick={() => {
										synBranch(_O.name);
									}}/>
								}
							</Card.Body>
						</Card>
						:
						<Card key={_I} className="mb-3" border="dark"
						      style={{"minWidth": '21rem', "maxWidth": '21rem'}}>
							<Card.Body>
								<small>
									{_O.name}
								</small>
								{ability.can('repo_branch', 'turnOn') &&
								<TurnOnButton
									className="float-right ml-3"
									onClick={() => {
										turnOnBranch(_O.name);
									}}
								/>
								}
							</Card.Body>
						</Card>
				))}
			</CardDeck>
		</div>
	);
}

function Tags({t, ability, turnOffTag, turnOnTag, repository, showTagsFor, showTagsForList, searchTag}) {
	const [fields, setFields] = useState({"tag": ""});
	
	function handleTagSearchChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setFields({
			...fields,
			[event.target.id]: value
		});
	}
	
	return (
		<div className="mt-5">
			<Form onSubmit={(event) => {
				event.preventDefault();
			}}>
				<Form.Group>
					<Form.Row className="mb-2">
						<Form.Label column="xs" xs={2}>{t("soajs:buttons.Search")}</Form.Label>
						<Col xs={7}>
							<Form.Control
								id="tag"
								size="sm"
								autoFocus
								value={fields.tag}
								onChange={handleTagSearchChange}
							/>
						</Col>
						<Col xs={3}>
							<Button className="ml-3" variant="dark" size="sm"
							        onClick={() => {
								        searchTag(fields.tag);
							        }}
							>
								<BiSearchAlt/>
							</Button>
						</Col>
					</Form.Row>
				</Form.Group>
			</Form>
			<hr/>
			{(showTagsFor && showTagsFor === repository) &&
			<CardDeck>
				{showTagsForList && showTagsForList.map((_O, _I) => (
					_O.active ?
						<Card key={_I} className="mb-3" border="success"
						      style={{"minWidth": '21rem', "maxWidth": '21rem'}}>
							<Card.Body>
								<small>
									{_O.name}
								</small>
								{ability.can('repo_tag', 'turnOff') &&
								<TurnOffButton
									className="float-right ml-3"
									onClick={() => {
										turnOffTag(_O.name);
									}}/>
								}
							</Card.Body>
						</Card>
						:
						<Card key={_I} className="mb-3" border="dark"
						      style={{"minWidth": '21rem', "maxWidth": '21rem'}}>
							<Card.Body>
								<small>
									{_O.name}
								</small>
								{ability.can('repo_tag', 'turnOn') &&
								<TurnOnButton
									className="float-right ml-3"
									onClick={() => {
										turnOnTag(_O.name);
									}}
								/>}
							</Card.Body>
						</Card>
				))}
			</CardDeck>
			}
		</div>
	);
}

function CustomToggle({children, eventKey, handleClick}) {
	const decoratedOnClick = useAccordionToggle(eventKey, () => {
		handleClick();
	});
	return (
		<div className="float-left" type="button" onClick={decoratedOnClick}>
			{children}
		</div>
	);
}

function OnMsgModal({t, onModalOpt, setOnModalOpt}) {
	const handleClose = () => setOnModalOpt({"show": false, opts: null});
	return (
		<Modal size="lg" show={onModalOpt.show} onHide={handleClose} animation={false}>
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:development.repositories.turnOnTitle")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{t("soajs:development.repositories.turnOnMsg")}
				<hr/>
				{onModalOpt.opts &&
				<Table responsive striped hover size="sm">
					<thead className="text-light bg-dark">
					<tr>
						<th>{t("soajs:development.repositories.name")}</th>
						<th>{t("soajs:development.repositories.type")}</th>
						<th>{t("soajs:development.repositories.swagger")}</th>
						<th></th>
					</tr>
					</thead>
					<tbody>
					{onModalOpt.opts && onModalOpt.opts.map((res, index) => (
						<tr key={index}>
							<td>
								{res.name}
							</td>
							<td className="small">
								{res.type}
							</td>
							<td className="small">
								{res.swagger ? "true" : "false"}
							</td>
							<td className="small text-danger">
								{res.error}
							</td>
						</tr>
					))}
					</tbody>
				</Table>
				}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="info" onClick={handleClose}>
					{t("soajs:buttons.Ok")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

async function onLoad(setFields, setPagination, currentPage, isSubscribed, criteria) {
	try {
		let c = {"limit": 50, "skip": (currentPage - 1) * 50};
		if (criteria) {
			if (criteria.textSearch && criteria.textSearch !== "") {
				c.textSearch = criteria.textSearch;
			}
			if (criteria.active) {
				c.active = true;
			}
			if (criteria.leaf) {
				c.leaf = true;
			}
		}
		const repositories = await repositoriesService.getRepositories(c);
		if (isSubscribed) {
			if (repositories.repositories) {
				setFields(repositories.repositories);
				setPagination(
					{
						"totalItems": repositories.count,
						"maxSize": 10,
						"itemsPerPage": repositories.limit
					}
				);
			}
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function Repositories() {
	const {t} = useTranslation(["common", "soajs"]);
	const {ability} = useAppContext();
	const [fields, setFields] = useState([]);
	const [showTagsFor, setShowTagsFor] = useState(null);
	const [showTagsForList, setShowTagsForList] = useState(null);
	const [activeKey, setActiveKey] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [criteria, setCriteria] = useState({
		"textSearch": "", "active": false, "leaf": false
	});
	const [pagination, setPagination] = useState({
		"totalItems": 1, "maxSize": 1, "itemsPerPage": 1
	});
	
	async function handleSearch(event) {
		event.preventDefault();
		setCurrentPage(1);
		await onLoad(setFields, setPagination, 1, true, criteria);
	}
	
	function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}
	
	async function reLoad(page) {
		await onLoad(setFields, setPagination, page || currentPage, true, criteria);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		onLoad(setFields, setPagination, 1, isSubscribed, null);
		return () => (isSubscribed = false);
	}, []);
	
	async function turnOnTag(item, tag) {
		try {
			const res = await repositoriesService.turnOnTag({
				"id": item._id,
				// "owner": item.owner,
				"owner": item.source[0].name,
				"provider": item.provider,
				"tag": tag
			});
			showOnMsgModal(res);
			await reLoad();
			if (!item.tags) {
				item.tags = [];
			}
			item.tags.push({"name": tag, "active": true})
			await onTabSelect(item);
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function turnOffTag(item, tag) {
		try {
			await repositoriesService.turnOffTag({
				"id": item._id,
				// "owner": item.owner,
				"owner": item.source[0].name,
				"provider": item.provider,
				"tag": tag
			});
			NotificationManager.success(t("soajs:development.repositories.turnOffTagMsg"));
			await reLoad();
			if (!item.tags) {
				item.tags = [];
			}
			_.pullAllBy(item.tags, [{"name": tag}], 'name');
			await onTabSelect(item);
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function turnOnBranch(item, branch) {
		try {
			const res = await repositoriesService.turnOnBranch({
				"id": item._id,
				// "owner": item.owner,
				"owner": item.source[0].name,
				"provider": item.provider,
				"branch": branch
			});
			showOnMsgModal(res);
			await reLoad();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function turnOffBranch(item, branch) {
		try {
			await repositoriesService.turnOffBranch({
				"id": item._id,
				// "owner": item.owner,
				"owner": item.source[0].name,
				"provider": item.provider,
				"branch": branch
			});
			NotificationManager.success(t("soajs:development.repositories.turnOffBranchMsg"));
			await reLoad();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function syncBranch(item, branch) {
		try {
			await repositoriesService.syncBranch({
				"id": item._id,
				// "owner": item.owner,
				"owner": item.source[0].name,
				"provider": item.provider,
				"branch": branch
			});
			NotificationManager.success(t("soajs:development.repositories.syncMsg"));
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function syncRepository(item) {
		try {
			await repositoriesService.syncRepository({
				"id": item._id,
				// "owner": item.owner,
				"owner": item.source[0].name,
				"provider": item.provider
			});
			NotificationManager.success(t("soajs:development.repositories.syncMsg"));
			await reLoad();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function turnOffRepository(item) {
		try {
			await repositoriesService.turnOffRepository({
				"id": item._id,
				// "owner": item.owner,
				"owner": item.source[0].name,
				"provider": item.provider
			});
			NotificationManager.success(t("soajs:development.repositories.turnOffRepo"));
			await reLoad();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function turnOnRepository(item) {
		try {
			await repositoriesService.turnOnRepository({
				"id": item._id,
				// "owner": item.owner,
				"owner": item.source[0].name,
				"provider": item.provider
			});
			NotificationManager.success(t("soajs:development.repositories.turnOnRepo"));
			await reLoad();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function deleteRepository(item) {
		try {
			await repositoriesService.deleteRepository({
				"id": item._id
			});
			NotificationManager.success(t("soajs:development.repositories.deleteRepo"));
			await reLoad();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	const [onModalOpt, setOnModalOpt] = useState({"show": false, opts: null});
	
	function showOnMsgModal(res) {
		if (res && !Array.isArray(res)) {
			res = [res];
		}
		setOnModalOpt({"show": true, opts: res});
	}
	
	async function searchTag(item, tag) {
		try {
			const res = await repositoriesService.getTag({
				"id": item._id,
				"tag": tag
			});
			let tagList = [];
			if (res && res.name) {
				if (item.tags && Array.isArray(item.tags) && item.tags.length > 0) {
					let index = _.findIndex(item.tags, ['name', res.name]);
					if (index !== -1) {
						tagList = [item.tags[index]];
					} else {
						tagList = [res];
					}
				} else {
					tagList = [res];
				}
			}
			setShowTagsForList(tagList);
			setShowTagsFor(item.repository);
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function onTabSelect(item) {
		try {
			const res = await repositoriesService.getTags({
				"id": item._id,
				"count": 10
			});
			let tagList = [];
			if (res && res.tags && Array.isArray(res.tags) && res.tags.length > 0) {
				if (item.tags && Array.isArray(item.tags) && item.tags.length > 0) {
					_.pullAllBy(res.tags, item.tags, 'name');
					tagList = _.concat(item.tags, res.tags);
				} else {
					tagList = res.tags;
				}
			}
			setShowTagsForList(tagList);
			setShowTagsFor(item.repository);
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	return (
		<>
			<OnMsgModal
				t={t}
				onModalOpt={onModalOpt}
				setOnModalOpt={setOnModalOpt}
			/>
			<Jumbotron className="p-3">
				<Form onSubmit={handleSearch}>
					<CardDeck>
						<Card>
							<Card.Body>
								<Form.Row className="mb-2">
									<Form.Label>{t("soajs:buttons.Search")}</Form.Label>
									<Form.Control
										id="textSearch"
										size="sm"
										autoFocus
										value={criteria.textSearch}
										onChange={handleCriteriaChange}
									/>
								</Form.Row>
							</Card.Body>
						</Card>
						<Card>
							<Card.Body>
								<ListGroup variant="flush">
									<ListGroupItem>
										<Form.Check
											id="active"
											className="small"
											label="Only Activated Repositories"
											defaultChecked={criteria.active}
											onChange={handleCriteriaChange}
										/>
									</ListGroupItem>
									<ListGroupItem>
										<Form.Check
											id="leaf"
											className="small"
											label="Only Leaf Repositories"
											defaultChecked={criteria.leaf}
											onChange={handleCriteriaChange}
										/>
									</ListGroupItem>
								</ListGroup>
							</Card.Body>
						</Card>
					</CardDeck>
					<Button className="mt-3 float-right" variant="dark" size="sm" onClick={handleSearch}>
						<BiSearchAlt/> {t("soajs:buttons.Search")}
					</Button>
					<span className="clearfix"></span>
				</Form>
			</Jumbotron>
			<Accordion activeKey={activeKey}>
				{fields.map((item, index) => (
					<Card key={index + 1}>
						<Card.Header>
							<CustomToggle
								as={Card.Header}
								eventKey={index + 1}
								handleClick={() => {
									if (activeKey === (index + 1)) {
										setActiveKey(null);
									} else {
										setActiveKey(index + 1);
									}
								}}
							>
								{activeKey === (index + 1) ? <FcExpand className="mr-1"/> :
									<FcCollapse className="mr-1"/>} <span
								className="font-weight-bold">{item.repository}</span>
							</CustomToggle>
							{(!item.source || item.source.length < 1) ?
								<>
									{ability.can('repo', 'delete') &&
									<DeleteButton
										className="float-right ml-3"
										onClick={() => {
											deleteRepository(item);
										}}
									/>
									}
								</>
								:
								<>
									{item.active ?
										<>
											
											{ability.can('repo', 'turnOff') &&
											<TurnOffButton
												className="float-right ml-3"
												onClick={() => {
													turnOffRepository(item);
												}}/>
											}
											
											{ability.can('repo', 'synchronize') &&
											<SynchroniseButton
												className="float-right ml-3"
												onClick={() => {
													syncRepository(item);
												}}/>
											}
										</>
										:
										<>
											{ability.can('repo', 'turnOn') &&
											<TurnOnButton
												className="float-right ml-3"
												onClick={() => {
													turnOnRepository(item);
												}}
											/>
											}
										</>
									}
								</>
							}
						</Card.Header>
						<Accordion.Collapse eventKey={index + 1}>
							<Card.Body>
								{item.active &&
								<>
									<Tabs defaultActiveKey="branch" id="repos"
									      style={{borderBottom: "1px solid #dee2e6"}}
									      onSelect={(T) => {
										      if (T === "tag") {
											      onTabSelect(item);
										      }
									      }}
									>
										<Tab eventKey="branch" title={t("soajs:development.repositories.Branches")}>
											
											<Branches
												branches={item.branches}
												t={t}
												ability={ability}
												synBranch={(branch) => {
													syncBranch(item, branch);
												}}
												turnOffBranch={(branch) => {
													turnOffBranch(item, branch);
												}}
												turnOnBranch={(branch) => {
													turnOnBranch(item, branch);
												}}
											/>
										</Tab>
										<Tab eventKey="tag" title={t("soajs:development.repositories.Tags")}>
											<Tags
												t={t}
												ability={ability}
												turnOffTag={(tag) => {
													turnOffTag(item, tag);
												}}
												turnOnTag={(tag) => {
													turnOnTag(item, tag);
												}}
												repository={item.repository}
												showTagsFor={showTagsFor}
												showTagsForList={showTagsForList}
												searchTag={(tag) => {
													searchTag(item, tag)
												}}
											/>
										</Tab>
									</Tabs>
								</>
								}
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				))}
				{fields.length === 0 ? (
					<span>{t("soajs:development.repositories.zeroMsg")}</span>
				) : null}
			</Accordion>
			{fields.length > 0 ? (
				<>
					<div className="float-right mt-3">
						<AutoPagination
							currentPage={currentPage}
							totalItems={pagination.totalItems}
							itemsPerPage={pagination.itemsPerPage}
							maxSize={pagination.maxSize}
							onClick={(p) => {
								setCurrentPage(p);
								reLoad(p);
							}}
						/>
					</div>
					<div className="clearfix"></div>
				</>
			) : null}
		</>
	);
}