import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {useLocation, Link} from "react-router-dom";
import {LoaderButton} from "../../helpers";
import {useFormFields} from "../../libs/hooksLib";

import {NotificationManager} from '../components';
import {UracService} from "../services";
import {useTranslation} from "react-i18next";

const uracService = UracService.getService();

export default function ValidateSignup() {
	const {t} = useTranslation(["common", "urac"]);
	const [validating, setValidating] = useState(null);
	const [fields, handleFieldChange] = useFormFields({
		username: ""
	});
	const [isLoading, setIsLoading] = useState(false);
	
	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}
	
	let query = useQuery();
	const token = query.get("token");
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			let valid = false;
			try {
				if (token) {
					valid = await uracService.validateJoin(token);
				} else {
					NotificationManager.warning(t("urac:msg.validateSignupWarning"));
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
			if (isSubscribed) {
				setValidating({
					success: valid
				});
			}
		}
		
		onLoad();
		return () => isSubscribed = false;
	}, [token, t]);
	
	function validateForm() {
		return (
			fields.username.length > 0
		);
	}
	
	async function handleSubmit(event) {
		event.preventDefault();
		
		setIsLoading(true);
		
		try {
			await uracService.emailToken(fields.username);
			NotificationManager.success(t("urac:msg.validateSignupSuccess"));
		} catch (e) {
			NotificationManager.error(e.message);
		}
		
		setIsLoading(false);
	}
	
	function validatingForm() {
		return (
			<Form>
				<Form.Text className="pb-3" muted>
					{t("urac:msg.validateSignupProcess")}
				</Form.Text>
			</Form>
		);
	}
	
	function renderConfirmationForm() {
		return (
			<Form>
				<Form.Text className="pb-3" muted>
					{t("urac:msg.validateSignupConfirm")} <Link to="/sign-in">{t("common:ClickHere")}</Link>
				</Form.Text>
			</Form>
		);
	}
	
	function renderSendValidationForm() {
		return (
			<Form onSubmit={handleSubmit}>
				<Form.Group controlId="username">
					<Form.Label>Username or Email</Form.Label>
					<Form.Control
						autoFocus
						value={fields.username}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Form.Text className="pb-3" muted>
					{t("urac:msg.validateSignupSuccess2")}
				</Form.Text>
				<LoaderButton
					block
					type="submit"
					isLoading={isLoading}
					disabled={!validateForm()}
				>
					{t("urac:button.Request")}
				</LoaderButton>
			</Form>
		);
	}
	
	return (
		<div className="URAC">
			{validating === null ? validatingForm() : (validating.success ? renderConfirmationForm() : renderSendValidationForm())}
		</div>
	);
}