import {Button, Col, Form, Jumbotron, Row, Badge} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {BiSearchAlt} from "react-icons/bi";
import {useTranslation} from "react-i18next";

import {NotificationManager} from "../../../../../soajs/urac/components";
import {InfraService} from "../../../../../services";

import NodesContainer from "../../catalog/common/container/Nodes";

const infraService = InfraService.getService();
const initCriteria = {
	"name": "", "mode": "Node", "continue": null, "remainingItemCount": 0
};

async function onLoad(setFields, criteria, env, setMode, setCriteria, isSubscribed) {
	try {
		let data = {"mode": criteria.mode, limit: 100}
		if (criteria.name && criteria.name.length > 0) {
			data.filter = {"fieldSelector": "metadata.name=" + criteria.name};
		}
		const response = await infraService.getClusters(env, data);
		if (isSubscribed && response && response.items) {
			setFields(response.items)
			setMode(criteria.mode);
			if (response.metadata && response.metadata.continue) {
				criteria.continue = response.metadata.continue;
				criteria.remainingItemCount = response.metadata.remainingItemCount;
				setCriteria({...criteria});
			} else {
				criteria.continue = null;
				criteria.remainingItemCount = 0;
				setCriteria({...criteria});
			}
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function Nodes({environment}) {
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState([]);
	const [mode, setMode] = useState("Pod");
	const [criteria, setCriteria] = useState(initCriteria);
	
	function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}
	
	async function reLoad(isSubscribed) {
		await onLoad(setFields, criteria, environment.code, setMode, setCriteria, isSubscribed);
	}
	
	async function loadMore() {
		try {
			let data = {"mode": criteria.mode, limit: 3}
			if (criteria.name && criteria.name.length > 0) {
				data.filter = {"fieldSelector": "metadata.name=" + criteria.name};
			}
			if (criteria.continue) {
				data.continue = criteria.continue;
			}
			const response = await infraService.getClusters(environment.code, data);
			if (response && response.items) {
				
				setFields(fields.concat(response.items));
				if (response.metadata && response.metadata.continue) {
					criteria.continue = response.metadata.continue;
					criteria.remainingItemCount = response.metadata.remainingItemCount;
					setCriteria({...criteria});
				} else {
					criteria.continue = null;
					criteria.remainingItemCount = 0;
					setCriteria({...criteria});
				}
			}
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function handleSearch(event) {
		event.preventDefault();
		await reLoad(true);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		if (isSubscribed) {
			setCriteria(initCriteria);
			onLoad(setFields, initCriteria, environment.code, setMode, setCriteria, isSubscribed).catch();
		}
		return () => (isSubscribed = false);
	}, [environment]);
	
	return (
		<>
			<Jumbotron className="p-3 m-0">
				<Row>
					<Col md={2} lg={2}>
						<h5 className="font-weight-bold m-0">{t("soajs:titles.nodes")}</h5>
					</Col>
					<Col md={3} lg={2}>
						<Form.Control
							style={{
								"fontSize": "80%"
							}}
							as="select"
							id="mode"
							value={criteria.mode}
							onChange={handleCriteriaChange}
						>
							<option value="Node">Node</option>
						</Form.Control>
					</Col>
					<Col>
						<Form onSubmit={handleSearch}>
							<Row>
								<Col xs={8} lg={9}>
									<Form.Control
										id="name"
										size="sm"
										autoFocus
										value={criteria.name}
										onChange={handleCriteriaChange}
									/>
								</Col>
								<Col xs={4} lg={3}>
									<Button className="float-right" variant="dark" size="sm" onClick={handleSearch}>
										<BiSearchAlt/> {t("soajs:buttons.Search")}
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			</Jumbotron>
			<hr/>
			{fields.length > 0 && mode === "Node" &&
			<NodesContainer
				items={fields}
				env={environment.code.toLowerCase()}
			/>
			}
			{criteria.continue &&
			<Button
				size="sm"
				className="mt-3"
				block variant="light"
				onClick={loadMore}
			>
				<Badge variant="light" className="text-muted">({criteria.remainingItemCount} remaining)</Badge> Click here to load more
				...
			</Button>
			}
		</>
	);
}