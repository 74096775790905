import React from "react";
import {useTranslation} from "react-i18next";

export default function NoAccess() {
	const {t} = useTranslation("common");
	return (
		<div className="container mt-3">
			<h3>{t("NoAccess")}</h3>
		</div>
	);
}