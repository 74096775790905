import React from 'react'
import {Switch, Route} from "react-router-dom";

import {AuthenticatedRoute, UnauthenticatedRoute} from "./soajs/helpers";
import {
	ChangeEmail,
	ChangePassword,
	ForgotPassword,
	Login,
	MyProfile,
	ResetPassword,
	SignUp,
	ValidateChangeEmail,
	ValidateSignup,
	ThirdPartyLogin
} from "./soajs/urac/containers"

import Home from "./components/Home/Home";
import NoAccess from "./components/NoAccess/NoAccess";
import NotFound from "./components/NotFound/NotFound";

import Reports from "./components/Reports/Reports";
import Catalogs from "./components/Catalogs/Catalogs";
import Settings from "./components/Settings/Settings";
import Development from "./components/Development/Development";
import Environments from "./components/Environments/Environments";
import Multitenancy from "./components/Multitenancy/Multitenancy";

export default function Routes() {
	return (
		<Switch>
			<UnauthenticatedRoute exact path='/'>
				<Login/>
			</UnauthenticatedRoute>
			<UnauthenticatedRoute path="/sign-in">
				<Login/>
			</UnauthenticatedRoute>
			<UnauthenticatedRoute path="/sign-up">
				<SignUp/>
			</UnauthenticatedRoute>
			<UnauthenticatedRoute path="/validate-signup">
				<ValidateSignup/>
			</UnauthenticatedRoute>
			<UnauthenticatedRoute path="/forgot-password">
				<ForgotPassword/>
			</UnauthenticatedRoute>
			<UnauthenticatedRoute path="/reset-password">
				<ResetPassword/>
			</UnauthenticatedRoute>
			<UnauthenticatedRoute path="/third-party-login/:strategy">
				<ThirdPartyLogin/>
			</UnauthenticatedRoute>
			
			
			<AuthenticatedRoute path="/change-password">
				<ChangePassword/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path="/change-email">
				<ChangeEmail/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path="/validate-change-email">
				<ValidateChangeEmail/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path="/myProfile">
				<MyProfile/>
			</AuthenticatedRoute>
			
			
			<AuthenticatedRoute path="/home">
				<Home/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path="/no-access">
				<NoAccess/>
			</AuthenticatedRoute>
			
			
			<AuthenticatedRoute path="/development">
				<Development/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path="/catalogs">
				<Catalogs/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path="/environments/manage/:env">
				<Environments/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path="/environments">
				<Environments/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path="/multitenancy">
				<Multitenancy/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path="/reports">
				<Reports/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path="/settings">
				<Settings/>
			</AuthenticatedRoute>
			
			<Route>
				<NotFound/>
			</Route>
		</Switch>
	);
}