import {Alert, Col, Row} from "react-bootstrap";
import React from "react";

import MaintenanceOperation from "./MaintenanceOperation";

export default function ManualDetails({manualAwareness, version, itemName, itemType, env}) {
	
	return (
		<>
			{manualAwareness &&
			manualAwareness.version === version.version &&
			manualAwareness.healthy &&
			<>
				<hr/>
				<Row>
					<Col>
					
					</Col>
					<Col xs="auto">
						<MaintenanceOperation
							version={version}
							env={env}
							itemName={itemName}
							itemType={itemType}
						/>
					</Col>
				</Row>
				<hr/>
				<Alert variant="info p-1">
					{manualAwareness.host}
				</Alert>
			</>}
		</>
	);
}