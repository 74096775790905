import axios from "axios";
import LocalStorageService from "./LocalStorageService.js";

const localStorageService = LocalStorageService.getService();

const OauthService = (function () {
	let _service = null;
	
	function _getService() {
		if (!_service) {
			_service = this;
			return _service
		}
		return _service
	}
	
	function _login(email, password) {
		return axios.post("/oauth/token",
			{
				"username": email,
				"password": password,
				"grant_type": "password"
			})
			.then(res => {
				if (res && res.data && res.data.access_token) {
					localStorageService.setToken(res.data);
					localStorageService.setUsername(email);
					return Promise.resolve(res.data);
				} else {
					return Promise.reject(new Error("Unable to login"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to login"));
			});
	}
	
	function _logout() {
		const refresh_token = localStorageService.getRefreshToken();
		const access_token = localStorageService.getAccessToken();
		
		if (refresh_token) {
			return axios.delete("/oauth/refreshToken/" + refresh_token)
				.then(() => {
					if (access_token) {
						return axios.delete("/oauth/accessToken/" + access_token);
					}
				})
				.catch(() => {
					if (access_token) {
						return axios.delete("/oauth/accessToken/" + access_token);
					}
				});
		} else {
			if (access_token) {
				return axios.delete("/oauth/accessToken/" + access_token);
			}
		}
	}
	
	function _availableLogin() {
		return axios.get("/oauth/available/login", {
			"headers": {"skip_access_token": true}
		})
			.then(res => {
				if (res && res.data && res.data.result) {
					//res.data.data.thirdparty = ["facebook", "google", "twitter", "apple"];
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to retrieve 3rd party login!"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to retrieve 3rd party login!"));
			});
	}
	
	function _thirdPartyLogin(strategy) {
		return axios.get("/oauth/passport/login/" + strategy)
			.then(res => {
				if (res && res.data && res.data.result) {
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to continue with 3rd party login!"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to continue with 3rd party login!"));
			});
	}
	
	function _validateThirdPartyLogin(strategy, params) {
		return axios.get("/oauth/passport/validate/" + strategy + params)
			.then(res => {
				if (res && res.data && res.data.result) {
					if (res && res.data && res.data.data && res.data.data.access) {
						localStorageService.setToken(res.data.data.access);
						localStorageService.setUsername(res.data.data.email);
					}
					return Promise.resolve(res.data.data);
				} else {
					return Promise.reject(new Error("Unable to validate your " + strategy + " login!"));
				}
			})
			.catch(() => {
				return Promise.reject(new Error("Unable to validate your " + strategy + " login!"));
			});
	}
	
	return {
		getService: _getService,
		login: _login,
		logout: _logout,
		availableLogin: _availableLogin,
		thirdPartyLogin: _thirdPartyLogin,
		validateThirdPartyLogin: _validateThirdPartyLogin
	}
})();
export default OauthService;