import {Button, Modal} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";
import {NotificationManager} from "../../../../../soajs/urac/components";
import {MultitenantService} from "../../../../../services";

const multitenantService = MultitenantService.getService();

export default function DeleteModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, item: null});
	
	async function handleSubmit() {
		try {
			if (modalOpt.item.type === "tenant") {
				await multitenantService.deleteTenant({
					"id": modalOpt.item.id
				});
				NotificationManager.success(t("soajs:messages.delete_" + modalOpt.item.type));
				handleAction();
				handleClose();
			}
			
			if (modalOpt.item.type === "application") {
				await multitenantService.deleteTenantApplication({
					"id": modalOpt.item.id,
					"appId": modalOpt.item.appId
				});
				NotificationManager.success(t("soajs:messages.delete_" + modalOpt.item.type));
				handleAction();
				handleClose();
			}
			
			if (modalOpt.item.type === "key") {
				await multitenantService.deleteTenantApplicationKey({
					"id": modalOpt.item.id,
					"appId": modalOpt.item.appId,
					"key": modalOpt.item.key
				});
				NotificationManager.success(t("soajs:messages.delete_" + modalOpt.item.type));
				handleAction();
				handleClose();
			}
			
			if (modalOpt.item.type === "extKey") {
				await multitenantService.deleteTenantApplicationKeyExt({
					"id": modalOpt.item.id,
					"appId": modalOpt.item.appId,
					"key": modalOpt.item.key,
					"extKey": modalOpt.item.extKey
				});
				NotificationManager.success(t("soajs:messages.delete_" + modalOpt.item.type));
				handleAction();
				handleClose();
			}
		} catch
			(e) {
			NotificationManager.error(e.message);
		}
	}
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:buttons.Delete")}</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{
				"wordBreak": "break-all"}}>
				{t("soajs:messages.deleteQuestion")}<span
				className="ml-3 font-weight-bold">{modalOpt.item ? modalOpt.item.type : ""}: [<span
				className="text-danger">{modalOpt.item ? modalOpt.item.label : ""}</span>]</span>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button variant="danger" onClick={handleSubmit}>
					{t("soajs:buttons.Delete")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}