import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";

import {useAppContext} from "../../libs/contextLib";
import {LoaderButton} from "../../helpers";

import {JsonEditor, NotificationManager} from '../components';
import {LocalStorageService, UracService} from "../services";
import {useTranslation} from "react-i18next";

const localStorageService = LocalStorageService.getService();
const uracService = UracService.getService();

export default function MyProfile() {
	const {t} = useTranslation(["common", "urac"]);
	const {setFirstName} = useAppContext();
	const [isLoading, setIsLoading] = useState(false);
	const [fields, setFields] = useState({
		firstName: "",
		lastName: "",
		email: "",
		username: "",
		profile: {}
	});
	let profile = null;
	
	function handleFieldChange(event) {
		setFields({
			...fields,
			[event.target.id]: event.target.value
		});
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				const user = await uracService.getUser();
				if (isSubscribed) {
					setFields({
						firstName: user.firstName,
						lastName: user.lastName,
						email: user.email,
						username: user.username,
						profile: user.profile
					});
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad();
		return () => (isSubscribed = false);
	}, []);
	
	function validateForm() {
		return (
			fields.firstName.length > 0 &&
			fields.lastName.length > 0
		);
	}
	
	async function handleSubmit(event) {
		event.preventDefault();
		
		setIsLoading(true);
		if (fields.profile && !profile) {
			profile = fields.profile;
		}
		try {
			const res = await uracService.updateMyProfile(fields, profile);
			setFirstName(localStorageService.getFirstname());
			NotificationManager.success(res);
			setFields({
				...fields,
				"profile": profile
			});
		} catch (e) {
			NotificationManager.error(e.message);
		}
		
		setIsLoading(false);
	}
	
	function JsonEditor_onChange(json) {
		if (json) {
			profile = json;
		}
	}
	
	return (
		<div className="URAC">
			<Form onSubmit={handleSubmit}>
				<Form.Group size="lg" controlId="firstName">
					<Form.Label>{t("urac:First name")}</Form.Label>
					<Form.Control
						autoFocus
						value={fields.firstName}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Form.Group size="lg" controlId="lastName">
					<Form.Label>{t("urac:Last name")}</Form.Label>
					<Form.Control
						value={fields.lastName}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Form.Group size="lg" controlId="email">
					<Form.Label>{t("urac:Email")}</Form.Label>
					<Form.Control
						type="email"
						readOnly
						value={fields.email}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Form.Group size="lg" controlId="username">
					<Form.Label>{t("urac:Username")}</Form.Label>
					<Form.Control
						value={fields.username}
						readOnly
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<JsonEditor
					mode='code'
					json={profile || fields.profile}
					onChange={JsonEditor_onChange}
				/>
				<LoaderButton
					block
					size="lg"
					type="submit"
					isLoading={isLoading}
					disabled={!validateForm()}
				>
					{t("urac:button.Change")}
				</LoaderButton>
			</Form>
		</div>
	);
}