import {Tab, Tabs, Accordion, Card, Button} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";

import Granular from "./Granular"
import Scope from "./Scope"

import APIs from "../../../../../../availableAbilities";

import config from "../../../../../../config";
import AbilitiesLabels from "../../lib/AbilitiesLabels.json";

export default function InfoModal({modalOpt}) {
	const {t} = useTranslation(["common", "soajs"]);
	
	
	return (
		modalOpt.opts &&
		<Tabs className="mb-3" defaultActiveKey="main" id="abilities">
			<Tab eventKey="main" title={t("soajs:titles.MainAbilities")}>
				{modalOpt.opts.acl && Object.keys(modalOpt.opts.acl).map((env, index) => (
					env === config.consoleEnvironment &&
					<div key={index}>
						{Object.keys(APIs.abilities).map((service, index) => (
							<div key={index}>
								<Accordion defaultActiveKey="0">
									<Card>
										<Card.Header className="p-1">
											<Accordion.Toggle as={Button} variant="link" eventKey="0">
												{AbilitiesLabels[service]}
											</Accordion.Toggle>
										</Card.Header>
										<Accordion.Collapse eventKey="0">
											<Card.Body>
												
												{((!modalOpt.opts.acl[env][service]) || (modalOpt.opts.aclTypeByEnv && modalOpt.opts.aclTypeByEnv[env] && modalOpt.opts.aclTypeByEnv[env] === "granular")) ?
													<Granular
														service={modalOpt.opts.acl[env][service]}
														name={service}
														abilityPath={"abilities"}
													/>
													:
													<Scope
														service={modalOpt.opts.acl[env][service]}
														scope={modalOpt.scope.acl[env][service]}
														name={service}
														abilityPath={"abilities"}
													/>
												}
											
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
						))}
					</div>
				))}
			</Tab>
			<Tab eventKey="default" title={t("soajs:titles.DefaultAbilities")}>
				{modalOpt.opts.acl && Object.keys(modalOpt.opts.acl).map((env, index) => (
					env === config.consoleEnvironment &&
					<div key={index}>
						{Object.keys(APIs.default).map((service, index) => (
							<div key={index}>
								<Accordion defaultActiveKey="0">
									<Card>
										<Card.Header className="p-1">
											<Accordion.Toggle as={Button} variant="link" eventKey="0">
												{AbilitiesLabels[service]}
											</Accordion.Toggle>
										</Card.Header>
										<Accordion.Collapse eventKey="0">
											<Card.Body>
												
												{((!modalOpt.opts.acl[env][service]) || (modalOpt.opts.aclTypeByEnv && modalOpt.opts.aclTypeByEnv[env] && modalOpt.opts.aclTypeByEnv[env] === "granular")) ?
													<Granular
														service={modalOpt.opts.acl[env][service]}
														name={service}
														abilityPath={"default"}
													/>
													:
													<Scope
														service={modalOpt.opts.acl[env][service]}
														scope={modalOpt.scope.acl[env][service]}
														name={service}
														abilityPath={"default"}
													/>
												}
											
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
						))}
					</div>
				))}
			</Tab>
		</Tabs>
	);
}