import Login from "./Login.js";
import ForgotPassword from "./ForgotPassword.js";
import ResetPassword from "./ResetPassword.js";
import ChangePassword from "./ChangePassword.js";
import ChangeEmail from "./ChangeEmail.js";
import ValidateChangeEmail from "./ValidateChangeEmail.js";
import MyProfile from "./MyProfile.js";
import SignUp from "./Signup.js";
import ValidateSignup from "./ValidateSignup.js";
import ThirdPartyLogin from "./ThirdPartyLogin.js";

export {
	Login,
	ForgotPassword,
	ResetPassword,
	ChangePassword,
	ChangeEmail,
	ValidateChangeEmail,
	MyProfile,
	SignUp,
	ValidateSignup,
	ThirdPartyLogin
};
export default Login;
