import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {AiFillCloseSquare} from "react-icons/ai";
import React from "react";
import {useTranslation} from "react-i18next";

export default function Close({...props}) {
	const {t} = useTranslation(["common", "soajs"]);
	return (
		<OverlayTrigger
			placement="top"
			overlay={
				<Tooltip id={`tooltip-top}`}>
					{t("soajs:buttons.Close")}
				</Tooltip>
			}
		>
			<Button variant="danger" size="sm" {...props}>
				<AiFillCloseSquare/>
			</Button>
		</OverlayTrigger>
	);
}