import {Button, Form, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {GoSync} from "react-icons/go";

import deepSet from "../../../../../../soajs/libs/deepSetLib";
import {MarketplaceService, RepositoriesService} from "../../../../../../services";
import {NotificationManager} from "../../../../../../soajs/urac/components";

import getTemplate, {getSourcesFromConfiguration, getSourcesFromContainer} from "./getTemplate";

const marketplaceService = MarketplaceService.getService();
const repositoriesService = RepositoriesService.getService();


async function getLastCommit(modalOpt, branch) {
	let data = {
		"repo": modalOpt.item.itemSrc.repo,
		"owner": modalOpt.item.itemSrc.owner,
		"provider": modalOpt.item.itemSrc.provider,
		"branch": branch
	}
	try {
		let response = await repositoriesService.getGitBranch(data);
		if (response && response.commit) {
			return Promise.resolve(response.commit);
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
	return Promise.resolve(null);
}

export default function RedeployModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState({
		"srcType": "",
		"branch": "",
		"commit": "",
		"tag": "",
		"imageTag": "",
		"config": {},
		"inspect": {}
	});
	const handleClose = () => setModalOpt({"show": false, "item": null, "title": ""});
	
	function validateForm() {
		let valid = true;
		if (fields.srcType === "branch") {
			valid = fields.branch.length > 0 && fields.commit.length > 0;
		}
		if (fields.srcType === "tag") {
			valid = fields.tag.length > 0;
		}
		return (
			fields.imageTag.length > 0 &&
			valid
		)
	}
	
	async function get_setLastCommit() {
		let commit = await getLastCommit(modalOpt, fields.branch);
		if (commit) {
			fields.commit = commit;
			setFields({...fields});
		}
	}
	
	async function reDeploy() {
		try {
			let data = {
				"name": modalOpt.item.name,
				"type": modalOpt.item.type,
				"env": modalOpt.item.env,
				"version": modalOpt.item.version,
				"branch": (fields.branch.length > 0 ? fields.branch : null),
				"commit": (fields.commit.length > 0 ? fields.commit : null),
				"tag": (fields.tag.length > 0 ? fields.tag : null),
				"imageTag": (fields.imageTag.length > 0 ? fields.imageTag : null),
			};
			await marketplaceService.redeploy(data);
			NotificationManager.success(t("soajs:messages.redeploySuccess"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		if (event.target.type === 'number') {
			value = parseInt(value);
		}
		
		deepSet(fields, event.target.id, value);
		setFields({...fields});
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad(data, inspectInfo, configInfo) {
			if (inspectInfo.branch || configInfo.branch) {
				let commit = await getLastCommit(modalOpt, inspectInfo.branch || configInfo.branch);
				data.commit = commit;
			}
			if (isSubscribed) {
				setFields(data);
			}
		}
		
		if (modalOpt && modalOpt.item) {
			let inspectInfo = {};
			if (modalOpt.item.inspect) {
				let template = getTemplate(modalOpt.item.inspect, modalOpt.item.kind);
				if (template) {
					inspectInfo = getSourcesFromContainer(template);
				}
			}
			let configInfo = {};
			if (modalOpt.item.deployConfiguration) {
				configInfo = getSourcesFromConfiguration(modalOpt.item.deployConfiguration);
			}
			let data = {
				"branch": inspectInfo.branch || configInfo.branch || "",
				"commit": inspectInfo.commit || configInfo.commit || "",
				"tag": inspectInfo.tag || configInfo.tag || "",
				"imageTag": inspectInfo.imageTag || configInfo.imageTag || "",
				"config": configInfo,
				"inspect": inspectInfo,
				"srcType": ""
			};
			if (inspectInfo.branch || configInfo.branch) {
				data.srcType = "branch";
			}
			if (inspectInfo.tag || configInfo.tag) {
				data.srcType = "tag";
			}
			onLoad(data, inspectInfo, configInfo).catch();
		}
		return () => (isSubscribed = false);
	}, [modalOpt]);
	
	return (
		modalOpt &&
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="xl">
			<Modal.Header closeButton className="bg-secondary text-white">
				<Modal.Title>Redeploy: {modalOpt.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{modalOpt.item &&
				<Form>
					{fields.srcType === "branch" &&
					<Form.Group>
						<Form.Label>{t("soajs:fields.Branch")}</Form.Label>
						
						<Form.Control
							as="select"
							id="branch"
							value={fields.branch}
							onChange={handleFieldChange}
						>
							{modalOpt.item && modalOpt.item.availableSrc && modalOpt.item.availableSrc.branches &&
							modalOpt.item.availableSrc.branches.map((rec, i) => (
								<option key={i} value={rec}>{rec}</option>
							))}
						</Form.Control>
						
						{fields.config.branch && fields.branch !== fields.config.branch &&
						<Form.Text className="text-danger">
							Mismatch with configuration: <b>{fields.config.branch}</b>
						</Form.Text>}
						{fields.inspect.branch && fields.branch !== fields.inspect.branch &&
						<Form.Text className="text-danger">
							Mismatch with deployment: <b>{fields.inspect.branch}</b>
						</Form.Text>}
					</Form.Group>}
					{fields.srcType === "branch" &&
					<Form.Group>
						<Form.Label>
							{t("soajs:fields.Commit")}
							<Button
								className="pt-0 pl-1 pr-1 ml-2"
								variant="info"
								onClick={get_setLastCommit}
							>
								<GoSync/>
							</Button>
						</Form.Label>
						<Form.Control
							id="commit"
							size="sm"
							value={fields.commit}
							onChange={handleFieldChange}
						/>
						{fields.config.commit && fields.commit !== fields.config.commit &&
						<Form.Text className="text-danger">
							Mismatch with configuration: <b>{fields.config.commit}</b>
						</Form.Text>}
						{fields.inspect.commit && fields.commit !== fields.inspect.commit &&
						<Form.Text className="text-danger">
							Mismatch with deployment: <b>{fields.inspect.commit}</b>
						</Form.Text>}
					</Form.Group>}
					{fields.srcType === "tag" &&
					<Form.Group>
						<Form.Label>{t("soajs:fields.Tag")}</Form.Label>
						
						<Form.Control
							as="select"
							id="tag"
							value={fields.tag}
							onChange={handleFieldChange}
						>
							{modalOpt.item && modalOpt.item.availableSrc && modalOpt.item.availableSrc.tags &&
							modalOpt.item.availableSrc.tags.map((rec, i) => (
								<option key={i} value={rec}>{rec}</option>
							))}
						</Form.Control>
						
						{fields.tag !== fields.config.tag &&
						<Form.Text className="text-danger">
							Mismatch with configuration: <b>{fields.config.tag}</b>
						</Form.Text>}
						{fields.tag !== fields.inspect.tag &&
						<Form.Text className="text-danger">
							Mismatch with deployment: <b>{fields.inspect.tag}</b>
						</Form.Text>}
					</Form.Group>}
					<Form.Group>
						<Form.Label>{t("soajs:fields.ImageTag")}</Form.Label>
						<Form.Control
							id="imageTag"
							size="sm"
							value={fields.imageTag}
							onChange={handleFieldChange}
						/>
						{fields.config.imageTag && fields.imageTag !== fields.config.imageTag &&
						<Form.Text className="text-danger">
							Mismatch with configuration: <b>{fields.config.imageTag}</b>
						</Form.Text>}
						{fields.inspect.imageTag && fields.imageTag !== fields.inspect.imageTag &&
						<Form.Text className="text-danger">
							Mismatch with deployment: <b>{fields.inspect.imageTag}</b>
						</Form.Text>}
					</Form.Group>
				</Form>
				}
			</Modal.Body>
			<Modal.Footer className="bg-light">
				
				{modalOpt.item &&
				<Button
					disabled={!validateForm()}
					variant="success"
					onClick={reDeploy}>
					{t("soajs:buttons.Redeploy")}
				</Button>
				}
				
				<Button variant="info" onClick={handleClose}>
					{t("soajs:buttons.Close")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}