import React, {Component} from 'react';

import _jsoneditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import './JsonEditor.css';

export default class JsonEditor extends Component {
	componentDidMount() {
		const options = {
			templates: this.props.templates,
			mode: this.props.mode,
			modes: this.props.modes,
			onChangeJSON: this.props.onChangeJSON,
			onChange: () => {
				if (this.props.onChange) {
					try {
						this.props.onChange(this.jeditor.get());
					} catch (e) {
						this.props.onChange(null);
					}
				}
			}
		};
		
		this.jeditor = new _jsoneditor (this.container, options);
		this.jeditor.set(this.props.json);
	}
	
	componentWillUnmount() {
		if (this.jeditor) {
			this.jeditor.destroy();
		}
	}
	
	componentDidUpdate() {
		this.jeditor.update(this.props.json);
	}
	
	render() {
		return (
			<div className="jsoneditor-react-container" ref={elem => this.container = elem}/>
		);
	}
}