import {Button, Modal} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";

export default function MetricsModal({modalOpt, setModalOpt}) {
	const {t} = useTranslation(["common", "soajs"]);
	
	const handleClose = () => setModalOpt({"show": false, "json": {}, "mode": "preview", "title": ""});
	
	return (
		modalOpt &&
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="xl">
			<Modal.Header closeButton className="bg-secondary text-white">
				<Modal.Title>{modalOpt.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				
				Coming soon!
			
			</Modal.Body>
			<Modal.Footer className="bg-light">
				<Button variant="info" onClick={handleClose}>
					{t("soajs:buttons.Ok")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}