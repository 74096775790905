import {useTranslation} from "react-i18next";
import React from "react";
import {
	Form,
	Jumbotron, Modal,
	Tab, Tabs
} from "react-bootstrap";


import ConfigureModalGroups from "./ConfigureModalGroups";
import ConfigureModalEnvironments from "./ConfigureModalEnvironments";
import ConfigureModalRecipes from "./ConfigureModalRecipes";

export default function ConfigureModal({modalOpt, setModalOpt, handleAfterSubmit}) {
	const {t} = useTranslation(["common", "soajs"]);
	
	const handleClose = () => setModalOpt({"show": false, item: null});
	
	return (
		<>
			{modalOpt.item &&
			<Modal size="xl" show={modalOpt.show} onHide={handleClose} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{t("soajs:catalogs.configure.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					
					<Jumbotron className="p-4">
						<span
							className="font-weight-bold">{modalOpt.item.name}</span> {(modalOpt.item.configuration && modalOpt.item.configuration.port) ? [modalOpt.item.configuration.port] : ""}
						- <small>{modalOpt.item.configuration.group}</small>
						<hr/>
						{modalOpt.item.description}
					</Jumbotron>
					<Form onSubmit={(event) => {
						event.preventDefault();
					}}>
						<Tabs className="mb-3" defaultActiveKey="acl" id="config">
							<Tab eventKey="acl" title={t("soajs:fields.Groups")}>
								<ConfigureModalGroups
									modalOpt={modalOpt}
									handleAction={handleAfterSubmit}
									handleClose={handleClose}
								/>
							</Tab>
							<Tab eventKey="environments" title={t("soajs:fields.Environments")}>
								<ConfigureModalEnvironments
									modalOpt={modalOpt}
									handleAction={handleAfterSubmit}
									handleClose={handleClose}
								/>
							</Tab>
							{(!modalOpt.settings || !modalOpt.settings.turnOffRecipesConfiguration) &&
							<Tab eventKey="recipes" title={t("soajs:fields.Recipes")}>
								<ConfigureModalRecipes
									modalOpt={modalOpt}
									handleAction={handleAfterSubmit}
									handleClose={handleClose}
								/>
							</Tab>}
						</Tabs>
					</Form>
				</Modal.Body>
				<Modal.Footer className="bg-info">
					<div className="small font-weight-bold text-white">
						{t("soajs:catalogs.configure.note")}
					</div>
				</Modal.Footer>
			</Modal>
			}
		</>
	);
}