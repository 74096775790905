import {Button, Card, Col, Form, Alert, Modal, Badge, Accordion, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {BiSearchAlt} from "react-icons/bi";

import _ from "lodash";

import {useAppContext} from "../../../../../../soajs/libs/contextLib";
import {JsonEditor, NotificationManager} from "../../../../../../soajs/urac/components";
import {MarketplaceService, RepositoriesService} from "../../../../../../services";
import deepSet from "../../../../../../soajs/libs/deepSetLib";
import AutoPagination from "../../../../../../lib/AutoPagination";

import getTemplate, {getSourcesFromConfiguration, getSourcesFromContainer} from "./getTemplate";
import _examples from "../../../../../Catalogs/containers/Recipe/model/examples.json";
import {GoSync} from "react-icons/go";

const marketplaceService = MarketplaceService.getService();
const repositoriesService = RepositoriesService.getService();

async function processAndAutoFill(modalOpt, recipeID, allowedRecipies, setFields, isSubscribed) {
	let inspectInfo = {};
	if (modalOpt.item.inspect) {
		let template = getTemplate(modalOpt.item.inspect, modalOpt.item.kind);
		if (template) {
			inspectInfo = getSourcesFromContainer(template);
		}
	}
	let configInfo = {};
	if (modalOpt.item.deployConfiguration) {
		configInfo = getSourcesFromConfiguration(modalOpt.item.deployConfiguration);
	}
	
	if (isSubscribed && recipeID) {
		let recipe = getRecipeFromAllowed(recipeID, allowedRecipies);
		if (recipe) {
			let repoId = "";
			let commit = inspectInfo.commit || configInfo.commit || "";
			try {
				if (modalOpt.item.itemSrc) {
					if (inspectInfo.branch || configInfo.branch) {
						let data = {
							"repo": modalOpt.item.itemSrc.repo,
							"owner": modalOpt.item.itemSrc.owner,
							"provider": modalOpt.item.itemSrc.provider,
							"branch": inspectInfo.branch || configInfo.branch
						}
						let response = await repositoriesService.getGitBranch(data);
						if (response && response.commit) {
							commit = response.commit;
						}
						if (response && response.repo && response.repo.id) {
							repoId = response.repo.id;
						}
					} else {
						let data = {
							"name": modalOpt.item.itemSrc.repo,
							"owner": [modalOpt.item.itemSrc.owner],
							"provider": [modalOpt.item.itemSrc.provider],
							"active": true
						}
						let response = await repositoriesService.getRepositories(data);
						if (response && response.repositories && response.repositories.length > 0) {
							repoId = response.repositories[0]._id;
						}
					}
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
			setFields(p => {
				p = _.merge({}, initFields);
				
				p.recipeId = recipeID;
				
				p.image.name = inspectInfo.imageName || configInfo.imageName || recipe.recipe.deployOptions.image.name || "";
				p.image.tag = inspectInfo.imageTag || configInfo.imageTag || recipe.recipe.deployOptions.image.tag || "";
				p.image.prefix = inspectInfo.imagePrefix || configInfo.imagePrefix || recipe.recipe.deployOptions.image.prefix || "";
				
				// p.image.name = recipe.recipe.deployOptions.image.name || configInfo.imageName || inspectInfo.imageName || "";
				// p.image.tag = recipe.recipe.deployOptions.image.tag || configInfo.imageTag || inspectInfo.imageTag || "";
				// p.image.prefix = recipe.recipe.deployOptions.image.prefix || configInfo.imagePrefix || inspectInfo.imagePrefix || "";
				p.image.override = recipe.recipe.deployOptions.image.override || p.image.override;
				p.image.repositoryType = recipe.recipe.deployOptions.image.repositoryType || p.image.repositoryType;
				p.image.registrySecret = configInfo.imageRegistrySecret || "";
				
				p.binary = !!recipe.recipe.deployOptions.image.binary;
				if (!p.binary && !modalOpt.item.itemSrc) {
					p.warning = "This item does not have a repository source and can only be deployed with recipes that do not require source. (binary only)";
				}
				if (!p.binary) {
					if (inspectInfo.branch || configInfo.branch) {
						p.srcType = "branch";
					}
					if (inspectInfo.tag || configInfo.tag) {
						p.srcType = "tag";
					}
					if (modalOpt.item.availableSrc) {
						p.branchOrTag = [];
						if (modalOpt.item.availableSrc.branches) {
							p.branchOrTag.push("branch");
						}
						if (modalOpt.item.availableSrc.tags) {
							p.branchOrTag.push("tag");
						}
						if (p.srcType === "") {
							p.srcType = p.branchOrTag[0];
						}
					}
					p.repoId = repoId;
					p.branch = inspectInfo.branch || configInfo.branch || (modalOpt.item.availableSrc && modalOpt.item.availableSrc.branches ? modalOpt.item.availableSrc.branches[0] || "" : "");
					p.commit = commit;
					p.tag = inspectInfo.tag || configInfo.tag || modalOpt.item.availableSrc.tags || "";
				}
				p.config = configInfo;
				p.inspect = inspectInfo;
				p.recipe = recipe.recipe.deployOptions.image || {};
				
				p.settings.mode = modalOpt.item.kind || p.settings.mode;
				p.readinessProbe = recipe.recipe.deployOptions.readinessProbe || p.readinessProbe;
				p.livenessProbe = recipe.recipe.deployOptions.livenessProbe || p.livenessProbe;
				
				if (modalOpt.item.deployConfiguration) {
					if (modalOpt.item.deployConfiguration.cd && modalOpt.item.deployConfiguration.cd.strategy) {
						p.cd.strategy = modalOpt.item.deployConfiguration.cd.strategy;
					}
					if (modalOpt.item.deployConfiguration.settings) {
						p.settings.memory = modalOpt.item.deployConfiguration.settings.memory || p.settings.memory;
						p.settings.mode = modalOpt.item.deployConfiguration.settings.mode || p.settings.mode;
						p.settings.replicas = modalOpt.item.deployConfiguration.settings.replicas || p.settings.replicas;
						p.settings.concurrencyPolicy = modalOpt.item.deployConfiguration.settings.concurrencyPolicy || p.settings.concurrencyPolicy;
						p.settings.schedule = modalOpt.item.deployConfiguration.settings.schedule || p.settings.schedule;
						p.settings.restartPolicy = modalOpt.item.deployConfiguration.settings.restartPolicy || p.settings.restartPolicy;
					}
					if (modalOpt.item.deployConfiguration.recipe) {
						p.readinessProbe = modalOpt.item.deployConfiguration.recipe.readinessProbe || p.readinessProbe;
						p.livenessProbe = modalOpt.item.deployConfiguration.recipe.livenessProbe || p.livenessProbe;
					}
				}
				if (recipe.recipe.deployOptions.ports) {
					if (modalOpt.item.deployConfiguration && modalOpt.item.deployConfiguration.recipe.ports) {
						if (modalOpt.item.deployConfiguration.recipe.ports.portType) {
							p.ports.portType = modalOpt.item.deployConfiguration.recipe.ports.portType;
						}
					}
					p.ports.ports = [];
					p.showPortType = false;
					for (let i = 0; i < recipe.recipe.deployOptions.ports.length; i++) {
						let port = {
							"name": recipe.recipe.deployOptions.ports[i].name,
							"target": recipe.recipe.deployOptions.ports[i].target,
							"isPublished": recipe.recipe.deployOptions.ports[i].isPublished,
							"published": 0
						};
						p.showPortType = (p.showPortType || port.isPublished);
						if (modalOpt.item.deployConfiguration &&
							modalOpt.item.deployConfiguration.recipe.ports &&
							modalOpt.item.deployConfiguration.recipe.ports.values) {
							for (let j = 0; j < modalOpt.item.deployConfiguration.recipe.ports.values.length; j++) {
								if (modalOpt.item.deployConfiguration.recipe.ports.values[j].name === port.name) {
									if (modalOpt.item.deployConfiguration.recipe.ports.values[j].published) {
										port.published = modalOpt.item.deployConfiguration.recipe.ports.values[j].published;
									}
								}
							}
						}
						p.ports.ports.push(port);
					}
				}
				if (recipe.recipe.buildOptions.env) {
					let envs = Object.keys(recipe.recipe.buildOptions.env);
					p.env = {...recipe.recipe.buildOptions.env};
					for (let i = 0; i < envs.length; i++) {
						if (recipe.recipe.buildOptions.env[envs[i]].type === "userInput") {
							if (modalOpt.item.deployConfiguration &&
								modalOpt.item.deployConfiguration.recipe &&
								modalOpt.item.deployConfiguration.recipe.env &&
								modalOpt.item.deployConfiguration.recipe.env.hasOwnProperty([envs[i]])) {
								p.env[envs[i]].value = modalOpt.item.deployConfiguration.recipe.env[envs[i]];
							}
						}
						if (recipe.recipe.buildOptions.env[envs[i]].type === "secret") {
							if (modalOpt.item.deployConfiguration &&
								modalOpt.item.deployConfiguration.recipe &&
								modalOpt.item.deployConfiguration.recipe.env &&
								modalOpt.item.deployConfiguration.recipe.env.hasOwnProperty([envs[i]])) {
								p.env[envs[i]].name = modalOpt.item.deployConfiguration.recipe.env[envs[i]].name;
								p.env[envs[i]].secret = modalOpt.item.deployConfiguration.recipe.env[envs[i]].name;
								p.env[envs[i]].key = modalOpt.item.deployConfiguration.recipe.env[envs[i]].key;
							}
						}
					}
				}
				
				return {...p};
			});
		} else {
			NotificationManager.error("The used recipe is not in the allowed recipe list or not available anymore.");
		}
	}
}

async function getRecipes(currentPage, criteria, setPagination, setRecipes) {
	try {
		let c = {"limit": 10, "start": (currentPage - 1) * 10};
		if (criteria) {
			if (criteria._keywords && criteria._keywords !== "") {
				c.keywords = criteria._keywords;
			}
		}
		const _recipes = await marketplaceService.getRecipes(c);
		setPagination(
			{
				"totalItems": _recipes.count,
				"maxSize": 10,
				"itemsPerPage": _recipes.limit
			}
		);
		setRecipes(_recipes.records);
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

function getRecipeFromAllowed(recipeID, allowedRecipies) {
	for (let i = 0; i < allowedRecipies.length; i++) {
		if (allowedRecipies[i]._id === recipeID) {
			return allowedRecipies[i];
		}
	}
	return null;
}

function getRecipeIDFromTemplate(template) {
	if (template.metadata && template.metadata.labels) {
		return template.metadata.labels["soajs.catalog.id"] || null;
	}
	return null;
}

const initFields = {
	"cd": {
		"strategy": "notify"
	},
	"settings": {
		"memory": "0",
		"mode": "Deployment",
		"replicas": 1,
		"concurrencyPolicy": "Allow",
		"schedule": "",
		"restartPolicy": "OnFailure"
	},
	"image": {
		"name": "",
		"prefix": "",
		"tag": "",
		"override": false,
		"registrySecret": "",
		"repositoryType": "public"
	},
	"showPortType": false,
	"ports": {
		"type": "kubernetes",
		"portType": "NodePort",
		"ports": [
			{
				"name": "",
				"target": 0,
				"published": 0,
				"isPublished": false
			}
		]
	},
	"env": null,
	"readinessProbe": null,
	"livenessProbe": null,
	"binary": false,
	"srcType": "",
	"recipeId": "",
	"repoId": "",
	"tag": "",
	"branch": "",
	"commit": "",
	"config": {},
	"inspect": {},
	"recipe": {},
	"branchOrTag": [],
	"warning": null
};

let _json_readiness = null;
let _json_liveness = null;

export default function ConfigureModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const {ability} = useAppContext();
	const [fields, setFields] = useState(initFields);
	
	const handleClose = () => setModalOpt({"show": false, "item": null, "title": ""});
	
	const [wizardStep, setWizardStep] = useState(1);
	const [recipes, setRecipes] = useState([]);
	const [recipesAllowed, setRecipesAllowed] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [criteria, setCriteria] = useState({
		"_keywords": ""
	});
	const [pagination, setPagination] = useState({
		"totalItems": 1, "maxSize": 1, "itemsPerPage": 1
	});
	
	async function get_setLastCommit() {
		let data = {
			"repo": modalOpt.item.itemSrc.repo,
			"owner": modalOpt.item.itemSrc.owner,
			"provider": modalOpt.item.itemSrc.provider,
			"branch": fields.branch
		}
		try {
			let response = await repositoriesService.getGitBranch(data);
			if (response && response.commit) {
				fields.commit = response.commit;
				if (response.repo && response.repo.id) {
					fields.repoId = response.repo.id;
				}
				setFields({...fields});
			}
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}
	
	async function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		if (event.target.type === 'number') {
			value = parseInt(value);
		}
		if (event.target.id === "recipeId") {
			await processAndAutoFill(modalOpt, event.target.value, recipesAllowed, setFields, true);
		} else {
			deepSet(fields, event.target.id, value);
			setFields({...fields});
		}
	}
	
	function handleFieldPortChange(event) {
		let value = event.target.value;
		let id = event.target.id - 1;
		let castedValue = parseInt(value);
		if (!castedValue) {
			castedValue = 0;
		}
		fields.ports.ports[id].published = castedValue;
		setFields({...fields});
	}
	
	async function reload(page) {
		await getRecipes(page, criteria, setPagination, setRecipes);
	}
	
	async function handleSearch(event) {
		event.preventDefault();
		await getRecipes(1, criteria, setPagination, setRecipes);
	}
	
	async function selectRecipe(recipe) {
		setRecipesAllowed([recipe]);
		setWizardStep(2);
		await processAndAutoFill(modalOpt, recipe._id, [recipe], setFields, true);
	}
	
	function readiness_onChange(json) {
		_json_readiness = json;
	}
	
	function liveness_onChange(json) {
		_json_liveness = json;
	}
	
	function buildConfigureData() {
		let params = {
			"name": modalOpt.item.name,
			"type": modalOpt.item.type
		};
		let data = {
			"config": {
				"env": modalOpt.item.env,
				"version": modalOpt.item.version,
				"cd": {
					"strategy": fields.cd.strategy
				},
				"settings": {
					"memory": fields.settings.memory,
					"mode": fields.settings.mode,
					"replicas": fields.settings.replicas,
					"concurrencyPolicy": fields.settings.concurrencyPolicy,
					"schedule": fields.settings.schedule,
					"restartPolicy": fields.settings.restartPolicy
				},
				"src": {
					"id": fields.repoId,
					"from": {
						"tag": fields.tag,
						"branch": fields.branch,
						"commit": fields.commit
					}
				},
				"recipe": {
					"id": fields.recipeId,
					"image": {
						"name": fields.image.name,
						"prefix": fields.image.prefix,
						"tag": fields.image.tag
					},
					"readinessProbe": _json_readiness || fields.readinessProbe || null,
					"livenessProbe": _json_liveness || fields.livenessProbe || null
				}
			}
		};
		if (fields.image.repositoryType === "private") {
			if (fields.image.registrySecret !== "") {
				data.config.recipe.image.registrySecret = fields.image.registrySecret;
			}
		}
		if (!data.config.recipe.readinessProbe || Object.keys(data.config.recipe.readinessProbe).length === 0) {
			delete data.config.recipe.readinessProbe;
		}
		if (!data.config.recipe.livenessProbe || Object.keys(data.config.recipe.livenessProbe).length === 0) {
			delete data.config.recipe.livenessProbe;
		}
		
		if (fields.binary || !modalOpt.item.itemSrc) {
			delete data.config.src;
		} else {
			if (fields.srcType === "branch") {
				delete data.config.src.from.tag;
			} else {
				delete data.config.src.from.branch;
				delete data.config.src.from.commit;
			}
		}
		
		if (data.config.settings.mode === "DaemonSet") {
			delete data.config.settings.replicas;
			delete data.config.settings.concurrencyPolicy;
			delete data.config.settings.schedule;
			delete data.config.settings.restartPolicy;
		}
		if (data.config.settings.mode === "Deployment") {
			delete data.config.settings.concurrencyPolicy;
			delete data.config.settings.schedule;
			delete data.config.settings.restartPolicy;
		}
		if (data.config.settings.mode === "CronJob") {
			delete data.config.settings.replicas;
		}
		
		if (fields.env) {
			let envs = Object.keys(fields.env);
			for (let i = 0; i < envs.length; i++) {
				if (!data.config.recipe.env) {
					data.config.recipe.env = {};
				}
				if (fields.env[envs[i]].type === "userInput") {
					data.config.recipe.env[envs[i]] = fields.env[envs[i]].value || fields.env[envs[i]].default;
				}
				if (fields.env[envs[i]].type === "secret") {
					data.config.recipe.env[envs[i]] = {
						"name": fields.env[envs[i]].secret || fields.env[envs[i]].name,
						"key": fields.env[envs[i]].key
					};
				}
			}
		}
		if (fields.ports.ports.length > 0) {
			data.config.recipe.ports = {
				"type": fields.ports.type,
				"portType": fields.ports.portType,
				"values": []
			};
			for (let i = 0; i < fields.ports.ports.length; i++) {
				if (fields.ports.ports[i].isPublished) {
					let port = {
						"name": fields.ports.ports[i].name,
						"target": fields.ports.ports[i].target
					}
					if (fields.ports.portType === "NodePort") {
						port.published = fields.ports.ports[i].published;
					}
					data.config.recipe.ports.values.push(port);
				}
			}
		}
		
		return ({data, params});
	}
	
	async function save() {
		try {
			let {data, params} = buildConfigureData();
			await marketplaceService.configure(params, data);
			NotificationManager.success(t("soajs:messages.saveConfigSuccess"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function saveAndDeploy() {
		try {
			let {data, params} = buildConfigureData();
			await marketplaceService.build(params, data);
			NotificationManager.success(t("soajs:messages.saveConfigDeploySuccess"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			if (modalOpt && modalOpt.item) {
				let recipeID = null;
				let allowedRecipies = [];
				let recipeIDs = [];
				
				let configRecipeID = null;
				if (modalOpt.item.deployConfiguration && modalOpt.item.deployConfiguration.recipe && modalOpt.item.deployConfiguration.recipe.id) {
					configRecipeID = modalOpt.item.deployConfiguration.recipe.id;
					recipeIDs.push(configRecipeID);
					recipeID = configRecipeID;
				}
				
				let deployRecipeID = null;
				if (modalOpt.item.inspect) {
					let template = getTemplate(modalOpt.item.inspect, modalOpt.item.kind);
					if (template) {
						deployRecipeID = getRecipeIDFromTemplate(template);
						recipeIDs.push(deployRecipeID);
						recipeID = deployRecipeID;
					}
				}
				
				if ((!modalOpt.item.itemSettings || !modalOpt.item.itemSettings.recipes ||
					!Array.isArray(modalOpt.item.itemSettings.recipes) ||
					modalOpt.item.itemSettings.recipes.length === 0)) {
					await getRecipes(1, {"_keywords": ""}, setPagination, setRecipes);
				}
				
				if (modalOpt.item.itemSettings && modalOpt.item.itemSettings.recipes &&
					Array.isArray(modalOpt.item.itemSettings.recipes) &&
					modalOpt.item.itemSettings.recipes.length > 0) {
					recipeIDs = _.union(recipeIDs, modalOpt.item.itemSettings.recipes);
				}
				if (recipeIDs.length > 0) {
					try {
						allowedRecipies = await marketplaceService.getRecipesByIds(recipeIDs);
						if (allowedRecipies && Array.isArray(allowedRecipies)) {
							if (!recipeID) {
								recipeID = allowedRecipies[0]._id;
							}
							if (isSubscribed) {
								setRecipesAllowed(allowedRecipies);
								setWizardStep(2);
							}
						}
					} catch (e) {
						NotificationManager.error(e.message);
					}
				}
				
				await processAndAutoFill(modalOpt, recipeID, allowedRecipies, setFields, isSubscribed);
			}
		}
		
		onLoad().catch();
		return () => (isSubscribed = false);
	}, [modalOpt]);
	
	return (
		modalOpt &&
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="xl">
			<Modal.Header closeButton className="bg-secondary text-white">
				<Modal.Title>{modalOpt.item && modalOpt.item.deployConfiguration ? "reConfigure Deployment" : "Configure Deployment"}: {modalOpt.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{wizardStep === 1 && modalOpt.item &&
				<>
					<Alert variant="secondary" className="p-3">
						<Form.Row>
							<Form.Label column="xs" xs={3}>{t("soajs:buttons.Search")}</Form.Label>
							<Col xs={6}>
								<Form.Control
									id="_keywords"
									size="sm"
									autoFocus
									value={criteria._keywords}
									onChange={handleCriteriaChange}
								/>
							</Col>
							<Col xs={3}>
								<Button
									className="float-right"
									variant="dark"
									size="sm"
									onClick={handleSearch}>
									<BiSearchAlt/> {t("soajs:buttons.Search")}
								</Button>
							</Col>
						</Form.Row>
					</Alert>
					<hr/>
					{recipes.map((rec, index) => (
						<Card key={index} className="m-1 p-1" bg="light">
							<Card.Body className="p-2">
								{rec.name} - <span
								className="font-italic small text-danger">[{rec.type}/{rec.subtype}]</span>
								<span className="float-right small">
									<Badge variant="dark">V {rec.v}</Badge>
									<Button
										className="float-right ml-2"
										variant="success"
										size="sm"
										onClick={() => {
											selectRecipe(rec).catch();
										}}
									>
									{t("soajs:buttons.Select")}
								</Button>
						</span>
							</Card.Body>
						</Card>
					))}
					{recipes.length > 0 &&
					<>
						<div className="float-right mt-3">
							<AutoPagination
								currentPage={currentPage}
								totalItems={pagination.totalItems}
								itemsPerPage={pagination.itemsPerPage}
								maxSize={pagination.maxSize}
								onClick={(p) => {
									setCurrentPage(p);
									reload(p).catch();
								}}
							/>
						</div>
						<div className="clearfix"></div>
					</>
					}
				</>
				}
				{wizardStep === 2 && modalOpt.item &&
				<Form>
					<Alert variant="secondary">
						<Form.Group>
							<Form.Label>{t("soajs:fields.SelectRecipe")}</Form.Label>
							{!(modalOpt.item && modalOpt.item.itemSettings && modalOpt.item.itemSettings.recipes &&
								Array.isArray(modalOpt.item.itemSettings.recipes) &&
								modalOpt.item.itemSettings.recipes.length > 0) &&
							<Button
								className="pt-0 pl-1 pr-1 ml-2 float-right"
								variant="warning"
								size="sm"
								onClick={() => {
									setWizardStep(1);
								}}
							>
								{t("soajs:buttons.Change")}
							</Button>}
							<Form.Control
								as="select"
								id="recipeId"
								value={fields.recipeId}
								onChange={handleFieldChange}
							>
								{recipesAllowed.map((rec, i) => (
									<option key={i} value={rec._id}>{rec.name}</option>
								))}
							</Form.Control>
						</Form.Group>
					</Alert>
					
					{fields.warning &&
					<Alert variant="danger" className="small">
						{fields.warning}
					</Alert>
					}
					
					<Card border="success" className="mb-3">
						<Card.Header className="pt-1 pb-0">
							Image Settings
						</Card.Header>
						<Card.Body className="pb-0">
							<Form.Group>
								<Form.Label>{t("soajs:fields.Prefix")}</Form.Label>
								<Form.Control
									id="image.prefix"
									size="sm"
									disabled={!fields.image.override}
									value={fields.image.prefix}
									onChange={handleFieldChange}
								/>
								<Form.Text className="text-info">
									Recipe configuration: <b>{fields.recipe.prefix}</b>
								</Form.Text>
								{modalOpt.item && modalOpt.item.deployConfiguration && fields.config.imagePrefix && fields.image.prefix !== fields.config.imagePrefix &&
								<Form.Text className="text-danger">
									Mismatch with configuration: <b>{fields.config.imagePrefix}</b>
								</Form.Text>}
								{modalOpt.item && modalOpt.item.deployed && fields.inspect.imagePrefix && fields.image.prefix !== fields.inspect.imagePrefix &&
								<Form.Text className="text-danger">
									Mismatch with deployment: <b>{fields.inspect.imagePrefix}</b>
								</Form.Text>}
							</Form.Group>
							<Form.Group>
								<Form.Label>{t("soajs:fields.Name")}</Form.Label>
								<Form.Control
									id="image.name"
									size="sm"
									disabled={!fields.image.override}
									value={fields.image.name}
									onChange={handleFieldChange}
								/>
								<Form.Text className="text-info">
									Recipe configuration: <b>{fields.recipe.name}</b>
								</Form.Text>
								{modalOpt.item && modalOpt.item.deployConfiguration && fields.config.imageName && fields.image.name !== fields.config.imageName &&
								<Form.Text className="text-danger">
									Mismatch with configuration: <b>{fields.config.imageName}</b>
								</Form.Text>}
								{modalOpt.item && modalOpt.item.deployed && fields.inspect.imageName && fields.image.name !== fields.inspect.imageName &&
								<Form.Text className="text-danger">
									Mismatch with deployment: <b>{fields.inspect.imageName}</b>
								</Form.Text>}
							</Form.Group>
							<Form.Group>
								<Form.Label>{t("soajs:fields.tag")}</Form.Label>
								<Form.Control
									id="image.tag"
									size="sm"
									disabled={!fields.image.override}
									value={fields.image.tag}
									onChange={handleFieldChange}
								/>
								<Form.Text className="text-info">
									Recipe configuration: <b>{fields.recipe.tag}</b>
								</Form.Text>
								{modalOpt.item && modalOpt.item.deployConfiguration && fields.config.imageTag && fields.image.tag !== fields.config.imageTag &&
								<Form.Text className="text-danger">
									Mismatch with configuration: <b>{fields.config.imageTag}</b>
								</Form.Text>}
								{modalOpt.item && modalOpt.item.deployed && fields.inspect.imageTag && fields.image.tag !== fields.inspect.imageTag &&
								<Form.Text className="text-danger">
									Mismatch with deployment: <b>{fields.inspect.imageTag}</b>
								</Form.Text>}
							</Form.Group>
							{fields.image.repositoryType === "private" &&
							<Form.Group>
								<Form.Label>{t("soajs:fields.privateRegistry")}</Form.Label>
								<Form.Control
									id="image.registrySecret"
									size="sm"
									value={fields.image.registrySecret}
									onChange={handleFieldChange}
								/>
								<Form.Text className="text-info">
									Enter the secret name of the Private Registry from where you want to pull the image.
								</Form.Text>
							</Form.Group>
							}
						</Card.Body>
					</Card>
					
					{!fields.binary && fields.branchOrTag && fields.branchOrTag.length > 0 &&
					<Card border="success" className="mb-3">
						<Card.Header className="pt-1 pb-0">
							Repository Settings
						</Card.Header>
						<Card.Body className="pb-0">
							<Form.Group>
								<Form.Label>{t("soajs:fields.BranchOrTag")}</Form.Label>
								<Form.Control
									as="select"
									id="srcType"
									value={fields.srcType}
									onChange={handleFieldChange}
								>
									{fields.branchOrTag.map((rec, i) => (
										<option key={i} value={rec}>{rec}</option>
									))}
								</Form.Control>
							</Form.Group>
							{fields.srcType === "branch" &&
							<Form.Group>
								<Form.Label>{t("soajs:fields.Branch")}</Form.Label>
								
								<Form.Control
									as="select"
									id="branch"
									value={fields.branch}
									onChange={handleFieldChange}
								>
									{modalOpt.item && modalOpt.item.availableSrc && modalOpt.item.availableSrc.branches &&
									modalOpt.item.availableSrc.branches.map((rec, i) => (
										<option key={i} value={rec}>{rec}</option>
									))}
								</Form.Control>
								
								{modalOpt.item && modalOpt.item.deployConfiguration && fields.config.branch && fields.branch !== fields.config.branch &&
								<Form.Text className="text-danger">
									Mismatch with configuration: <b>{fields.config.branch}</b>
								</Form.Text>}
								{modalOpt.item && modalOpt.item.deployed && fields.inspect.branch && fields.branch !== fields.inspect.branch &&
								<Form.Text className="text-danger">
									Mismatch with configuration: <b>{fields.inspect.branch}</b>
								</Form.Text>}
							</Form.Group>}
							{fields.srcType === "branch" &&
							<Form.Group>
								<Form.Label>
									{t("soajs:fields.Commit")}
									<Button
										className="pt-0 pl-1 pr-1 ml-2"
										variant="info"
										onClick={get_setLastCommit}
									>
										<GoSync/>
									</Button>
								</Form.Label>
								<Form.Control
									id="commit"
									size="sm"
									value={fields.commit}
									onChange={handleFieldChange}
								/>
								{modalOpt.item && modalOpt.item.deployConfiguration && fields.config.commit && fields.commit !== fields.config.commit &&
								<Form.Text className="text-danger">
									Mismatch with configuration: <b>{fields.config.commit}</b>
								</Form.Text>}
								{modalOpt.item && modalOpt.item.deployed && fields.inspect.commit && fields.commit !== fields.inspect.commit &&
								<Form.Text className="text-danger">
									Mismatch with deployment: <b>{fields.inspect.commit}</b>
								</Form.Text>}
							</Form.Group>}
							{fields.srcType === "tag" &&
							<Form.Group>
								<Form.Label>{t("soajs:fields.Tag")}</Form.Label>
								
								<Form.Control
									as="select"
									id="tag"
									value={fields.tag}
									onChange={handleFieldChange}
								>
									{modalOpt.item && modalOpt.item.availableSrc && modalOpt.item.availableSrc.tags &&
									modalOpt.item.availableSrc.tags.map((rec, i) => (
										<option key={i} value={rec}>{rec}</option>
									))}
								</Form.Control>
								
								{modalOpt.item && modalOpt.item.deployConfiguration && fields.config.tag && fields.tag !== fields.config.tag &&
								<Form.Text className="text-danger">
									Mismatch with configuration: <b>{fields.config.tag}</b>
								</Form.Text>}
								{modalOpt.item && modalOpt.item.deployed && fields.inspect.tag && fields.tag !== fields.inspect.tag &&
								<Form.Text className="text-danger">
									Mismatch with deployment: <b>{fields.inspect.tag}</b>
								</Form.Text>}
							</Form.Group>}
						</Card.Body>
					</Card>
					}
					
					<Card border="primary" className="mb-3">
						<Card.Header className="pt-1 pb-0">
							Deployment Settings
						</Card.Header>
						<Card.Body className="pb-0">
							<Form.Group>
								<Form.Label>{t("soajs:fields.CDStrategy")}</Form.Label>
								<Form.Control
									as="select"
									id="cd.strategy"
									value={fields.cd.strategy}
									onChange={handleFieldChange}
								>
									<option value="notify">Notify</option>
									<option value="update">Update</option>
								</Form.Control>
							</Form.Group>
							
							<Form.Group>
								<Form.Label>{t("soajs:fields.Memory")}</Form.Label>
								<Form.Control
									id="settings.memory"
									size="sm"
									value={fields.settings.memory}
									onChange={handleFieldChange}
								/>
								<Form.Text className="text-muted">
									Specify the Maximum memory limit that should be provided for this deployment.
									Example: You can set it to 1024Mi or 1Gi. <a
									href="https://kubernetes.io/docs/concepts/configuration/manage-compute-resources-container/#meaning-of-memory%22">Click
									here</a> for more information. You can use 0 if you want the memory limits
									(minimum and maximum) to be elastic.
								</Form.Text>
							</Form.Group>
							
							<Form.Group>
								<Form.Label>{t("soajs:fields.DeploymentType")}</Form.Label>
								<Form.Control
									as="select"
									id="settings.mode"
									value={fields.settings.mode}
									onChange={handleFieldChange}
								>
									<option value="Deployment">Deployment</option>
									<option value="DaemonSet">DaemonSet</option>
									<option value="CronJob">CronJob</option>
								</Form.Control>
							</Form.Group>
							<hr/>
							{fields.settings.mode === "CronJob" &&
							<>
								<Form.Group>
									<Form.Label>{t("soajs:fields.Schedule")}</Form.Label>
									<Form.Control
										id="settings.schedule"
										size="sm"
										value={fields.settings.schedule}
										onChange={handleFieldChange}
									/>
									<Form.Text className="text-muted">
										A CronJob takes a <a href="https://en.wikipedia.org/wiki/Cron">Cron</a> format
										string, such as 0 * * * * or @hourly, as schedule time of its jobs to be created
										and executed.
									</Form.Text>
								</Form.Group>
								<Form.Group>
									<Form.Label>{t("soajs:fields.ConcurrencyPolicy")}</Form.Label>
									<Form.Control
										as="select"
										id="settings.concurrencyPolicy"
										value={fields.settings.concurrencyPolicy}
										onChange={handleFieldChange}
									>
										<option value="Allow">Allow</option>
										<option value="Forbid">Forbid</option>
										<option value="Replace">Replace</option>
									</Form.Control>
								</Form.Group>
								<Form.Group>
									<Form.Label>{t("soajs:fields.RestartPolicy")}</Form.Label>
									<Form.Control
										as="select"
										id="settings.restartPolicy"
										value={fields.settings.restartPolicy}
										onChange={handleFieldChange}
									>
										<option value="OnFailure">OnFailure</option>
										<option value="Never">Never</option>
									</Form.Control>
								</Form.Group>
							</>
							}
							{(fields.settings.mode === "Deployment") &&
							<>
								<Form.Group>
									<Form.Label>{t("soajs:fields.Replicas")}</Form.Label>
									<Form.Control
										id="settings.replicas"
										size="sm"
										type="number"
										min="1"
										value={fields.settings.replicas}
										onChange={handleFieldChange}
									/>
								</Form.Group>
							</>
							}
						</Card.Body>
					</Card>
					
					{fields.ports.ports && fields.ports.ports.length > 0 &&
					<Accordion defaultActiveKey="0">
						<Card border="secondary" className="mb-3">
							<Card.Header className="pt-1 pb-0">
								<Accordion.Toggle className="p-0" as={Button} variant="link" eventKey="1">
									Ports
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="1">
								<Card.Body className="pb-0 small">
									{fields.showPortType &&
									<Form.Group>
										<Form.Label>{t("soajs:fields.PortType")}</Form.Label>
										<Form.Control
											as="select"
											id="ports.portType"
											value={fields.ports.portType}
											onChange={handleFieldChange}
										>
											<option value="LoadBalancer">LoadBalancer</option>
											<option value="NodePort">NodePort</option>
											<option value="Internal">Internal</option>
										</Form.Control>
									</Form.Group>}
									<hr/>
									{fields.ports.ports.map((rec, i) => (
										<Alert key={i} className="p-2" variant={rec.isPublished ? "info" : "secondary"}>
											<Row>
												<Col>
													{rec.name} <Badge className="ml-3 mr-3"
													                  variant="secondary">{rec.target}</Badge>
												</Col>
												<Col>
													{rec.isPublished && fields.ports.portType === "NodePort" &&
													<Form.Control
														id={i + 1}
														size="sm"
														min={0}
														type={"number"}
														value={rec.published}
														onChange={handleFieldPortChange}
													/>
													}
												</Col>
											</Row>
										</Alert>
									))}
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					</Accordion>}
					
					{fields.env &&
					<Accordion defaultActiveKey="0">
						<Card border="secondary" className="mb-3">
							<Card.Header className="pt-1 pb-0">
								<Accordion.Toggle className="p-0" as={Button} variant="link" eventKey="1">
									Environment Variables
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="1">
								<Card.Body className="pb-0 small">
									{fields.env && Object.keys(fields.env).map((rec, i) => (
										<Alert key={i} className="p-2"
										       variant={fields.env[rec].type === "userInput" ? "info" : "secondary"}>
											<Row>
												<Col>
													{rec} <Badge
													className="ml-3 mr-3"
													variant={fields.env[rec].type === "userInput" ? "success" :
														fields.env[rec].type === "computed" ? "secondary" : "info"}>
													{fields.env[rec].type}</Badge>
												</Col>
												<Col>
													{fields.env[rec].type !== "userInput" &&
													<>{fields.env[rec].value}</>}
												</Col>
											</Row>
											{fields.env[rec].type === "userInput" &&
											<Row className="mt-2">
												<Col>
													<>
														<Form.Control
															id={"env." + rec + ".value"}
															size="sm"
															value={fields.env[rec].value || fields.env[rec].default}
															onChange={handleFieldChange}
														/>
														<Form.Text className="text-muted">
															<b>{fields.env[rec].label}: </b>{fields.env[rec].fieldMsg}
														</Form.Text>
													</>
												</Col>
											</Row>
											}
											{fields.env[rec].type === "secret" &&
											<Row className="mt-2">
												<Col>
													<>
														<Form.Control
															id={"env." + rec + ".secret"}
															size="sm"
															value={fields.env[rec].secret}
															onChange={handleFieldChange}
														/>
														<Form.Text className="text-muted">
															{"Secret name"}
														</Form.Text>
													</>
												</Col>
												<Col>
													<>
														<Form.Control
															id={"env." + rec + ".key"}
															size="sm"
															value={fields.env[rec].key}
															onChange={handleFieldChange}
														/>
														<Form.Text className="text-muted">
															{"Secret label"}
														</Form.Text>
													</>
												</Col>
											</Row>
											}
										</Alert>
									))}
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					</Accordion>
					}
					
					<Accordion defaultActiveKey="0">
						<Card border="warning" className="mb-3">
							<Card.Header className="pt-1 pb-0">
								<Accordion.Toggle className="p-0" as={Button} variant="link" eventKey="1">
									Readiness Information
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="1">
								<Card.Body className="pb-0">
									<Row style={{"height": "250px"}}>
										<Col>
											<JsonEditor
												modes={["code", "tree"]}
												mode="code"
												json={_json_readiness || fields.readinessProbe || null}
												onChange={readiness_onChange}
											/>
										</Col>
										<Col xs={4} className="small">
											<div>
												<Alert variant="dark" className="m-0 pb-0 pt-1"><h6>Example</h6>
												</Alert>
												<Alert variant="info" className="m-0 pb-0 pt-1">
													<pre className="small, text-muted">
														{JSON.stringify(_examples.readinessProbe, null, 2)}
													</pre>
												</Alert>
											</div>
										</Col>
									</Row>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					</Accordion>
					
					<Accordion defaultActiveKey="0">
						<Card border="warning" className="mb-3">
							<Card.Header className="pt-1 pb-0">
								<Accordion.Toggle className="p-0" as={Button} variant="link" eventKey="1">
									Liveness Information
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="1">
								<Card.Body className="pb-0">
									<Row style={{"height": "250px"}}>
										<Col>
											<JsonEditor
												mode="code"
												modes={["code", "tree"]}
												json={_json_liveness || fields.livenessProbe || null}
												onChange={liveness_onChange}
											/>
										</Col>
										<Col xs={4} className="small">
											<div>
												<Alert variant="dark" className="m-0 pb-0 pt-1"><h6>Example</h6>
												</Alert>
												<Alert variant="info" className="m-0 pb-0 pt-1">
													<pre className="small, text-muted">
														{JSON.stringify(_examples.livenessProbe, null, 2)}
													</pre>
												</Alert>
											</div>
										</Col>
									</Row>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					</Accordion>
				
				</Form>
				}
			</Modal.Body>
			<Modal.Footer className="bg-light">
				{wizardStep === 2 && modalOpt.item &&
				<>
					<Button variant="success" onClick={save}>
						{modalOpt.item && modalOpt.item.deployConfiguration ? t("soajs:buttons.Update") : t("soajs:buttons.Save")}
					</Button>
					
					{ability.can('market_item', 'build') &&
					<Button variant="dark" onClick={saveAndDeploy}>
						{modalOpt.item && modalOpt.item.deployConfiguration ? t("soajs:buttons.Update") : t("soajs:buttons.Save")} & {modalOpt.item && modalOpt.item.deployed ? t("soajs:buttons.reDeploy") : t("soajs:buttons.Deploy")}
					</Button>}
				</>
				}
				<Button variant="info" onClick={handleClose}>
					{t("soajs:buttons.Close")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}