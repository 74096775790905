import {Button, Modal, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {NotificationManager} from "../../../../soajs/urac/components";
import {InfraService} from "../../../../services";

const infraService = InfraService.getService();

export default function AddModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, item: null});
	const [fields, setFields] = useState({
		"label": ""
	});
	
	function validateForm() {
		let valid = true;
		valid = (valid && fields.label.length > 0);
		return (
			valid
		);
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setFields({
			...fields,
			[event.target.id]: value
		});
	}
	
	async function handleSubmit() {
		try {
			await infraService.addToken(fields.label);
			NotificationManager.success(t("soajs:messages.addToken"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		if (isSubscribed && modalOpt) {
			setFields({
				"label": ""
			});
		}
		return () => (isSubscribed = false);
	}, [modalOpt]);
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.tokenAdd")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Label")}</Form.Label>
						<Form.Control
							id="label"
							size="sm"
							value={fields.label}
							onChange={handleFieldChange}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="warning"
					onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button
					variant="success"
					onClick={handleSubmit}
					disabled={!validateForm()}
				>
					{t("soajs:buttons.Add")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}