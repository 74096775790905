import React, {useEffect, useState} from "react";
import {Table, Button} from "react-bootstrap";

import {GoDiffAdded} from "react-icons/go";

import {NotificationManager} from "../../../../soajs/urac/components";
import {MultitenantService} from "../../../../services";
import {UracService} from "../../../../soajs/urac/services";
import {useAppContext} from "../../../../soajs/libs/contextLib";
import {useTranslation} from "react-i18next";

import DeleteModal from "./DeleteModal"
import InfoModal from "./InfoModal"
import EditModal from "./EditModal"
import AddModal from "./AddModal"
import DeleteButton from "../../../buttons/Delete";
import ViewButton from "../../../buttons/View";
import EditButton from "../../../buttons/Edit";

const uracService = UracService.getService();
const multitenantService = MultitenantService.getService();

async function onLoad(setFields, isSubscribed, setMyProduct) {
	try {
		const groups = await uracService.getGroups();
		const product = await multitenantService.getMyConsoleProduct();
		if (groups && Array.isArray(groups) && groups.length > 0) {
			if (isSubscribed) {
				setFields(groups);
			}
		} else {
			if (isSubscribed) {
				setFields(null);
			}
		}
		if (isSubscribed) {
			setMyProduct(product);
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function Groups() {
	const {ability} = useAppContext();
	const {t} = useTranslation(["common", "soajs", "organization"]);
	const [fields, setFields] = useState([]);
	const [myProduct, setMyProduct] = useState(null);
	
	const [modalInfoOpt, setModalInfoOpt] = useState({"show": false, opts: null});
	const modalInfoOptShow = (opt) => setModalInfoOpt({"show": true, opts: opt});
	
	const [modalEditOpt, setModalEditOpt] = useState({"show": false, opts: null});
	const modalEditOptShow = (opt) => setModalEditOpt({"show": true, opts: opt});
	
	const [modalDeleteOpt, setModalDeleteOpt] = useState({"show": false, opts: null});
	const modalDeleteOptShow = (opt) => setModalDeleteOpt({"show": true, opts: opt});
	
	const [modalAddOpt, setModalAddOpt] = useState({"show": false, opts: null});
	const modalAddOptShow = (opt) => setModalAddOpt({"show": true, opts: opt});
	
	async function reLoad() {
		await onLoad(setFields, true, setMyProduct)
	}
	
	useEffect(() => {
		let isSubscribed = true;
		onLoad(setFields, isSubscribed, setMyProduct);
		return () => (isSubscribed = false);
	}, []);
	
	return (
		fields && (
			<>
				<AddModal
					modalOpt={modalAddOpt}
					setModalOpt={setModalAddOpt}
					handleAction={reLoad}
				/>
				<DeleteModal
					modalOpt={modalDeleteOpt}
					setModalOpt={setModalDeleteOpt}
					handleAction={reLoad}
				/>
				<EditModal
					modalOpt={modalEditOpt}
					setModalOpt={setModalEditOpt}
					handleAction={reLoad}
					product={myProduct}
				/>
				<InfoModal
					modalOpt={modalInfoOpt}
					setModalOpt={setModalInfoOpt}
					product={myProduct}
				/>
				<div>
					<h4 className="float-left">{t("soajs:titles.groups")}</h4>
					{ability.can('group', 'add') &&
					<Button variant="success" className="float-right" size="sm"
					        onClick={() => modalAddOptShow(null)}
					>
						<GoDiffAdded/> {t("soajs:buttons.Add")}
					</Button>}
				</div>
				<Table responsive striped hover size="sm">
					<thead className="text-light bg-dark">
					<tr>
						<th>{t("soajs:buttons.Actions")}</th>
						<th>{t("soajs:fields.Code")}</th>
						<th>{t("soajs:fields.Name")}</th>
						<th>{t("soajs:fields.Description")}</th>
					</tr>
					</thead>
					<tbody>
					{fields.map((group, index) => (
						<tr key={index}>
							<td nowrap="true">
								<ViewButton
									className="mr-1"
									onClick={() => {
										modalInfoOptShow(group);
									}}
								/>
								{ability.can('group', 'delete') &&
								<DeleteButton
									className="mr-1"
									onClick={() => {
										modalDeleteOptShow(group);
									}}/>
								}
								{ability.can('group', 'edit') &&
								<EditButton
									className="mr-1"
									onClick={() => {
										modalEditOptShow(group);
									}}/>
								}
							</td>
							<td>{group.code}</td>
							<td>{group.name}</td>
							<td>{group.description}</td>
						</tr>
					))}
					</tbody>
				</Table>
			</>
		)
	);
}