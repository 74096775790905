import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";
import {BiPowerOff} from "react-icons/bi";

export default function TurnOn({...props}) {
	const {t} = useTranslation(["common", "soajs"]);
	return (
		<OverlayTrigger
			placement="top"
			overlay={
				<Tooltip id={`tooltip-top}`}>
					{t("soajs:buttons.TurnOn")}
				</Tooltip>
			}
		>
			<Button size="sm" variant="light" {...props}>
				<BiPowerOff/>
			</Button>
		</OverlayTrigger>
	);
}