import React from "react";
import {Redirect, Route, useLocation} from "react-router-dom";
import {useAppContext} from "../libs/contextLib";

export default function AuthenticatedRoute({children, ...rest}) {
	const {pathname, search} = useLocation();
	const {isAuthenticated} = useAppContext();
	let allowed = true;
	if (rest.ability) {
		if (rest.ability.cannot('route', rest.path)) {
			allowed = false;
		}
	}
	return (
		<Route {...rest}>
			{isAuthenticated ? (allowed ? (
				children
			) : (
				<Redirect to={
					`/no-access`
				}/>
			)) : (
				<Redirect to={
					`/sign-in?redirect=${pathname}${search}`
				}/>
			)}
		</Route>
	);
}