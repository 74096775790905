import axios from "axios";
import {response_catch, response_then} from "./commonResponse";

const ConsoleService = (function () {
	let _service = null;
	
	function _getService() {
		if (!_service) {
			_service = this;
			return _service;
		}
		return _service;
	}
	
	function _getByType(type, env, fields) {
		return axios.get("/infra/kubernetes/" + type + "/" + fields.mode,
			{
				"params": {
					"configuration": {"env": env},
					"limit": fields.limit,
					"filter": fields.filter || null,
					"continue": fields.continue || null
				}
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getStorage(env, fields) {
		return _getByType("storages", env, fields);
	}
	
	function _getServices(env, fields) {
		return _getByType("services", env, fields);
	}
	
	function _getWorkloads(env, fields) {
		return _getByType("workloads", env, fields);
	}
	
	function _getClusters(env, fields) {
		return _getByType("clusters", env, fields);
	}
	
	function _getConfigurations(env, fields) {
		return _getByType("configurations", env, fields);
	}
	
	function _updateByType(type, env, fields) {
		return axios.put("/infra/kubernetes/" + type + "/" + fields.mode,
			{
				"name": fields.name,
				"body": fields.body
			},
			{
				"params": {
					"configuration": {"env": env}
				}
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateStorage(env, fields) {
		return _updateByType("storage", env, fields);
	}
	
	function _updateWorkload(env, fields) {
		return _updateByType("workload", env, fields);
	}
	
	function _updateService(env, fields) {
		return _updateByType("service", env, fields);
	}
	
	function _addByType(type, env, fields) {
		return axios.post("/infra/kubernetes/" + type + "/" + fields.mode,
			{
				"body": fields.body
			},
			{
				"params": {
					"configuration": {"env": env}
				}
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addStorage(env, fields) {
		return _addByType("storage", env, fields);
	}
	
	function _addWorkload(env, fields) {
		return _addByType("workload", env, fields);
	}
	
	function _addService(env, fields) {
		return _addByType("service", env, fields);
	}
	
	function _addConfiguration(env, fields) {
		return _addByType("configuration", env, fields);
	}
	
	function _deleteByType(type, env, fields) {
		return axios.delete("/infra/kubernetes/" + type + "/" + fields.mode,
			{
				"params": {
					"configuration": {"env": env},
					"name": fields.name
				}
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deletePod(env, fields) {
		return axios.delete("/infra/kubernetes/pods",
			{
				"params": {
					"configuration": {"env": env}
				},
				"data": {
					"filter": {
						"fieldSelector": "metadata.name=" + fields.name,
						"includeUninitialized": true
					}
				}
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteWorkload(env, fields) {
		if (fields.mode === "Pod") {
			return _deletePod(env, fields);
		} else {
			return _deleteByType("workload", env, fields);
		}
	}
	
	function _deleteStorage(env, fields) {
		return _deleteByType("storage", env, fields);
	}
	
	function _deleteService(env, fields) {
		return _deleteByType("service", env, fields);
	}
	
	function _deleteConfiguration(env, fields) {
		return _deleteByType("configuration", env, fields);
	}
	
	function _addSecretWizard(env, name, content) {
		return axios.post("/infra/kubernetes/secret",
			{
				"content": content,
				"name": name
			},
			{
				"params": {
					"configuration": {"env": env}
				}
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addSecretRegistryWizard(env, name, content) {
		return axios.post("/infra/kubernetes/secret/registry",
			{
				"content": content,
				"name": name
			},
			{
				"params": {
					"configuration": {"env": env}
				}
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	
	function _inspect(env, item) {
		return axios.get("/infra/kubernetes/item/inspect",
			{
				"params": {
					"configuration": {"env": env},
					"item": {
						"env": env,
						"name": item.name,
						"version": item.version
					}
				}
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getManualAwareness(env) {
		return axios.get("/infra/manual/awareness", {"params": {"env": env}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getNamespaces(env) {
		return axios.get("/infra/kubernetes/clusters/Namespace", {"params": {"configuration": {"env": env}}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getInfraAccounts() {
		return axios.get("/infra/account/kubernetes")
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteInfraAccount(id) {
		return axios.delete("/infra/account/kubernetes", {"data": {"id": id}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _saveInfraAccountAcl(fields) {
		return axios.put("/infra/account/kubernetes/acl",
			{
				"id": fields.id,
				"type": fields.type,
				"groups": fields.groups
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteInfraAccountAcl(id) {
		return axios.delete("/infra/account/kubernetes/acl", {"params": {"id": id}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addInfraAccount(fields) {
		let data = {
			"label": fields.label,
			"description": fields.description,
			"configuration": {
				"type": fields.type,
				"token": fields.token,
				"url": fields.url,
				"port": fields.port
			}
		};
		if (fields.ca) {
			data.configuration.ca = fields.ca;
		}
		return axios.post("/infra/account/kubernetes",
			data)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateInfraAccount(fields) {
		let data = {
			"id": fields.id,
			"label": fields.label,
			"description": fields.description,
			"configuration": {
				"type": fields.type,
				"token": fields.token,
				"url": fields.url,
				"port": fields.port
			}
		};
		if (fields.ca) {
			data.configuration.ca = fields.ca;
		}
		return axios.put("/infra/account/kubernetes/configuration",
			data)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _podExec(env, fields) {
		let data = {
			"name": fields.name,
			"commands": fields.commands,
			"processResult": fields.processResult || false,
			"configuration": {"env": env},
		};
		if (fields.ca) {
			data.configuration.ca = fields.ca;
		}
		return axios.put("/infra/kubernetes/pod/exec",
			data)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _podLog(env, item) {
		return axios.get("/infra/kubernetes/pod/log",
			{
				"params": {
					"configuration": {"env": env},
					"name": item.name,
					"lines": 400
				}
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _restart(env, fields) {
		let data = {
			"mode": fields.mode,
			"name": fields.name,
			"configuration": {"env": env},
		};
		if (fields.ca) {
			data.configuration.ca = fields.ca;
		}
		return axios.put("/infra/kubernetes/resource/restart",
			data)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteItem(env, fields) {
		let data = {
			"data": {
				"mode": fields.mode,
				"name": fields.name,
				"configuration": {"env": env},
				"keepService": fields.keepService || false,
			}
		};
		if (fields.ca) {
			data.configuration.ca = fields.ca;
		}
		return axios.delete("/infra/kubernetes/item",
			data)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getCDTokens() {
		return axios.get("/infra/cd/tokens")
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteCDToken(token) {
		return axios.delete("/infra/cd/token", {"data": {"token": token}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateCDToken(token, status) {
		return axios.put("/infra/cd/token/status", {"token": token, "status": status})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addToken(label) {
		return axios.post("/infra/cd/token", {"label": label})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	return {
		getService: _getService,
		
		addSecretRegistryWizard: _addSecretRegistryWizard,
		addSecretWizard: _addSecretWizard,
		
		getStorage: _getStorage,
		getServices: _getServices,
		getClusters: _getClusters,
		getWorkloads: _getWorkloads,
		getConfigurations: _getConfigurations,
		
		inspect: _inspect,
		getManualAwareness: _getManualAwareness,
		getNamespaces: _getNamespaces,
		getInfraAccounts: _getInfraAccounts,
		deleteInfraAccount: _deleteInfraAccount,
		saveInfraAccountAcl: _saveInfraAccountAcl,
		deleteInfraAccountAcl: _deleteInfraAccountAcl,
		addInfraAccount: _addInfraAccount,
		updateInfraAccount: _updateInfraAccount,
		podExec: _podExec,
		podLog: _podLog,
		restart: _restart,
		deleteItem: _deleteItem,
		
		addWorkload: _addWorkload,
		updateWorkload: _updateWorkload,
		deleteWorkload: _deleteWorkload,
		addService: _addService,
		updateService: _updateService,
		deleteService: _deleteService,
		addConfiguration: _addConfiguration,
		deleteConfiguration: _deleteConfiguration,
		
		addStorage: _addStorage,
		updateStorage: _updateStorage,
		deleteStorage: _deleteStorage,
		
		getCDTokens: _getCDTokens,
		deleteCDToken: _deleteCDToken,
		updateCDToken: _updateCDToken,
		addToken: _addToken
	}
})();
export default ConsoleService;