import {Button, Col, Form, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NotificationManager} from "../../../../../soajs/urac/components";
import {ConsoleService} from "../../../../../services";

const consoleService = ConsoleService.getService();

export default function AddUserModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, item: null});
	const [fields, setFields] = useState({
		"userId": "",
		"id": null,
		"password": "",
		"confirmPassword": ""
	});
	
	function validateForm() {
		return (
			fields.userId.length > 0 &&
			fields.id.length > 0 &&
			fields.password.length > 0 &&
			fields.confirmPassword.length > 0 &&
			fields.confirmPassword === fields.password
		);
	}
	
	function handleFieldChange(event) {
		setFields({
			...fields,
			[event.target.id]: event.target.value
		});
	}
	
	async function handleSubmit() {
		try {
			await consoleService.addUser(fields);
			NotificationManager.success(t("soajs:messages.addUser"));
			handleAction("users", modalOpt.item.id);
			handleClose();
		} catch
			(e) {
			NotificationManager.error(e.message);
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		if (isSubscribed && modalOpt && modalOpt.item) {
			setFields({
				"userId": "",
				"id": modalOpt.item.id || null,
				"password": "",
				"confirmPassword": ""
			});
		} else {
			setFields({
				"userId": "",
				"id": null,
				"password": "",
				"confirmPassword": ""
			});
		}
		return () => (isSubscribed = false);
	}, [modalOpt]);
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.userAdd")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Username")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="userId"
									size="sm"
									value={fields.userId}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
					</Form.Group>
					<Form.Group>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Password")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="password"
									size="sm"
									type="password"
									value={fields.password}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
					</Form.Group>
					<Form.Group>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.ConfirmPassword")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="confirmPassword"
									size="sm"
									type="password"
									value={fields.confirmPassword}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button
					variant="success"
					onClick={handleSubmit}
					disabled={!validateForm()}
				>
					{t("soajs:buttons.Add")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}