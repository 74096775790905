import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import common_fr from "./translations/fr/common.json";
import common_en from "./translations/en/common.json";
import urac_en from "./translations/en/urac.json";
import urac_fr from "./translations/fr/urac.json";
import organization_en from "./translations/en/organization.json";
import organization_fr from "./translations/fr/organization.json";
import soajs_en from "./translations/en/soajs.json";
import soajs_fr from "./translations/fr/soajs.json";

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'EN',
		debug: false,
		interpolation: {
			escapeValue: false,
		},
		resources: {
			EN: {
				common: common_en,
				urac: urac_en,
				organization: organization_en,
				soajs: soajs_en,
			},
			FR: {
				common: common_fr,
				urac: urac_fr,
				organization: organization_fr,
				soajs: soajs_fr,
			}
		}
	});


export default i18n;