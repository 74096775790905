import Template from "../Template/Template";


export default function Config() {
	
	return (
		<Template
			type={"config"}
			settings={{"turnOffRecipesConfiguration": true}}
		/>
	);
}