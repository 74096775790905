import {Route, Switch, Redirect} from "react-router-dom";
import React from "react";
import {AuthenticatedRoute} from "../../soajs/helpers";
import Repositories from "./containers/Repositories/Repositories";
import Accounts from "./containers/Accounts/Accounts";
import AddAccount from "./containers/Accounts/AddAccount";

const rootPath = "/development";
const defaultPath = "/git/repositories";

export default function Routes() {
	return (
		<Switch>
			<AuthenticatedRoute path={rootPath + "/git/accounts/add"}>
				<AddAccount
					rootPath={rootPath + "/git/accounts"}
				/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/git/accounts"}>
				<Accounts
					rootPath={rootPath + "/git/accounts"}
				/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/git/repositories"}>
				<Repositories/>
			</AuthenticatedRoute>
			<Route
				render={() => {
					return (<Redirect to={rootPath + defaultPath}/>)
				}}/>
		</Switch>
	)
}