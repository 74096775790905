import axios from "axios";
import {response_catch, response_then} from "./commonResponse";

const MultitenantService = (function () {
	let _service = null;
	
	function _getService() {
		if (!_service) {
			_service = this;
			return _service;
		}
		return _service;
	}
	
	function _getMyConsoleProduct() {
		return axios.get("/multitenant/product/console")
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getMyConsoleTenant() {
		return axios.get("/multitenant/tenant/console")
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getProduct(id) {
		return axios.get("/multitenant/product", {"params": {"id": id}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getProducts() {
		return axios.get("/multitenant/products")
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getTenants(criteria) {
		
		return axios.get("/multitenant/tenants", {
			"params": {
				"limit": criteria.limit,
				"start": criteria.skip,
				"type": criteria.type,
				"keywords": criteria.keywords || null,
				"category": criteria.category || null
			}
		})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getTenantSubTenants(code, criteria) {
		
		return axios.get("/multitenant/tenant/tenants", {
			"params": {
				"limit": criteria.limit,
				"start": criteria.skip,
				"keywords": criteria.keywords || null,
				"code": code
			}
		})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _editConsolePackageAclEnv(fields) {
		// fields = {id, code, type, env, acl}
		return axios.put("/multitenant/product/console/package/acl/env",
			{"acl": fields.acl},
			{
				"params": {
					"code": fields.code,
					"type": fields.type,
					"env": fields.env,
					"id": fields.id
				}
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _editConsolepackage(fields) {
		return axios.put("/multitenant/product/console/package", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addConsolepackage(fields) {
		return axios.post("/multitenant/product/console/package", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteConsolepackage(fields) {
		return axios.delete("/multitenant/product/console/package", {"params": fields})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteTenant(fields) {
		return axios.delete("/multitenant/tenant", {"params": fields})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteConsoleTenant(fields) {
		return axios.delete("/multitenant/tenant/console", {"params": fields})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteTenantApplication(fields) {
		return axios.delete("/multitenant/tenant/application", {"params": fields})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteTenantConsoleApplication(fields) {
		return axios.delete("/multitenant/tenant/console/application", {"params": fields})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteTenantApplicationKey(fields) {
		return axios.delete("/multitenant/tenant/application/key", {"params": fields})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteTenantConsoleApplicationKey(fields) {
		return axios.delete("/multitenant/tenant/console/application/key", {"params": fields})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteTenantApplicationKeyExt(fields) {
		return axios.delete("/multitenant/tenant/application/key/ext", {"params": fields})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteTenantConsoleApplicationKeyExt(fields) {
		return axios.delete("/multitenant/tenant/console/application/key/ext", {"params": fields})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addTenantApplicationKey(data) {
		return axios.post("/multitenant/admin/tenant/application/key", data)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addTenantConsoleApplicationKey(data) {
		return axios.post("/multitenant/tenant/console/application/key", data)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateTenantApplicationKey(fields) {
		return axios.put("/multitenant/admin/tenant/application/key/config", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateTenantConsoleApplicationKey(fields) {
		return axios.put("/multitenant/tenant/console/application/key/config", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateTenantApplicationExtKey(fields) {
		return axios.put("/multitenant/admin/tenant/application/key/ext", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateTenantConsoleApplicationExtKey(fields) {
		return axios.put("/multitenant/tenant/console/application/key/ext", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateTenantOauth(fields) {
		return axios.put("/multitenant/admin/tenant/oauth", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addTenant(fields) {
		return axios.post("/multitenant/tenant", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addConsoleTenant(fields) {
		return axios.post("/multitenant/tenant/console", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addApplication(fields) {
		return axios.post("/multitenant/admin/tenant/application", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addConsoleApplication(fields) {
		return axios.post("/multitenant/tenant/console/application", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addTenantExtKey(fields) {
		return axios.post("/multitenant/admin/tenant/application/key/ext", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addTenantConsoleExtKey(fields) {
		return axios.post("/multitenant/tenant/console/application/key/ext", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateTenant(fields) {
		return axios.put("/multitenant/admin/tenant", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateConsoleTenant(fields) {
		return axios.put("/multitenant/tenant/console", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateApplication(fields) {
		return axios.put("/multitenant/admin/tenant/application", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateConsoleApplication(fields) {
		return axios.put("/multitenant/tenant/console/application", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addProduct(fields) {
		return axios.post("/multitenant/product", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateProduct(fields) {
		return axios.put("/multitenant/product", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteProduct(fields) {
		return axios.delete("/multitenant/product", {"params": fields})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addPackage(fields) {
		return axios.post("/multitenant/product/package", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updatePackage(fields) {
		return axios.put("/multitenant/product/package", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deletePackage(fields) {
		return axios.delete("/multitenant/product/package", {"params": fields})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updatePackageAcl(fields) {
		return axios.put("/multitenant/product/package/acl/env", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getConsoleTenants(criteria) {
		
		return axios.get("/multitenant/tenants/console", {
			"params": {
				"limit": criteria.limit,
				"start": criteria.skip,
				"scope": criteria.scope,
				"keywords": criteria.keywords || null
			}
		})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getConsoleProducts() {
		return axios.get("/multitenant/products/console")
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	return {
		getService: _getService,
		getMyConsoleProduct: _getMyConsoleProduct,
		getProduct: _getProduct,
		getProducts: _getProducts,
		getTenants: _getTenants,
		getTenantSubTenants: _getTenantSubTenants,
		editConsolepackage: _editConsolepackage,
		addConsolepackage: _addConsolepackage,
		deleteConsolepackage: _deleteConsolepackage,
		editConsolePackageAclEnv: _editConsolePackageAclEnv,
		
		deleteTenant: _deleteTenant,
		deleteTenantApplication: _deleteTenantApplication,
		deleteTenantApplicationKey: _deleteTenantApplicationKey,
		deleteTenantApplicationKeyExt: _deleteTenantApplicationKeyExt,
		
		addTenant: _addTenant,
		addTenantApplicationKey: _addTenantApplicationKey,
		addApplication: _addApplication,
		addTenantExtKey: _addTenantExtKey,
		
		addProduct: _addProduct,
		updateProduct: _updateProduct,
		deleteProduct: _deleteProduct,
		addPackage: _addPackage,
		updatePackage: _updatePackage,
		deletePackage: _deletePackage,
		
		updateTenant: _updateTenant,
		updateApplication: _updateApplication,
		updateTenantApplicationKey: _updateTenantApplicationKey,
		updateTenantApplicationExtKey: _updateTenantApplicationExtKey,
		updateTenantOauth: _updateTenantOauth,
		
		updatePackageAcl: _updatePackageAcl,
		
		getMyConsoleTenant: _getMyConsoleTenant,
		updateTenantConsoleApplicationKey: _updateTenantConsoleApplicationKey,
		
		getConsoleTenants: _getConsoleTenants,
		getConsoleProducts: _getConsoleProducts,
		
		updateConsoleTenant: _updateConsoleTenant,
		deleteConsoleTenant:_deleteConsoleTenant,
		addConsoleTenant: _addConsoleTenant,
		updateConsoleApplication: _updateConsoleApplication,
		updateTenantConsoleApplicationExtKey: _updateTenantConsoleApplicationExtKey,
		addTenantConsoleApplicationKey: _addTenantConsoleApplicationKey,
		addConsoleApplication: _addConsoleApplication,
		addTenantConsoleExtKey: _addTenantConsoleExtKey,
		deleteTenantConsoleApplication: _deleteTenantConsoleApplication,
		deleteTenantConsoleApplicationKey: _deleteTenantConsoleApplicationKey,
		deleteTenantConsoleApplicationKeyExt: _deleteTenantConsoleApplicationKeyExt
	}
})();
export default MultitenantService;