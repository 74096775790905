import {Dropdown, DropdownButton} from "react-bootstrap";
import React, {useState} from "react";

import {NotificationManager} from "../../../../../soajs/urac/components";
import {MarketplaceService} from "../../../../../services";
import JsonModal from "./modal/JsonModal";

const marketplaceService = MarketplaceService.getService();

export default function MaintenanceOperation({version, env, itemName, itemType}) {
	const [modalOpt, setModalOpt] = useState({"show": false, "data": [], "mode": "preview", "title": ""});
	const modalOptShow = (data, title) => setModalOpt({
		"show": true,
		"data": data,
		"mode": "view",
		"title": title || ""
	});
	
	async function handleMaintenance(route) {
		try {
			let response = await marketplaceService.itemMaintenance({
				"env": env,
				"name": itemName,
				"type": itemType,
				"version": version.version,
				"operation": route,
				"port": {
					"portType": version.maintenance.port.type,
					"portValue": version.maintenance.port.value || null
				}
			});
			if (response) {
				modalOptShow(response, itemName);
			}
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	return (
		<>
			<JsonModal
				modalOpt={modalOpt}
				setModalOpt={setModalOpt}
			/>
			{version.maintenance &&
			<DropdownButton
				size="sm"
				id="dropdown-item-button"
				title="Maintenance Operations"
				variant="info"
				>
				{version.maintenance && version.maintenance.readiness &&
				<Dropdown.Item
					onClick={() => {
						handleMaintenance(version.maintenance.readiness).catch();
					}}
				>
					<small>Heartbeat</small>
				</Dropdown.Item>}
				{version.maintenance &&
				version.maintenance.commands &&
				version.maintenance.commands.map((m, i) => (
					<Dropdown.Item
						key={i}
						onClick={() => {
							handleMaintenance(m.path).catch();
						}}
					>
						<small>{m.label}</small>
					</Dropdown.Item>
				))}
			</DropdownButton>
			}
		</>
	);
}