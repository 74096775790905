import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {JsonEditor, NotificationManager} from "../../../../soajs/urac/components";
import deepSet from "../../../../soajs/libs/deepSetLib";
import {LoaderButton} from "../../../../soajs/helpers";
import {MarketplaceService} from "../../../../services";

const fieldsInit = {
	"src": {"provider": "manual"},
	"soa": {
		"type": "resource",
		"name": "",
		"group": "",
		"description": "",
		"version": "",
		"subType": "",
		"tags": [],
		"attributes": {
			"att1": [],
			"att2": []
		},
		"program": [],
		"profile": {}
	},
	"readme": "",
	"release": ""
};
let _json_soa = null;
const marketplaceService = MarketplaceService.getService();

export default function AddResourceModal({modalOpt, setModalOpt}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, "reLoad": null});
	const [isLoading, setIsLoading] = useState(false);
	const [fields, setFields] = useState(fieldsInit);
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		if (event.target.type === 'number') {
			value = parseInt(value);
		}
		
		deepSet(fields, event.target.id, value);
		setFields({...fields});
	}
	
	function soa_onChange(json) {
		_json_soa = json;
	}
	
	function validateForm() {
		let valid = true;
		if (_json_soa) {
			valid = valid && _json_soa.type === "resource";
			valid = valid && _json_soa.name.length > 0;
			valid = valid && _json_soa.group.length > 0;
			valid = valid && _json_soa.description.length > 0;
			valid = valid && _json_soa.version.length > 0;
		} else {
			valid = false;
		}
		return (
			valid
		);
	}
	
	async function handleSubmit(event) {
		event.preventDefault();
		setIsLoading(true);
		try {
			await marketplaceService.addResource({
				"src": fields.src,
				"soa": _json_soa,
				"documentation": {
					"readme": fields.readme,
					"release": fields.release
				}
			});
			NotificationManager.success(t("soajs:messages.add_resource"));
			if (modalOpt.reLoad) {
				modalOpt.reLoad();
			}
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
		setIsLoading(false);
	}
	
	useEffect(() => {
		setFields(fieldsInit);
		_json_soa = null;
	}, []);
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Form onSubmit={handleSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>{t("soajs:titles.resourceAdd")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row style={{"height": "350px"}}>
						<Col>
							<JsonEditor
								mode="code"
								modes={["code", "tree"]}
								json={_json_soa || fields.soa || null}
								onChange={soa_onChange}
							/>
						</Col>
					</Row>
					<hr/>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Readme")}</Form.Label>
						<Form.Control
							style={{"fontSize": "80%"}}
							id="readme"
							size="sm"
							as="textarea"
							rows={5}
							value={fields.readme}
							onChange={handleFieldChange}
						/>
						<Form.Text className="text-info">
							Markdown text
						</Form.Text>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Release")}</Form.Label>
						<Form.Control
							style={{"fontSize": "80%"}}
							id="release"
							size="sm"
							as="textarea"
							rows={5}
							value={fields.release}
							onChange={handleFieldChange}
						/>
						<Form.Text className="text-info">
							Markdown text
						</Form.Text>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleClose}>
						{t("soajs:buttons.Close")}
					</Button>
					<LoaderButton
						variant="success"
						type="submit"
						isLoading={isLoading}
						disabled={!validateForm()}
					>
						{t("soajs:buttons.Add")}
					</LoaderButton>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}