import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";

import './i18n.js';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
		<BrowserRouter>
			<App/>
		</BrowserRouter>,
	document.getElementById('root')
);
reportWebVitals();
