import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
	Accordion,
	Button,
	Card,
	Form,
	Jumbotron,
	useAccordionToggle,
	Tab, Tabs, Row, Col
} from "react-bootstrap";
import {BiSearchAlt} from "react-icons/bi";
import {FcCollapse, FcExpand} from "react-icons/fc";

import {NotificationManager} from "../../../../soajs/urac/components";
import {MarketplaceService} from "../../../../services";
import AutoPagination from "../../../../lib/AutoPagination";

import ConfigureModal from "./ConfigureModal";

import AddButton from "../../../buttons/Add";
import EditButton from "../../../buttons/Edit";
import GearButton from "../../../buttons/Gear";
import DetailButton from "../../../buttons/Detail";
import DeleteButton from "../../../buttons/Delete";

const marketplaceService = MarketplaceService.getService();

function CustomToggle({children, eventKey, handleClick}) {
	const decoratedOnClick = useAccordionToggle(eventKey, () => {
		handleClick();
	});
	return (
		<div className="float-left" type="button" onClick={decoratedOnClick}>
			{children}
		</div>
	);
}

async function onLoad(setFields, setPagination, currentPage, criteria, isSubscribed, type, subType) {
	try {
		let c = {"limit": 50, "start": (currentPage - 1) * 50, "type": type, "subType": subType};
		if (criteria) {
			if (criteria.keywords && criteria.keywords !== "") {
				c.keywords = criteria.keywords;
			}
		}
		const response = await marketplaceService.getItems(c);
		if (isSubscribed) {
			if (response.records) {
				setFields(response.records);
				setPagination(
					{
						"totalItems": response.count,
						"maxSize": 10,
						"itemsPerPage": response.limit
					}
				);
			}
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function Template({type, subType, Children, settings, addItem, editItem, deleteItem}) {
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState([]);
	const [activeKey, setActiveKey] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [criteria, setCriteria] = useState({
		"keywords": ""
	});
	const [pagination, setPagination] = useState({
		"totalItems": 1, "maxSize": 1, "itemsPerPage": 1
	});
	const history = useHistory();
	
	function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}
	
	async function handleSearch(event) {
		event.preventDefault();
		setCurrentPage(1);
		await reLoad(1);
	}
	
	async function reLoad(page) {
		await onLoad(setFields, setPagination, page || currentPage, criteria, true, type, subType);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		onLoad(setFields, setPagination, 1, null, isSubscribed, type, subType);
		return () => (isSubscribed = false);
	}, [type, subType]);
	
	const [modalOpt, setModalOpt] = useState({"show": false, "item": null, "settings": settings});
	
	function handleModalShow(item) {
		setModalOpt({"show": true, "item": item, "settings": settings})
	}
	
	function handleDetailShow(item) {
		let uri = "/catalogs/";
		if (subType === "soajs") {
			uri = uri + "soajs/";
		}
		if (type === "service") {
			uri = uri + "api";
		} else if (type === "custom") {
			uri = uri + "custom";
		} else if (type === "resource") {
			uri = uri + "resource";
		} else if (type === "daemon") {
			uri = uri + "daemon";
		} else if (type === "static") {
			uri = uri + "frontend";
		} else if (type === "config") {
			uri = uri + "config";
		}
		history.push(uri + "/detail/" + item.type + "/" + item.name);
	}
	
	return (
		<>
			<ConfigureModal
				modalOpt={modalOpt}
				setModalOpt={setModalOpt}
				handleAfterSubmit={reLoad}
			/>
			<Jumbotron className="p-3 mb-2">
				<Row>
					<Col lg={4} xl={3}>
						<div className="float-left">
							<h5 className="font-weight-bold m-0">{t("soajs:titles.env_cat_" + type)}</h5>
							{subType && <span className="text-muted small">{subType}</span>}
						</div>
						{addItem &&
						<AddButton
							className="float-right"
							onClick={() => {
								addItem(() => {
									reLoad(1).catch();
								})
							}}
						/>
						}
					</Col>
					<Col>
						<Form onSubmit={handleSearch}>
							<Row>
								<Col xs={9} md={10}>
									<Form.Control
										id="keywords"
										size="sm"
										autoFocus
										value={criteria.keywords}
										onChange={handleCriteriaChange}
									/>
								</Col>
								<Col xs={3} md={2}>
									<Button className="float-right" variant="dark" size="sm" onClick={handleSearch}>
										<BiSearchAlt/> {t("soajs:buttons.Search")}
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			</Jumbotron>
			
			<Accordion activeKey={activeKey}>
				{fields.map((item, index) => (
					<Card key={index + 1}>
						<Card.Header>
							<Row>
								<Col xs={12} md={7}>
									<CustomToggle
										as={Card.Header}
										eventKey={index + 1}
										handleClick={() => {
											if (activeKey === (index + 1)) {
												setActiveKey(null);
											} else {
												setActiveKey(index + 1);
											}
										}}
									>
										{activeKey === (index + 1) ? <FcExpand className="mr-1"/> :
											<FcCollapse className="mr-1"/>} <span
										className="font-weight-bold">{item.name}</span> {(item.configuration && item.configuration.port) ? [item.configuration.port] : ""}
										- <small>{item.configuration.group}</small>
									</CustomToggle>
								</Col>
								<Col xs={12} md={5}>
									<GearButton className="float-right ml-2" onClick={() => {
										handleModalShow(item)
									}}/>
									<DetailButton className="float-right ml-2" onClick={() => {
										handleDetailShow(item)
									}}/>
									
									{deleteItem &&
									<DeleteButton className="float-right ml-2" onClick={() => {
										deleteItem(item, () => {
											reLoad(1).catch();
										})
									}}/>
									}
								</Col>
							</Row>
						</Card.Header>
						<Accordion.Collapse eventKey={index + 1}>
							<Card.Body>
								<Jumbotron className="p-3 bg-light">
									{item.description ? item.description : "-"}
								</Jumbotron>
								{item.versions &&
								<>
									<Tabs className="mb-3" defaultActiveKey="1" id="repos"
									      style={{borderBottom: "1px solid #dee2e6"}}>
										{item.versions.map((_O, _I) => (
											<Tab key={_I} eventKey={_I + 1}
											     title={t("soajs:catalogs.version") + " " + _O.version}>
												<Jumbotron className="p-3 bg-light">
													{item.src && item.src.provider === "manual" ?
														<>
															{_O.lastSync ?
																<>
																	{t("soajs:fields.lastUpdated")}
																	<small className="m-3">
																	<span className="font-italic">
																		{Date(_O.lastSync.ts)}
																	</span>
																	</small>
																</>
																: "-"}
															{editItem &&
															<EditButton
																className="float-right ml-3"
																onClick={() => {
																	editItem(item, _O.version, () => {
																		reLoad(1).catch();
																	});
																}}
															/>
															}
														</>
														:
														<>
															{_O.lastSync ?
																<>
																	{t("soajs:catalogs.lastSynchronized")}<small
																	className="m-3">from <span
																	className="font-weight-bold">{_O.lastSync.branch || _O.lastSync.tag}</span> @ <span
																	className="font-italic">{Date(_O.lastSync.ts)}</span></small>
																	<hr/>
																	{t("soajs:catalogs.availableBranches")}<small
																	className="m-3">[{_O.branches ? _O.branches.join(", ") : ""}]</small>
																</>
																: "-"}
														</>
													}
												</Jumbotron>
												{Children &&
												<Children
													item={item}
													version={_O}
												/>
												}
											</Tab>))}
									</Tabs>
								</>}
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				))}
				{fields.length === 0 ? (
					<span>{t("soajs:catalogs.zeroMsg")}</span>
				) : null}
			</Accordion>
			
			{fields.length > 0 ? (
				<>
					<div className="float-right mt-3">
						<AutoPagination
							currentPage={currentPage}
							totalItems={pagination.totalItems}
							itemsPerPage={pagination.itemsPerPage}
							maxSize={pagination.maxSize}
							onClick={(p) => {
								setCurrentPage(p);
								reLoad(p).catch();
							}}
						/>
					</div>
					<div className="clearfix"></div>
				</>
			) : null}
		</>
	);
}