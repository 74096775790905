import {Button, Form, Table, Alert, Col, Row} from "react-bootstrap";
import {BiSearchAlt} from "react-icons/bi";
import {GoDiffAdded} from "react-icons/go";
import {GiBroadDagger} from "react-icons/gi";
import {ImDrawer} from "react-icons/im";

import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";

import Edit from "../../../../buttons/Edit";
import Delete from "../../../../buttons/Delete";
import Uninvite from "../../../../buttons/Uninvite";

import {NotificationManager} from "../../../../../soajs/urac/components";
import {UracService} from "../../../../../soajs/urac/services";
import AutoPagination from "../../../../../lib/AutoPagination";

const uracService = UracService.getService();

function getTenantGroups({user, tenantId}) {
	let groups = null;
	if (user.config && user.config.allowedTenants && user.config.allowedTenants.length > 0) {
		for (let i = 0; i < user.config.allowedTenants.length; i++) {
			if (user.config.allowedTenants[i].tenant && user.config.allowedTenants[i].tenant.id === tenantId) {
				if (user.config.allowedTenants[i].groups) {
					groups = user.config.allowedTenants[i].groups
				} else {
					groups = null;
				}
				break;
			}
		}
	}
	return groups;
}

function PrintGroups({user, tenantId}) {
	let groups = getTenantGroups({user, tenantId});
	return (
		<>
			{groups ? groups.join(", ") : ""}
		</>
	)
}

async function onLoad(setFields, extKey, scope, env, criteria, page, setPagination, isSubscribed) {
	try {
		let c = {"limit": 50, "start": (page - 1) * 50};
		if (criteria) {
			if (criteria.keywords) {
				c.keywords = criteria.keywords;
			}
		}
		const users = await uracService.getUsersProxy(extKey, scope, c, env);
		const count = await uracService.getUsersCountProxy(extKey, scope, c, env);
		if (isSubscribed) {
			setPagination(
				{
					"totalItems": count,
					"maxSize": 10,
					"itemsPerPage": c.limit
				}
			);
		}
		if (users && Array.isArray(users)) {
			if (isSubscribed) {
				setFields(users);
			}
		} else {
			if (isSubscribed) {
				setFields([]);
			}
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function TenantUsers({extKey, tenantId, scope, env}) {
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState([]);
	
	const [criteria, setCriteria] = useState({
		"keywords": ""
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [pagination, setPagination] = useState({
		"totalItems": 1, "maxSize": 1, "itemsPerPage": 1
	});
	
	async function fetch(page) {
		if (extKey && env) {
			await onLoad(setFields, extKey, scope, env, criteria, page || currentPage, setPagination, true);
		}
	}
	
	async function handleSearch(event) {
		event.preventDefault();
		setCurrentPage(1);
		await fetch(1);
	}
	
	function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		if (event.target.type === 'number') {
			value = parseInt(value);
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}
	
	useEffect(() => {
		let isSubscribed = true;
		if (extKey && env) {
			onLoad(setFields, extKey, scope, env, null, 1, setPagination, isSubscribed).catch();
		}
		return () => (isSubscribed = false);
	}, [extKey, scope, env]);
	
	return (
		<>
			<Alert variant="light">
				<div className="float-left">
					<Row>
						<Col>
							<Form.Control
								id="keywords"
								size="sm"
								value={criteria.keywords}
								onChange={handleCriteriaChange}
							/>
						</Col>
						<Col>
							<Button
								className="float-left"
								variant="dark"
								size="sm"
								onClick={handleSearch}>
								<BiSearchAlt/> {t("soajs:buttons.Search")}
							</Button>
						</Col>
					</Row>
				</div>
				<Button
					className="float-right ml-1"
					variant="primary"
					size="sm"
					onClick={fetch}>
					<ImDrawer/> {t("soajs:buttons.Fetch")}
				</Button>
				{scope === "myTenancy" &&
				<Button
					className="float-right"
					variant="success"
					size="sm"
					disabled>
					<GoDiffAdded/> {t("soajs:buttons.Add")}
				</Button>
				}
				{scope !== "myTenancy" &&
				<Button
					className="float-right"
					variant="success"
					size="sm"
					disabled>
					<GiBroadDagger/> {t("soajs:buttons.Invite")}
				</Button>
				}
				<div className="clearfix"></div>
			</Alert>
			<Table responsive striped hover size="sm">
				<thead className="text-light bg-dark">
				<tr>
					<th>{t("soajs:buttons.Actions")}</th>
					<th>{t("soajs:fields.FirstName")}</th>
					<th>{t("soajs:fields.LastName")}</th>
					<th>{t("soajs:fields.Email")}</th>
					<th>{t("soajs:fields.Groups")}</th>
					<th>{t("soajs:fields.Username")}</th>
					<th>{t("soajs:fields.Status")}</th>
				</tr>
				</thead>
				<tbody>
				{fields && fields.map((user, index) => (
					<tr key={index}>
						<td>
							<Edit
								className="mr-1 p-1"
								disabled
							/>
							{scope === "myTenancy" &&
							<Delete
								className="mr-1 p-1"
								disabled
							/>
							}
							{scope !== "myTenancy" &&
							<Uninvite
								className="mr-1 p-1"
								disabled
							/>
							}
						</td>
						<td>{user.firstName}</td>
						<td>{user.lastName}</td>
						<td>{user.email}</td>
						<td>
							<PrintGroups
								user={user}
								tenantId={tenantId}
							/>
						</td>
						<td>{user.username}</td>
						<td>{user.status}</td>
					</tr>
				))}
				</tbody>
			</Table>
			<div className="float-right mt-3">
				<AutoPagination
					currentPage={currentPage}
					totalItems={pagination.totalItems}
					itemsPerPage={pagination.itemsPerPage}
					maxSize={pagination.maxSize}
					onClick={(p) => {
						setCurrentPage(p);
						fetch(p, null).catch();
					}}
				/>
			</div>
			<div className="clearfix"></div>
		</>
	);
}