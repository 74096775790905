import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import React from "react";
import {LinkContainer} from "react-router-bootstrap";
import {useTranslation} from "react-i18next";

function Navigation() {
	const {t} = useTranslation(["common", "soajs"]);
	const rootPath = "/reports";
	return (
		<Navbar collapseOnSelect expand="md" className="pt-0 pb-0" bg="secondary" variant="dark">
			<span></span>
			<Navbar.Toggle aria-controls="reports-navbar-nav"/>
			<Navbar.Collapse id="settings-navbar-nav" className="justify-content-end">
				<Nav activeKey={window.location.pathname}>
					<LinkContainer to={rootPath + "/api/metrics"}>
						<NavDropdown.Item><small>{t("soajs:menu.APIMetrics")}</small></NavDropdown.Item>
					</LinkContainer>
					<LinkContainer to={rootPath + "/api/catalog"}>
						<NavDropdown.Item><small>{t("soajs:menu.APICatalog")}</small></NavDropdown.Item>
					</LinkContainer>
					<LinkContainer to={rootPath + "/api/acl"}>
						<NavDropdown.Item><small>{t("soajs:menu.APIACL")}</small></NavDropdown.Item>
					</LinkContainer>
					<LinkContainer to={rootPath + "/api/monitor"}>
						<NavDropdown.Item><small>{t("soajs:menu.APIMonitor")}</small></NavDropdown.Item>
					</LinkContainer>
					<LinkContainer to={rootPath + "/api/analytics"}>
						<NavDropdown.Item><small>{t("soajs:menu.APIAnalytics")}</small></NavDropdown.Item>
					</LinkContainer>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}

export default Navigation