import React from 'react';
import PropTypes from 'prop-types';
import Button from "react-bootstrap/Button";
import Jumbotron from "react-bootstrap/Jumbotron";

import {FaApple, FaFacebook, FaGoogle, FaTwitter, FaLinkedinIn} from "react-icons/fa";
import {SiMicrosoftazure} from "react-icons/si";

class ThirdPartyLogin extends React.Component {
	static propTypes = {
		strategies: PropTypes.array,
		onRequest: PropTypes.func,
		isThirdParty: PropTypes.bool
	};
	
	static defaultProps = {
		strategies: [],
		onRequest: () => {
		},
		isThirdParty: false
	};
	
	render() {
		const {strategies, isThirdParty, onRequest} = this.props;
		const items = strategies.map((strategy, i) => {
			if (strategy === "facebook") {
				return (
					<Button
						key={i}
						block
						size="md"
						className="mt-2 mb-2"
						variant="outline-info"
						disabled={isThirdParty}
						onClick={(event) => onRequest(event, strategy)}>
						<FaFacebook/> Login in with {strategy}
					</Button>
				);
			} else if (strategy === "twitter") {
				return (
					<Button
						key={i}
						block
						size="md"
						className="mt-2 mb-2"
						variant="outline-info"
						disabled={isThirdParty}
						onClick={(event) => onRequest(event, strategy)}>
						<FaTwitter/> Login in with {strategy}
					</Button>
				);
			} else if (strategy === "apple") {
				return (
					<Button
						key={i}
						block
						size="md"
						className="mt-2 mb-2"
						variant="outline-info"
						disabled={isThirdParty}
						onClick={(event) => onRequest(event, strategy)}>
						<FaApple/> Login in with {strategy}
					</Button>
				);
			} else if (strategy === "google") {
				return (
					<Button
						key={i}
						block
						size="md"
						className="mt-2 mb-2"
						variant="outline-info"
						disabled={isThirdParty}
						onClick={(event) => onRequest(event, strategy)}>
						<FaGoogle/> Login in with {strategy}
					</Button>
				);
			} else if (strategy === "linkedin") {
				return (
					<Button
						key={i}
						block
						size="md"
						className="mt-2 mb-2"
						variant="outline-info"
						disabled={isThirdParty}
						onClick={(event) => onRequest(event, strategy)}>
						<FaLinkedinIn/> Login in with {strategy}
					</Button>
				);
			} else if (strategy === "azure") {
				return (
					<Button
						key={i}
						block
						size="md"
						className="mt-2 mb-2"
						variant="outline-info"
						disabled={isThirdParty}
						onClick={(event) => onRequest(event, strategy)}>
						<SiMicrosoftazure/> Login in with {strategy}
					</Button>
				);
			} else {
				return (null);
			}
		});
		return (
			items &&
			items.length > 0 &&
			(
				<div>
					<div className="mt-4">
						<hr/>
					</div>
					<Jumbotron
						className="thirdPartyLogin mt-4 p-3"
					>
						{items}
					</Jumbotron>
				</div>
			)
		);
	}
}

export default ThirdPartyLogin;
