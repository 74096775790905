import {Button, Form, Modal} from "react-bootstrap";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import {NotificationManager} from "../../../../soajs/urac/components";
import {MultitenantService} from "../../../../services";
import deepSet from "../../../../soajs/libs/deepSetLib";

const multitenantService = MultitenantService.getService();
const initFields = {
	"name": "",
	"description": "",
	"code": ""
};

export default function AddModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, item: null});
	
	const [fields, setFields] = useState(initFields);
	
	function validateForm() {
		return (
			fields.name.length > 0 &&
			fields.description.length > 0 &&
			fields.code.length === 5
		);
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		let obj = fields;
		deepSet(obj, event.target.id, value);
		setFields({...obj});
	}
	
	async function handleSubmit() {
		try {
			await multitenantService.addProduct(fields);
			NotificationManager.success(t("soajs:messages.productAdd"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.productAdd")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Code")}</Form.Label>
						<Form.Control
							placeholder="5 alphanumeric characters only"
							id="code"
							size="sm"
							value={fields.code}
							onChange={handleFieldChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Name")}</Form.Label>
						<Form.Control
							id="name"
							size="sm"
							value={fields.name}
							onChange={handleFieldChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Description")}</Form.Label>
						<Form.Control
							id="description"
							size="sm"
							as="textarea"
							rows={3}
							value={fields.description}
							onChange={handleFieldChange}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button
					variant="success"
					onClick={handleSubmit}
					disabled={!validateForm()}
				>
					{t("soajs:buttons.Add")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}