import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import React from "react";
import {LinkContainer} from "react-router-bootstrap";
import {useTranslation} from "react-i18next";

function Navigation() {
	const {t} = useTranslation(["common", "soajs"]);
	const rootPath = "/multitenancy";
	return (
		<Navbar collapseOnSelect expand="sm" className="pt-0 pb-0" bg="secondary" variant="dark">
			<span></span>
			<Navbar.Toggle aria-controls="settings-navbar-nav"/>
			<Navbar.Collapse id="settings-navbar-nav" className="justify-content-end">
				<Nav activeKey={window.location.pathname}>
					<LinkContainer to={rootPath + "/products"}>
						<NavDropdown.Item><small>{t("soajs:menu.products")}</small></NavDropdown.Item>
					</LinkContainer>
					<LinkContainer to={rootPath + "/tenants/main"}>
						<NavDropdown.Item><small>{t("soajs:menu.maintenants")}</small></NavDropdown.Item>
					</LinkContainer>
					<LinkContainer to={rootPath + "/tenants/sub"}>
						<NavDropdown.Item><small>{t("soajs:menu.subtenants")}</small></NavDropdown.Item>
					</LinkContainer>
					<LinkContainer to={rootPath + "/users"}>
						<NavDropdown.Item><small>{t("soajs:menu.tenantusers")}</small></NavDropdown.Item>
					</LinkContainer>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}

export default Navigation