import {Card, ListGroup, ListGroupItem} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";

export default function Awareness({registry}) {
	const {t} = useTranslation(["common", "soajs"]);
	
	return (
		registry &&
		<div>
			{registry.deployer && registry.deployer.type === "container" ? (
				<Card>
					<Card.Header>
						{t("soajs:titles.Containerdeploymentdetect")}:
					</Card.Header>
					<Card.Body className="mt-3 mb-3">
						{t("soajs:messages.containerMsg1")}
					</Card.Body>
					<Card.Footer>
						<small className="text-muted">
							{t("soajs:messages.manualMsg2")} <a href="https://soajsorg.atlassian.net/wiki/spaces/CON/pages/1914142723/Awareness">{t("common:ClickHere")}</a>
						</small>
					</Card.Footer>
				</Card>
			) : (
				<Card>
					<Card.Header>
						{t("soajs:titles.Manualdeploymentdetect")}:
					</Card.Header>
					<Card.Body className="mt-3 mb-3">
						{t("soajs:messages.manualMsg1")}
						<hr/>
						<ListGroup variant="flush">
							<ListGroupItem><span className="font-weight-bold mr-3">SOAJS_ENV:</span> {registry.code}
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">SOAJS_DEPLOY_MANUAL:</span> 1
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">SOAJS_REGISTRY_API:</span> {registry.deployer.manual.nodes}:{registry.services.config.ports.controller + registry.services.config.ports.maintenanceInc}
							</ListGroupItem>
						</ListGroup>
					</Card.Body>
					<Card.Footer>
						<small className="text-muted">
							{t("soajs:messages.manualMsg2")} <a href="https://soajsorg.atlassian.net/wiki/spaces/CON/pages/1914142723/Awareness">{t("common:ClickHere")}</a>
						</small>
					</Card.Footer>
				</Card>
			)}
		</div>
	);
}