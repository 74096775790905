// import programsData from "../charts/programs.json"
// import methodsData from "../charts/methods.json"
// import groupsData from "../charts/groups.json"

import _ from "lodash";


const _methods = ["get", "post", "put", "delete", "head", "patch", "other"];

function sumMethods(methods, response) {
	_methods.map((m, i) => {
		let v = 0;
		if (response && response.methods && response.methods[i]) {
			v = response.methods[i].value;
		}
		response.methods[i] = {
			"id": m,
			"label": m,
			"value": v + (methods[m] || 0)
		};
		return true;
	});
}

function process(data) {
	let response = {
		"programs": [],
		"groups": [],
		"methods": [],
		"services": []
	};
	let holder = {
		"groups": {},
		"methods": {}
	};
	//response.programs = programsData;
	//response.methods = methodsData;
	//response.groups = groupsData;
	
	if (data) {
		data.map((_d1, i) => {
			let p = {
				"id": _d1.name,
				"label": _d1.name,
				"value": 0
			};
			if (_d1.groups) {
				_d1.groups.map((_d2, i) => {
					let g = {
						"Group": _d2.name,
						"count": 0
					};
					if (holder.groups.hasOwnProperty(_d2.name)) {
						g = response.groups[holder.groups[_d2.name]];
					}
					if (_d2.services) {
						_d2.services.map((_d3, i) => {
							p.value = p.value + (_d3.APIs || 0);
							g.count = g.count + (_d3.APIs || 0);
							sumMethods(_d3.methods, response);
							response.services.push({
								"serviceName": _d3.name,
								"serviceGroup": _d2.name,
								"program": _d3.program,
								"apis": _d3.APIs || 0,
								"version": _d3.version
							});
							return true;
						});
					}
					if (holder.groups.hasOwnProperty(_d2.name)) {
						response.groups[holder.groups[_d2.name]] = g;
					} else {
						holder.groups[_d2.name] = response.groups.length;
						response.groups.push(g);
					}
					return true;
				});
			}
			response.programs.push(p);
			return true;
		});
	}
	
	response.services = _.uniqWith(response.services,
		function (arrVal, othVal) {
			return arrVal.serviceName === othVal.serviceName && arrVal.version === othVal.version;
		});
	return response
}

function _process(data) {
	let response = null;
	try {
		response = process(data);
	} catch (e) {
		console.log(e);
	}
	return response
}

export default _process;