import {
	Accordion,
	Alert, Button,
	Card,
	CardDeck, Col,
	ListGroup,
	ListGroupItem, Row,
	useAccordionToggle
} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {GoDiffAdded} from "react-icons/go";
import {useTranslation} from "react-i18next";
import {FcCollapse, FcExpand} from "react-icons/fc";

import {NotificationManager} from "../../../../soajs/urac/components";
import {InfraService} from "../../../../services";

import {useAppContext} from "../../../../soajs/libs/contextLib";
import DeleteButton from "../../../buttons/Delete";
import EditButton from "../../../buttons/Edit";
import AclButton from "../../../buttons/Acl";

import ConfigureModal from "./ConfigureModal";
import DeleteModal from "./DeleteModal";
import AddModal from "./AddModal";
import UpdateModal from "./UpdateModal";

const infraService = InfraService.getService();

function CustomToggle({children, eventKey, handleClick}) {
	const decoratedOnClick = useAccordionToggle(eventKey, () => {
		handleClick();
	});
	return (
		<div className="float-left" type="button" onClick={decoratedOnClick}>
			{children}
		</div>
	);
}

async function onLoad(setFields, isSubscribed) {
	try {
		const response = await infraService.getInfraAccounts();
		if (isSubscribed && response) {
			setFields(response);
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function Infrastructure() {
	const {t} = useTranslation(["common", "soajs"]);
	const {ability} = useAppContext();
	const [fields, setFields] = useState([]);
	const [activeKey, setActiveKey] = useState(1);
	const [modalOpt, setModalOpt] = useState({"show": false, "item": null});
	const handleModalShow = (item) => setModalOpt({"show": true, "item": item});
	const [deleteModalOpt, setDeleteModalOpt] = useState({"show": false, item: null});
	const handleDeleteShow = (item) => setDeleteModalOpt({"show": true, "item": item});
	const [addModalOpt, setAddModalOpt] = useState({"show": false});
	const handleAddShow = () => setAddModalOpt({"show": true});
	const [updateModalOpt, setUpdateModalOpt] = useState({"show": false, item: null});
	const handleUpdateShow = (item) => setUpdateModalOpt({"show": true, "item": item});
	
	
	async function reLoad() {
		await onLoad(setFields, true);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		onLoad(setFields, isSubscribed);
		return () => (isSubscribed = false);
	}, []);
	
	return (
		fields.length > 0 &&
		<>
			<UpdateModal
				modalOpt={updateModalOpt}
				setModalOpt={setUpdateModalOpt}
				handleAction={reLoad}
			/>
			<AddModal
				modalOpt={addModalOpt}
				setModalOpt={setAddModalOpt}
				handleAction={reLoad}
			/>
			<DeleteModal
				modalOpt={deleteModalOpt}
				setModalOpt={setDeleteModalOpt}
				handleAction={reLoad}
			/>
			<ConfigureModal
				modalOpt={modalOpt}
				setModalOpt={setModalOpt}
				handleAfterSubmit={reLoad}
			/>
			<div>
				<h4 className="float-left">{t("soajs:titles.infrastructure")}</h4>
				{ability.can('infra_account', 'add') &&
				<Button variant="success" className="float-right" size="sm"
				        onClick={() => {
					        handleAddShow();
				        }}
				>
					<GoDiffAdded/> {t("soajs:buttons.Add")}
				</Button>}
				<span className="clearfix"></span>
			</div>
			<hr/>
			<Accordion activeKey={activeKey}>
				{fields.map((d, i) => (
					<Card key={i + 1}>
						<Card.Header>
							<Row>
								<Col xs={12} md={8}>
									<CustomToggle
										as={Card.Header}
										eventKey={i + 1}
										handleClick={() => {
											if (activeKey === (i + 1)) {
												setActiveKey(null);
											} else {
												setActiveKey(i + 1);
											}
										}}
									>
										{activeKey === (i + 1) ? <FcExpand className="mr-1"/> :
											<FcCollapse className="mr-1"/>} <span
										className="font-weight-bold">{d.label}</span>
									</CustomToggle>
								</Col>
								<Col xs={12} md={4}>
									{ability.can('infra_account', 'delete') &&
									<DeleteButton
										className="float-right ml-1"
										onClick={() => {
											handleDeleteShow(d)
										}}
									/>
									}
									{ability.can('infra_account', 'updateAcl') &&
									<AclButton
										className="float-right ml-1"
										onClick={() => {
											handleModalShow(d)
										}}
									/>
									}
									{ability.can('infra_account', 'edit') &&
									<EditButton
										className="float-right ml-1"
										onClick={() => {
											handleUpdateShow(d);
										}}
									/>
									}
								</Col>
							</Row>
						</Card.Header>
						<Accordion.Collapse eventKey={i + 1}>
							<Card.Body>
								{d.description}
								<Alert variant="info" className="small mt-3 mb-3">
									<ListGroup variant="flush">
										<ListGroupItem><span
											className="font-weight-bold">{t("soajs:fields.MasterNode")}:</span> {d.configuration.url}
										</ListGroupItem>
										<ListGroupItem><span
											className="font-weight-bold">{t("soajs:fields.Port")}:</span> {d.configuration.port}
										</ListGroupItem>
										{d.deployments && d.deployments.length > 0 &&
										<ListGroupItem><span
											className="font-weight-bold">{t("soajs:fields.Environment")} / {t("soajs:fields.Namespace")}:</span>
											
											<CardDeck className="mt-2">
												{d.deployments.map((env, index) => (
													<Card key={index}
													      className="text-center text-white mb-2 mt-2 ml-2 mr-2"
													      bg="secondary"
													      style={{"minWidth": '11rem', "maxWidth": '11rem'}}>
														<Card.Body
															className="p-2">{env.env} / {env.namespace}</Card.Body>
													</Card>))}
											</CardDeck>
										
										
										</ListGroupItem>
										}
									</ListGroup>
								</Alert>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				))}
			</Accordion>
		</>
	)
};