import React from "react";
import {Card, Row, Col, Alert, Badge} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import Emmp from "../../images/emmp.png";
import {Link} from 'react-router-dom';

export default function Home() {
	const {t} = useTranslation("common");
	return (
		<div className="container mt-3" style={{"minHeight": "500px"}}>
			<Alert variant="primary">
				You can start by connecting <b>SOAJS</b> to your <Badge variant="secondary">Infrastructures</Badge> and <Badge variant="secondary">GIT Accounts</Badge> from under <Link to="/settings">{t("soajs:menu.Manage")}</Link>.
			</Alert>
			<Card className="mb-3 text-white" bg={"dark"}>
				<Card.Header>
					{t("welcome")}
				</Card.Header>
				<Card.Body>
					<Row>
						<Col className="small">
							<img alt="microservice management platform" className="float-right" src={Emmp} style={{"width":"50%"}} />
							The only complete, end-to-end microservices management platform, SOAJS simplifies and
							accelerates the adoption of multitenant microservices architecture by eliminating
							proliferation pain.
							<hr/>
							The SOAJS platform empowers organizations to create and operate a microservices architecture
							capable of supporting any framework while providing API productization, multi-tenancy,
							multi-layer security, cataloging, awareness, and adaptable to existing source code; to
							automatically catalog and release software components with multi-tenant, multi-version and
							multi-platform capabilities.
							<hr/>
							SOAJS integrates and orchestrates multiple infrastructures and technologies in a simplistic
							and secured approach while accelerating the release cycle with custom continuous integration
							& a smart continuous delivery pipeline.
							<hr/>
							The SOAJS platform is capable of creating and managing custom environments per product, per
							department, per team, per resource, and per technology in a simplistic approach empowering
							every member of the organization.
							
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</div>
	);
}