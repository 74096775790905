import {
	Alert,
	Button,
	Col,
	Dropdown,
	DropdownButton,
	Row,
	Badge,
	Accordion,
	useAccordionToggle,
	Form, Jumbotron
} from "react-bootstrap";
import React, {useEffect, useState} from "react";

import {NotificationManager} from "../../../../soajs/urac/components";
import {ConsoleService, SoamonitorService} from "../../../../services";
import AutoPagination from "../../../../lib/AutoPagination";

import JsonModal from "../../../../soajs/urac/components/JsonModal";
import {FcCollapse, FcExpand} from "react-icons/fc";
import {BiSearchAlt} from "react-icons/bi";
import {useTranslation} from "react-i18next";
import DatePicker from "react-datepicker";

const consoleService = ConsoleService.getService();
const soamonitorService = SoamonitorService.getService();

const methodVariant = {
	"get": "primary",
	"post": "success",
	"delete": "danger",
	"put": "warning",
	"patch": "info",
	"head": "secondary",
	"other": "light"
};

function getMethodVariant(method) {
	if (method && methodVariant[method]) {
		return methodVariant[method];
	} else {
		return methodVariant["other"];
	}
}

function CustomToggle({children, eventKey, handleClick}) {
	const decoratedOnClick = useAccordionToggle(eventKey, () => {
		handleClick();
	});
	return (
		<div className="float-right" type="button" onClick={decoratedOnClick}>
			{children}
		</div>
	);
}

export default function ApiMonitor() {
	const {t} = useTranslation(["common", "soajs"]);
	const [items, setItems] = useState([]);
	const [environments, setEnvironments] = useState([]);
	const [selectedEnv, setSelectedEnv] = useState(null);
	const [selectedMsg, setSelected] = useState("-- Select an environment --");
	
	const [criteria, setCriteria] = useState({
		"threshold": 0,
		"dateFrom": "",
		"dateTo": ""
	});
	
	const [activeKey, setActiveKey] = useState(0);
	
	const [currentPage, setCurrentPage] = useState(1);
	const [pagination, setPagination] = useState({
		"totalItems": 1, "maxSize": 1, "itemsPerPage": 1
	});
	
	const [modalOpt, setModalOpt] = useState({"show": false, "json": {}, "mode": "view", "title": ""});
	const modalOptShow = (json, title) => setModalOpt({
		"show": true,
		"json": json,
		"mode": "view",
		"title": title || ""
	});
	
	function showJson(input, title) {
		let json = input;
		if (typeof json === "string") {
			try {
				json = JSON.parse(json);
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		modalOptShow(json, title);
	}
	
	function handleFromDateChange(dp) {
		if (dp) {
			criteria.dateFrom = dp.toISOString();
			setCriteria({...criteria});
		} else {
			criteria.dateFrom = "";
			setCriteria({...criteria});
		}
	}
	
	function handleToDateChange(dp) {
		if (dp) {
			criteria.dateTo = dp.toISOString();
			setCriteria({...criteria});
		} else {
			criteria.dateTo = "";
			setCriteria({...criteria});
		}
	}
	
	async function reLoad(page, env) {
		try {
			let c = {"limit": 12, "start": (page - 1) * 12};
			if (criteria.threshold) {
				c.threshold = {"above": criteria.threshold};
			}
			if (criteria.dateFrom && criteria.dateFrom.length > 0) {
				if (!c.dates) {
					c.dates = {};
				}
				c.dates.from = new Date(criteria.dateFrom).getTime();
			}
			if (criteria.dateTo && criteria.dateTo.length > 0) {
				if (!c.dates) {
					c.dates = {};
				}
				c.dates.to = new Date(criteria.dateTo).getTime();
			}
			const response = await soamonitorService.getMonitorItems(c, env || selectedEnv);
			if (response) {
				setPagination(
					{
						"totalItems": response.count,
						"maxSize": 10,
						"itemsPerPage": response.limit
					}
				);
				setItems(response.items);
			}
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function handleSelectEnv(code) {
		setSelectedEnv(code);
		setSelected(code);
		await reLoad(1, code);
	}
	
	function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		if (event.target.type === 'number') {
			value = parseInt(value);
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}
	
	async function handleSearch(event) {
		event.preventDefault();
		setCurrentPage(1);
		await reLoad(1, null);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				const _environments = await consoleService.getEnvironments();
				if (_environments && isSubscribed) {
					setEnvironments(_environments);
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad().catch();
		return () => (isSubscribed = false);
	}, []);
	
	return (
		<>
			<Alert variant="info" className="mt-4 mb-2">
				<h6 className="float-left mr-3 mt-2">
					Environment:
				</h6>
				<DropdownButton
					size="sm"
					id="dropdown-item-button"
					title={selectedMsg}
					variant="light"
					className="small">
					{environments.map((item, index) => (
						<Dropdown.Item
							as="button"
							key={index}
							active={selectedEnv === item.code}
							onClick={() => {
								handleSelectEnv(item.code).catch();
							}}>
							<small>
								{item.code}
							</small>
						</Dropdown.Item>
					))}
				</DropdownButton>
				<span className="clearfix"></span>
			</Alert>
			<Jumbotron className="p-2 mb-1">
				<Form onSubmit={handleSearch}>
					<Row className="small">
						<Col xs={3}>
							<Form.Control
								style={{
									"fontSize": "80%"
								}}
								id="threshold"
								size="sm"
								type="number"
								min={0}
								value={criteria.threshold}
								onChange={handleCriteriaChange}
							/>
							<Form.Text className="text-muted">
								Request above threshold
							</Form.Text>
						</Col>
						<Col>
							<DatePicker
								selected={(criteria.dateFrom && criteria.dateFrom !== "") ? new Date(criteria.dateFrom) : null}
								onChange={handleFromDateChange}
							/>
							<Form.Text className="text-muted">
								From Date
							</Form.Text>
						</Col>
						<Col>
							<DatePicker
								selected={(criteria.dateTo && criteria.dateTo !== "") ? new Date(criteria.dateTo) : null}
								onChange={handleToDateChange}
							/>
							<Form.Text className="text-muted">
								To Date
							</Form.Text>
						</Col>
						<Col>
							<Button
								disabled={!selectedEnv}
								className="float-right"
								variant="dark"
								size="sm"
								onClick={handleSearch}>
								<BiSearchAlt/> {t("soajs:buttons.Search")}
							</Button>
						</Col>
					</Row>
				</Form>
			</Jumbotron>
			
			{items && items.length > 0 &&
			<>
				<JsonModal
					modalOpt={modalOpt}
					setModalOpt={setModalOpt}
				/>
				<Accordion activeKey={activeKey}>
					{items.map((d, i) => (
						<Alert key={i} variant="light" className="mt-2 mb-2" style={{"borderColor": "#c6c8ca"}}>
							<Row>
								<Col>
								<span className="float-left">
									<Button size="sm" className="mr-2 pl-1 pr-1 pt-0 pb-0"
									        variant={getMethodVariant(d.method.toLowerCase())}><span
										className="small">{d.method.toUpperCase()}</span></Button>
									<span className="font-weight-bold">{d.api}</span> <Badge
									variant="secondary">{d.time.total}ms</Badge>
								</span>
									
									<span className="float-right">
									<Badge variant="info mr-5">{d.name} v{d.version}</Badge>
									<span className="mr-2 small">{d.ts ? new Date(d.ts).toISOString() : null}</span>
									<CustomToggle
										eventKey={i + 1}
										handleClick={() => {
											if (activeKey === (i + 1)) {
												setActiveKey(null);
											} else {
												setActiveKey(i + 1);
											}
										}}
									>
									{activeKey === (i + 1) ? <FcExpand/> :
										<FcCollapse/>}
								</CustomToggle>
								</span>
									<span className="clearfix"></span>
								</Col>
							</Row>
							
							<Accordion.Collapse eventKey={i + 1}>
								<>
									<hr className="mt-2 mb-1"/>
									<Row className="mt-2 small">
										<Col>
								<span className="float-left">
									{d.tenant ? d.tenant.id : null} [{d.tenant ? d.tenant.code : null}]
								</span>
											<span
												className="float-right">{d.application ? d.application.product : null} [{d.application ? d.application.package : null}]</span>
										</Col>
									</Row>
									<Row className="mt-3">
										<Col>
											{d.body &&
											<Button
												className="float-left ml-2"
												variant="link"
												size="sm"
												style={{"borderColor": "#c6c8ca"}}
												onClick={() => {
													showJson(d.body);
												}}>
												body
											</Button>}
											
											<Button
												className="float-right ml-2"
												variant="link"
												size="sm"
												style={{"borderColor": "#c6c8ca"}}
												onClick={() => {
													showJson(d.response);
												}}>
												response
											</Button>
											<Button
												className="float-right ml-5"
												variant="link"
												size="sm"
												style={{"borderColor": "#c6c8ca"}}
												onClick={() => {
													showJson(d.query);
												}}>
												request
											</Button>
											
											<Button
												className="float-right ml-2"
												variant="link"
												size="sm"
												style={{"borderColor": "#c6c8ca"}}
												onClick={() => {
													showJson(d.headers);
												}}>
												header
											</Button>
											<Button
												className="float-right ml-2"
												variant="link"
												size="sm"
												style={{"borderColor": "#c6c8ca"}}
												onClick={() => {
													showJson({
														"tenant": d.tenant,
														"key": d.key,
														"user": d.urac,
														"time": d.time,
														"url": d.url
													});
												}}>
												info
											</Button>
										</Col>
									</Row>
								</>
							</Accordion.Collapse>
						</Alert>
					))}
				</Accordion>
				<div className="float-right mt-3">
					<AutoPagination
						currentPage={currentPage}
						totalItems={pagination.totalItems}
						itemsPerPage={pagination.itemsPerPage}
						maxSize={pagination.maxSize}
						onClick={(p) => {
							setCurrentPage(p);
							reLoad(p, null).catch();
						}}
					/>
				</div>
				<div className="clearfix"></div>
			</>}
		</>
	);
}