import {Accordion, Button, Card, ListGroup, ListGroupItem, Modal, Tab, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import _ from "lodash";

import AbilitiesLabels from "../lib/AbilitiesLabels.json";

import Granular from "./Granular"

import getACL from "../lib/scopeAclLib";
import APIs from "../../../../../availableAbilities";
import {NotificationManager} from "../../../../../soajs/urac/components";
import {MultitenantService} from "../../../../../services";

import config from "../../../../../config";

const multitenantService = MultitenantService.getService();

function getAllServiceAbilitiesAcl(name, service) {
	if (APIs.abilities[name] || APIs.default[name]) {
		service.apisPermission = "restricted";
		if (APIs.abilities[name]) {
			Object.keys(APIs.abilities[name]).map((method, index) => {
				service[method] = {
					"apis": {}
				};
				Object.keys(APIs.abilities[name][method]).map((api, index) => {
					service[method].apis[api] = {"access": true};
					return true;
				});
				return true;
			});
		}
		if (APIs.default[name]) {
			Object.keys(APIs.default[name]).map((method, index) => {
				service[method] = {
					"apis": {}
				};
				Object.keys(APIs.default[name][method]).map((api, index) => {
					if (APIs.default[name][method][api].isPublic) {
						service[method].apis[api] = {"access": false};
					} else {
						service[method].apis[api] = {"access": true};
					}
					return true;
				});
				return true;
			});
		}
		return service;
	} else {
		return service;
	}
}

function initializeACL(opts, scope) {
	let pack = _.merge({}, opts);
	Object.keys(pack.acl).map((env, index) => {
		if (env === config.consoleEnvironment) {
			if (!pack.aclTypeByEnv || !pack.aclTypeByEnv[env] || pack.aclTypeByEnv[env] !== "granular") {
				if (!pack.aclTypeByEnv) {
					pack.aclTypeByEnv = {};
				}
				pack.aclTypeByEnv[env] = "granular";
				Object.keys(pack.acl[env]).map((name, index) => {
					let service = pack.acl[env][name];
					let obj = {};
					for (let i = 0; i < service.length; i++) {
						if (service[i].version) {
							let version = service[i].version;
							if (scope.acl[env][name][version]) {
								obj[version] = getACL(scope.acl[env][name][version], service[i]);
							}
							if (!obj[version].apisPermission || obj[version].apisPermission !== "restricted") {
								obj[version] = getAllServiceAbilitiesAcl(name, obj[version]);
							}
						}
					}
					pack.acl[env][name] = obj;
					return true;
				});
			} else {
				Object.keys(pack.acl[env]).map((name, index) => {
					Object.keys(pack.acl[env][name]).map((version, index) => {
						if (!pack.acl[env][name][version].apisPermission || pack.acl[env][name][version].apisPermission !== "restricted") {
							pack.acl[env][name][version] = getAllServiceAbilitiesAcl(name, pack.acl[env][name][version]);
						}
						return true;
					});
					return true;
				});
			}
		}
		return true;
	});
	
	return pack;
}

export {initializeACL}
export default function AclModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const [initialized, setInitialized] = useState(false);
	
	const handleClose = () => {
		setInitialized(false);
		setModalOpt({"show": false, opts: null, scope: null});
	};
	
	function removeAbility(name, method, version, api, env) {
		if (modalOpt.opts.acl[env][name]) {
			if (modalOpt.opts.acl[env][name][version]) {
				if (modalOpt.opts.acl[env][name][version][method]) {
					if (modalOpt.opts.acl[env][name][version][method].apis) {
						if (modalOpt.opts.acl[env][name][version][method].apis[api]) {
							delete modalOpt.opts.acl[env][name][version][method].apis[api];
							setModalOpt({...modalOpt});
						}
					}
				}
			}
		}
	}
	
	function addAbility(name, method, version, api, env, isPublic) {
		if (!modalOpt.opts.acl[env][name]) {
			modalOpt.opts.acl[env][name] = {};
		}
		if (!modalOpt.opts.acl[env][name][version]) {
			modalOpt.opts.acl[env][name][version] = {};
		}
		modalOpt.opts.acl[env][name][version].access = true;
		modalOpt.opts.acl[env][name][version].apisPermission = "restricted";
		if (!modalOpt.opts.acl[env][name][version][method]) {
			modalOpt.opts.acl[env][name][version][method] = {
				"apis": {}
			};
		}
		modalOpt.opts.acl[env][name][version][method].apis[api] = {
			"access": isPublic ? false : true
		}
		setModalOpt({...modalOpt});
	}
	
	function validateForm() {
		return true;
	}
	
	async function handleSubmit() {
		try {
			let fields = {
				"id": modalOpt.opts._id,
				"code": modalOpt.opts.code,
				"type": "granular",
				"env": config.consoleEnvironment,
				"acl": modalOpt.opts.acl[config.consoleEnvironment]
			};
			await multitenantService.editConsolePackageAclEnv(fields);
			NotificationManager.success(t("soajs:messages.editPackage"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	useEffect(() => {
		if (modalOpt.opts && modalOpt.opts.acl) {
			// Object.keys(modalOpt.opts.acl).map((env, index) => {
			// 	if (env === config.consoleEnvironment) {
			// 		if (!modalOpt.opts.aclTypeByEnv || !modalOpt.opts.aclTypeByEnv[env] || modalOpt.opts.aclTypeByEnv[env] !== "granular") {
			// 			if (!modalOpt.opts.aclTypeByEnv) {
			// 				modalOpt.opts.aclTypeByEnv = {};
			// 			}
			// 			modalOpt.opts.aclTypeByEnv[env] = "granular";
			// 			Object.keys(modalOpt.opts.acl[env]).map((name, index) => {
			// 				let service = modalOpt.opts.acl[env][name];
			// 				let obj = {};
			// 				for (let i = 0; i < service.length; i++) {
			// 					if (service[i].version) {
			// 						let version = service[i].version;
			// 						let scope = modalOpt.scope.acl[env][name]
			// 						if (scope[version]) {
			// 							obj[version] = getACL(scope[version], service[i]);
			// 						}
			// 						if (!obj[version].apisPermission || obj[version].apisPermission !== "restricted") {
			// 							obj[version] = getAllServiceAbilitiesAcl(name, obj[version]);
			// 						}
			// 					}
			// 				}
			// 				modalOpt.opts.acl[env][name] = obj;
			// 				return true;
			// 			});
			// 			setModalOpt({...modalOpt});
			// 		} else {
			// 			Object.keys(modalOpt.opts.acl[env]).map((name, index) => {
			// 				Object.keys(modalOpt.opts.acl[env][name]).map((version, index) => {
			// 					if (!modalOpt.opts.acl[env][name][version].apisPermission || modalOpt.opts.acl[env][name][version].apisPermission !== "restricted") {
			// 						modalOpt.opts.acl[env][name][version] = getAllServiceAbilitiesAcl(name, modalOpt.opts.acl[env][name][version]);
			// 					}
			// 					return true;
			// 				});
			// 				return true;
			// 			});
			// 		}
			// 	}
			// 	return true;
			// });
			setInitialized(true);
		}
	}, [modalOpt, setModalOpt]);
	
	return (
		modalOpt.opts && initialized &&
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="xl">
			<Modal.Header closeButton className="bg-secondary text-white">
				<Modal.Title>{t("soajs:titles.packageInfo")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ListGroup variant="flush">
					<ListGroupItem>
						<span className="font-weight-bold">
							{t("soajs:fields.Code")}:</span> {modalOpt.opts.code}
					</ListGroupItem>
					<ListGroupItem>
						<span className="font-weight-bold">
							{t("soajs:fields.Name")}:</span> {modalOpt.opts.name}
					</ListGroupItem>
					<ListGroupItem>
						<span className="font-weight-bold">
						{t("soajs:fields.Description")}:</span> {modalOpt.opts.description}
					</ListGroupItem>
				</ListGroup>
				<hr/>
				<Tabs className="mb-3" defaultActiveKey="main" id="abilities">
					<Tab eventKey="main" title={t("soajs:titles.MainAbilities")}>
						{modalOpt.opts.acl && Object.keys(modalOpt.opts.acl).map((env, index) => (
							env === config.consoleEnvironment &&
							<div key={index}>
								{Object.keys(APIs.abilities).map((name, index) => (
									<div key={index}>
										<Accordion defaultActiveKey="0">
											<Card>
												<Card.Header className="p-1">
													<Accordion.Toggle as={Button} variant="link" eventKey="0">
														{AbilitiesLabels[name]}
													</Accordion.Toggle>
												</Card.Header>
												<Accordion.Collapse eventKey="0">
													<Card.Body>
														
														<Granular
															service={modalOpt.opts.acl[env][name]}
															name={name}
															env={env}
															addAbility={addAbility}
															removeAbility={removeAbility}
															abilityPath={"abilities"}
														/>
													
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										</Accordion>
									</div>
								))}
							</div>
						))}
					</Tab>
					<Tab eventKey="default" title={t("soajs:titles.DefaultAbilities")}>
						{modalOpt.opts.acl && Object.keys(modalOpt.opts.acl).map((env, index) => (
							env === config.consoleEnvironment &&
							<div key={index}>
								{Object.keys(APIs.default).map((name, index) => (
									<div key={index}>
										<Accordion defaultActiveKey="0">
											<Card>
												<Card.Header className="p-1">
													<Accordion.Toggle as={Button} variant="link" eventKey="0">
														{AbilitiesLabels[name]}
													</Accordion.Toggle>
												</Card.Header>
												<Accordion.Collapse eventKey="0">
													<Card.Body>
														
														<Granular
															service={modalOpt.opts.acl[env][name]}
															name={name}
															env={env}
															addAbility={addAbility}
															removeAbility={removeAbility}
															abilityPath={"default"}
														/>
													
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										</Accordion>
									</div>
								))}
							</div>
						))}
					</Tab>
				</Tabs>
			</Modal.Body>
			<Modal.Footer className="bg-light">
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button variant="success" onClick={handleSubmit}
				        disabled={!validateForm()}
				>
					{t("soajs:buttons.Update")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}