import {Alert, Table, Card, CardDeck, Form, Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {NotificationManager} from "../../../../soajs/urac/components";
import {ConsoleService} from "../../../../services";
import DeleteButton from "../../../buttons/Delete";
import AddButton from "../../../buttons/Add";

import ThrottlingAddModal from "./ThrottlingAddModal"
import {useAppContext} from "../../../../soajs/libs/contextLib";

const consoleService = ConsoleService.getService();

export default function Throttling({environment}) {
	const {ability} = useAppContext();
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState({});
	
	const [addModalOpt, setAddModalOpt] = useState({"show": false, item: null});
	const handleAddShow = (item) => setAddModalOpt({"show": true, "item": item});
	
	async function handleSubmit() {
		try {
			await consoleService.updateThrottling(environment.code, fields);
			NotificationManager.success(t("soajs:messages.updateThrottling"));
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function addThrottling(record) {
		fields[record.name] = {
			"type": record.type,
			"window": record.window,
			"limit": record.limit,
			"retries": record.retries,
			"delay": record.delay
		};
		setFields({...fields});
		try {
			await consoleService.updateThrottling(environment.code, fields);
			NotificationManager.success(t("soajs:messages.addThrottling"));
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function deleteThrottling(name) {
		delete fields[name];
		if (fields.publicAPIStrategy === name) {
			fields.publicAPIStrategy = "";
		}
		if (fields.privateAPIStrategy === name) {
			fields.privateAPIStrategy = "";
		}
		setFields({...fields});
		try {
			await consoleService.updateThrottling(environment.code, fields);
			NotificationManager.success(t("soajs:messages.deleteThrottling"));
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setFields({
			...fields,
			[event.target.id]: value
		});
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				const response = await consoleService.getThrottlingRegistry(environment.code);
				if (isSubscribed && response) {
					setFields(response);
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		environment && onLoad();
		return () => (isSubscribed = false);
	}, [environment]);
	
	return (
		<>
			<ThrottlingAddModal
				modalOpt={addModalOpt}
				setModalOpt={setAddModalOpt}
				handleAction={addThrottling}
			/>
			<CardDeck>
				<Card className="small">
					<Card.Body>
						<Card.Title>{t("soajs:titles.ThrottlingPublic")}</Card.Title>
						<Card.Text className="mt-4">
							{t("soajs.title.throttlingStrategy")}:
							<Form.Control
								className="mt-2"
								as="select"
								id="publicAPIStrategy"
								value={fields.publicAPIStrategy}
								onChange={handleFieldChange}
							>
								<option value="">---</option>
								{Object.keys(fields).map((rec, index) => (
									rec !== "publicAPIStrategy" && rec !== "privateAPIStrategy" &&
									<option key={index} value={rec}>{rec}</option>
								))}
							</Form.Control>
							
							{ability.can('registry', 'throttling') &&
							<Button
								variant="success"
								className="float-right mt-3"
								onClick={handleSubmit}
							>
								{t("soajs:buttons.Update")}
							</Button>}
						</Card.Text>
					</Card.Body>
					<Card.Footer className="small text-muted">
						Select the default throttling strategy you would like to apply to all APIs deployed in this
						environment that are not protected by oAuth <a
						href="https://soajsorg.atlassian.net/wiki/spaces/SOAJ/pages/679641089/API+Traffic+Throttling">Learn
						More</a>
					</Card.Footer>
				</Card>
				<Card className="small">
					<Card.Body>
						<Card.Title>{t("soajs:titles.ThrottlingPrivate")}</Card.Title>
						<Card.Text className="mt-4">
							{t("soajs.title.throttlingStrategy")}:
							<Form.Control
								className="mt-2"
								as="select"
								id="privateAPIStrategy"
								value={fields.privateAPIStrategy}
								onChange={handleFieldChange}
							>
								<option value="">---</option>
								{Object.keys(fields).map((rec, index) => (
									rec !== "publicAPIStrategy" && rec !== "privateAPIStrategy" &&
									<option key={index} value={rec}>{rec}</option>
								))}
							</Form.Control>
							
							{ability.can('registry', 'throttling') &&
							<Button
								variant="success"
								className="float-right mt-3"
								onClick={handleSubmit}
							>
								{t("soajs:buttons.Update")}
							</Button>}
						</Card.Text>
					</Card.Body>
					<Card.Footer className="small text-muted">
						Select the default throttling strategy you would like to apply to all APIs deployed in this
						environment that are protected by oAuth and require the access token of a logged in user. <a
						href="https://soajsorg.atlassian.net/wiki/spaces/SOAJ/pages/679641089/API+Traffic+Throttling">Learn
						More</a>
					</Card.Footer>
				</Card>
			</CardDeck>
			
			<hr className="mb-4 mt-4"/>
			<Alert variant="info" className="text-muted small mt-3">
				If you are not familiar with how API Traffic Throttling works, <a
				href="https://soajsorg.atlassian.net/wiki/spaces/SOAJ/pages/679641089/API+Traffic+Throttling">Click
				Here</a>
			</Alert>
			<hr className="mb-4 mt-4"/>
			
			<h5 className="float-left">{t("soajs:titles.ManageThrottling")}</h5>
			
			{ability.can('registry', 'throttling') &&
			<AddButton
				className="float-right mr-1"
				onClick={() => {
					handleAddShow({});
				}}
			/>}
			<Table responsive striped hover size="sm" className="mt-3">
				<thead className="text-light bg-dark">
				<tr>
					<th>{t("soajs:buttons.Actions")}</th>
					<th>{t("soajs:fields.Name")}</th>
					<th>{t("soajs:fields.Type")}</th>
					<th className="text-center">{t("soajs:fields.Window")} [ms]</th>
					<th className="text-center">{t("soajs:fields.Limit")}</th>
					<th className="text-center">{t("soajs:fields.Retries")}</th>
					<th className="text-center">{t("soajs:fields.Delay")} [ms]</th>
				</tr>
				</thead>
				<tbody>
				{Object.keys(fields).map((t, index) => (
					t !== "publicAPIStrategy" && t !== "privateAPIStrategy" &&
					<tr key={index}>
						<td>
							
							{ability.can('registry', 'throttling') &&
							<DeleteButton
								className="float-left mr-1"
								onClick={() => {
									deleteThrottling(t).catch();
								}}
							/>}
						</td>
						<td>{t}</td>
						<td>{fields[t].type === 0 ? "Per Tenant" : "Per Tenant & Per IP Address"}</td>
						<td className="text-center">{fields[t].window}</td>
						<td className="text-center">{fields[t].limit}</td>
						<td className="text-center">{fields[t].retries}</td>
						<td className="text-center">{fields[t].delay}</td>
					</tr>
				))}
				</tbody>
			</Table>
		</>
	)
};