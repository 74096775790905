import {Button, Modal} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";

export default function DeleteModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, "item": {}});
	
	function handleSubmit() {
		handleAction(modalOpt.item.name);
		handleClose();
	}
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:buttons.Delete")}</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{
				"wordBreak": "break-all"
			}}>
				{t("soajs:messages.deleteQuestion")}<span
				className="ml-3 font-weight-bold">{modalOpt.item ? modalOpt.item.title : ""}</span>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button variant="danger" onClick={handleSubmit}>
					{t("soajs:buttons.Delete")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}