import React, {Component} from 'react';
import PropTypes from 'prop-types';

import SwaggerUi, {presets} from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';

class SwaggerUI extends Component {
	
	componentDidMount() {
		SwaggerUi({
			dom_id: '#swaggerContainer',
			url: this.props.url,
			spec: this.props.spec,
			presets: [presets.apis]
		});
	}
	
	componentDidUpdate(prevProps) {
		
		if (this.props.ver !== prevProps.ver) {
			SwaggerUi({
				dom_id: '#swaggerContainer',
				url: this.props.url,
				spec: this.props.spec,
				presets: [presets.apis]
			});
		}
	}
	
	render() {
		
		return (
			<div id="swaggerContainer"/>
		);
	}
}

SwaggerUI.propTypes = {
	url: PropTypes.string,
	spec: PropTypes.object,
	ver: PropTypes.string,
	localVer: PropTypes.string
};

SwaggerUI.defaultProps = {
	url: "", //`http://petstore.swagger.io/v2/swagger.json`
	localVer: null
};

export default SwaggerUI;