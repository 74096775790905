const APIs = {
	"abilities": {
		"infra": {
			"get": {
				"/kubernetes/pod/log": {
					"I": 'infra_pod',
					"A": 'log',
					"M": "View the log of a pod"
				}
			},
			"post": {
				"/kubernetes/workload/:mode": {
					"I": 'infra_workload',
					"A": 'add',
					"M": "Add a Workload (Deployment, DaemonSet, CronJob, Pod, HPA)"
				},
				"/kubernetes/secret": {
					"I": 'infra_secret',
					"A": 'add_wizard',
					"M": "Add a text, file, or json secret via the wizard"
				},
				"/kubernetes/secret/registry": {
					"I": 'infra_secret',
					"A": 'add_wizard_registry',
					"M": "Add a private registry account information as a secret via the wizard"
				},
				"/kubernetes/configuration/:mode": {
					"I": 'infra_configuration',
					"A": 'add',
					"M": "Add a Configuration (secret)"
				},
				"/kubernetes/service/:mode": {
					"I": 'infra_service',
					"A": 'add',
					"M": "Add a new Service"
				},
				"/kubernetes/storage/:mode": {
					"I": 'infra_storage',
					"A": 'add',
					"M": "Add a new Storage (PVC, PV, StorageClass)"
				},
				"/account/kubernetes": {
					"I": 'infra_account',
					"A": 'add',
					"M": "Add a new cluster"
				},
				"/cd/token": {
					"I": 'infra_cd_token',
					"A": 'add',
					"M": "Add a a continuous delivery token"
				}
			},
			"put": {
				"/cd/token/status": {
					"I": 'infra_cd_token',
					"A": 'update',
					"M": "Update the status of a continuous delivery token"
				},
				"/kubernetes/workload/:mode": {
					"I": 'infra_workload',
					"A": 'edit',
					"M": "Update a Workload (Deployment, DaemonSet, CronJob, Pod, HPA)"
				},
				"/kubernetes/service/:mode": {
					"I": 'infra_service',
					"A": 'edit',
					"M": "Update a Service"
				},
				"/kubernetes/storage/:mode": {
					"I": 'infra_storage',
					"A": 'edit',
					"M": "Update a Storage (PV, StorageClass)"
				},
				"/kubernetes/resource/restart": {
					"I": 'infra_item',
					"A": 'restart',
					"M": "Restart all pods of a deployed item"
				},
				"/account/kubernetes/configuration": {
					"I": 'infra_account',
					"A": 'edit',
					"M": "Edit the cluster configuration"
				},
				"/account/kubernetes/acl": {
					"I": 'infra_account',
					"A": 'updateAcl',
					"M": "Update the cluster group configuration"
				},
				"/kubernetes/pod/exec": {
					"I": 'infra_pod',
					"A": 'exec',
					"M": "Execute commands inside a pod"
				}
			},
			"delete": {
				"/cd/token": {
					"I": 'infra_cd_token',
					"A": 'delete',
					"M": "Delete a continuous delivery token"
				},
				"/kubernetes/workload/:mode": {
					"I": 'infra_workload',
					"A": 'delete',
					"M": "Delete a Workload (Deployment, DaemonSet, CronJob, HPA)"
				},
				"/kubernetes/pods": {
					"I": 'infra_pod',
					"A": 'delete',
					"M": "Delete a Pod"
				},
				"/kubernetes/configuration/:mode": {
					"I": 'infra_configuration',
					"A": 'delete',
					"M": "Delete a Configuration (secret)"
				},
				"/kubernetes/service/:mode": {
					"I": 'infra_service',
					"A": 'delete',
					"M": "Delete a Service"
				},
				"/kubernetes/storage/:mode": {
					"I": 'infra_storage',
					"A": 'delete',
					"M": "Delete a Storage (PVC, PV, StorageClass)"
				},
				"/kubernetes/item": {
					"I": 'infra_item',
					"A": 'delete',
					"M": "Delete an item deployment"
				},
				"/account/kubernetes": {
					"I": 'infra_account',
					"A": 'delete',
					"M": "Delete a cluster"
				},
				"/account/kubernetes/acl": {
					"I": 'infra_account',
					"A": 'deleteAcl',
					"M": "Delete the cluster group configuration"
				}
			}
		},
		"console": {
			"post": {
				"/environment": {
					"I": 'env',
					"A": 'add',
					"M": "Add an environment"
				},
				"/registry/custom": {
					"I": 'registry_custom',
					"A": 'add',
					"M": "Add a custom registry"
				},
				"/registry/resource": {
					"I": 'registry_resource',
					"A": 'add',
					"M": "Add a resource registry"
				},
				"/registry/db/custom": {
					"I": 'registry_db',
					"A": 'add',
					"M": "Add a database"
				},
				"/tenant/oauth/user": {
					"I": 'integration_user',
					"A": 'add',
					"M": "Add an integration user"
				}
			},
			"put": {
				"/registry/throttling": {
					"I": 'registry',
					"A": 'throttling',
					"M": "Add, Update and delete throttling"
				},
				"/registry/db/prefix": {
					"I": 'registry_db',
					"A": 'prefix',
					"M": "Update global databases prefix for an environment"
				},
				"/environment/acl": {
					"I": 'env_aclGroups',
					"A": 'update',
					"M": "Update the environment group configuration"
				},
				"/environment": {
					"I": 'env',
					"A": 'update',
					"M": "Update the environment"
				},
				"/registry/custom/acl": {
					"I": 'registry_custom',
					"A": 'update_acl',
					"M": "Update the custom registry group configuration"
				},
				"/registry/resource/acl": {
					"I": 'registry_resource',
					"A": 'update_acl',
					"M": "Update the resource registry group configuration"
				},
				"/registry/custom": {
					"I": 'registry_custom',
					"A": 'edit',
					"M": "Edit and plug/unplug a custom registry"
				},
				"/registry/resource": {
					"I": 'registry_resource',
					"A": 'edit',
					"M": "Edit and plug/unplug a resource registry"
				},
				"/registry": {
					"I": 'registry',
					"A": 'edit',
					"M": "Edit registry"
				},
				"/tenant/oauth/user": {
					"I": 'integration_user',
					"A": 'edit',
					"M": "Edit an integration user"
				}
			},
			"delete": {
				"/registry/db/custom": {
					"I": 'registry_db',
					"A": 'delete',
					"M": "Delete a database"
				},
				"/registry/custom/acl": {
					"I": 'registry_custom',
					"A": 'delete_acl',
					"M": "Delete the custom registry group configuration"
				},
				"/registry/custom": {
					"I": 'registry_custom',
					"A": 'delete',
					"M": "Delete a custom registry"
				},
				"/registry/resource/acl": {
					"I": 'registry_resource',
					"A": 'delete_acl',
					"M": "Delete the resource registry group configuration"
				},
				"/registry/resource": {
					"I": 'registry_resource',
					"A": 'delete',
					"M": "Delete a resource registry configuration"
				},
				"/environment/acl": {
					"I": 'env_aclGroups',
					"A": 'delete',
					"M": "Delete the environment group configuration"
				},
				"/environment": {
					"I": 'env',
					"A": 'delete',
					"M": "Delete an environment"
				},
				"/tenant/oauth/user": {
					"I": 'integration_user',
					"A": 'delete',
					"M": "Delete an integration user"
				}
			}
		},
		"marketplace": {
			"post": {
				"/recipe": {
					"I": 'market_recipe',
					"A": 'add',
					"M": "Add a recipe"
				}
			},
			"put": {
				"/item/resource": {
					"I": 'market_item_resource',
					"A": 'add',
					"M": "Add/Update a resource item"
				},
				"/item/deploy/build": {
					"I": 'market_item',
					"A": 'build',
					"M": "Save deployment configuration and deploy"
				},
				"/item/deploy/configure": {
					"I": 'market_item',
					"A": 'configure',
					"M": "Configure a deployment for an item"
				},
				"/item/deploy/redeploy": {
					"I": 'market_item',
					"A": 'redeploy',
					"M": "Redeploy an item"
				},
				"/recipe": {
					"I": 'market_recipe',
					"A": 'edit',
					"M": "Edit a recipe"
				},
				"/item/acl": {
					"I": 'market_item',
					"A": 'settings_groups',
					"M": "Update the item group configuration"
				},
				"/item/environments": {
					"I": 'market_item',
					"A": 'settings_environments',
					"M": "Update the item environment configuration"
				},
				"/item/recipes": {
					"I": 'market_item',
					"A": 'settings_recipe',
					"M": "Update the item recipe configuration"
				},
				"/soajs/item/acl": {
					"I": 'market_item_soajs',
					"A": 'settings_groups',
					"M": "Update a soajs item group configuration"
				},
				"/soajs/item/environments": {
					"I": 'market_item_soajs',
					"A": 'settings_environments',
					"M": "Update a soajs item environment configuration"
				},
				"/soajs/item/recipes": {
					"I": 'market_item_soajs',
					"A": 'settings_recipe',
					"M": "Update a soajs item recipe configuration"
				}
			},
			"delete": {
				"/item": {
					"I": 'market_item',
					"A": 'delete',
					"M": "Delete an item from a catalog (Resource)"
				},
				"/item/configure/deploy": {
					"I": 'market_item_configure',
					"A": 'delete',
					"M": "Delete the deployment configuration of an item"
				}
			}
		},
		"repositories": {
			"post": {
				"/git/account": {
					"I": 'repo_account',
					"A": 'add',
					"M": "Add a git account"
				}
			},
			"put": {
				"/git/sync/account": {
					"I": 'repo_account',
					"A": 'synchronize',
					"M": "Synchronize a git account"
				},
				"/git/account": {
					"I": 'repo_account',
					"A": 'upgrade',
					"M": "Upgrade a git account from public to private"
				},
				"/git/repo/activate": {
					"I": 'repo',
					"A": 'turnOn',
					"M": "Turn on a repository"
				},
				"/git/repo/deactivate": {
					"I": 'repo',
					"A": 'turnOff',
					"M": "Turn off a repository"
				},
				"/git/sync/repository": {
					"I": 'repo',
					"A": 'synchronize',
					"M": "Synchronise a repository"
				},
				"/git/branch/activate": {
					"I": 'repo_branch',
					"A": 'turnOn',
					"M": "Turn on a branch"
				},
				"/git/branch/deactivate": {
					"I": 'repo_branch',
					"A": 'turnOff',
					"M": "Turn off a branch"
				},
				"/git/sync/branch": {
					"I": 'repo_branch',
					"A": 'synchronize',
					"M": "Synchronise a branch"
				},
				"/git/tag/activate": {
					"I": 'repo_tag',
					"A": 'turnOn',
					"M": "Turn on a tag"
				},
				"/git/tag/deactivate": {
					"I": 'repo_tag',
					"A": 'turnOff',
					"M": "Turn off a tag"
				}
			},
			"delete": {
				"/git/account": {
					"I": 'repo_account',
					"A": 'delete',
					"M": "Delete a git account"
				},
				"/git/repo": {
					"I": 'repo',
					"A": 'delete',
					"M": "Delete a repository"
				}
			}
		},
		"multitenant": {
			"put": {
				"/product": {
					"I": 'product',
					"A": 'edit',
					"M": "Edit a product"
				},
				"/product/package": {
					"I": 'package',
					"A": 'edit',
					"M": "Edit a product package"
				},
				"/product/package/acl/env": {
					"I": 'package',
					"A": 'acl',
					"M": "Edit a product package ACL"
				},
				"/product/console/package": {
					"I": 'console_package',
					"A": 'edit',
					"M": "Edit the information of a console package"
				},
				"/product/console/package/acl/env": {
					"I": 'console_package',
					"A": 'acl',
					"M": "Edit the ACL of a console package"
				},
				"/admin/tenant/application/key/config": {
					"I": 'tenant_application_key',
					"A": 'configure',
					"M": "Configure tenant application key by environment"
				},
				"/tenant/console/application/key/config": {
					"I": 'tenant_console_application_key',
					"A": 'configure',
					"M": "Update console configuration"
				},
				"/admin/tenant/application/key/ext": {
					"I": 'tenant_application_ekey',
					"A": 'configure',
					"M": "Configure tenant application external key by environment"
				},
				"/admin/tenant/oauth": {
					"I": 'tenant',
					"A": 'configure',
					"M": "Configure tenant oauth"
				},
				"/admin/tenant": {
					"I": 'tenant',
					"A": 'edit',
					"M": "Update tenant"
				},
				"/admin/tenant/application": {
					"I": 'tenant_application',
					"A": 'edit',
					"M": "Update tenant application"
				},
				
				"/tenant/console": {
					"I": 'console_tenant',
					"A": 'edit',
					"M": "Update console integration tenant"
				},
				"/tenant/console/application": {
					"I": 'console_tenant_application',
					"A": 'edit',
					"M": "Update console tenant application"
				},
				"/tenant/console/application/key/ext": {
					"I": 'console_tenant_application_ekey',
					"A": 'configure',
					"M": "Configure console tenant application external key by environment"
				}
			},
			"post": {
				"/product": {
					"I": 'product',
					"A": 'add',
					"M": "Add a product"
				},
				"/product/package": {
					"I": 'package',
					"A": 'add',
					"M": "Add a product package"
				},
				"/product/console/package": {
					"I": 'console_package',
					"A": 'add',
					"M": "Add a console package"
				},
				"/admin/tenant/application/key": {
					"I": 'tenant_application_key',
					"A": 'add',
					"M": "Add a tenant application key"
				},
				"/tenant": {
					"I": 'tenant',
					"A": 'add',
					"M": "Add a tenant"
				},
				"/admin/tenant/application": {
					"I": 'tenant_application',
					"A": 'add',
					"M": "Add a tenant application"
				},
				"/admin/tenant/application/key/ext": {
					"I": 'tenant_application_ekey',
					"A": 'add',
					"M": "Add a tenant application external key"
				},
				
				"/tenant/console": {
					"I": 'console_tenant',
					"A": 'add',
					"M": "Add a console integration tenant"
				},
				"/tenant/console/application": {
					"I": 'console_tenant_application',
					"A": 'add',
					"M": "Add a console tenant application"
				},
				"/tenant/console/application/key": {
					"I": 'console_tenant_application_key',
					"A": 'add',
					"M": "Add a console tenant application key"
				},
				"/tenant/console/application/key/ext": {
					"I": 'console_tenant_application_ekey',
					"A": 'add',
					"M": "Add a console tenant application external key"
				}
			},
			"delete": {
				"/product": {
					"I": 'product',
					"A": 'delete',
					"M": "Delete a product"
				},
				"/product/package": {
					"I": 'package',
					"A": 'delete',
					"M": "Delete a product package"
				},
				"/product/console/package": {
					"I": 'console_package',
					"A": 'delete',
					"M": "Delete a console package"
				},
				"/tenant": {
					"I": 'tenant',
					"A": 'delete',
					"M": "Delete a tenant"
				},
				"/tenant/console": {
					"I": 'console_tenant',
					"A": 'delete',
					"M": "Delete a console integration tenant"
				},
				"/tenant/application": {
					"I": 'tenant_application',
					"A": 'delete',
					"M": "Delete a tenant application"
				},
				"/tenant/application/key": {
					"I": 'tenant_application_key',
					"A": 'delete',
					"M": "Delete a tenant application key"
				},
				"/tenant/application/key/ext": {
					"I": 'tenant_application_ekey',
					"A": 'delete',
					"M": "Delete a tenant application external key"
				},
				
				"/tenant/console/application": {
					"I": 'console_tenant_application',
					"A": 'delete',
					"M": "Delete a console tenant application"
				},
				"/tenant/console/application/key": {
					"I": 'console_tenant_application_key',
					"A": 'delete',
					"M": "Delete a console tenant application key"
				},
				"/tenant/console/application/key/ext": {
					"I": 'console_tenant_application_ekey',
					"A": 'delete',
					"M": "Delete a console tenant application external key"
				}
			}
		},
		"urac": {
			"put": {
				"/admin/group": {
					"I": 'group',
					"A": 'edit',
					"M": "Edit a group"
				},
				"/admin/user": {
					"I": 'user',
					"A": 'edit',
					"M": "Edit a user"
				}
			},
			"post": {
				"/admin/group": {
					"I": 'group',
					"A": 'add',
					"M": "Add a group"
				},
				"/admin/user": {
					"I": 'user',
					"A": 'add',
					"M": "Add a user"
				}
			},
			"delete": {
				"/admin/group": {
					"I": 'group',
					"A": 'delete',
					"M": "Delete a group"
				},
				"/admin/user": {
					"I": 'user',
					"A": 'delete',
					"M": "Delete a user"
				}
			}
		}
	},
	"default": {
		"infra": {
			"get": {
				"/cd/tokens": {
					"M": "Get the list of continuous delivery tokens"
				},
				"/kubernetes/clusters/Namespace": {
					"M": "Get the list of available namespaces"
				},
				"/account/kubernetes": {
					"M": "Get the list of available infrastructure accounts"
				},
				"/manual/awareness": {
					"M": "Trigger manual awareness"
				},
				"/kubernetes/workloads/:mode": {
					"M": "Get the list of available workload by mode"
				},
				"/kubernetes/clusters/:mode": {
					"M": "Get the list of available cluster by mode"
				},
				"/kubernetes/configurations/:mode": {
					"M": "Get the list of available configurations by mode"
				},
				"/kubernetes/services/:mode": {
					"M": "Get the list of available services by mode"
				},
				"/kubernetes/item/inspect": {
					"M": "Inspect an item"
				},
				"/kubernetes/storages/:mode": {
					"M": "Get the list of available storage by mode"
				}
			}
		},
		"console": {
			"get": {
				"/api/acl/usage/tenants": {
					"M": "Get the API usage information by tenant and environment"
				},
				"/api/acl/usage": {
					"M": "Get the API usage information by product and environment"
				},
				"/dashboard/services": {
					"M": "Get the list of available Services"
				},
				"/dashboard/apis/v2": {
					"M": "Get the list of available APIs as well as the related metadata"
				},
				"/apis/v2": {
					"M": "Get the list of available APIs"
				},
				"/tenant/oauth/users": {
					"M": "Get the list of integration users"
				},
				"/ledger": {
					"M": "Get the list of available notifications"
				},
				"/registry/throttling": {
					"M": "Get the list of available throttling by environment"
				},
				"/registry/custom": {
					"M": "Get the list of available custom registry by environment"
				},
				"/registry/resource": {
					"M": "Get the list of available resource configuration by environment"
				},
				"/environment": {
					"M": "Get the list of available environment"
				},
				"/environment/settings": {
					"M": "Get the environment settings"
				},
				"/registry/deployer": {
					"M": "Get the environment infrastructure deployment information"
				},
				"/registry": {
					"M": "Get the registry information by environment"
				}
			}
		},
		"marketplace": {
			"get": {
				"/recipes/ids": {
					"M": "Get the list of specific recipes by IDs"
				},
				"/recipes": {
					"M": "Get the list of available recipes"
				},
				"/soajs/items": {
					"M": "Get the list of available soajs items"
				},
				"/items": {
					"M": "Get the list of available items"
				},
				"/item/type": {
					"M": "Get an item by type"
				}
			},
			"put": {
				"/item/maintenance": {
					"M": "Trigger maintenance command on an item"
				},
				"/item/deploy/cd": {
					"isPublic": true,
					"M": "To deploy an item from an integration tool programmatically"
				}
			}
		},
		"multitenant": {
			"get": {
				"/tenant/console": {
					"M": "Get the information of the console tenant"
				},
				"/tenants/console": {
					"M": "Get the list of the console tenants"
				},
				"/product/console": {
					"M": "Get the information of the console product"
				},
				"/products/console": {
					"M": "Get the list of the console products"
				},
				"/products": {
					"M": "Get the list of available products"
				},
				"/product": {
					"M": "Get the information of a specific product"
				},
				"/tenants": {
					"M": "Get the list of available tenants"
				},
				"/tenant/tenants": {
					"M": "Get the list of available sub tenants for a specific main tenant"
				},
				"/admin/tenant": {
					"M": "Get the information of a specific tenant"
				}
			}
		},
		"repositories": {
			"get": {
				"/git/account": {
					"M": "Get the infromation of a specific git account"
				},
				"/git/accounts": {
					"M": "Get the list of available git accounts"
				},
				"/git/tag": {
					"M": "Get a specific tag by repository"
				},
				"/git/tags": {
					"M": "Get the list of available tags by repository"
				},
				"/git/branch": {
					"M": "Get the list of available branches by repository"
				}
			},
			"post": {
				"/git/repos": {
					"M": "List repositories with advance search capabilities"
				}
			}
		},
		"urac": {
			"get": {
				"/admin/groups": {
					"M": "List of groups"
				},
				"/admin/users": {
					"M": "List of users"
				},
				"/admin/users/count": {
					"M": "Count of users"
				},
				"/password/forgot": {
					"isPublic": true,
					"M": "Request a forgot password email"
				},
				"/user": {
					"M": "Get the logged in user information"
				},
				"/emailToken": {
					"isPublic": true,
					"M": "Email a token if the previous one expired"
				},
				"/validate/changeEmail": {
					"isPublic": true,
					"M": "Validate the email of changed email"
				}
			},
			"put": {
				"/account": {
					"M": "Update account information"
				},
				"/account/email": {
					"M": "Update the email"
				},
				"/account/password": {
					"M": "Update the password"
				},
				"/pasword/reset": {
					"isPublic": true,
					"M": "Reset the password"
				}
			}
		},
		"oauth": {
			"get": {
				"/available/login": {
					"isPublic": true,
					"M": "Available third party login"
				},
				"/passport/login/:strategy": {
					"isPublic": true,
					"M": "Login with third party"
				},
				"/passport/validate/:strategy": {
					"isPublic": true,
					"M": "Validate a third party login"
				}
			},
			"post": {
				"/token": {
					"isPublic": true,
					"M": "Login and get a valid access token"
				}
			},
			"delete": {
				"/accessToken/:token": {
					"isPublic": true,
					"M": "Delete the access token"
				},
				"/refreshToken/:token": {
					"isPublic": true,
					"M": "Delete the refresh token"
				}
			}
		},
		"soaanalytics": {
			"get": {
				"/api/acl/usage": {
					"M": "Get the API usage information by tenant, product and environment"
				},
				"/dashboard/services": {
					"M": "Get the list of available Services"
				},
				"/dashboard/apis/v2": {
					"M": "Get the list of available APIs as well as the related metadata"
				},
				"/apis/v2": {
					"M": "Get the list of available APIs"
				}
			}
		},
		"soamonitor": {
			"get": {
				"/monitor/analytics/:type": {
					"M": "Get the analytic information per type (API, Service, tenant, User)"
				},
				"/monitor/items": {
					"M": "Get the list of daily API requests"
				}
			}
		}
	},
	"versions": {
		"console": "1",
		"marketplace": "1",
		"repositories": "1",
		"multitenant": "1",
		"urac": "3",
		"oauth": "1",
		"infra": "1",
		"soaanalytics": "1",
		"soamonitor": "1"
	}
};

export default APIs;