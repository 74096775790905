import {Button, Modal} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";
import {JsonEditor} from "../index";


export default function JsonModal({modalOpt, setModalOpt}) {
	const {t} = useTranslation(["common", "soajs"]);
	
	const handleClose = () => setModalOpt({"show": false, "json": {}, "mode": "view", "title": ""});
	
	return (
		modalOpt &&
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="xl">
			<Modal.Header closeButton className="bg-secondary text-white">
				<Modal.Title>{t("soajs:titles.DetailedInformation")} {modalOpt.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<JsonEditor
					modes={["view", "preview"]}
					mode={modalOpt.mode || "view"}
					json={modalOpt.json || {}}
				/>
			</Modal.Body>
			<Modal.Footer className="bg-light">
				<Button variant="info" onClick={handleClose}>
					{t("soajs:buttons.Ok")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}