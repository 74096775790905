import {Route, Switch, useHistory} from "react-router-dom";
import {Alert, Badge, Table, Button} from "react-bootstrap";
import React from "react";
import {AuthenticatedRoute} from "../../soajs/helpers";

import {SiManageiq} from "react-icons/si";

import Settings from "./containers/Settings/Settings";
import Cluster from "./containers/Cluster/Cluster";
import Registry from "./containers/Registry/Registry";
import Add from "./containers/Add/Add";

import SoajsApi from "./containers/catalog/soajs/Api/Api";
import SoajsFrontend from "./containers/catalog/soajs/Frontend/Frontend";

import CatalogApi from "./containers/catalog/Api/Api";
import CatalogDaemon from "./containers/catalog/Daemon/Daemon";
import CatalogFrontend from "./containers/catalog/Frontend/Frontend";
import CatalogCustom from "./containers/catalog/Custom/Custom";
import CatalogResource from "./containers/catalog/Resource/Resource";
import {useTranslation} from "react-i18next";

const rootPath = "/environments";

export default function Routes({environment, environments, clusters, reloadClusters}) {
	const {t} = useTranslation(["common", "soajs"]);
	
	const history = useHistory();
	
	return (
		<Switch>
			<AuthenticatedRoute path={rootPath + "/add"}>
				<Add/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/manage/:env/settings"}>
				<Settings
					environment={environment}
				/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/manage/:env/registry"}>
				<Registry
					environment={environment}
					environments={environments}
					clusters={clusters}
					reloadClusters={reloadClusters}
				/>
			</AuthenticatedRoute>
			
			<AuthenticatedRoute path={rootPath + "/manage/:env/cluster"}>
				<Cluster
					environment={environment}
				/>
			</AuthenticatedRoute>
			
			<AuthenticatedRoute path={rootPath + "/manage/:env/catalog/soajs/api"}>
				<SoajsApi
					environment={environment}
				/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/manage/:env/catalog/soajs/frontend"}>
				<SoajsFrontend
					environment={environment}
				/>
			</AuthenticatedRoute>
			
			<AuthenticatedRoute path={rootPath + "/manage/:env/catalog/api"}>
				<CatalogApi
					environment={environment}
				/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/manage/:env/catalog/daemon"}>
				<CatalogDaemon
					environment={environment}
				/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/manage/:env/catalog/frontend"}>
				<CatalogFrontend
					environment={environment}
				/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/manage/:env/catalog/custom"}>
				<CatalogCustom
					environment={environment}
				/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/manage/:env/catalog/resource"}>
				<CatalogResource
					environment={environment}
				/>
			</AuthenticatedRoute>
			
			<Route>
				{environments.length > 0 &&
				
				<div>
					<Table responsive striped hover size="sm">
						<thead className="text-light bg-dark">
						<tr>
							<th>{t("soajs:fields.Code")}</th>
							<th>{t("soajs:fields.Description")}</th>
							<th className="text-center">{t("soajs:fields.Type")}</th>
							<th></th>
						</tr>
						</thead>
						<tbody>
						{environments.map((rec, i) => (
							<tr key={i} className="small">
								<td>{rec.code}</td>
								<td>{rec.description}</td>
								<td className="text-center"><Badge
									variant={rec.deployer.type === "container" ? "info" : "primary"}>{rec.deployer.type}</Badge>
								</td>
								<td className="small">
									<Button
										size={"sm"}
										variant="light"
										className="small p-1 border-secondary"
										onClick={() => {
											history.push(rootPath + "/manage/" + rec.code + "/settings");
										}}
									>
										<SiManageiq
										className="mr-2 text-success"
										/>
										Manage
									</Button>
								</td>
							</tr>
						))}
						</tbody>
					</Table>
				</div>
				}
				{environments.length === 0 &&
				
				<Alert variant="info">
					You do not have any environment, try to add one!
				</Alert>
				}
			</Route>
		</Switch>
	)
}