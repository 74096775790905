import {Badge, Button, Col, Form, Jumbotron, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {BiSearchAlt} from "react-icons/bi";
import {useAppContext} from "../../../../../soajs/libs/contextLib";
import {NotificationManager} from "../../../../../soajs/urac/components";
import AddModal from "../../catalog/common/container/modals/AddModal";
import AddButton from "../../../../buttons/Add";

import PVCContainer from "../../catalog/common/container/PVC";
import PVContainer from "../../catalog/common/container/PV";
import StorageClassContainer from "../../catalog/common/container/StorageClass";

import {InfraService} from "../../../../../services";

const infraService = InfraService.getService();
const initCriteria = {
	"name": "", "mode": "PVC", "continue": null, "remainingItemCount": 0
};

async function onLoad(setFields, criteria, env, setMode, setCriteria, isSubscribed) {
	try {
		let data = {"mode": criteria.mode, limit: 100}
		if (criteria.name && criteria.name.length > 0) {
			data.filter = {"fieldSelector": "metadata.name=" + criteria.name};
		}
		const response = await infraService.getStorage(env, data);
		if (isSubscribed && response && response.items) {
			let items = response.items;
			setFields([...items]);
			setMode(criteria.mode);
			if (response.metadata && response.metadata.continue) {
				criteria.continue = response.metadata.continue;
				criteria.remainingItemCount = response.metadata.remainingItemCount;
				setCriteria({...criteria});
			} else {
				criteria.continue = null;
				criteria.remainingItemCount = 0;
				setCriteria({...criteria});
			}
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function Storage({environment}) {
	const {ability} = useAppContext();
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState([]);
	const [mode, setMode] = useState("PVC");
	const [criteria, setCriteria] = useState(initCriteria);
	
	const [modalAddOpt, setModalAddOpt] = useState({"show": false, "item": {}, "json": null});
	const modalAddOptShow = (item, json) => setModalAddOpt({"show": true, "item": item, "json": json});
	
	async function addItem(mode, body) {
		try {
			await infraService.addStorage(environment.code, {body, mode});
			await reLoad(true);
			NotificationManager.success(t("soajs:messages.add_" + mode));
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function deleteItem(mode, name) {
		try {
			await infraService.deleteStorage(environment.code, {name, mode});
			await reLoad(true);
			NotificationManager.success(t("soajs:messages.delete_" + mode));
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function editItem(mode, name, body) {
		try {
			await infraService.updateStorage(environment.code, {name, body, mode});
			await reLoad(true);
			NotificationManager.success(t("soajs:messages.update_" + mode));
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}
	
	async function reLoad(isSubscribed) {
		await onLoad(setFields, criteria, environment.code, setMode, setCriteria, isSubscribed);
	}
	
	async function loadMore() {
		try {
			let data = {"mode": criteria.mode, limit: 3}
			if (criteria.name && criteria.name.length > 0) {
				data.filter = {"fieldSelector": "metadata.name=" + criteria.name};
			}
			if (criteria.continue) {
				data.continue = criteria.continue;
			}
			const response = await infraService.getStorage(environment.code, data);
			if (response && response.items) {
				
				setFields(fields.concat(response.items));
				if (response.metadata && response.metadata.continue) {
					criteria.continue = response.metadata.continue;
					criteria.remainingItemCount = response.metadata.remainingItemCount;
					setCriteria({...criteria});
				} else {
					criteria.continue = null;
					criteria.remainingItemCount = 0;
					setCriteria({...criteria});
				}
			}
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function handleSearch(event) {
		event.preventDefault();
		await reLoad(true);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		if (isSubscribed) {
			setCriteria(initCriteria);
			onLoad(setFields, initCriteria, environment.code, setMode, setCriteria, isSubscribed).catch();
		}
		return () => (isSubscribed = false);
	}, [environment]);
	
	return (
		<>
			<AddModal
				modalOpt={modalAddOpt}
				setModalOpt={setModalAddOpt}
				handleAction={
					(body) => {
						body.kind = criteria.mode;
						addItem(body.kind, body).catch();
					}
				}
			/>
			<Jumbotron className="p-3 m-0">
				<Row>
					<Col md={3} lg={2}>
						<h5 className="font-weight-bold m-0 float-left">{t("soajs:titles.storage")}</h5>
						{ability.can('infra_storage', 'add') &&
						<AddButton
							className="float-right pt-0 pb-1 pl-1 pr-1 "
							onClick={() => {
								modalAddOptShow({
									"title": "Storage " + criteria.mode,
								}, null);
							}}
						/>}
					</Col>
					<Col md={3} lg={2}>
						<Form.Control
							style={{
								"fontSize": "80%"
							}}
							as="select"
							id="mode"
							value={criteria.mode}
							onChange={handleCriteriaChange}
						>
							<option value="PVC">PVC - Persist Volume Claim</option>
							<option value="PV">PV - Persist Volume</option>
							<option value="StorageClass">Storage Class</option>
						</Form.Control>
					
					</Col>
					<Col>
						<Form onSubmit={handleSearch}>
							<Row>
								<Col xs={8} lg={9}>
									<Form.Control
										id="name"
										size="sm"
										autoFocus
										value={criteria.name}
										onChange={handleCriteriaChange}
									/>
								</Col>
								<Col xs={4} lg={3}>
									<Button className="float-right" variant="dark" size="sm" onClick={handleSearch}>
										<BiSearchAlt/> {t("soajs:buttons.Search")}
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			</Jumbotron>
			<hr/>
			{fields.length > 0 && mode === "PVC" &&
			<PVCContainer
				items={fields}
				env={environment.code.toLowerCase()}
				deleteItem={ability.can('infra_storage', 'delete') ? deleteItem : null}
				// editItem={ability.can('infra_storage', 'edit') ? editItem : null}
			/>
			}
			{fields.length > 0 && mode === "PV" &&
			<PVContainer
				items={fields}
				env={environment.code.toLowerCase()}
				deleteItem={ability.can('infra_storage', 'delete') ? deleteItem : null}
				editItem={ability.can('infra_storage', 'edit') ? editItem : null}
			/>
			}
			{fields.length > 0 && mode === "StorageClass" &&
			<StorageClassContainer
				items={fields}
				env={environment.code.toLowerCase()}
				deleteItem={ability.can('infra_storage', 'delete') ? deleteItem : null}
				editItem={ability.can('infra_storage', 'edit') ? editItem : null}
			/>
			}
			{criteria.continue &&
			<Button
				size="sm"
				className="mt-3"
				block variant="light"
				onClick={loadMore}
			>
				<Badge variant="light" className="text-muted">({criteria.remainingItemCount} remaining)</Badge> Click
				here to load more
				...
			</Button>
			}
		</>
	);
}