import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import React from "react";
import {LinkContainer} from "react-router-bootstrap";
import {useTranslation} from "react-i18next";

function Navigation() {
	const {t} = useTranslation(["common", "soajs"]);
	const rootPath = "/development";
	return (
		<Navbar collapseOnSelect expand="sm" className="pt-0 pb-0" bg="secondary" variant="dark">
			<span></span>
			<Navbar.Toggle aria-controls="development-navbar-nav"/>
			<Navbar.Collapse id="development-navbar-nav" className="justify-content-end">
				<Nav>
					<LinkContainer to={rootPath + "/git/repositories"}>
						<NavDropdown.Item><small>{t("soajs:menu.GitRepositories")}</small></NavDropdown.Item>
					</LinkContainer>
					<LinkContainer to={rootPath + "/git/accounts"}>
						<NavDropdown.Item><small>{t("soajs:menu.GitAccounts")}</small></NavDropdown.Item>
					</LinkContainer>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}

export default Navigation