import {useTranslation} from "react-i18next";
import React from "react";
import {
	Form,
	Jumbotron, Modal,
	Tab, Tabs
} from "react-bootstrap";

import ConfigureModalGroups from "./ConfigureModalGroups";

export default function ConfigureModal({modalOpt, setModalOpt, handleAfterSubmit}) {
	const {t} = useTranslation(["common", "soajs"]);
	
	const handleClose = () => setModalOpt({"show": false, "item": null});
	
	return (
		<>
			{modalOpt.item &&
			<Modal size="lg" show={modalOpt.show} onHide={handleClose} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{t("soajs:titles.ResourceRegistry")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					
					<Jumbotron className="p-4">
						<span
							className="font-weight-bold">{modalOpt.item.name}</span>
					</Jumbotron>
					<Form onSubmit={(event) => {
						event.preventDefault();
					}}>
						<Tabs className="mb-3" defaultActiveKey="acl" id="config">
							<Tab eventKey="acl" title={t("soajs:fields.Groups")}>
								<ConfigureModalGroups
									settings={modalOpt.item.settings}
									handleAction={() => {
										handleAfterSubmit();
									}}
									handleClose={handleClose}
									id={modalOpt.item._id}
								/>
							</Tab>
						</Tabs>
					</Form>
				</Modal.Body>
			</Modal>
			}
		</>
	);
}