import {Form, ListGroup, ListGroupItem, Modal, Alert, Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import AbilityView from "./Ability/InfoModal"
import APIView from "./API/InfoModal"

export default function InfoModal({modalOpt, setModalOpt}) {
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState({
		"viewMode": "ability"
	});
	
	const handleClose = () => setModalOpt({"show": false, opts: null, scope: null});
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setFields({
			...fields,
			[event.target.id]: value
		});
	}
	
	useEffect(() => {
		let isSubscribed = true;
		if (isSubscribed && modalOpt && modalOpt.opts) {
			if (modalOpt.opts.turnOffAbilityView) {
				setFields({
					"viewMode": "api"
				});
			}
		}
		return () => (isSubscribed = false);
	}, [modalOpt]);
	
	return (
		modalOpt.opts &&
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="xl">
			<Modal.Header closeButton className="bg-secondary text-white">
				<Modal.Title>{t("soajs:titles.packageInfo")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ListGroup variant="flush">
					<ListGroupItem>
						<span className="font-weight-bold">
							{t("soajs:fields.Code")}:</span> {modalOpt.opts.code}
					</ListGroupItem>
					<ListGroupItem>
						<span className="font-weight-bold">
							{t("soajs:fields.Name")}:</span> {modalOpt.opts.name}
					</ListGroupItem>
					<ListGroupItem>
						<span className="font-weight-bold">
						{t("soajs:fields.Description")}:</span> {modalOpt.opts.description}
					</ListGroupItem>
				</ListGroup>
				<hr/>
				{!modalOpt.opts.turnOffAbilityView &&
				<Alert variant="dark">
					<Form.Control as="select" id="viewMode"
					              defaultValue={fields.viewMode}
					              onChange={handleFieldChange}
					>
						<option value="ability">Basic Abilities view</option>
						<option value="api">Advanced API view</option>
					</Form.Control>
				</Alert>}
				
				{fields.viewMode === "ability" &&
				<AbilityView
					modalOpt={modalOpt}
				/>
				}
				{fields.viewMode === "api" &&
				<APIView
					modalOpt={modalOpt}
				/>
				}
			
			</Modal.Body>
			<Modal.Footer className="bg-light">
				<Button variant="info" onClick={handleClose}>
					{t("soajs:buttons.Close")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}