import {Button, Modal, Form, Col} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {NotificationManager} from "../../../../soajs/urac/components";
import {MultitenantService} from "../../../../services";

const multitenantService = MultitenantService.getService();

export default function EditModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, opts: null});
	const [fields, setFields] = useState({
		"code": "",
		"name": "",
		"description": "",
		"_TTL": ""
	});
	
	function validateForm() {
		return (
			fields.code.length > 0 &&
			fields.name.length > 0 &&
			fields.description.length > 0
		);
	}
	
	function handleFieldChange(event) {
		setFields({
			...fields,
			[event.target.id]: event.target.value
		});
	}
	
	async function handleSubmit() {
		try {
			await multitenantService.editConsolepackage(
				{
					"id": modalOpt.opts._id,
					"code": fields.code,
					"name": fields.name,
					"description": fields.description,
					"_TTL": fields._TTL
				});
			NotificationManager.success(t("soajs:messages.editPackage"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		if (isSubscribed && modalOpt.opts) {
			setFields({
				"code": modalOpt.opts.code,
				"name": modalOpt.opts.name,
				"description": modalOpt.opts.description,
				"_TTL": "" + modalOpt.opts._TTL / 3600000
			});
		}
		return () => (isSubscribed = false);
	}, [modalOpt]);
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.packageEdit")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={2}>{t("soajs:fields.Code")}</Form.Label>
							<Col sm={10}>
								<Form.Control
									id="code"
									size="sm"
									autoFocus
									disabled
									value={fields.code}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={2}>{t("soajs:fields.Name")}</Form.Label>
							<Col sm={10}>
								<Form.Control
									id="name"
									size="sm"
									value={fields.name}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={2}>{t("soajs:fields.Description")}</Form.Label>
							<Col sm={10}>
								<Form.Control
									id="description"
									as="textarea"
									rows={3}
									size="sm"
									value={fields.description}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={2}>{t("soajs:fields.TTL")}</Form.Label>
							<Col sm={10}>
								<Form.Control as="select" id="_TTL"
								              value={fields._TTL}
								              onChange={handleFieldChange}
								>
									<option value="12">12 hours</option>
									<option value="24">24 hours</option>
									<option value="48">2 days</option>
									<option value="72">3 days</option>
									<option value="96">4 days</option>
									<option value="120">5 days</option>
									<option value="144">6 days</option>
									<option value="168">7 days</option>
								</Form.Control>
							</Col>
						</Form.Row>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button variant="success" onClick={handleSubmit}
				        disabled={!validateForm()}
				>
					{t("soajs:buttons.Update")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}