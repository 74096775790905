import {Button, Card, CardDeck, Form, Jumbotron} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import _ from "lodash";

import {NotificationManager} from "../../../../soajs/urac/components";
import {InfraService} from "../../../../services";
import {UracService} from "../../../../soajs/urac/services";
import {useAppContext} from "../../../../soajs/libs/contextLib";

const uracService = UracService.getService();
const infraService = InfraService.getService();

function isGroupSelected(settings, value) {
	return (
		settings &&
		settings.acl &&
		settings.acl.groups &&
		settings.acl.groups.value &&
		settings.acl.groups.value.includes(value)
	);
}

export default function ConfigureModalGroups({settings, id, handleAction, handleClose}) {
	const {t} = useTranslation(["common", "soajs"]);
	const {ability} = useAppContext();
	const [groups, setGroups] = useState([]);
	const [fields, setFields] = useState({
		"groups": [],
		"type": "whitelist"
	});
	
	function validateForm() {
		let valid = true;
		
		return (
			valid
		);
	}
	
	function whitelistBlacklist(checked, what) {
		let obj = fields;
		obj.type = what;
		setFields({...obj});
	}
	
	function onChangeGroup(checked, code) {
		let obj = fields;
		if (checked) {
			obj.groups.push(code);
		} else {
			_.pullAll(obj.groups, [code]);
		}
		setFields({...obj});
	}
	
	async function handleDelete(event) {
		event.preventDefault();
		try {
			await infraService.deleteInfraAccountAcl(id);
			NotificationManager.success(t("soajs:messages.envGroupDeleteSuccess"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function handleSave(event) {
		event.preventDefault();
		try {
			await infraService.saveInfraAccountAcl({
				"id": id,
				"groups": fields.groups,
				"type": fields.type
			});
			NotificationManager.success(t("soajs:messages.envGroupSaveSuccess"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				if (settings && settings.acl && settings.acl.groups) {
					setFields({
						"groups": settings.acl.groups.value,
						"type": settings.acl.groups.type
					});
				}
				const _groups = await uracService.getGroups();
				if (isSubscribed) {
					setGroups(_groups);
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad();
		return () => (isSubscribed = false);
	}, [settings]);
	
	return (
		<>
			<Jumbotron className="p-4 m-0 bg-light border-dark small">
				<fieldset>
					<Form.Check
						type="radio"
						label="whitelist"
						name="groupsType"
						id="groupsType1"
						defaultChecked={fields.type === "whitelist"}
						onChange={(event) => {
							whitelistBlacklist(event.target.checked, "whitelist")
						}}
					/>
					<Form.Check
						type="radio"
						label="blacklist"
						name="groupsType"
						id="groupsType2"
						defaultChecked={fields.type === "blacklist"}
						onChange={(event) => {
							whitelistBlacklist(event.target.checked, "blacklist")
						}}
					/>
				</fieldset>
			</Jumbotron>
			<CardDeck className="ml-2">
				{groups.map((oo, index) => (
					<Card key={index} className="m-1 p-1" bg="light"
					      style={{"minWidth": '11rem', "maxWidth": '11rem'}}>
						<Card.Body className="p-2">
							<Form.Check className="small" key={index + 1} type="checkbox"
							            defaultChecked={isGroupSelected(settings, oo.code)}
							            label={oo.code}
							            onChange={(event) => {
								            onChangeGroup(event.target.checked, oo.code)
							            }}
							/>
						</Card.Body>
					</Card>
				))}
			</CardDeck>
			<hr/>
			<div>
				<Button className="ml-1 float-right" variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Close")}
				</Button>
				
				{ability.can('env_aclGroups', 'update') &&
				<Button className="ml-1 float-right" variant="success"
				        disabled={!validateForm()}
				        onClick={handleSave}
				>
					{t("soajs:buttons.Save")}
				</Button>}
				
				{ability.can('env_aclGroups', 'delete') &&
				<Button className="ml-1 float-right" variant="danger"
				        disabled={!validateForm()}
				        onClick={handleDelete}
				>
					{t("soajs:buttons.Delete")}
				</Button>}
			</div>
		</>
	);
}