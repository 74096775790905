import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";

import {AuthenticatedRoute} from "../../soajs/helpers";

import ApiMetrics from "./containers/ApiMetrics/ApiMetrics";
import ApiCatalog from "./containers/ApiCatalog/ApiCatalog";
import ApiAcl from "./containers/ApiAcl/ApiAcl";
import ApiMonitor from "./containers/ApiMonitor/ApiMonitor";
import ApiAnalytics from "./containers/ApiAnalytics/ApiAnalytics";

const rootPath = "/reports";
const defaultPath= "/api/metrics"

export default function Routes() {
	return (
		<Switch>
			<AuthenticatedRoute path={rootPath + "/api/metrics"}>
				<ApiMetrics/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/api/catalog"}>
				<ApiCatalog/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/api/acl"}>
				<ApiAcl/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/api/monitor"}>
				<ApiMonitor/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/api/analytics"}>
				<ApiAnalytics/>
			</AuthenticatedRoute>
			
			<Route
				render={() => {
					return (<Redirect to={rootPath + defaultPath}/>)
				}}/>
		</Switch>
	)
}