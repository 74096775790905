import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import {MarketplaceService} from "../../../../services";

import RecipeRW from "./RecipeRW";
import envModel from "./model/envModel.json";
import itemModel from "./model/itemModel.json";
import {NotificationManager} from "../../../../soajs/urac/components";
import _ from "lodash";

const marketplaceService = MarketplaceService.getService();

export default function EditRecipe() {
	const [fields, setFields] = useState(null);
	
	const {id} = useParams();
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				let data = _.merge({}, itemModel);
				let response = await marketplaceService.getRecipesByIds([id]);
				if (isSubscribed && response && response[0]) {
					data = {
						"envs": data.envs,
						"name": response[0].name || data.name,
						"type": response[0].type || data.type,
						"description": response[0].description || data.description,
						"subtype": response[0].subtype || data.subtype,
						"recipe": {
							"deployOptions": {
								"image": data.recipe.deployOptions.image,
								"readinessProbe": response[0].recipe.deployOptions.readinessProbe || data.recipe.deployOptions.readinessProbe,
								"liveinessProbe": response[0].recipe.deployOptions.liveinessProbe || data.recipe.deployOptions.liveinessProbe,
								"ports": response[0].recipe.deployOptions.ports || data.recipe.deployOptions.ports,
								"voluming": data.recipe.deployOptions.voluming,
								"labels": response[0].recipe.deployOptions.labels || data.recipe.deployOptions.labels,
								"execCommands": response[0].recipe.deployOptions.execCommands || data.recipe.deployOptions.execCommands,
								"securityContext": response[0].recipe.deployOptions.securityContext || data.recipe.deployOptions.securityContext
							},
							"buildOptions": {
								"cmd": data.recipe.buildOptions.cmd,
								"env": data.recipe.buildOptions.env,
								"settings": response[0].recipe.buildOptions.settings || data.recipe.buildOptions.settings
							}
						}
					};
					if (response[0].recipe.deployOptions.voluming) {
						for (let i = 0; i < response[0].recipe.deployOptions.voluming.length; i++) {
							data.recipe.deployOptions.voluming.push({
								"kubernetes": response[0].recipe.deployOptions.voluming[i].kubernetes
							})
						}
					}
					if (response[0].recipe.buildOptions.cmd && response[0].recipe.buildOptions.cmd.deploy) {
						data.recipe.buildOptions.cmd.deploy = {
							"command": response[0].recipe.buildOptions.cmd.deploy.command || data.recipe.buildOptions.cmd.deploy.command,
							"args": response[0].recipe.buildOptions.cmd.deploy.args || data.recipe.buildOptions.cmd.deploy.args
						};
					}
					if (response[0].recipe.deployOptions.securityContext) {
						data.recipe.deployOptions.securityContext = {
							"pod": response[0].recipe.deployOptions.securityContext.pod || data.recipe.deployOptions.securityContext.pod,
							"container": response[0].recipe.deployOptions.securityContext.container || data.recipe.deployOptions.securityContext.container
						};
					}
					if (response[0].recipe.deployOptions.image) {
						data.recipe.deployOptions.image = {
							"name": response[0].recipe.deployOptions.image.name || data.recipe.deployOptions.image.name,
							"tag": response[0].recipe.deployOptions.image.tag || data.recipe.deployOptions.image.tag,
							"prefix": response[0].recipe.deployOptions.image.prefix || data.recipe.deployOptions.image.prefix,
							"repositoryType": response[0].recipe.deployOptions.image.repositoryType || data.recipe.deployOptions.image.repositoryType,
							"pullPolicy": response[0].recipe.deployOptions.image.pullPolicy || data.recipe.deployOptions.image.pullPolicy,
							"shell": response[0].recipe.deployOptions.image.shell || data.recipe.deployOptions.image.shell,
							"override": response[0].recipe.deployOptions.image.override || data.recipe.deployOptions.image.override,
							"binary": response[0].recipe.deployOptions.image.binary || data.recipe.deployOptions.image.binary
						};
					}
					if (response[0].recipe.buildOptions.env) {
						Object.keys(response[0].recipe.buildOptions.env).map((e) => {
							let o = _.merge({}, envModel, response[0].recipe.buildOptions.env[e]);
							o.name = e;
							data.envs.push(o);
							return true;
						});
					}
				}
				setFields(data);
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad().catch();
		return () => (isSubscribed = false);
	}, [id]);
	
	return (
		<>
			{fields &&
			<RecipeRW
				starterFields={fields}
				itemId={id}
				mode={"edit"}
			/>}
		</>
	);
}