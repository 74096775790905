import axios from "axios";
import {response_catch, response_then} from "./commonResponse";

const ConsoleService = (function () {
	let _service = null;
	
	function _getService() {
		if (!_service) {
			_service = this;
			return _service;
		}
		return _service;
	}
	
	function _getUsers(id) {
		return axios.get("/console/tenant/oauth/users", {"params": {"id": id}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addUser(fields) {
		return axios.post("/console/tenant/oauth/user?id=" + fields.id,
			{
				"userId": fields.userId,
				"password": fields.password
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteUser(id, uid) {
		return axios.delete("/console/tenant/oauth/user?id=" + id + "&uId=" + uid)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getLedger(criteria) {
		return axios.get("/console/ledger", {"params": {"limit": criteria.limit, "start": criteria.skip}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getThrottlingRegistry(env) {
		return axios.get("/console/registry/throttling", {"params": {"env": env}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getCustomRegistry(env) {
		return axios.get("/console/registry/custom", {"params": {"env": env}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getResourceRegistry(env, type) {
		let params = {"params": {"env": env}};
		if (type) {
			params.params.type = type;
		}
		return axios.get("/console/registry/resource", params)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getEnvironments() {
		return axios.get("/console/environment")
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getEnvironment(code) {
		return axios.get("/console/environment", {"params": {"code": code}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getEnvironmentSettings(code) {
		return axios.get("/console/environment/settings", {"params": {"code": code}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateEnvironmentNamespace(fields) {
		return axios.put("/console/environment",
			{
				"code": fields.code,
				"settings": {
					id: fields.id,
					namespace: fields.namespace
				}
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _saveEnvironmentAcl(fields) {
		return axios.put("/console/environment/acl",
			{
				"code": fields.code,
				"type": fields.type,
				"groups": fields.groups
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteEnvironmentAcl(code) {
		return axios.delete("/console/environment/acl", {"params": {"code": code}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getRegistryDeployer(code) {
		return axios.get("/console/registry/deployer", {"params": {"env": code}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getRegistry(code) {
		return axios.get("/console/registry", {"params": {"env": code}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addEnvironment(fields) {
		return axios.post("/console/environment",
			{
				"code": fields.code,
				"description": fields.description,
				"settings": fields.settings
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteEnvironment(id) {
		return axios.delete("/console/environment", {"data": {"id": id}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteCustomRegistry(id, env) {
		return axios.delete("/console/registry/custom", {"data": {"id": id, "env": env}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _saveCustomRegistryAcl(fields) {
		return axios.put("/console/registry/custom/acl",
			{
				"id": fields.id,
				"type": fields.type,
				"groups": fields.groups
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteCustomRegistryAcl(id) {
		return axios.delete("/console/registry/custom/acl", {"params": {"id": id}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteResourceRegistry(id, env) {
		return axios.delete("/console/registry/resource", {"data": {"id": id, "env": env}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _saveResourceRegistryAcl(fields) {
		return axios.put("/console/registry/resource/acl",
			{
				"id": fields.id,
				"type": fields.type,
				"groups": fields.groups
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteResourceRegistryAcl(id) {
		return axios.delete("/console/registry/resource/acl", {"params": {"id": id}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _unplugPlugCustom(fields) {
		return axios.put("/console/registry/custom",
			{
				"id": fields.id,
				"env": fields.env,
				"data": {"plugged": fields.plugged}
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _unplugPlugResource(fields) {
		return axios.put("/console/registry/resource",
			{
				"id": fields.id,
				"env": fields.env,
				"data": {"plugged": fields.plugged}
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addCustom(env, data) {
		return axios.post("/console/registry/custom",
			{
				"env": env,
				"data": data
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateCustom(env, id, data) {
		return axios.put("/console/registry/custom",
			{
				"env": env,
				"id": id,
				"data": data
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addResource(env, data) {
		return axios.post("/console/registry/resource",
			{
				"env": env,
				"data": data
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateResource(env, id, data) {
		return axios.put("/console/registry/resource",
			{
				"env": env,
				"id": id,
				"data": data
			})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateRegistry(data) {
		return axios.put("/console/registry", data)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateDBPrefix(fields) {
		return axios.put("/console/registry/db/prefix", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addDB(fields) {
		return axios.post("/console/registry/db/custom", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteDB(fields) {
		return axios.delete("/console/registry/db/custom", {"data": fields})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateThrottling(env, fields) {
		return axios.put("/console/registry/throttling", {"env": env, "throttling": fields})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	return {
		getService: _getService,
		getUsers: _getUsers,
		addUser: _addUser,
		deleteUser: _deleteUser,
		
		getLedger: _getLedger,
		getThrottlingRegistry: _getThrottlingRegistry,
		getCustomRegistry: _getCustomRegistry,
		getResourceRegistry: _getResourceRegistry,
		getEnvironments: _getEnvironments,
		getEnvironment: _getEnvironment,
		getEnvironmentSettings: _getEnvironmentSettings,
		saveEnvironmentAcl: _saveEnvironmentAcl,
		deleteEnvironmentAcl: _deleteEnvironmentAcl,
		getRegistryDeployer: _getRegistryDeployer,
		updateEnvironmentNamespace: _updateEnvironmentNamespace,
		getRegistry: _getRegistry,
		addEnvironment: _addEnvironment,
		deleteEnvironment: _deleteEnvironment,
		deleteCustomRegistry: _deleteCustomRegistry,
		saveCustomRegistryAcl: _saveCustomRegistryAcl,
		deleteCustomRegistryAcl: _deleteCustomRegistryAcl,
		deleteResourceRegistry: _deleteResourceRegistry,
		saveResourceRegistryAcl: _saveResourceRegistryAcl,
		deleteResourceRegistryAcl: _deleteResourceRegistryAcl,
		unplugPlugCustom: _unplugPlugCustom,
		unplugPlugResource: _unplugPlugResource,
		addCustom: _addCustom,
		updateCustom: _updateCustom,
		addResource: _addResource,
		updateResource: _updateResource,
		updateRegistry: _updateRegistry,
		updateDBPrefix: _updateDBPrefix,
		addDB: _addDB,
		deleteDB: _deleteDB,
		updateThrottling: _updateThrottling
	}
})();
export default ConsoleService;