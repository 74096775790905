import {Button, Accordion, Card, Form, Alert} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import Granular from "./Granular"
import Scope from "./Scope"
import {NotificationManager} from "../../../../../../soajs/urac/components";
import {ConsoleService} from "../../../../../../services";

import _config from "../../../../../../config"

const consoleService = ConsoleService.getService();

export default function InfoModal({modalOpt}) {
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState([]);
	const [selectedEnv, setFSelectedEnv] = useState(null);
	
	function handleEnvChange(event) {
		setFSelectedEnv(event.target.value);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				const _environments = await consoleService.getEnvironments();
				if (_environments && isSubscribed) {
					let envs = [];
					for (let i = 0; i < _environments.length; i++) {
						envs.push(_environments[i].code.toLowerCase());
					}
					setFields(envs);
					let selected_env = _config.consoleEnvironment;
					if (!envs.includes(selected_env)) {
						selected_env = envs[0] || null;
					}
					setFSelectedEnv(selected_env);
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad().catch();
		return () => (isSubscribed = false);
	}, [modalOpt]);
	
	return (
		modalOpt.opts &&
		<>
			<Alert variant="info">
				<Form.Control
					as="select"
					id="env"
					value={selectedEnv || ""}
					onChange={handleEnvChange}
				>
					<option value="">--- {t("soajs:titles.selectEnvironment")}</option>
					{fields.map((env, index) => (
						<option key={index} value={env}>{t("soajs:titles.environment")}: {env}</option>
					))}
				</Form.Control>
			</Alert>
			<hr/>
			{selectedEnv && modalOpt.opts.acl && modalOpt.opts.acl[selectedEnv] &&
			<Accordion defaultActiveKey={1}>
				{Object.keys(modalOpt.opts.acl[selectedEnv]).map((service, index) => (
					<Card key={index + 1}>
						<Card.Header className="pt-1 pb-1 pr-2 pl-2">
							<Accordion.Toggle className="p-0" as={Button} variant="link"
							                  eventKey={index + 1}>
								{service}
							</Accordion.Toggle>
						</Card.Header>
						<Accordion.Collapse eventKey={index + 1}>
							<Card.Body>
								{(modalOpt.opts.aclTypeByEnv && modalOpt.opts.aclTypeByEnv[selectedEnv] && modalOpt.opts.aclTypeByEnv[selectedEnv] === "granular") ?
									<Granular
										service={modalOpt.opts.acl[selectedEnv][service]}
									/>
									:
									<Scope
										service={modalOpt.opts.acl[selectedEnv][service]}
										scope={modalOpt.scope.acl[selectedEnv][service]}
									/>
								}
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				))}
			</Accordion>
			}
		</>
	);
}