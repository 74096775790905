import {Button, Alert, Form, Modal} from "react-bootstrap";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {NotificationManager} from "../../../../../../../soajs/urac/components";
import {InfraService} from "../../../../../../../services";

const infraService = InfraService.getService();

export default function ExecModal({modalOpt, setModalOpt}) {
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState({
		"commands": ""
	});
	const [execResponse, setExecResponse] = useState("");
	const handleClose = () => setModalOpt({"show": false, "data": {}, "mode": "preview", "title": ""});
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		if (event.target.type === 'number' || event.target.id === "type") {
			value = parseInt(value);
		}
		setFields({
			...fields,
			[event.target.id]: value
		});
	}
	
	function validateForm() {
		return (
			fields.commands.length > 0
		);
	}
	
	async function handleExecute() {
		try {
			let response = await infraService.podExec(modalOpt.data.env, {
				"name": modalOpt.data.name,
				"commands": fields.commands.split('\n')
			});
			if (response && response.response) {
				NotificationManager.success(t("soajs:messages.execCommand"));
				setExecResponse(response.response);
			}
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	return (
		modalOpt &&
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="xl">
			<Modal.Header closeButton className="bg-secondary text-white">
				<Modal.Title>{modalOpt.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Alert variant="info">
						<Form.Label>{t("soajs:fields.Commands")}</Form.Label>
						<Form.Control
							className="mb-3"
							id="commands"
							size="sm"
							as="textarea"
							rows={3}
							value={fields.commands}
							onChange={handleFieldChange}
						/>
						<Button
							className="float-right"
							variant="info"
							onClick={handleClose}>
							{t("soajs:buttons.Close")}
						</Button>
						<Button
							className="float-right mr-3"
							variant="success"
							onClick={handleExecute}
							disabled={!validateForm()}
						>
							{t("soajs:buttons.Execute")}
						</Button>
						<span className="clearfix"></span>
					</Alert>
					<Form.Label>{t("soajs:fields.Response")}</Form.Label>
					<Form.Control
						style={{
							"fontSize": "70%"
						}}
						id="response"
						size="sm"
						as="textarea"
						rows={6}
						value={execResponse}
						readOnly
					/>
				</Form>
			</Modal.Body>
			<Modal.Footer className="bg-light">
			
			</Modal.Footer>
		</Modal>
	);
}