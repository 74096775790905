import {Alert, Button, Col, ListGroup, ListGroupItem, Row, Tab, Tabs} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";

import {BsLockFill, BsUnlockFill} from "react-icons/bs";

function getAccess(access) {
	
	return (
		<>
			<span className="font-weight-bold mr-3">Service's APIs default access is:</span>
			{access ?
				<>
					<span className="font-weight-bold text-success"><BsLockFill
						className="mb-1"/> Private</span> - <span
					className="font-italic">requires logged in user</span>
				</>
				:
				<>
					<span className="font-weight-bold text-info"><BsUnlockFill className="mb-1"/> Public</span>
				</>
			}
		</>
	);
}

const methodVariant = {
	"get": "primary",
	"post": "success",
	"delete": "danger",
	"put": "warning",
	"patch": "info",
	"head": "secondary",
	"other": "light"
};

function getMethodVariant(method) {
	if (method && methodVariant[method]) {
		return methodVariant[method];
	} else {
		return methodVariant["other"];
	}
}

function getApiAccess(api) {
	if (api.hasOwnProperty("access")) {
		return (
			api.access ?
				(
					<span className="font-weight-bold"><BsLockFill className="mb-1"/> Private</span>
				)
				:
				(
					<span className="font-weight-bold"><BsUnlockFill className="mb-1"/> Public</span>
				)
		);
	} else {
		return (
			"Inherit from service"
		);
	}
}

export default function Granular({service}) {
	const {t} = useTranslation(["common", "soajs"]);
	
	return (
		<Tabs defaultActiveKey="1"
		      style={{borderBottom: "1px solid #dee2e6"}}
		>
			{Object.keys(service).map((version, index) => (
				<Tab key={index + 1} eventKey={index + 1}
				     title={t("soajs:fields.Version") + " " + version}>
					<ListGroup variant="flush" className="small mt-3">
						<ListGroupItem>
							{getAccess(service[version].access)}
						</ListGroupItem>
						<ListGroupItem>
							<span
								className="font-weight-bold mr-3">Access is restricted to the selected APIs:</span> {service[version].apisPermission === "restricted" ? "Yes - only the selected APIs are accessible" : "No - all APIs are accessible"}
						</ListGroupItem>
					</ListGroup>
					<hr/>
					{Object.keys(methodVariant).map((method, index) => (
						service[version][method] && service[version][method].apis &&
						Object.keys(service[version][method].apis).map((api, index) => (
							<Alert key={index} variant={getMethodVariant(method)} className="m-1 p-2">
								<Row>
									<Col className="small">
										<Button size="sm" className="mr-2 pl-1 pr-1 pt-0 pb-0 float-left"
										        variant={getMethodVariant(method)}>{method.toUpperCase()}</Button>
									
										<span className="float-left mt-1">{api}</span>
									</Col>
									<Col className="small">
										<span
											className="small">{getApiAccess(service[version][method].apis[api])}</span>
									</Col>
									<Col className="small">
										<div className="font-italic small">
											{service[version][method].apis[api].group}
										</div>
									</Col>
								</Row>
							</Alert>
						))
					))}
				</Tab>
			))}
		</Tabs>
	);
}