import {Alert, Col, Row, Tab, Tabs, Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {NotificationManager} from "../../../../soajs/urac/components";
import {ConsoleService} from "../../../../services";

import DefaultView from "./DefaultView"
import DefaultEdit from "./DefaultEdit"
import Awareness from "./Awareness"
import Resource from "./Resource/Resource"
import Custom from "./Custom/Custom"
import Databases from "./Databases"
import Throttling from "./Throttling"
import EditButton from "../../../buttons/Edit";
import CloseButton from "../../../buttons/Close";
import {useAppContext} from "../../../../soajs/libs/contextLib";

const consoleService = ConsoleService.getService();

async function onLoad(environment, setRegistry, isSubscribed) {
	try {
		if (environment) {
			const response = await consoleService.getRegistry(environment.code);
			if (response && isSubscribed) {
				setRegistry(response);
			}
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function Registry({environment, environments, clusters, reloadClusters}) {
	const {t} = useTranslation(["common", "soajs"]);
	const {ability} = useAppContext();
	const [registry, setRegistry] = useState(null);
	const [registryDefaultEdit, setRegistryDefaultEdit] = useState(false);
	const [fields, setFields] = useState({
		"env": "",
		"domain": "",
		"sitePrefix": "",
		"apiPrefix": "",
		"port": 0,
		"protocol": "https",
		"description": "",
		"services": {
			"controller": {
				"authorization": false,
				"requestTimeout": 60,
				"requestTimeoutRenewal": 5
			},
			"config": {
				"awareness": {
					"cacheTTL": 3600000,
					"healthCheckInterval": 5000,
					"autoRelaodRegistry": 86400000,
					"maxLogCount": 5,
					"autoRegisterService": true
				},
				"logger": {},
				"ports": {
					"controller": 4000,
					"maintenanceInc": 1000,
					"randomInc": 100
				},
				"oauth": {
					"debug": false,
					"getUserFromToken": false,
					"accessTokenLifetime": 7200,
					"refreshTokenLifetime": 1209600
				},
				"cors": {
					"credentials": true,
					"enabled": true,
					"headers": "key,soajsauth,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Authorization,__env,access_token",
					"maxage": 1728000,
					"methods": "GET,HEAD,PUT,PATCH,POST,DELETE",
					"origin": "*"
				}
			}
		}
	});
	
	function handleEditRegistry() {
		let obj = {
			"env": registry.code,
			"domain": registry.domain || fields.domain,
			"sitePrefix": registry.sitePrefix || fields.sitePrefix,
			"apiPrefix": registry.apiPrefix || fields.apiPrefix,
			"port": registry.port || fields.port,
			"protocol": registry.protocol || fields.protocol,
			"description": registry.description || fields.description,
			"services": {
				"controller": {
					"authorization": registry.services.controller.authorization || fields.services.controller.authorization,
					"requestTimeout": registry.services.controller.requestTimeout || fields.services.controller.requestTimeout,
					"requestTimeoutRenewal": registry.services.controller.requestTimeoutRenewal || fields.services.controller.requestTimeoutRenewal
				},
				"config": {
					"awareness": {
						"cacheTTL": registry.services.config.awareness.cacheTTL || fields.services.config.awareness.cacheTTL,
						"healthCheckInterval": registry.services.config.awareness.healthCheckInterval || fields.services.config.awareness.healthCheckInterval,
						"autoRelaodRegistry": registry.services.config.awareness.autoRelaodRegistry || fields.services.config.awareness.autoRelaodRegistry,
						"maxLogCount": registry.services.config.awareness.maxLogCount || fields.services.config.awareness.maxLogCount,
						"autoRegisterService": registry.services.config.awareness.autoRegisterService || fields.services.config.awareness.autoRegisterService
					},
					"logger": registry.services.config.logger || fields.services.config.logger,
					"ports": {
						"controller": registry.services.config.ports.controller || fields.services.config.ports.controller,
						"maintenanceInc": registry.services.config.ports.maintenanceInc || fields.services.config.ports.maintenanceInc,
						"randomInc": registry.services.config.ports.randomInc || fields.services.config.ports.randomInc
					},
					"oauth": {
						"debug": registry.services.config.oauth.debug || fields.services.config.oauth.debug,
						"getUserFromToken": registry.services.config.oauth.getUserFromToken || fields.services.config.oauth.getUserFromToken,
						"accessTokenLifetime": registry.services.config.oauth.accessTokenLifetime || fields.services.config.oauth.accessTokenLifetime,
						"refreshTokenLifetime": registry.services.config.oauth.refreshTokenLifetime || fields.services.config.oauth.refreshTokenLifetime
					},
					"cors": {
						"credentials": registry.services.config.cors.credentials || fields.services.config.cors.credentials,
						"enabled": registry.services.config.cors.enabled || fields.services.config.cors.enabled,
						"headers": registry.services.config.cors.headers || fields.services.config.cors.headers,
						"maxage": registry.services.config.cors.maxage || fields.services.config.cors.maxage,
						"methods": registry.services.config.cors.methods || fields.services.config.cors.methods,
						"origin": registry.services.config.cors.origin || fields.services.config.cors.origin
					}
				}
			}
		};
		setFields(obj)
		setRegistryDefaultEdit(true);
	}
	
	async function reload() {
		await onLoad(environment, setRegistry, true);
	}
	
	async function handleSaveRegistry() {
		try {
			await consoleService.updateRegistry(fields);
			await reload();
			setRegistryDefaultEdit(false);
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		onLoad(environment, setRegistry, isSubscribed).catch();
		return () => (isSubscribed = false);
	}, [environment]);
	
	return (
		environment && registry &&
		<Tabs className="mb-3" defaultActiveKey="default" id="config">
			<Tab eventKey="default" title={t("soajs:titles.DefaultRegistry")}>
				<Alert variant="secondary" className="mt-4">
					<Row>
						<Col className="font-weight-bold">
							{registry.code}
						</Col>
						<Col xs={4} md={3}>
							{!registryDefaultEdit && ability.can('registry', 'edit') &&
							<EditButton
								className="float-right"
								onClick={handleEditRegistry}
							/>}
							{registryDefaultEdit &&
							<>
								<CloseButton
									className="float-right ml-3"
									onClick={() => {
										setRegistryDefaultEdit(false);
									}}
								/>
								<Button
									size="sm"
									className="float-right ml-3"
									variant="success"
									onClick={handleSaveRegistry}
								>
									Save
								</Button>
							</>}
						</Col>
					</Row>
				</Alert>
				{!registryDefaultEdit &&
				<DefaultView
					registry={registry}
				/>}
				{registryDefaultEdit &&
				<DefaultEdit
					registry={registry}
					fields={fields}
					setFields={setFields}
				/>}
			</Tab>
			<Tab eventKey="awareness" title={t("soajs:titles.Awareness")}>
				<Awareness
					registry={registry}
				/>
			</Tab>
			<Tab eventKey="throttling" title={t("soajs:titles.Throttling")}>
				<Throttling
					environment={environment}
				/>
			</Tab>
			<Tab eventKey="databases" title={t("soajs:titles.Databases")}>
				<Databases
					environment={environment}
					registry={registry}
					clusters={clusters}
					reload={reload}
				/>
			</Tab>
			<Tab eventKey="custom" title={t("soajs:titles.CustomRegistry")}>
				<Custom
					environment={environment}
					environments={environments}
				/>
			</Tab>
			<Tab eventKey="resource" title={t("soajs:titles.ResourceConfiguration")}>
				<Resource
					environment={environment}
					environments={environments}
					reloadClusters={reloadClusters}
				/>
			</Tab>
		</Tabs>
	);
}