import {Card, CardDeck, ListGroup, ListGroupItem, Row, Col} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";

import {JsonEditor} from "../../../../soajs/urac/components";

export default function DefaultViewRegistry({registry}) {
	const {t} = useTranslation(["common", "soajs"]);
	
	return (
		registry &&
		<div>
			<Card bg="light">
				<Card.Body>
					<Row>
						<Col>
						<span
							className="small">{registry.protocol}://{registry.apiPrefix ? registry.apiPrefix + "." : ""}{registry.domain}:{registry.port}</span>
						</Col>
					</Row>
					<Row className="mt-3 small">
						<Col>
							{registry.description}
						</Col>
					</Row>
				</Card.Body>
			</Card>
			
			<CardDeck className="mb-4 small mt-4">
				<Card bg="light" style={{minWidth: '22rem'}}>
					<Card.Body>
						<Card.Title>{t("soajs:fields.Awareness")}</Card.Title>
						<ListGroup variant="flush">
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.CacheTTL")}:</span> {registry.services.config.awareness.cacheTTL}
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Healthcheckinterval")}:</span> {registry.services.config.awareness.healthCheckInterval}
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Autoreloadregistry")}:</span> {registry.services.config.awareness.autoRelaodRegistry}
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Maxlogcount")}:</span> {registry.services.config.awareness.maxLogCount}
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Automaticregisterservice")}:</span> {"" + registry.services.config.awareness.autoRegisterService}
							</ListGroupItem>
						</ListGroup>
					</Card.Body>
				</Card>
				<Card bg="light" style={{minWidth: '22rem'}}>
					<Card.Body>
						<Card.Title>{t("soajs:fields.Cors")}</Card.Title>
						<ListGroup variant="flush">
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Enabled")}:</span> {"" + registry.services.config.cors.enabled}
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Credentials")}:</span> {"" + registry.services.config.cors.credentials}
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Origin")}:</span> {registry.services.config.cors.origin}
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Maxage")}:</span> {"" + registry.services.config.cors.maxage}
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Methods")}:</span> {registry.services.config.cors.methods}
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Headers")}:</span> {"" + registry.services.config.cors.headers}
							</ListGroupItem>
						</ListGroup>
					</Card.Body>
				</Card>
			</CardDeck>
			
			<CardDeck className="mb-4 small">
				<Card bg="light" style={{minWidth: '22rem'}}>
					<Card.Body>
						<Card.Title>{t("soajs:fields.Requests")}</Card.Title>
						<ListGroup variant="flush">
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Authorization")}:</span> {"" + registry.services.controller.authorization}
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Timeout")}:</span> {registry.services.controller.requestTimeout}
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Timeoutrenewal")}:</span> {registry.services.controller.requestTimeoutRenewal}
							</ListGroupItem>
						</ListGroup>
					</Card.Body>
				</Card>
				<Card bg="light" style={{minWidth: '22rem'}}>
					<Card.Body>
						<Card.Title>{t("soajs:fields.Ports")}</Card.Title>
						<ListGroup variant="flush">
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Gatewayport")}:</span> {registry.services.config.ports.controller}
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Gatewaymaintenanceport")}:</span> {registry.services.config.ports.controller + registry.services.config.ports.maintenanceInc}
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Randomizedportincrement")}:</span> {registry.services.config.ports.randomInc}
							</ListGroupItem>
						</ListGroup>
					</Card.Body>
				</Card>
			</CardDeck>
			
			<CardDeck className="mb-4 small">
				<Card bg="light" style={{minWidth: '22rem'}}>
					<Card.Body>
						<Card.Title>{t("soajs:fields.Logger")}</Card.Title>
						
						<JsonEditor
							mode={"preview"}
							json={registry.services.config.logger || {}}
						/>
						
						{/*<ListGroup variant="flush">*/}
						{/*	<ListGroupItem><span*/}
						{/*		className="font-weight-bold mr-3">{t("soajs:fields.Source")}:</span> {"" + registry.services.config.logger.src}*/}
						{/*	</ListGroupItem>*/}
						{/*	<ListGroupItem><span*/}
						{/*		className="font-weight-bold mr-3">{t("soajs:fields.Level")}:</span> {registry.services.config.logger.level}*/}
						{/*	</ListGroupItem>*/}
						{/*	<ListGroupItem><span*/}
						{/*		className="font-weight-bold mr-3">{t("soajs:fields.Levelinstring")}:</span> {"" + registry.services.config.logger.formatter.levelInString}*/}
						{/*	</ListGroupItem>*/}
						{/*	<ListGroupItem><span*/}
						{/*		className="font-weight-bold mr-3">{t("soajs:fields.Outputtype")}:</span> {registry.services.config.logger.formatter.outputMode}*/}
						{/*	</ListGroupItem>*/}
						{/*</ListGroup>*/}
					</Card.Body>
				</Card>
				<Card bg="light" style={{minWidth: '22rem'}}>
					<Card.Body>
						<Card.Title>{t("soajs:fields.Oauth")}</Card.Title>
						<ListGroup variant="flush">
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Grants")}:</span> {registry.services.config.oauth.grants.join(", ")}
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Getuserinformationfromtoken")}:</span> {"" + registry.services.config.oauth.getUserFromToken}
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Accesstokenlifetime")}:</span> {registry.services.config.oauth.accessTokenLifetime}
							</ListGroupItem>
							<ListGroupItem><span
								className="font-weight-bold mr-3">{t("soajs:fields.Refreshtokenlifetime")}:</span> {registry.services.config.oauth.refreshTokenLifetime}
							</ListGroupItem>
						</ListGroup>
					</Card.Body>
				</Card>
			</CardDeck>
		</div>
	);
}