import {Button, Form, Modal, Alert} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {JsonEditor, NotificationManager} from "../../../../../../../soajs/urac/components";
import {LoaderButton} from "../../../../../../../soajs/helpers";
import {useAppContext} from "../../../../../../../soajs/libs/contextLib";
import {InfraService} from "../../../../../../../services";

const initModel = {
	"type": "",
	"name": "",
	"label": "",
	
	"server": "",
	"email": "",
	"username": "",
	"password": "",
	
	"text": "",
	"file": null,
	"json": null
};
const infraService = InfraService.getService();
let _json = null;

export default function AddSecretWizardModal({modalOpt, setModalOpt, handleAction}) {
	const {ability} = useAppContext();
	const {t} = useTranslation(["common", "soajs"]);
	const [isLoading, setIsLoading] = useState(false);
	const [fields, setFields] = useState(initModel);
	
	function validateForm() {
		let valid = true;
		if (fields.type === "Text") {
			valid = valid && fields.label.length > 0 && fields.text.length > 0;
		}
		if (fields.type === "Json") {
			valid = valid && fields.label.length > 0 && _json;
		}
		if (fields.type === "File") {
			valid = valid && fields.label.length > 0 && fields.file;
		}
		if (fields.type === "Registry") {
			valid = valid && fields.server.length > 0 && fields.email.length > 0 && fields.username.length > 0 && fields.password.length > 0;
		}
		return (
			valid && fields.name.length > 0
		);
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		if (event.target.type === 'number') {
			value = parseInt(value);
		}
		setFields({
			...fields,
			[event.target.id]: value
		});
	}
	
	function handleFileInput(event) {
		const file = event.target.files[0];
		if (file) {
			if (file.size > 2048000) {
				NotificationManager.error("File size cannot exceed more than 2MB");
				event.target.value = null;
				fields[event.target.id] = null;
				setFields({...fields});
			} else {
				let fileReader = new FileReader();
				fileReader.onloadend = () => {
					const content = fileReader.result;
					fields[event.target.id] = content;
					setFields({...fields});
				};
				fileReader.readAsText(file);
			}
		} else {
			fields[event.target.id] = null;
			setFields({...fields});
		}
	}
	
	const handleClose = () => {
		_json = {};
		setModalOpt({"show": false, "json": {}, "mode": "code", "item": {}});
	};
	
	async function handleSubmit(event) {
		event.preventDefault();
		setIsLoading(true);
		if (fields.type === "Registry") {
			try {
				let content = {
					"username": fields.username,
					"password": fields.password,
					"email": fields.email,
					"server": fields.server
				};
				await infraService.addSecretRegistryWizard(modalOpt.item.env, fields.name, content);
				handleAction();
				NotificationManager.success(t("soajs:messages.add_Secret"));
				handleClose();
			} catch (e) {
				NotificationManager.error(e.message);
			}
		} else {
			try {
				let content = {"name": fields.label};
				if (fields.type === "Text") {
					content.content = fields.text;
				}
				if (fields.type === "Json") {
					content.content = JSON.stringify(fields.json);
				}
				if (fields.type === "File") {
					content.content = fields.file;
				}
				await infraService.addSecretWizard(modalOpt.item.env, fields.name, [content]);
				handleAction();
				NotificationManager.success(t("soajs:messages.add_Secret"));
				handleClose();
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		setIsLoading(false);
	}
	
	function _json_onChange(json) {
		_json = json;
		fields.json = _json;
	}
	
	useEffect(() => {
		_json = {};
		setFields({...initModel});
	}, [modalOpt]);
	
	return (
		modalOpt &&
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="xl">
			<Form onSubmit={handleSubmit}>
				<Modal.Header closeButton className="bg-secondary text-white">
					<Modal.Title>{modalOpt.item.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Type")}</Form.Label>
						<Form.Control as="select" id="type" size="sm"
						              defaultValue={fields.type}
						              onChange={handleFieldChange}
						>
							<option value="---"> --- Select a type ---</option>
							{ability.can('infra_secret', 'add_wizard') &&
							<option value="Text">Text Content</option>}
							{ability.can('infra_secret', 'add_wizard') &&
							<option value="Json">Json Content</option>}
							{ability.can('infra_secret', 'add_wizard') &&
							<option value="File">File Content</option>}
							{ability.can('infra_secret', 'add_wizard_registry') &&
							<option value="Registry">Private Registry</option>}
						</Form.Control>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Name")}</Form.Label>
						<Form.Control
							required
							id="name"
							size="sm"
							value={fields.name}
							onChange={handleFieldChange}
						/>
					</Form.Group>
					<hr/>
					<Alert variant="light">
						{fields.type === "Text" &&
						<>
							<Form.Group>
								<Form.Label>{t("soajs:fields.Label")}</Form.Label>
								<Form.Control
									id="label"
									size="sm"
									value={fields.label}
									onChange={handleFieldChange}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Control
									id="text"
									as="textarea"
									rows={15}
									size="sm"
									value={fields.text}
									onChange={handleFieldChange}
								/>
							</Form.Group>
						</>
						}
						{fields.type === "Json" &&
						<>
							<Form.Group>
								<Form.Label>{t("soajs:fields.Label")}</Form.Label>
								<Form.Control
									id="label"
									size="sm"
									value={fields.label}
									onChange={handleFieldChange}
								/>
							</Form.Group>
							<div style={{"height": "400px"}}>
								<JsonEditor
									mode={modalOpt.mode || "code"}
									modes={["tree", "code"]}
									json={_json || modalOpt.json || null}
									onChange={_json_onChange}
								/>
							</div>
						</>
						}
						{fields.type === "File" &&
						<>
							<Form.Group>
								<Form.Label>{t("soajs:fields.Label")}</Form.Label>
								<Form.Control
									id="label"
									size="sm"
									value={fields.label}
									onChange={handleFieldChange}
								/>
							</Form.Group>
							<Form.Group>
								<Form.File
									className="position-relative"
									required
									name="file"
									label="File"
									onChange={handleFileInput}
									id="file"
								/>
							</Form.Group>
						</>
						}
						{fields.type === "Registry" &&
						<>
							<Form.Group>
								<Form.Label>{t("soajs:fields.Server")}</Form.Label>
								<Form.Control
									id="server"
									size="sm"
									value={fields.server}
									onChange={handleFieldChange}
								/>
								<Form.Text className="text-muted">
									Enter the Registry Server: https://index.docker.io/v1/
								</Form.Text>
							</Form.Group>
							<Form.Group>
								<Form.Label>{t("soajs:fields.Email")}</Form.Label>
								<Form.Control
									id="email"
									size="sm"
									value={fields.email}
									onChange={handleFieldChange}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>{t("soajs:fields.Username")}</Form.Label>
								<Form.Control
									id="username"
									size="sm"
									value={fields.username}
									onChange={handleFieldChange}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>{t("soajs:fields.Password")}</Form.Label>
								<Form.Control
									id="password"
									size="sm"
									value={fields.password}
									onChange={handleFieldChange}
								/>
							</Form.Group>
						</>
						}
					</Alert>
				</Modal.Body>
				<Modal.Footer className="bg-light">
					<Button variant="info" onClick={handleClose}>
						{t("soajs:buttons.Close")}
					</Button>
					<LoaderButton
						variant="success"
						type="submit"
						isLoading={isLoading}
						disabled={!validateForm()}
					>
						{t("soajs:buttons.Add")}
					</LoaderButton>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}