const LocalStorageService = (function () {
	let _service;
	
	function _getService() {
		if (!_service) {
			_service = this;
			return _service
		}
		return _service
	}
	
	function _setToken(tokenObj) {
		localStorage.setItem('access_token', tokenObj.access_token);
		localStorage.setItem('refresh_token', tokenObj.refresh_token);
	}
	
	function _setUsername(username) {
		localStorage.setItem('username', username);
	}
	
	function _getUsername() {
		return localStorage.getItem('username');
	}
	
	function _setUserid(id) {
		localStorage.setItem('userid', id);
	}
	
	function _getUserid() {
		return localStorage.getItem('userid');
	}
	
	function _setFirstrname(firstname) {
		localStorage.setItem('firstname', firstname);
	}
	
	function _getFirstname() {
		return localStorage.getItem('firstname');
	}
	
	function _setEmail(firstname) {
		localStorage.setItem('email', firstname);
	}
	
	function _getEmail() {
		return localStorage.getItem('email');
	}
	
	function _getAccessToken() {
		return localStorage.getItem('access_token');
	}
	
	function _getRefreshToken() {
		return localStorage.getItem('refresh_token');
	}
	
	function _clearToken() {
		localStorage.removeItem('access_token');
		localStorage.removeItem('refresh_token');
	}
	
	function _setUserKeyACL(acl, index) {
		let acl_cached = localStorage.getItem('userACL');
		if (!acl_cached) {
			acl_cached = {};
		} else {
			acl_cached = JSON.parse(acl_cached);
		}
		acl_cached[index] = acl;
		localStorage.setItem('userACL', JSON.stringify(acl_cached));
	}
	
	function _getUserKeyACL(index) {
		let acl_cached = localStorage.getItem('userACL');
		if (acl_cached) {
			acl_cached = JSON.parse(acl_cached)
			if (acl_cached[index]) {
				return acl_cached[index];
			}
		}
		return null;
	}
	
	function _clearUser() {
		localStorage.removeItem('username');
		localStorage.removeItem('firstname');
		localStorage.removeItem('userid');
		localStorage.removeItem('userACL');
	}
	
	return {
		getService: _getService,
		
		setUserKeyACL: _setUserKeyACL,
		getUserKeyACL: _getUserKeyACL,
		
		setToken: _setToken,
		getAccessToken: _getAccessToken,
		getRefreshToken: _getRefreshToken,
		clearToken: _clearToken,
		clearUser: _clearUser,
		
		setUsername: _setUsername,
		getUsername: _getUsername,
		
		setUserid: _setUserid,
		getUserid: _getUserid,
		
		setFirstrname: _setFirstrname,
		getFirstname: _getFirstname,
		
		setEmail: _setEmail,
		getEmail: _getEmail
	}
})();
export default LocalStorageService;