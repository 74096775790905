import {Button, Modal, Form, Col} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import _ from "lodash";

import {NotificationManager} from "../../../../soajs/urac/components";
import {UracService} from "../../../../soajs/urac/services";

const uracService = UracService.getService();
const initFields = {
	"user": "",
	"email": "",
	"username": "",
	"firstName": "",
	"lastName": "",
	"groups": []
};
export default function AddModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, opts: null});
	const [fields, setFields] = useState(initFields);
	const [groupFields, setGroupFields] = useState([]);
	
	function validateForm() {
		return (
			fields.email.length > 0 &&
			fields.username.length > 4 &&
			fields.firstName.length > 0 &&
			fields.lastName.length > 0 &&
			fields.groups.length > 0
		);
	}
	
	function handleFieldChange(event) {
		setFields({
			...fields,
			[event.target.id]: event.target.value
		});
	}
	
	async function handleSubmit() {
		try {
			await uracService.addUser(fields);
			NotificationManager.success(t("soajs:messages.addUser"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	function onChangeGroup(checked, code) {
		if (checked) {
			fields.groups.push(code);
		} else {
			_.pull(fields.groups, code);
		}
		setFields({...fields});
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				const _groups = await uracService.getGroups();
				let _g = null;
				if (_groups) {
					_g = [];
					for (let i = 0; i < _groups.length; i++) {
						let _item = {
							"code": _groups[i].code,
							"name": _groups[i].name,
							"selected": false
						};
						_g.push(_item);
					}
					if (isSubscribed) {
						setGroupFields(_g);
					}
				}
				if (isSubscribed) {
					setFields(initFields);
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad();
		return () => (isSubscribed = false);
	}, []);
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.userAdd")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Username")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="username"
									size="sm"
									value={fields.username}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Email")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="email"
									size="sm"
									value={fields.email}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.FirstName")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="firstName"
									size="sm"
									value={fields.firstName}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.LastName")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="lastName"
									size="sm"
									value={fields.lastName}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						
						{groupFields.map((group, index) => (
							<Form.Check key={index + 1} type="checkbox"
							            label={group.code + " - " + group.name}
							            onChange={(event) => {
								            onChangeGroup(event.target.checked, group.code)
							            }}
							/>
						))}
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button variant="success" onClick={handleSubmit}
				        disabled={!validateForm()}
				>
					{t("soajs:buttons.Add")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}