
import {ResponsivePie} from '@nivo/pie'

function myPatterns(datum, set) {
	let c = datum.id.substr(0, 1);
	c = c.toLowerCase();
	if (set === 1) {
		return ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'].includes(c);
	}
	if (set === 2) {
		return ['i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q',].includes(c);
	}
	if (set === 3) {
		return ['r', 's', 't', 'u', 'v', 'w', 'x', 'z'].includes(c);
	}
}

export default function ProgramsChart({data /* see data tab */}) {
	
	return (
		<ResponsivePie
			data={data}
			margin={{top: 40, right: 80, bottom: 80, left: 80}}
			innerRadius={0.5}
			padAngle={0.7}
			cornerRadius={3}
			colors={{scheme: 'paired'}}
			borderWidth={1}
			borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
			radialLabelsSkipAngle={10}
			radialLabelsTextColor="#000000"
			radialLabelsLinkColor={{from: 'color'}}
			sliceLabel="value"
			sliceLabelsSkipAngle={10}
			arcLinkLabelsSkipAngle={10}
			sliceLabelsTextColor="#000000"
			defs={[
				{
					id: 'dots',
					type: 'patternDots',
					background: 'inherit',
					color: 'rgba(255, 255, 255, 0.3)',
					size: 2,
					padding: 1,
					stagger: true
				},
				{
					id: 'lines',
					type: 'patternLines',
					background: 'inherit',
					color: 'rgba(255, 255, 255, 0.3)',
					rotation: -45,
					lineWidth: 5,
					spacing: 10
				},
				{
					id: 'squares',
					type: 'patternSquares',
					background: 'inherit',
					color: 'rgba(255, 255, 255, 0.3)',
					"size": 2,
					"padding": 3,
					stagger: true
				}
			]}
			fill={[
				{match: (d) => myPatterns(d, 1), id: 'dots'},
				{match: (d) => myPatterns(d, 2), id: 'lines'},
				{match: (d) => myPatterns(d, 3), id: 'squares'}
			]}
			legends={[]}
		/>
	)
}