
import {ResponsiveBar} from '@nivo/bar'


export default function GroupsChart({data /* see data tab */}) {
	
	return (
		<ResponsiveBar
			data={data}
			keys={['count']}
			indexBy="Group"
			margin={{top: 50, right: 130, bottom: 50, left: 150}}
			padding={0.3}
			layout="horizontal"
			valueScale={{type: 'linear'}}
			indexScale={{type: 'band', round: true}}
			colors={{scheme: 'paired'}}
			defs={[
				{
					id: 'dots',
					type: 'patternDots',
					background: 'inherit',
					color: '#38bcb2',
					size: 4,
					padding: 1,
					stagger: true
				},
				{
					id: 'lines',
					type: 'patternLines',
					background: 'inherit',
					color: '#eed312',
					rotation: -45,
					lineWidth: 6,
					spacing: 10
				}
			]}
			fill={[
				{
					match: {
						id: 'fries'
					},
					id: 'dots'
				},
				{
					match: {
						id: 'sandwich'
					},
					id: 'lines'
				}
			]}
			borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
			axisTop={null}
			axisRight={null}
			// axisBottom={{
			// 	tickSize: 5,
			// 	tickPadding: 5,
			// 	tickRotation: 0,
			// 	legend: 'count',
			// 	legendPosition: 'middle',
			// 	legendOffset: 32
			// }}
			// axisLeft={{
			// 	tickSize: 5,
			// 	tickPadding: 5,
			// 	tickRotation: 0,
			// 	legend: 'program',
			// 	legendPosition: 'middle',
			// 	legendOffset: -40
			// }}
			labelSkipWidth={12}
			labelSkipHeight={12}
			enableGridX={true}
			enableGridY={false}
			labelTextColor={{from: 'color', modifiers: [['darker', 1.6]]}}
			legends={[]}
			animate={true}
			motionStiffness={90}
			motionDamping={15}
		/>
	)
}