import {Button, Modal, Form, Col} from "react-bootstrap";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";


import {JsonEditor, NotificationManager} from "../../../../soajs/urac/components";
import {InfraService} from "../../../../services";

const infraService = InfraService.getService();

let _json = null;
export default function AddModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, opts: null});
	const [fields, setFields] = useState({
		"label": "",
		"description": "",
		"url": "",
		"port": 0,
		"token": "",
		"type": "secret"
	});
	
	function validateForm() {
		return (
			fields.label.length > 0 &&
			fields.description.length > 0 &&
			fields.token.length > 0 &&
			fields.url.length > 0 &&
			fields.port > 0
		);
	}
	
	function handleFieldChange(event) {
		setFields({
			...fields,
			[event.target.id]: event.target.value
		});
	}
	
	async function handleSubmit() {
		try {
			let data = {
				"label": fields.label,
				"description": fields.description,
				"url": fields.url,
				"port": parseInt(fields.port),
				"token": fields.token,
				"type": fields.type,
				"ca": _json
			};
			await infraService.addInfraAccount(data);
			NotificationManager.success(t("soajs:messages.addInfraAccount"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	function JsonEditor_onChange(json) {
		if (json) {
			_json = json;
		}
	}
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.clusterAdd")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Label")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="label"
									size="sm"
									value={fields.label}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Description")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="description"
									size="sm"
									as="textarea"
									rows={3}
									value={fields.description}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Url")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="url"
									size="sm"
									value={fields.url}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Port")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="port"
									size="sm"
									type="number"
									value={fields.port}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Token")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="token"
									size="sm"
									as="textarea"
									rows={3}
									value={fields.token}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Certificate")}</Form.Label>
							<Col sm={9}>
								<JsonEditor
									mode="code"
									json={_json}
									onChange={JsonEditor_onChange}
								/>
							</Col>
						</Form.Row>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button variant="success" onClick={handleSubmit}
				        disabled={!validateForm()}
				>
					{t("soajs:buttons.Add")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}