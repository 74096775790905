import axios from "axios";
import LocalStorageService from "../urac/services/LocalStorageService.js";
import Config from "../../config.js";

const localStorageService = LocalStorageService.getService();

axios.interceptors.request.use(
	config => {
		let key = Config.tenant;
		if (window.apiSettings && window.apiSettings.key) {
			key = window.apiSettings.key;
		}
		let apiEndpoint = Config.apiEndpoint;
		if (window.apiSettings && window.apiSettings.api) {
			apiEndpoint = window.apiSettings.api;
		}
		const access_token = localStorageService.getAccessToken();
		let url = config.url;
		if (url.indexOf("?") !== -1) {
			url = url.substr(0, url.indexOf("?"));
		}
		if (url.indexOf("://") !== -1) {
			url = url.substr(url.indexOf("://") + 3);
			url = url.substr(url.indexOf("/"));
		} else {
			config.url = apiEndpoint + config.url;
		}
		if (url !== '' && (Config.noToken.includes(url) || !access_token || config.headers['skip_access_token'])) {
			if (config.headers['skip_access_token']) {
				delete config.headers['skip_access_token'];
			}
			if (!config.headers['key']) {
				config.headers['key'] = key;
			}
		} else {
			if (access_token) {
				config.headers['access_token'] = access_token;
			}
			if (!config.headers['key']) {
				config.headers['key'] = key;
			}
		}
		return config;
	},
	error => {
		Promise.reject(error)
	}
);

axios.interceptors.response.use(
	response => {
		return response
	},
	error => {
		if (error && error.response && error.config) {
			let apiEndpoint = Config.apiEndpoint;
			if (window.apiSettings && window.apiSettings.api) {
				apiEndpoint = window.apiSettings.api;
			}
			const originalRequest = error.config;
			if (error.response.status === 401 &&
				!originalRequest._retry &&
				error.response.data &&
				error.response.data.errors &&
				error.response.data.errors.codes &&
				error.response.data.errors.codes[0] === 401 &&
				Config.badTokenMessages.includes(error.response.data.errors.details[0].message)) {
				originalRequest._retry = true;
				const refreshToken = localStorageService.getRefreshToken();
				return axios.post(apiEndpoint + "/oauth/token",
					{
						"refresh_token": refreshToken,
						"grant_type": "refresh_token"
					})
					.then(res => {
						if (res && res.data && res.data.access_token) {
							localStorageService.setToken(res.data);
							// axios.defaults.headers.common['access_token'] = localStorageService.getAccessToken();
							return axios(originalRequest);
						}
					})
			}
		}
		return Promise.reject(error);
	}
);