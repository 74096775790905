import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import deepSet from "../../../../soajs/libs/deepSetLib";
import AddButton from "../../../buttons/Add";
import DeleteButton from "../../../buttons/Delete";
import {useAppContext} from "../../../../soajs/libs/contextLib";

import DatabasesDeleteModal from "./DatabasesDeleteModal";
import DatabasesAddModal from "./DatabasesAddModal";
import {ConsoleService} from "../../../../services";
import {NotificationManager} from "../../../../soajs/urac/components";

const prefixFieldInit = {
	"prefix": ""
}

const consoleService = ConsoleService.getService();

export default function Databases({registry, environment, clusters, reload}) {
	const {t} = useTranslation(["common", "soajs"]);
	const {ability} = useAppContext();
	const [prefixField, setPrefixField] = useState(prefixFieldInit);
	
	const [deleteModalOpt, setDeleteModalOpt] = useState({"show": false, item: null});
	const handleDeleteShow = (item) => setDeleteModalOpt({"show": true, "item": item});
	const [addModalOpt, setAddModalOpt] = useState({"show": false, item: null});
	const handleAddShow = (item) => setAddModalOpt({"show": true, "item": item});
	
	function handlePrefixFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		let obj = prefixField;
		deepSet(obj, event.target.id, value);
		setPrefixField({...obj});
	}
	
	async function handlePrefixSubmit() {
		try {
			let data = {
				"env": registry.code,
				"prefix": prefixField.prefix
			};
			await consoleService.updateDBPrefix(data);
			NotificationManager.success(t("soajs:messages.updatePrefix"));
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	function validatePrefixForm() {
		return (
			prefixField.prefix.length >= 0
		);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		if (isSubscribed && registry) {
			setPrefixField(Object.assign({}, prefixFieldInit, {"prefix": registry.dbs.config.prefix || ""}));
		}
		return () => (isSubscribed = false);
	}, [registry]);
	
	return (
		<>
			<DatabasesDeleteModal
				modalOpt={deleteModalOpt}
				setModalOpt={setDeleteModalOpt}
				handleAction={reload}
			/>
			<DatabasesAddModal
				modalOpt={addModalOpt}
				setModalOpt={setAddModalOpt}
				handleAction={reload}
				clusters={clusters}
				environment={environment}
			/>
			<Card className="mb-3">
				<Card.Header>
					{t("soajs:titles.GlobalPrefix")}:
				</Card.Header>
				<Card.Body className="mt-3 mb-3">
					<Form>
						<Row>
							<Col xs={9} md={10}>
								<Form.Control
									className="float-left"
									id="prefix"
									size="sm"
									value={prefixField.prefix}
									onChange={handlePrefixFieldChange}
								/>
							</Col>
							<Col xs={3} md={2}>
								{ability.can('registry_db', 'prefix') &&
								<Button
									className="float-right"
									variant="success"
									onClick={handlePrefixSubmit}
									disabled={!validatePrefixForm()}
								>
									{t("soajs:buttons.Update")}
								</Button>}
							</Col>
						</Row>
					</Form>
				</Card.Body>
				<Card.Footer>
					<small className="text-muted">
						{t("soajs:messages.GlobalPrefix")}
					</small>
				</Card.Footer>
			</Card>
			
			<Card>
				<Card.Header>
					{t("soajs:titles.Databases")}
					
					{ability.can('registry_db', 'add') &&
					<AddButton
						className="float-right ml-1"
						onClick={() => {
							handleAddShow({"env": registry.code});
						}}
					/>}
				</Card.Header>
				<Card.Body className="mt-3 mb-3">
					<Table responsive striped hover size="sm">
						<thead className="text-light bg-dark">
						<tr>
							<th>{t("soajs:buttons.Actions")}</th>
							<th>{t("soajs:fields.Name")}</th>
							<th>{t("soajs:fields.Cluster")}</th>
							<th>{t("soajs:fields.Multitenant")}</th>
							<th>{t("soajs:fields.Prefix")}</th>
						</tr>
						</thead>
						<tbody>
						{registry && registry.dbs && registry.dbs.databases &&
						Object.keys(registry.dbs.databases).map((db, index) => (
							<tr key={index}>
								<td>
									{ability.can('registry_db', 'delete') &&
									<DeleteButton
										className="float-left mr-1"
										onClick={() => {
											handleDeleteShow({"env": registry.code, "name": db})
										}}
									/>}
								</td>
								<td>{db}</td>
								<td>{registry.dbs.databases[db].cluster}</td>
								<td>{"" + registry.dbs.databases[db].tenantSpecific}</td>
								<td>{registry.dbs.databases[db].prefix}</td>
							</tr>
						))}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		</>
	)
};