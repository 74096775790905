import {Dropdown, DropdownButton, Nav, Navbar, NavDropdown} from "react-bootstrap";
import React, {useState, useEffect} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {LinkContainer} from "react-router-bootstrap";
import {GoDiffAdded} from "react-icons/go";
import {useTranslation} from "react-i18next";
import {useAppContext} from "../../soajs/libs/contextLib";

const rootPath = "/environments";


function Navigation({environments, setEnvironment, environment}) {
	const {ability} = useAppContext();
	const {t} = useTranslation(["common", "soajs"]);
	const [selectedEnv, setSelectedEnv] = useState(null);
	const [uri, setUri] = useState("settings");
	const [selectedMsg, setSelected] = useState("-- Select an environment --");
	const history = useHistory();
	const {env} = useParams();
	let location = useLocation();
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			if (env && environments.length > 0) {
				if (!environment || environment.code !== env) {
					for (let i = 0; i < environments.length; i++) {
						if (environments[i].code === env) {
							setEnvironment({...environments[i]});
						}
					}
				}
				if (isSubscribed) {
					setSelected(env);
					setSelectedEnv(env);
					let uri = location.pathname.substr(location.pathname.indexOf(env + "/") + env.length + 1);
					setUri(uri);
				}
			} else {
				setSelected("-- Select an environment --");
				setSelectedEnv(null);
			}
		}
		
		onLoad().catch();
		return () => (isSubscribed = false);
	}, [env, environment, environments, setEnvironment, location]);
	
	return (
		<Navbar collapseOnSelect expand="sm" className="pt-0 pb-0" bg="secondary" variant="light">
			<Navbar.Brand>
				<DropdownButton size="sm" id="dropdown-item-button" title={selectedMsg} variant="light"
				                className="small">
					{environments.map((item, index) => (
						<Dropdown.Item as="button" key={index} active={selectedEnv === item.code} onClick={() => {
							history.push(rootPath + "/manage/" + item.code + "/" + uri);
						}}>
							<small>
								{item.code}
							</small>
						</Dropdown.Item>
					))}
					<Dropdown.Divider/>
					{ability.can('env', 'add') &&
					<Dropdown.Item className="small"
					               onClick={() => {
						               history.push(rootPath + "/add");
					               }}
					><GoDiffAdded/> Add an environment</Dropdown.Item>}
				</DropdownButton>
			</Navbar.Brand>
			{env ? (
				<>
					<Navbar.Toggle aria-controls="environment-navbar-nav"/>
					<Navbar.Collapse id="environment-navbar-nav" className="justify-content-end">
						<Nav activeKey={window.location.pathname}>
							<LinkContainer to={rootPath + "/manage/" + env + "/settings"}>
								<NavDropdown.Item><small>{t("soajs:menu.settings")}</small></NavDropdown.Item>
							</LinkContainer>
							<LinkContainer
								to={rootPath + "/manage/" + env + "/registry"}>
								<NavDropdown.Item><small>{t("soajs:menu.registry")}</small></NavDropdown.Item>
							</LinkContainer>
							
							<NavDropdown className="small" alignRight title={t("soajs:menu.cluster")}
							             id="collasible-nav1-dropdown">
								<LinkContainer
									to={rootPath + "/manage/" + env + "/cluster/workloads"}>
									<NavDropdown.Item><small>{t("soajs:menu.workloads")}</small></NavDropdown.Item>
								</LinkContainer>
								<LinkContainer
									to={rootPath + "/manage/" + env + "/cluster/services"}>
									<NavDropdown.Item><small>{t("soajs:menu.services")}</small></NavDropdown.Item>
								</LinkContainer>
								<LinkContainer
									to={rootPath + "/manage/" + env + "/cluster/configuration"}>
									<NavDropdown.Item><small>{t("soajs:menu.configuration")}</small></NavDropdown.Item>
								</LinkContainer>
								<LinkContainer
									to={rootPath + "/manage/" + env + "/cluster/nodes"}>
									<NavDropdown.Item><small>{t("soajs:menu.nodes")}</small></NavDropdown.Item>
								</LinkContainer>
								<LinkContainer
									to={rootPath + "/manage/" + env + "/cluster/storage"}>
									<NavDropdown.Item><small>{t("soajs:menu.storage")}</small></NavDropdown.Item>
								</LinkContainer>
								<LinkContainer
									to={rootPath + "/manage/" + env + "/cluster/rbac"}>
									<NavDropdown.Item><small>{t("soajs:menu.RBAC")}</small></NavDropdown.Item>
								</LinkContainer>
							</NavDropdown>
							
							<NavDropdown className="small" alignRight title={t("soajs:menu.catalogs")}
							             id="collasible-nav2-dropdown">
								<LinkContainer to={rootPath + "/manage/" + env + "/catalog/api"}>
									<NavDropdown.Item><small>{t("soajs:menu.API")}</small></NavDropdown.Item>
								</LinkContainer>
								<LinkContainer to={rootPath + "/manage/" + env + "/catalog/daemon"}>
									<NavDropdown.Item><small>{t("soajs:menu.Daemon")}</small></NavDropdown.Item>
								</LinkContainer>
								<LinkContainer to={rootPath + "/manage/" + env + "/catalog/frontend"}>
									<NavDropdown.Item><small>{t("soajs:menu.Frontend")}</small></NavDropdown.Item>
								</LinkContainer>
								<LinkContainer to={rootPath + "/manage/" + env + "/catalog/custom"}>
									<NavDropdown.Item><small>{t("soajs:menu.Custom")}</small></NavDropdown.Item>
								</LinkContainer>
								<LinkContainer to={rootPath + "/manage/" + env + "/catalog/resource"}>
									<NavDropdown.Item><small>{t("soajs:menu.Resource")}</small></NavDropdown.Item>
								</LinkContainer>
							</NavDropdown>
							
							<NavDropdown className="small" alignRight title={t("soajs:menu.SOAJS")}
							             id="collasible-nav1-dropdown">
								<LinkContainer to={rootPath + "/manage/" + env + "/catalog/soajs/api"}>
									<NavDropdown.Item><small>{t("soajs:menu.API")}</small></NavDropdown.Item>
								</LinkContainer>
								<LinkContainer to={rootPath + "/manage/" + env + "/catalog/soajs/frontend"}>
									<NavDropdown.Item><small>{t("soajs:menu.Frontend")}</small></NavDropdown.Item>
								</LinkContainer>
							</NavDropdown>
						</Nav>
					</Navbar.Collapse>
				</>
			) : null}
		</Navbar>
	);
}

export default Navigation