import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import React from "react";
import {LinkContainer} from "react-router-bootstrap";
import {useTranslation} from "react-i18next";

function Navigation() {
	const {t} = useTranslation(["common", "soajs"]);
	const rootPath = "/settings";
	return (
		<Navbar collapseOnSelect expand="lg" className="pt-0 pb-0" bg="secondary" variant="light">
			<span></span>
			<Navbar.Toggle aria-controls="settings-navbar-nav"/>
			<Navbar.Collapse id="settings-navbar-nav" className="justify-content-end">
				<Nav activeKey={window.location.pathname}>
					{/*<LinkContainer to={rootPath + "/release/update"}>*/}
					{/*	<NavDropdown.Item><small>{t("soajs:menu.ReleaseUpdate")}</small></NavDropdown.Item>*/}
					{/*</LinkContainer>*/}
					
					<LinkContainer to={rootPath + "/infrastructure"}>
						<NavDropdown.Item><small>{t("soajs:menu.Infrastructure")}</small></NavDropdown.Item>
					</LinkContainer>
					
					<LinkContainer to={rootPath + "/git/accounts"}>
						<NavDropdown.Item><small>{t("soajs:menu.GitAccounts")}</small></NavDropdown.Item>
					</LinkContainer>
					
					<LinkContainer to={rootPath + "/notifications"}>
						<NavDropdown.Item><small>{t("soajs:menu.Notifications")}</small></NavDropdown.Item>
					</LinkContainer>
					
					<NavDropdown className="small" alignRight title={t("soajs:menu.settings")}
					             id="collasible-nav1-dropdown">
						<LinkContainer to={rootPath + "/configuration"}>
							<NavDropdown.Item><small>{t("soajs:menu.configuration")}</small></NavDropdown.Item>
						</LinkContainer>
						<LinkContainer to={rootPath + "/integration"}>
							<NavDropdown.Item><small>{t("soajs:menu.integration")}</small></NavDropdown.Item>
						</LinkContainer>
					</NavDropdown>
					
					<NavDropdown className="small" alignRight title={t("soajs:menu.AccessRights")}
					             id="collasible-nav1-dropdown">
						<LinkContainer to={rootPath + "/users"}>
							<NavDropdown.Item><small>{t("soajs:menu.users")}</small></NavDropdown.Item>
						</LinkContainer>
						<LinkContainer to={rootPath + "/groups"}>
							<NavDropdown.Item><small>{t("soajs:menu.groups")}</small></NavDropdown.Item>
						</LinkContainer>
						<LinkContainer to={rootPath + "/packages"}>
							<NavDropdown.Item><small>{t("soajs:menu.packages")}</small></NavDropdown.Item>
						</LinkContainer>
					</NavDropdown>
					
					<NavDropdown className="small" alignRight title={t("soajs:menu.ContinuousDelivery")}
					             id="collasible-nav1-dropdown">
						<LinkContainer to={rootPath + "/cd/configuration"}>
							<NavDropdown.Item><small>{t("soajs:menu.Configuration")}</small></NavDropdown.Item>
						</LinkContainer>
						<LinkContainer to={rootPath + "/tokens"}>
							<NavDropdown.Item><small>{t("soajs:menu.Tokens")}</small></NavDropdown.Item>
						</LinkContainer>
					</NavDropdown>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}

export default Navigation