import React from "react";
import {Alert} from "react-bootstrap";

export default function Manual({environment}) {
	
	return (
		<>
			<hr/>
			<Alert variant="secondary">
				This environment only operates on your Local Machine with this IP Address <b
				className="text-success">( {environment.deployer.manual.nodes} )</b>. It requires you deploy
				Microservices and Daemons manually in order to be able to
				operate them form under the appropriate catalogs.
			</Alert>
		</>
	);
}