import {Button, Modal, Badge} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";

export default function EditResourceModal({modalOpt, setModalOpt}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, item: null});
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.resourceEdit")} <Badge
					variant="light">{modalOpt.item && modalOpt.item.record.name}</Badge> <Badge
					variant="secondary">{modalOpt.item && "v" + modalOpt.item.version}</Badge></Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Coming soon
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={handleClose}>
					{t("soajs:buttons.Close")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}