import React, {useEffect, useState} from "react";

import Granular from "./Granular"
import getACL from "../../lib/scopeAclLib";

export default function Scope({service, scope}) {
	const [fields, setFields] = useState(null);
	
	useEffect(() => {
		let isSubscribed = true;
		
		if (service && scope) {
			let ACL = {};
			for (let i = 0; i < service.length; i++) {
				if (service[i].version) {
					let version = service[i].version;
					if (scope[version]) {
						ACL[version] = getACL(scope[version], service[i]);
					}
				}
			}
			if (isSubscribed) {
				setFields(ACL);
			}
		}
		
		return () => (isSubscribed = false);
	}, [service, scope]);
	
	return (
		fields &&
		<Granular
			service={fields}
		/>
	);
}