import {Alert, Button, Col, Dropdown, DropdownButton, Form, Row, Tab, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {NotificationManager} from "../../../../soajs/urac/components";
import {ConsoleService, MultitenantService} from "../../../../services";
import {BiSearchAlt} from "react-icons/bi";
import {useTranslation} from "react-i18next";

import TenantUsers from "./containers/TenantUsers";
import TenantGroups from "./containers/TenantGroups";

import config from "../../../../config";

const multitenantService = MultitenantService.getService();
const consoleService = ConsoleService.getService();

const criteriaModel = {
	"keywords_mainTenant": "",
	"keywords_subTenant": "",
	"mainTenant": -1,
	"subTenant": -1,
	"mainTenantExtKey": "",
	"subTenantExtKey": "",
	"tenantType": "Product"
};

function getTenantExtKey(tenant, env) {
	let extKeys = [];
	if (env && tenant && tenant.applications && tenant.applications.length > 0) {
		tenant.applications.forEach((oneApp) => {
			if (oneApp && oneApp.keys && oneApp.keys.length > 0) {
				oneApp.keys.forEach((oneKey) => {
					if (oneKey && oneKey.extKeys && oneKey.extKeys.length > 0) {
						oneKey.extKeys.forEach((oneExt) => {
							if (oneExt && oneExt.extKey && (oneExt.env.toUpperCase() === env.toUpperCase() || oneExt.env === null || !oneExt.env)) {
								extKeys.push({
									v: oneExt.extKey,
									l: oneApp.product + " " + oneApp.package + " " + (oneExt.label ? oneExt.label : oneExt.extKey.substring(0, 10) + "..." + oneExt.extKey.substring(oneExt.extKey.length - 10, oneExt.extKey.length))
								});
							}
						});
					}
				});
			}
		});
	}
	return extKeys;
}

export default function Users() {
	const {t} = useTranslation(["common", "soajs"]);
	const [environments, setEnvironments] = useState([]);
	const [selectedEnv, setSelectedEnv] = useState(null);
	const [selectedMsg, setSelected] = useState("-- Select an environment --");
	const [mainTenantItems, setMainTenantItems] = useState([]);
	const [subTenantItems, setSubTenantItems] = useState([]);
	const [mainTenantKeys, setMainTenantKeys] = useState([]);
	const [subTenantKeys, setSubTenantKeys] = useState([]);
	const [criteria, setCriteria] = useState(criteriaModel);
	
	async function reLoad() {
		try {
			let c = {"limit": 50, "start": 0, "type": "product"};
			if (criteria.keywords_mainTenant) {
				c.keywords = criteria.keywords_mainTenant;
			}
			const response = await multitenantService.getTenants(c);
			if (response) {
				setMainTenantItems(response.items);
				setMainTenantKeys([]);
				setSubTenantKeys([]);
			}
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function reLoad_SubTenant() {
		try {
			if (criteria.mainTenant >= 0) {
				let c = {"limit": 50, "start": 0};
				if (criteria.keywords_mainTenant) {
					c.keywords = criteria.keywords_subTenant;
				}
				let code = mainTenantItems[criteria.mainTenant].code;
				const response = await multitenantService.getTenantSubTenants(code, c);
				if (response) {
					setSubTenantItems(response.items);
					setSubTenantKeys([]);
				}
			}
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function handleSelectEnv(code) {
		setSelectedEnv(code);
		setSelected(code);
		let reset = {
			"keywords_mainTenant": criteria.keywords_mainTenant,
			"keywords_subTenant": criteria.keywords_subTenant,
			"mainTenant": criteriaModel.mainTenant,
			"subTenant": criteriaModel.subTenant,
			"mainTenantExtKey": criteriaModel.mainTenantExtKey,
			"subTenantExtKey": criteriaModel.subTenantExtKey,
			"tenantType": criteriaModel.tenantType
		};
		setCriteria(reset);
		setMainTenantKeys([]);
		setSubTenantKeys([]);
		await reLoad();
	}
	
	async function handleCriteriaChange(event) {
		let value = event.target.value;
		let id = event.target.id;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		if (event.target.type === 'number') {
			value = parseInt(value);
		}
		if (id === "mainTenant") {
			if (value >= 0) {
				let extKeys = getTenantExtKey(mainTenantItems[value], selectedEnv);
				setMainTenantKeys(extKeys);
				setSubTenantKeys([]);
				criteria.mainTenant = value;
				await reLoad_SubTenant();
			}
			let reset = {
				"keywords_mainTenant": criteria.keywords_mainTenant,
				"keywords_subTenant": criteria.keywords_subTenant,
				"mainTenant": value,
				"mainTenantExtKey": criteriaModel.mainTenantExtKey,
				"subTenant": criteriaModel.subTenant,
				"subTenantExtKey": criteriaModel.subTenantExtKey,
				"tenantType": criteria.tenantType
			};
			setCriteria({...reset});
		} else if (id === "subTenant") {
			if (value >= 0) {
				let extKeys = getTenantExtKey(subTenantItems[value], selectedEnv);
				setSubTenantKeys(extKeys);
			}
			let reset = {
				"keywords_mainTenant": criteria.keywords_mainTenant,
				"keywords_subTenant": criteria.keywords_subTenant,
				"mainTenant": criteria.mainTenant,
				"mainTenantExtKey": criteria.mainTenantExtKey,
				"subTenant": value,
				"subTenantExtKey": criteriaModel.subTenantExtKey,
				"tenantType": criteria.tenantType
			};
			setCriteria({...reset});
		} else if (id === "tenantType") {
			setCriteria({
				...criteria,
				[id]: value
			});
		} else {
			setCriteria({
				...criteria,
				[id]: value
			});
		}
	}
	
	async function handleSearch(event) {
		event.preventDefault();
		await reLoad();
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				const _environments = await consoleService.getEnvironments();
				if (_environments && isSubscribed) {
					setEnvironments(_environments);
				}
				if (isSubscribed) {
					setSelectedEnv(null);
					setSelected("-- Select an environment --");
					setCriteria(criteriaModel);
					setMainTenantItems([]);
					setMainTenantKeys([]);
					setSubTenantItems([]);
					setSubTenantKeys([]);
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad().catch();
		return () => (isSubscribed = false);
	}, []);
	
	return (
		<>
			<Alert variant="info" className="mt-4 mb-2 p-2 pl-3 pr-3">
				<h6 className="float-left mr-3 mt-2">
					Environment:
				</h6>
				<DropdownButton
					size="sm"
					id="dropdown-item-button"
					title={selectedMsg}
					variant="light"
					className="small">
					{environments.map((item, index) => (
						item.code.toLowerCase() !== config.consoleEnvironment &&
						<Dropdown.Item
							as="button"
							key={index}
							active={selectedEnv === item.code}
							onClick={() => {
								handleSelectEnv(item.code).catch();
							}}>
							<small>
								{item.code}
							</small>
						</Dropdown.Item>
					))}
				</DropdownButton>
				<span className="clearfix"></span>
			</Alert>
			{selectedEnv &&
			<>
				<Alert variant="secondary" className="mt-4 mb-2 p-2 pl-3 pr-3">
					<Row>
						<Col md={4}>
							<Form.Control
								style={{
									"fontSize": "80%"
								}}
								as="select"
								id="mainTenant"
								value={criteria.mainTenant}
								onChange={handleCriteriaChange}
							>
								<option value={-1}> ---</option>
								{mainTenantItems.map((rec, i) => (
									<option key={i} value={i}>{rec.code} - {rec.name}</option>
								))}
							</Form.Control>
						</Col>
						<Col md={4}>
							<Row>
								<Col xs={6} md={7}>
									<Form.Control
										style={{
											"fontSize": "80%"
										}}
										id="keywords_mainTenant"
										size="sm"
										value={criteria.keywords_mainTenant}
										onChange={handleCriteriaChange}
									/>
								</Col>
								<Col xs={6} md={5}>
									<Button
										className="float-left"
										variant="info"
										size="sm"
										onClick={handleSearch}>
										<BiSearchAlt/> {t("soajs:buttons.Filter")}
									</Button>
								</Col>
							</Row>
						</Col>
						<Col md={4}>
							<Form.Control
								style={{
									"fontSize": "80%"
								}}
								as="select"
								id="mainTenantExtKey"
								value={criteria.mainTenantExtKey}
								onChange={handleCriteriaChange}
							>
								<option value=""> ---</option>
								{mainTenantKeys.map((rec, i) => (
									<option key={i} value={rec.v}>{rec.l}</option>
								))}
							</Form.Control>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Text className="text-muted">
								Only 50 tenant appears in this list, you can filter by name and code
							</Form.Text>
						</Col>
					</Row>
					{criteria.mainTenantExtKey !== "" &&
					<>
						<hr/>
						<Form.Control
							as="select"
							id="tenantType"
							value={criteria.tenantType}
							onChange={handleCriteriaChange}
						>
							<option value="Product">Main Tenant Users and Groups</option>
							<option value="Client">Sub Tenant Users and Groups</option>
						</Form.Control>
						{criteria.tenantType === "Client" &&
						<>
							<hr/>
							<Row>
								<Col md={4}>
									<Form.Control
										style={{
											"fontSize": "80%"
										}}
										as="select"
										id="subTenant"
										value={criteria.subTenant}
										onChange={handleCriteriaChange}
									>
										<option value={-1}> ---</option>
										{subTenantItems.map((rec, i) => (
											<option key={i} value={i}>{rec.code} - {rec.name}</option>
										))}
									</Form.Control>
								</Col>
								<Col md={4}>
									<Row>
										<Col xs={6} md={7}>
											<Form.Control
												style={{
													"fontSize": "80%"
												}}
												id="keywords_subTenant"
												size="sm"
												value={criteria.keywords_subTenant}
												onChange={handleCriteriaChange}
											/>
										</Col>
										<Col xs={6} md={5}>
											<Button
												className="float-left"
												variant="info"
												size="sm"
												onClick={handleSearch}>
												<BiSearchAlt/> {t("soajs:buttons.Filter")}
											</Button>
										</Col>
									</Row>
								</Col>
								<Col md={4}>
									<Form.Control
										style={{
											"fontSize": "80%"
										}}
										as="select"
										id="subTenantExtKey"
										value={criteria.subTenantExtKey}
										onChange={handleCriteriaChange}
									>
										<option value=""> ---</option>
										{subTenantKeys.map((rec, i) => (
											<option key={i} value={rec.v}>{rec.l}</option>
										))}
									</Form.Control>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Text className="text-muted">
										Only 50 tenant appears in this list, you can filter by name and code
									</Form.Text>
								</Col>
							</Row>
						</>
						}
					</>
					}
				</Alert>
				{criteria.tenantType === "Product" && criteria.mainTenantExtKey !== "" &&
				<Tabs className="mt-4">
					<Tab eventKey="Users" title={t("soajs:fields.Users")}>
						<TenantUsers
							scope={"myTenancy"}
							tenantId={mainTenantItems[criteria.mainTenant]._id}
							extKey={criteria.mainTenantExtKey}
							env={selectedEnv}
						/>
					</Tab>
					<Tab eventKey="InvitedUsers" title={t("soajs:fields.InvitedUsers")}>
						<TenantUsers
							scope={"otherTenancyInvited"}
							tenantId={mainTenantItems[criteria.mainTenant]._id}
							extKey={criteria.mainTenantExtKey}
							env={selectedEnv}
						/>
					</Tab>
					<Tab eventKey="Groups" title={t("soajs:fields.Groups")}>
						<TenantGroups
							extKey={criteria.mainTenantExtKey}
							env={selectedEnv}
						/>
					</Tab>
				</Tabs>
				}
				{criteria.tenantType === "Client" && criteria.subTenantExtKey !== "" &&
				<>
					<Tabs className="mt-4">
						<Tab eventKey="InvitedUsers" title={t("soajs:fields.InvitedUsers")}>
							<TenantUsers
								scope={null}
								tenantId={subTenantItems[criteria.subTenant]._id}
								extKey={criteria.subTenantExtKey}
								env={selectedEnv}
							/>
						</Tab>
						<Tab eventKey="Groups" title={t("soajs:fields.Groups")}>
							<TenantGroups
								extKey={criteria.subTenantExtKey}
								env={selectedEnv}
							/>
						</Tab>
					</Tabs>
				</>
				}
			</>
			}
		</>
	);
}