import {Button, ListGroup, ListGroupItem, Modal, Accordion, Card, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export default function InfoModal({modalOpt, setModalOpt, product}) {
	const {t} = useTranslation(["common", "soajs"]);
	const [productFields, setProductFields] = useState([]);
	
	const handleClose = () => setModalOpt({"show": false, opts: null});
	
	useEffect(() => {
		let isSubscribed = true;
		if (product && modalOpt.opts) {
			let item = {};
			item.code = product.code;
			item.name = product.name;
			item.description = product.description;
			item.packages = [];
			for (let j = 0; j < product.packages.length; j++) {
				item.packages.push({
					"code": product.packages[j].code,
					"name": product.packages[j].name,
					"selected": (
						modalOpt.opts && modalOpt.opts.config && modalOpt.opts.config.allowedPackages &&
						modalOpt.opts.config.allowedPackages[product.code] &&
						modalOpt.opts.config.allowedPackages[product.code].includes(product.packages[j].code)
					)
				})
			}
			if (isSubscribed) {
				setProductFields([item]);
			}
		} else {
			if (isSubscribed) {
				setProductFields(null);
			}
		}
		return () => (isSubscribed = false);
	}, [product, modalOpt]);
	
	return (
		modalOpt.opts &&
		<Modal show={modalOpt.show} onHide={handleClose} animation={false}>
			<Modal.Header closeButton className="bg-secondary text-white">
				<Modal.Title>{t("soajs:titles.groupInfo")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ListGroup variant="flush">
					<ListGroupItem>
						<span className="font-weight-bold">
							{t("soajs:fields.Code")}:</span> {modalOpt.opts.code}
					</ListGroupItem>
					<ListGroupItem>
						<span className="font-weight-bold">
							{t("soajs:fields.Name")}:</span> {modalOpt.opts.name}
					</ListGroupItem>
					<ListGroupItem>
						<span className="font-weight-bold">
						{t("soajs:fields.Description")}:</span> {modalOpt.opts.description}
					</ListGroupItem>
				</ListGroup>
				<hr/>
				<Accordion defaultActiveKey={1}>
					{productFields && productFields.map((item, index) => (
						<Card key={index + 1}>
							<Card.Header>
								<div>
									<Accordion.Toggle className="p-0" as={Button} variant="link"
									                  eventKey={index + 1}>
										{item.code} - {item.name}
									</Accordion.Toggle>
								</div>
								<small className="font-italic">{item.description}</small>
							</Card.Header>
							<Accordion.Collapse eventKey={index + 1}>
								<Card.Body>
									{item.packages.map((pack, index) => (
										<Form.Check key={index + 1} type="switch" disabled
										            label={pack.code + " - " + pack.name}
										            checked={pack.selected}
										/>
									))}
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					))}
				</Accordion>
			</Modal.Body>
			<Modal.Footer className="bg-light">
				<Button variant="info" onClick={handleClose}>
					{t("soajs:buttons.Ok")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}