import {Form, Row, Col, Alert, Card, CardDeck} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";
import deepSet from "../../../../soajs/libs/deepSetLib";
import {JsonEditor} from "../../../../soajs/urac/components";

export default function DefaultEditRegistry({registry, fields, setFields}) {
	const {t} = useTranslation(["common", "soajs"]);
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		let obj = fields;
		if (event.target.type === 'number') {
			value = parseInt(value);
		}
		deepSet(obj, event.target.id, value);
		setFields({...obj});
	}
	function handleFieldChangeBoolean(event) {
		let value = event.target.value;
		let obj = fields;
		value = (value === "true");
		deepSet(obj, event.target.id, value);
		setFields({...obj});
	}
	
	function JsonEditor_onChange(json) {
		fields.services.config.logger = json;
	}
	
	return (
		registry &&
		<Form>
			<Form.Group>
				<Form.Label>{t("soajs:fields.Description")}</Form.Label>
				<Form.Control
					id="description"
					size="sm"
					as="textarea"
					rows={3}
					value={fields.description}
					onChange={handleFieldChange}
				/>
			</Form.Group>
			<Alert variant="light" style={{"border": "1px solid rgba(0,0,0,.125)"}}>
				<Form.Group>
					<Form.Label>{t("soajs:fields.Domain")}</Form.Label>
					<Form.Control
						id="domain"
						size="sm"
						value={fields.domain}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Row>
					<Col>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Protocol")}</Form.Label>
							<Form.Control as="select" id="protocol" size="sm"
							              defaultValue={fields.protocol}
							              onChange={handleFieldChange}
							>
								<option value="https">https</option>
								<option value="http">http</option>
							</Form.Control>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Port")}</Form.Label>
							<Form.Control
								id="port"
								size="sm"
								type="number"
								value={fields.port}
								onChange={handleFieldChange}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Group>
							<Form.Label>{t("soajs:fields.apiPrefix")}</Form.Label>
							<Form.Control
								id="apiPrefix"
								size="sm"
								value={fields.apiPrefix}
								onChange={handleFieldChange}
							/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group>
							<Form.Label>{t("soajs:fields.sitePrefix")}</Form.Label>
							<Form.Control
								id="sitePrefix"
								size="sm"
								value={fields.sitePrefix}
								onChange={handleFieldChange}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col className="small text-muted text-center">
						{t("soajs:messages.prefixMsg")}
					</Col>
				</Row>
			</Alert>
			<hr/>
			<CardDeck className="mb-4 small mt-4">
				<Card bg="light" style={{minWidth: '22rem'}}>
					<Card.Body>
						<Card.Title>{t("soajs:fields.Awareness")}</Card.Title>
						<Form.Group>
							<Form.Label>{t("soajs:fields.CacheTTL")}</Form.Label>
							<Form.Control
								id="services.config.awareness.cacheTTL"
								size="sm"
								type="number"
								value={fields.services.config.awareness.cacheTTL}
								onChange={handleFieldChange}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Healthcheckinterval")}</Form.Label>
							<Form.Control
								id="services.config.awareness.healthCheckInterval"
								size="sm"
								type="number"
								value={fields.services.config.awareness.healthCheckInterval}
								onChange={handleFieldChange}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Autoreloadregistry")}</Form.Label>
							<Form.Control
								id="services.config.awareness.autoRelaodRegistry"
								size="sm"
								type="number"
								value={fields.services.config.awareness.autoRelaodRegistry}
								onChange={handleFieldChange}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Maxlogcount")}</Form.Label>
							<Form.Control
								id="services.config.awareness.maxLogCount"
								size="sm"
								type="number"
								value={fields.services.config.awareness.maxLogCount}
								onChange={handleFieldChange}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Automaticregisterservice")}</Form.Label>
							<Form.Control
								id="services.config.awareness.autoRegisterService"
								as="select" size="sm"
								defaultValue={fields.services.config.awareness.autoRegisterService}
								onChange={handleFieldChangeBoolean}
							>
								<option value="true">True</option>
								<option value="false">False</option>
							</Form.Control>
						</Form.Group>
					</Card.Body>
				</Card>
				<Card bg="light" style={{minWidth: '22rem'}}>
					<Card.Body>
						<Card.Title>{t("soajs:fields.Cors")}</Card.Title>
						<Row>
							<Col>
								<Form.Group>
									<Form.Label>{t("soajs:fields.Enabled")}</Form.Label>
									<Form.Control
										id="services.config.cors.enabled"
										as="select" size="sm"
										defaultValue={fields.services.config.cors.enabled}
										onChange={handleFieldChangeBoolean}
									>
										<option value="true">True</option>
										<option value="false">False</option>
									</Form.Control>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group>
									<Form.Label>{t("soajs:fields.Credentials")}</Form.Label>
									<Form.Control
										id="services.config.cors.credentials"
										as="select" size="sm"
										defaultValue={fields.services.config.cors.credentials}
										onChange={handleFieldChangeBoolean}
									>
										<option value="true">True</option>
										<option value="false">False</option>
									</Form.Control>
								</Form.Group>
							</Col>
						</Row>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Origin")}</Form.Label>
							<Form.Control
								id="services.config.cors.origin"
								size="sm"
								value={fields.services.config.cors.origin}
								onChange={handleFieldChange}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Maxage")}</Form.Label>
							<Form.Control
								id="services.config.cors.maxage"
								size="sm"
								type="number"
								value={fields.services.config.cors.maxage}
								onChange={handleFieldChange}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Methods")}</Form.Label>
							<Form.Control
								id="services.config.cors.methods"
								size="sm"
								value={fields.services.config.cors.methods}
								onChange={handleFieldChange}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Headers")}</Form.Label>
							<Form.Control
								id="services.config.cors.headers"
								size="sm"
								as="textarea"
								rows={3}
								value={fields.services.config.cors.headers}
								onChange={handleFieldChange}
							/>
						</Form.Group>
					</Card.Body>
				</Card>
			</CardDeck>
			<CardDeck className="mb-4 small mt-4">
				<Card bg="light" style={{minWidth: '22rem'}}>
					<Card.Body>
						<Card.Title>{t("soajs:fields.Requests")}</Card.Title>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Authorization")}</Form.Label>
							<Form.Control
								id="services.controller.authorization"
								size="sm"
								as="select"
								type="boolean"
								defaultValue={fields.services.controller.authorization}
								onChange={handleFieldChangeBoolean}
							>
								<option value="true">True</option>
								<option value="false">False</option>
							</Form.Control>
						</Form.Group>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Timeout")}</Form.Label>
							<Form.Control
								id="services.controller.requestTimeout"
								size="sm"
								type="number"
								value={fields.services.controller.requestTimeout}
								onChange={handleFieldChange}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Timeoutrenewal")}</Form.Label>
							<Form.Control
								id="services.controller.requestTimeoutRenewal"
								size="sm"
								type="number"
								value={fields.services.controller.requestTimeoutRenewal}
								onChange={handleFieldChange}
							/>
						</Form.Group>
					</Card.Body>
				</Card>
				<Card bg="light" style={{minWidth: '22rem'}}>
					<Card.Body>
						<Card.Title>{t("soajs:fields.Ports")}</Card.Title>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Controller")}</Form.Label>
							<Form.Control
								id="services.config.ports.controller"
								size="sm"
								type="number"
								value={fields.services.config.ports.controller}
								onChange={handleFieldChange}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>{t("soajs:fields.MaintenanceInc")}</Form.Label>
							<Form.Control
								id="services.config.ports.maintenanceInc"
								size="sm"
								type="number"
								value={fields.services.config.ports.maintenanceInc}
								onChange={handleFieldChange}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>{t("soajs:fields.RandomInc")}</Form.Label>
							<Form.Control
								id="services.config.ports.randomInc"
								size="sm"
								type="number"
								value={fields.services.config.ports.randomInc}
								onChange={handleFieldChange}
							/>
						</Form.Group>
					</Card.Body>
				</Card>
			</CardDeck>
			<CardDeck className="mb-4 small mt-4">
				<Card bg="light" style={{minWidth: '22rem'}}>
					<Card.Body>
						<Card.Title>{t("soajs:fields.Logger")}</Card.Title>
						<JsonEditor
							mode={"code"}
							json={fields.services.config.logger || {}}
							onChange={JsonEditor_onChange}
						/>
					</Card.Body>
				</Card>
				<Card bg="light" style={{minWidth: '22rem'}}>
					<Card.Body>
						<Card.Title>{t("soajs:fields.Oauth")}</Card.Title>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Getuserinformationfromtoken")}</Form.Label>
							<Form.Control
								id="services.config.oauth.getUserFromToken"
								as="select" size="sm"
								defaultValue={fields.services.config.oauth.getUserFromToken}
								onChange={handleFieldChangeBoolean}
							>
								<option value="true">True</option>
								<option value="false">False</option>
							</Form.Control>
						</Form.Group>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Accesstokenlifetime")}</Form.Label>
							<Form.Control
								id="services.config.oauth.accessTokenLifetime"
								size="sm"
								type="number"
								value={fields.services.config.oauth.accessTokenLifetime}
								onChange={handleFieldChange}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Refreshtokenlifetime")}</Form.Label>
							<Form.Control
								id="services.config.oauth.refreshTokenLifetime"
								size="sm"
								type="number"
								value={fields.services.config.oauth.refreshTokenLifetime}
								onChange={handleFieldChange}
							/>
						</Form.Group>
					</Card.Body>
				</Card>
			</CardDeck>
		</Form>
	);
}