import {Alert} from "react-bootstrap";
import React from "react";
import Routes from "./Routes";

export default function Cluster({environment}) {
	
	return (
		environment &&
		<>
			{environment.deployer && environment.deployer.type === "container" ? (
				<Routes
					environment={environment}
				/>
			) : (
				<Alert variant="info" className="mt-4 mb-4">
					This environment is of type <span className="text-success">manual</span>, Cluster is only available
					for environments of type <span className="text-info">container</span>
				</Alert>
			)}
		</>
	);
}