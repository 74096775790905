import {Card} from "react-bootstrap";
import React from "react";

export default function Configuration() {
	
	return (
		<>
			<Card>
				<Card.Header>
					This Section will provide you some of the information you need for Continuous Delivery.
				</Card.Header>
				<Card.Body>
					Here is some of the Environment Variables you would need :
					<br/>
					<br/>
					<b>SOAJS_URL:</b> The url to the your console api.<br/>
					<b>SOAJS_key:</b> The external key to your console tenant application.<br/>
					<b>SOAJS_cd_auth:</b> A valid continuous delivery token.<br/>
					<hr/>
					<a href="https://soajsorg.atlassian.net/wiki/x/ZYBBa"> Click here </a>to Learn more about Continuous
					Delivery
				</Card.Body>
			</Card>
		
		</>
	);
}