import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import {useHistory} from "react-router-dom";

import {useFormFields} from "../../libs/hooksLib";
import {LoaderButton} from "../../helpers";

import {NotificationManager} from '../components';
import {UracService} from "../services";
import {useTranslation} from "react-i18next";

const uracService = UracService.getService();

export default function Signup() {
	const {t} = useTranslation(["common", "urac"]);
	const [fields, handleFieldChange] = useFormFields({
		username: "",
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		confirmPassword: ""
	});
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	
	function validateForm() {
		return (
			fields.username.length > 4 &&
			fields.firstName.length > 0 &&
			fields.lastName.length > 0 &&
			fields.email.length > 0 &&
			fields.password.length > 0 &&
			fields.password === fields.confirmPassword
		);
	}
	
	async function handleSubmit(event) {
		event.preventDefault();
		
		setIsLoading(true);
		
		try {
			await uracService.signup(fields);
			NotificationManager.success(t("urac:msg.signupSuccess"));
			setIsLoading(false);
			history.push("/home");
		} catch (e) {
			NotificationManager.error(e.message);
			setIsLoading(false);
		}
	}
	
	return (
		<div className="URAC">
			<Form onSubmit={handleSubmit}>
				<Form.Group controlId="username">
					<Form.Label>{t("urac:Username")}</Form.Label>
					<Form.Control
						autoFocus
						value={fields.username}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Form.Group controlId="firstName">
					<Form.Label>{t("urac:First Name")}</Form.Label>
					<Form.Control
						value={fields.firstName}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Form.Group controlId="lastName">
					<Form.Label>{t("urac:Last Name")}</Form.Label>
					<Form.Control
						value={fields.lastName}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Form.Group controlId="email">
					<Form.Label>{t("urac:Email")}</Form.Label>
					<Form.Control
						type="email"
						value={fields.email}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Form.Group controlId="password">
					<Form.Label>{t("urac:Password")}</Form.Label>
					<Form.Control
						type="password"
						value={fields.password}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Form.Group controlId="confirmPassword">
					<Form.Label>{t("urac:Confirm Password")}</Form.Label>
					<Form.Control
						type="password"
						onChange={handleFieldChange}
						value={fields.confirmPassword}
					/>
				</Form.Group>
				<Form.Text className="pb-3" muted>
					{t("urac:msg.signup")}
				</Form.Text>
				<LoaderButton
					block
					type="submit"
					variant="success"
					isLoading={isLoading}
					disabled={!validateForm()}
				>
					{t("urac:button.Signup")}
				</LoaderButton>
			</Form>
		</div>
	);
}