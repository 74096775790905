import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {
	Accordion,
	Button,
	Card,
	Form,
	Jumbotron,
	useAccordionToggle,
	Tab, Tabs, Row, Col
} from "react-bootstrap";
import {BiSearchAlt} from "react-icons/bi";
import {FcCollapse, FcExpand} from "react-icons/fc";

import {NotificationManager} from "../../../../../soajs/urac/components";
import {MarketplaceService} from "../../../../../services";
import AutoPagination from "../../../../../lib/AutoPagination";

const marketplaceService = MarketplaceService.getService();

function CustomToggle({children, eventKey, handleClick}) {
	const decoratedOnClick = useAccordionToggle(eventKey, () => {
		handleClick();
	});
	return (
		<div className="float-left" type="button" onClick={decoratedOnClick}>
			{children}
		</div>
	);
}

async function onLoad(setFields, setPagination, currentPage, criteria, isSubscribed, type, subType) {
	try {
		let c = {"limit": 15, "start": (currentPage - 1) * 15, "type": type, "subType": subType};
		if (criteria) {
			if (criteria.keywords && criteria.keywords !== "") {
				c.keywords = criteria.keywords;
			}
		}
		const response = await marketplaceService.getItems(c);
		if (isSubscribed) {
			if (response.records) {
				setFields(response.records);
				setPagination(
					{
						"totalItems": response.count,
						"maxSize": 10,
						"itemsPerPage": response.limit
					}
				);
			}
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function Template({type, subType, Children, Header}) {
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState([]);
	const [activeKey, setActiveKey] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [criteria, setCriteria] = useState({
		"keywords": ""
	});
	const [pagination, setPagination] = useState({
		"totalItems": 1, "maxSize": 1, "itemsPerPage": 1
	});
	
	function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}
	
	async function handleSearch(event) {
		event.preventDefault();
		setCurrentPage(1);
		await reLoad(1);
	}
	
	async function reLoad(page) {
		await onLoad(setFields, setPagination, page || currentPage, criteria, true, type, subType);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		if (isSubscribed) {
			onLoad(setFields, setPagination, 1, null, isSubscribed, type, subType).catch();
		}
		return () => (isSubscribed = false);
	}, [type, subType]);
	
	return (
		<>
			<Jumbotron className="p-3 m-0">
				<Row>
					<Col lg={3}>
						<h5 className="font-weight-bold m-0">{t("soajs:titles.env_cat_" + type)}</h5>
						{subType && <span className="text-muted small">{subType}</span>}
					</Col>
					<Col>
						<Form onSubmit={handleSearch}>
							<Row>
								<Col xs={9} md={10}>
									<Form.Control
										id="keywords"
										size="sm"
										autoFocus
										value={criteria.keywords}
										onChange={handleCriteriaChange}
									/>
								</Col>
								<Col xs={3} md={2}>
									<Button className="float-right" variant="dark" size="sm" onClick={handleSearch}>
										<BiSearchAlt/> {t("soajs:buttons.Search")}
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			</Jumbotron>
			<hr/>
			<Accordion activeKey={activeKey}>
				{fields.map((item, index) => (
					<Card key={index + 1}>
						<Card.Header>
							<CustomToggle
								as={Card.Header}
								eventKey={index + 1}
								handleClick={() => {
									if (activeKey === (index + 1)) {
										setActiveKey(null);
									} else {
										setActiveKey(index + 1);
									}
								}}
							>
								{activeKey === (index + 1) ?
									<FcExpand className="mr-1"/> :
									<FcCollapse className="mr-1"/>}
								<span className="font-weight-bold mr-2">{item.name}</span>
								{(item.configuration && item.configuration.port) ?
									<span className="mr-2 text-primary">[{item.configuration.port}]</span>
									: ""}
								<small>{item.configuration.group}</small>
							</CustomToggle>
							<span className="float-right">
								{Header &&
								<Header
									item={item}
								/>
								}
							</span>
						</Card.Header>
						<Accordion.Collapse eventKey={index + 1}>
							<Card.Body>
								<Jumbotron className="p-3 bg-light small">
									{item.description ? item.description : "-"}
								</Jumbotron>
								{item.versions &&
								<>
									<Tabs className="mb-3" defaultActiveKey="1" id="repos"
									      style={{borderBottom: "1px solid #dee2e6"}}>
										{item.versions.map((_O, _I) => (
											<Tab key={_I} eventKey={_I + 1}
											     title={t("soajs:catalogs.version") + " " + _O.version}>
												<Jumbotron className="p-3 bg-light">
													{_O.lastSync ?
														<>
															<small>{t("soajs:catalogs.lastSynchronized")}</small><small
															className="m-3">from <span
															className="font-weight-bold">{_O.lastSync.branch}</span> @ <span
															className="font-italic">{Date(_O.lastSync.ts)}</span></small>
															<hr/>
															<small>{t("soajs:catalogs.availableBranches")}</small><small
															className="m-3">[{_O.branches ? _O.branches.join(", ") : ""}]</small>
														</>
														: "-"}
												</Jumbotron>
												{Children &&
												<Children
													reloadItem={reLoad}
													item={item}
													version={_O}
													active={(activeKey === (index + 1))}
													deactivate={() => {
														setActiveKey(0);
													}}
												/>
												}
											</Tab>))}
									</Tabs>
								</>}
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				))}
				{fields.length === 0 ? (
					<span>{t("soajs:catalogs.zeroMsg")}</span>
				) : null}
			</Accordion>
			
			{fields.length > 0 ? (
				<>
					<div className="float-right mt-3">
						<AutoPagination
							currentPage={currentPage}
							totalItems={pagination.totalItems}
							itemsPerPage={pagination.itemsPerPage}
							maxSize={pagination.maxSize}
							onClick={(p) => {
								setCurrentPage(p);
								reLoad(p);
							}}
						/>
					</div>
					<div className="clearfix"></div>
				</>
			) : null}
		</>
	);
}