import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";

import {AuthenticatedRoute} from "../../soajs/helpers";

import Api from "./containers/Api/Api";
import Custom from "./containers/Custom/Custom";
import Resource from "./containers/Resource/Resource";
import Daemon from "./containers/Daemon/Daemon";
import Frontend from "./containers/Frontend/Frontend";
import Config from "./containers/Config/Config";
import Recipe from "./containers/Recipe/Recipe";
import SoajsApi from "./containers/SoajsApi/SoajsApi";
import SoajsFrontend from "./containers/SoajsFrontend/SoajsFrontend";
import Detail from "./containers/Template/Detail";

import Repositories from "../Development/containers/Repositories/Repositories";

import AddRecipe from "./containers/Recipe/AddRecipe";
import EditRecipe from "./containers/Recipe/EditRecipe";

const rootPath = "/catalogs";
const defaultPath = "/api";

export default function Routes() {
	return (
		<Switch>
			<AuthenticatedRoute path={rootPath + "/git/repositories"}>
				<Repositories/>
			</AuthenticatedRoute>
			
			<AuthenticatedRoute path={rootPath + "/api/detail/:type/:name/:ver"}>
				<Detail/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/custom/detail/:type/:name/:ver"}>
				<Detail/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/resource/detail/:type/:name/:ver"}>
				<Detail/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/daemon/detail/:type/:name/:ver"}>
				<Detail/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/frontend/detail/:type/:name/:ver"}>
				<Detail/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/config/detail/:type/:name/:ver"}>
				<Detail/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/soajs/api/detail/:type/:name/:ver"}>
				<Detail/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/soajs/frontend/detail/:type/:name/:ver"}>
				<Detail/>
			</AuthenticatedRoute>
			
			<AuthenticatedRoute path={rootPath + "/api/detail/:type/:name"}>
				<Detail/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/custom/detail/:type/:name"}>
				<Detail/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/resource/detail/:type/:name"}>
				<Detail/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/daemon/detail/:type/:name"}>
				<Detail/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/frontend/detail/:type/:name"}>
				<Detail/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/config/detail/:type/:name"}>
				<Detail/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/soajs/api/detail/:type/:name"}>
				<Detail/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/soajs/frontend/detail/:type/:name"}>
				<Detail/>
			</AuthenticatedRoute>
			
			
			<AuthenticatedRoute path={rootPath + "/recipe/add"}>
				<AddRecipe/>
			</AuthenticatedRoute>
			
			<AuthenticatedRoute path={rootPath + "/recipe/edit/:id"}>
				<EditRecipe/>
			</AuthenticatedRoute>
			
			<AuthenticatedRoute path={rootPath + "/api"}>
				<Api/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/custom"}>
				<Custom/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/resource"}>
				<Resource/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/daemon"}>
				<Daemon/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/frontend"}>
				<Frontend/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/config"}>
				<Config/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/recipe"}>
				<Recipe/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/soajs/api"}>
				<SoajsApi/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/soajs/frontend"}>
				<SoajsFrontend/>
			</AuthenticatedRoute>
			
			
			<Route
				render={() => {
					return (<Redirect to={rootPath + defaultPath}/>)
				}}/>
		</Switch>
	)
}