import {Button, Form, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {JsonEditor, NotificationManager} from "../../../../../soajs/urac/components";
import {MultitenantService} from "../../../../../services";
import deepSet from "../../../../../soajs/libs/deepSetLib";

const multitenantService = MultitenantService.getService();

const initFields = {
	"id": "",
	"appId": "",
	"key": "",
	"envCode": "",
	"config": {},
	"configAllEnv": {}
};

export default function ConfigureKeyModal({modalOpt, setModalOpt, handleAction, environments}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, item: null});
	
	const [fields, setFields] = useState(initFields);
	
	function validateForm() {
		return (
			fields.envCode.length > 0
		);
	}
	
	function JsonEditor_onChange(json) {
		fields.config = json;
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		let obj = fields;
		deepSet(obj, event.target.id, value);
		if (event.target.id === "envCode") {
			if (fields.configAllEnv[value.toLowerCase()]) {
				deepSet(obj, "config", fields.configAllEnv[value.toLowerCase()]);
			} else {
				deepSet(obj, "config", {});
			}
		}
		setFields({...obj});
	}
	
	async function handleSubmit() {
		try {
			await multitenantService.updateTenantApplicationKey({
				"id": fields.id,
				"appId": fields.appId,
				"key": fields.key,
				"envCode": fields.envCode,
				"config": fields.config
			});
			NotificationManager.success(t("soajs:messages.tenantApplicationKey"));
			handleAction();
			handleClose();
		} catch
			(e) {
			NotificationManager.error(e.message);
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		if (isSubscribed && modalOpt && modalOpt.item) {
			setFields(Object.assign({}, initFields, modalOpt.item));
		}
		return () => (isSubscribed = false);
	}, [modalOpt]);
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.tenantApplicationKey")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Env")}</Form.Label>
						<Form.Control
							as="select"
							id="envCode"
							size="sm"
							value={fields.envCode}
							onChange={handleFieldChange}
						>
							<option value="">---</option>
							{environments.map((rec, index) => (
								<option key={index} value={rec.code}>{rec.code}</option>
							))}
						</Form.Control>
					</Form.Group>
					{fields.envCode && fields.envCode !== "" &&
					<Form.Group style={{"height": "400px"}}>
						<Form.Label>{t("soajs:fields.Configuration")}</Form.Label>
						<JsonEditor
							mode={"code"}
							json={fields.config || {}}
							onChange={JsonEditor_onChange}
						/>
					</Form.Group>}
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button
					variant="success"
					onClick={handleSubmit}
					disabled={!validateForm()}
				>
					{t("soajs:buttons.Update")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}