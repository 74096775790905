import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {useLocation} from "react-router-dom";

import {NotificationManager} from '../components';
import {UracService} from "../services";
import {useTranslation} from "react-i18next";

const uracService = UracService.getService();

export default function ValidateChangeEmail() {
	const {t} = useTranslation(["common", "urac"]);
	const [validating, setValidating] = useState(false);
	const [validatingMsg, setValidatingMsg] = useState(t("urac:msg.validateChangeEmailProcess"));
	
	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}
	
	let query = useQuery();
	const token = query.get("token");
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			let valid = false;
			try {
				if (token) {
					valid = await uracService.validateChangeEmail(token);
				} else {
					NotificationManager.warning(t("urac:msg.validateChangeEmailError"));
				}
			} catch (e) {
				NotificationManager.error(e.message);
				if (isSubscribed) {
					setValidatingMsg(t("urac:msg.validateChangeEmailError"));
				}
			}
			setValidating(valid);
		}
		
		onLoad();
		return () => (isSubscribed = false);
	}, [token, t]);
	
	function validatingErrorForm() {
		return (
			<Form>
				<Form.Text className="pb-3" muted>
					{t("urac:msg.validateChangeEmailError2")}
				</Form.Text>
			</Form>
		);
	}
	
	function validatingForm() {
		return (
			<Form>
				<Form.Text className="pb-3" muted>
					{validatingMsg}
				</Form.Text>
			</Form>
		);
	}
	
	function renderConfirmationForm() {
		return (
			<Form>
				<Form.Text className="pb-3" muted>
					{t("urac:msg.validateChangeEmailSuccess")}
				</Form.Text>
			</Form>
		);
	}
	
	return (
		<div className="URAC">
			{!token ? validatingErrorForm() : (!validating ? validatingForm() : renderConfirmationForm())}
		</div>
	);
}