import {Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";

import MaintenanceOperation from "./MaintenanceOperation";
import ServiceOperation from "./ServiceOperation";

import Services from "./container/Services";
import Deployments from "./container/Deployments";
import Cronjobs from "./container/Cronjobs";
import Daemonsets from "./container/Daemonsets";
import Hpas from "./container/Hpas";
import Pods from "./container/Pods";

import {NotificationManager} from "../../../../../soajs/urac/components";
import {InfraService} from "../../../../../services";

const infraService = InfraService.getService();

async function onLoad(env, itemName, setInspect, setDeployed, active, version, setKind, setName, isSubscribed) {
	try {
		if (active) {
			let response = await infraService.inspect(env, {"name": itemName, "version": version.version});
			if (isSubscribed && response) {
				setInspect(response);
				
				if (response.cronjobs && response.cronjobs.items && response.cronjobs.items.length > 0) {
					setKind("CronJob");
					setName(response.cronjobs.items[0].metadata.name);
					setDeployed(true)
				} else if (response.daemonsets && response.daemonsets.items && response.daemonsets.items.length > 0) {
					setKind("DaemonSet");
					setName(response.daemonsets.items[0].metadata.name);
					setDeployed(true)
				} else if (response.deployments && response.deployments.items && response.deployments.items.length > 0) {
					setKind("Deployment");
					setName(response.deployments.items[0].metadata.name);
					setDeployed(true)
				}
				
				// let count = 0;
				// Object.keys(response).map((rec, i) => {
				// 	console.log(rec)
				// 	count = count + response[rec].items.length;
				// 	return true;
				// });
				// if (count > 0) {
				// 	setDeployed(true)
				// }
			}
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function ContainerDetails(
	{
		reloadItem,
		version,
		itemName,
		itemType,
		itemSettings,
		itemSrc,
		env,
		active,
		deactivate,
		deployConfiguration
	}) {
	const [inspect, setInspect] = useState(null);
	const [inspected, setInspected] = useState({
		"env": "", "itemName": "", "version": "", "active": ""
	});
	const [deployed, setDeployed] = useState(false);
	const [kind, setKind] = useState(null);
	const [name, setName] = useState(null);
	
	async function reload() {
		reloadItem();
		//await onLoad(env, itemName, setInspect, setDeployed, active, version, setKind, setName, true);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		if (isSubscribed) {
			setInspected(p => {
				if (p.itemName === itemName) {
					onLoad(env, itemName, setInspect, setDeployed, active, version, setKind, setName, isSubscribed).catch();
				} else {
					if (active) {
						deactivate();
					}
				}
				return {"env": env, "itemName": itemName, "version": version.version, "active": active};
			});
		}
		return () => (isSubscribed = false);
	}, [env, itemName, version, active, deactivate]);
	
	return (
		<>
			<hr/>
			<Row>
				<Col>
					<ServiceOperation
						version={version}
						env={env}
						itemName={itemName}
						itemType={itemType}
						itemSettings={itemSettings}
						itemSrc={itemSrc}
						deployed={deployed}
						name={name}
						kind={kind}
						reload={reload}
						active={active}
						deployConfiguration={deployConfiguration}
						inspect={inspect}
					/>
				</Col>
				<Col xs="auto">
					{inspect && deployed &&
					<MaintenanceOperation
						version={version}
						env={env}
						itemName={itemName}
						itemType={itemType}
					/>}
				</Col>
			</Row>
			<hr/>
			{inspect && inspect.services && inspect.services.items && inspect.services.items.length > 0 &&
			<Services
				items={inspect.services.items}
			/>
			}
			{inspect && inspect.cronjobs && inspect.cronjobs.items && inspect.cronjobs.items.length > 0 &&
			<Cronjobs
				items={inspect.cronjobs.items}
			/>
			}
			{inspect && inspect.daemonsets && inspect.daemonsets.items && inspect.daemonsets.items.length > 0 &&
			<Daemonsets
				items={inspect.daemonsets.items}
			/>
			}
			{inspect && inspect.deployments && inspect.deployments.items && inspect.deployments.items.length > 0 &&
			<Deployments
				items={inspect.deployments.items}
			/>
			}
			{inspect && inspect.hpas && inspect.hpas.items && inspect.hpas.items.length > 0 &&
			<Hpas
				items={inspect.hpas.items}
			/>
			}
			{inspect && inspect.pods && inspect.pods.items && inspect.pods.items.length > 0 &&
			<Pods
				items={inspect.pods.items}
				env={env}
			/>
			}
		</>
	);
}