import React from "react";
import {AiFillCheckCircle} from "react-icons/ai";

export default function ManualHeader({manualAwareness, item}) {
	let add2Header = false;
	if (manualAwareness[item.name] && manualAwareness[item.name].healthy) {
		add2Header = true;
	}
	
	return (
		<>
			{add2Header &&
			<>
				<span className="text-success"><AiFillCheckCircle/> <span className="small">Deployed</span></span>
			</>}
		</>
	);
}