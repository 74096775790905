import React from "react";
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";

import {BiUserCircle} from "react-icons/bi";

import {useTranslation} from "react-i18next";
import getGravatarUrl from "./lib/getGravatarUrl";
import logo from "./favicon.png";
import {LocalStorageService} from "./soajs/urac/services";

// const userIcon = (<BiUserCircle/>);

const getIcon = (url, alt) => {
	if (url) {
		return (<img alt={alt} width="20px" height="20px" className="avatar" src={url}/>);
	}
	return (<BiUserCircle/>);
}

const localStorageService = LocalStorageService.getService();

// function Language() {
// 	const {i18n} = useTranslation("common");
// 	return (
// 		<NavDropdown alignRight title={i18n.language} id="collasible-nav-dropdown" className="text-dark">
// 			<NavDropdown.Item onClick={() => i18n.changeLanguage('EN')}>EN</NavDropdown.Item>
// 			<NavDropdown.Item onClick={() => i18n.changeLanguage('FR')}>FR</NavDropdown.Item>
// 		</NavDropdown>
// 	)
// }

function Navigation({isAuthenticated, firstName, handleLogout}) {
	const {t} = useTranslation(["common", "soajs"]);
	const email = localStorageService.getEmail();
	let gravatar_url = null;
	if (email) {
		gravatar_url = getGravatarUrl(email);
	}
	return (
		<Navbar collapseOnSelect expand="md" className="pt-0 pb-0" bg="dark" variant="dark">
			<LinkContainer to="/home">
				<Navbar.Brand className="font-weight-bold text-muted">
					<img src={logo} alt="soajs" width="35px" height="35px"/> SOAJS
				</Navbar.Brand>
			</LinkContainer>
			<Navbar.Toggle aria-controls="main-navbar-nav"/>
			<Navbar.Collapse id="main-navbar-nav" className="justify-content-end">
				<Nav activeKey={window.location.pathname}>
					{isAuthenticated ? (
						<>
							{/*<LinkContainer to="/development">*/}
							{/*	<Nav.Link>{t("soajs:menu.development")}</Nav.Link>*/}
							{/*</LinkContainer>*/}
							<LinkContainer to="/catalogs">
								<Nav.Link>{t("soajs:menu.catalogs")}</Nav.Link>
							</LinkContainer>
							<LinkContainer to="/environments">
								<Nav.Link>{t("soajs:menu.environments")}</Nav.Link>
							</LinkContainer>
							<LinkContainer to="/multitenancy">
								<Nav.Link>{t("soajs:menu.multitenancy")}</Nav.Link>
							</LinkContainer>
							<LinkContainer to="/reports">
								<Nav.Link>{t("soajs:menu.reports")}</Nav.Link>
							</LinkContainer>
							<LinkContainer to="/settings">
								<Nav.Link>{t("soajs:menu.Manage")}</Nav.Link>
							</LinkContainer>
							<NavDropdown alignRight title={getIcon(gravatar_url)} id="collasible-nav2-dropdown">
								<span
									className="ml-4 font-weight-bold">{getIcon(gravatar_url, firstName)} {firstName}</span>
								<NavDropdown.Divider/>
								<LinkContainer to="/myProfile">
									<NavDropdown.Item>{t("menu.profile")}</NavDropdown.Item>
								</LinkContainer>
								<LinkContainer to="/change-password">
									<NavDropdown.Item>{t("menu.changePassword")}</NavDropdown.Item>
								</LinkContainer>
								<LinkContainer to="/change-email">
									<NavDropdown.Item>{t("menu.changeEmail")}</NavDropdown.Item>
								</LinkContainer>
								<NavDropdown.Divider/>
								<NavDropdown.Item onClick={handleLogout}>{t("menu.logout")}</NavDropdown.Item>
							</NavDropdown>
							{/*<Language/>*/}
						</>
					) : (
						<>
							<LinkContainer to="/sign-in">
								<Nav.Link>{t("menu.login")}</Nav.Link>
							</LinkContainer>
							{/*<Language/>*/}
						</>
					)}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}

export default Navigation