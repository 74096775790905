import React, {useEffect, useState} from "react";

import Template from "../Template/Template";
import {InfraService} from "../../../../../services";
import {NotificationManager} from "../../../../../soajs/urac/components";

import ManualHeader from "../common/ManualHeader"
import ManualDetails from "../common/ManualDetails"
import ContainerDetails from "../common/ContainerDetails"

const infraService = InfraService.getService();

function getDeployConfiguration(deployment, v, env) {
	if (deployment && deployment[env]) {
		for (let i = 0; i < deployment[env].length; i++) {
			if (deployment[env][i].version === v) {
				return deployment[env][i];
			}
		}
	}
	return null;
}

export default function Api({environment}) {
	
	const [manualAwareness, setManualAwareness] = useState({});
	
	function Header({item}) {
		
		return (
			environment.deployer && environment.deployer.type === "manual" &&
			<ManualHeader
				manualAwareness={manualAwareness}
				item={item}
			/>
		);
	}
	
	function Details({version, item, active, deactivate, reloadItem}) {
		return (
			<>
				{environment.deployer && environment.deployer.type === "manual" &&
				<ManualDetails
					manualAwareness={manualAwareness[item.name]}
					version={version}
					itemName={item.name}
					itemType={item.type}
					env={environment.code.toLowerCase()}
				/>}
				{environment.deployer && environment.deployer.type === "container" &&
				<ContainerDetails
					reloadItem={reloadItem}
					version={version}
					itemName={item.name}
					itemType={item.type}
					itemSettings={item.settings || null}
					itemSrc={item.src || null}
					env={environment.code.toLowerCase()}
					active={active}
					deactivate={deactivate}
					deployConfiguration={getDeployConfiguration(item.deploy, version.version, environment.code.toLowerCase())}
				/>}
			</>
		);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				if (environment && environment.deployer && environment.deployer.type === "manual") {
					let response = await infraService.getManualAwareness(environment.code.toLowerCase());
					if (isSubscribed) {
						setManualAwareness(response);
					}
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad().catch();
		return () => (isSubscribed = false);
	}, [environment]);
	
	return (
		environment &&
		<Template
			type={"service"}
			Children={Details}
			Header={Header}
		/>
	);
}