import {Alert, Row, Col, Button, useAccordionToggle, Accordion} from "react-bootstrap";
import React, {useEffect, useState} from "react";

import {NotificationManager} from "../../../../soajs/urac/components";
import {ConsoleService} from "../../../../services";
import AutoPagination from "../../../../lib/AutoPagination";

import JsonModal from "../../../../soajs/urac/components/JsonModal";
import {FcCollapse, FcExpand} from "react-icons/fc";

function getDate(timestamp) {
	let dateStr = "";
	if (timestamp) {
		let date = new Date(timestamp);
		dateStr = dateStr +
			date.getDate() +
			"/" + (date.getMonth() + 1) +
			"/" + date.getFullYear() +
			" - " + date.getHours() +
			":" + date.getMinutes() +
			":" + date.getSeconds()
	}
	return dateStr;
}

function CustomToggle({children, eventKey, handleClick}) {
	const decoratedOnClick = useAccordionToggle(eventKey, () => {
		handleClick();
	});
	return (
		<div className="float-right" type="button" onClick={decoratedOnClick}>
			{children}
		</div>
	);
}

const consoleService = ConsoleService.getService();

async function onLoad(setFields, setPagination, currentPage, isSubscribed) {
	try {
		let c = {"limit": 12, "skip": (currentPage - 1) * 12};
		
		const response = await consoleService.getLedger(c);
		if (isSubscribed && response && response.items) {
			setFields(response.items);
			setPagination(
				{
					"totalItems": response.count,
					"maxSize": 10,
					"itemsPerPage": response.limit
				}
			);
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}


export default function Notifications() {
	const [fields, setFields] = useState([]);
	const [activeKey, setActiveKey] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pagination, setPagination] = useState({
		"totalItems": 1, "maxSize": 1, "itemsPerPage": 1
	});
	const [modalOpt, setModalOpt] = useState({"show": false, "json": {}, "mode": "view", "title": ""});
	const modalOptShow = (json, title) => setModalOpt({
		"show": true,
		"json": json,
		"mode": "view",
		"title": title || ""
	});
	
	function showJson(input, title) {
		let json = input;
		if (typeof json === "string") {
			try {
				json = JSON.parse(json);
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		modalOptShow(json, title);
	}
	
	async function reLoad(page) {
		await onLoad(setFields, setPagination, page || currentPage, true);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		onLoad(setFields, setPagination, 1, isSubscribed);
		return () => (isSubscribed = false);
	}, []);
	
	return (
		fields.length > 0 &&
		<>
			<JsonModal
				modalOpt={modalOpt}
				setModalOpt={setModalOpt}
			/>
			<Accordion activeKey={activeKey}>
				{fields.map((d, i) => (
					<Alert key={i} variant="light" className="mt-2 mb-2" style={{"borderColor": "#c6c8ca"}}>
						<Row>
							<Col>
							<span className="float-left">
								<span className="font-weight-bold mr-5">{d.type} > {d.section}</span>
								[<span className="small text-info mr-2">{d.env}:</span>
								<span className="small">{getDate(d.time)}</span>]
							</span>
								<CustomToggle
									eventKey={i + 1}
									handleClick={() => {
										if (activeKey === (i + 1)) {
											setActiveKey(null);
										} else {
											setActiveKey(i + 1);
										}
									}}
								>
									{activeKey === (i + 1) ? <FcExpand/> :
										<FcCollapse/>}
								</CustomToggle>
								<span className="float-right mr-2">{d.action}: {d.status}</span>
								<span className="clearfix"></span>
							</Col>
						</Row>
						<Accordion.Collapse eventKey={i + 1}>
							<>
								<hr className="mt-2 mb-1"/>
								<Row className="mt-2 small">
									<Col>
							<span className="float-left">
							{d.locator.join("- ")}
							</span>
										
										<Button className="float-right" variant="link" size="sm"
										        style={{"borderColor": "#c6c8ca"}}
										        onClick={() => {
											        showJson(d.input);
										        }}>request</Button>
										<Button
											className="float-right mr-2" variant="link" size="sm"
											style={{"borderColor": "#c6c8ca"}}
											onClick={() => {
												showJson(d.output);
											}}>response</Button>
										{d.who &&
										<Button className="float-right mr-4" variant="link" size="sm"
										        style={{"borderColor": "#c6c8ca"}}
										        onClick={() => {
											        showJson(d.who);
										        }}>{d.who.username}</Button>
										}
									</Col>
								</Row>
							</>
						</Accordion.Collapse>
					</Alert>
				))}
			</Accordion>
			<div className="float-right mt-3">
				<AutoPagination
					currentPage={currentPage}
					totalItems={pagination.totalItems}
					itemsPerPage={pagination.itemsPerPage}
					maxSize={pagination.maxSize}
					onClick={(p) => {
						setCurrentPage(p);
						reLoad(p);
					}}
				/>
			</div>
			<div className="clearfix"></div>
		</>
	)
}
;