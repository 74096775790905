import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Accordion, Alert, Button, Card, Col, Form, Row} from "react-bootstrap";
import {useHistory} from "react-router-dom";

import _ from "lodash";

import deepSet from "../../../../soajs/libs/deepSetLib";

import envModel from "./model/envModel.json";
import itemModel from "./model/itemModel.json";
import _examples from "./model/examples.json";
import {JsonEditor, NotificationManager} from "../../../../soajs/urac/components";

import DeleteButton from "../../../buttons/Delete";
import AddButton from "../../../buttons/Add";
import {MarketplaceService} from "../../../../services";

import computeVaribales from "./model/computedVariables.json";

let _json_readiness = null;
let _json_liveness = null;
let _json_securityContext = null;
let _json_voluming = [];
let _json_ports = [];
let _json_labels = null;
let _json_execCommands = null;

const marketplaceService = MarketplaceService.getService();

export default function RecipeRW({starterFields, mode, itemId}) {
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState(itemModel);
	
	const history = useHistory();
	
	function validateForm() {
		return (
			fields.name.length > 0 &&
			fields.description.length > 0 &&
			fields.type.length > 0 &&
			fields.subtype.length > 0 &&
			fields.recipe.deployOptions.image.name.length > 0
		);
	}
	
	async function handleSubmit() {
		try {
			fields.recipe.deployOptions.readinessProbe = _json_readiness;
			fields.recipe.deployOptions.liveinessProbe = _json_liveness;
			fields.recipe.deployOptions.securityContext = _json_securityContext;
			fields.recipe.deployOptions.voluming = _json_voluming;
			fields.recipe.deployOptions.ports = _json_ports;
			fields.recipe.deployOptions.labels = _json_labels;
			fields.recipe.deployOptions.execCommands = _json_execCommands;
			
			if (fields.envs.length > 0) {
				for (let i = 0; i < fields.envs.length; i++) {
					fields.recipe.buildOptions.env[fields.envs[i].name] = {
						"type": fields.envs[i].type
					};
					if (fields.envs[i].type === "static" || fields.envs[i].type === "computed") {
						fields.recipe.buildOptions.env[fields.envs[i].name].value = fields.envs[i].value;
					}
					if (fields.envs[i].type === "userInput") {
						fields.recipe.buildOptions.env[fields.envs[i].name].label = fields.envs[i].label;
						fields.recipe.buildOptions.env[fields.envs[i].name].default = fields.envs[i].default;
						fields.recipe.buildOptions.env[fields.envs[i].name].fieldMsg = fields.envs[i].fieldMsg;
					}
					if (fields.envs[i].type === "secret") {
						fields.recipe.buildOptions.env[fields.envs[i].name].key = fields.envs[i].key;
						fields.recipe.buildOptions.env[fields.envs[i].name].secret = fields.envs[i].secret;
					}
				}
			}
			
			let data = {
				"name": fields.name,
				"type": fields.type,
				"description": fields.description,
				"subtype": fields.subtype,
				"recipe": fields.recipe
			};
			if (mode === "add") {
				await marketplaceService.addRecipe(data);
				NotificationManager.success(t("soajs:messages.addRecipe"));
			}
			if (mode === "edit") {
				await marketplaceService.updateRecipe(itemId, data)
				NotificationManager.success(t("soajs:messages.editRecipe"));
			}
			history.push("/catalogs/recipe");
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	function handleFieldEnvChange(event, i) {
		let value = event.target.value;
		let id = event.target.id;
		
		fields.envs[i][id] = value;
		setFields({...fields});
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		let id = event.target.id;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		if (event.target.type === 'number') {
			value = parseInt(value);
		}
		
		deepSet(fields, id, value);
		setFields({...fields});
	}
	function handleFieldCommandChange(event) {
		let value = event.target.value;
		let id = event.target.id;
		
		if (id === "recipe.buildOptions.cmd.deploy.command") {
			// value = [value];
			value = value.split(",").map(item => item.trim());
			deepSet(fields, id, value);
			setFields({...fields});
		}
	}
	function handleFieldArgsChange(event) {
		let value = event.target.value;
		let id = event.target.id;
		
		if (id === "recipe.buildOptions.cmd.deploy.args") {
			value = value.split("\n");
			deepSet(fields, id, value);
			setFields({...fields});
		}
	}
	
	function readiness_onChange(json) {
		_json_readiness = json;
	}
	
	function liveness_onChange(json) {
		_json_liveness = json;
	}
	
	function securityContext_onChange(json) {
		_json_securityContext = json;
	}
	
	function port_onChange(json, i) {
		_json_ports[i] = json;
	}
	
	function voluming_onChange(json, i) {
		_json_voluming[i] = {"kubernetes": json};
	}
	
	function execCommands_onChange(json) {
		_json_execCommands = json;
	}
	
	function labels_onChange(json) {
		_json_labels = json
	}
	
	function deleteVolumeEntry(i) {
		_json_voluming.splice(i, 1);
		fields.recipe.deployOptions.voluming = _json_voluming;
		setFields({...fields});
	}
	
	function addVolumeEntry() {
		_json_voluming.push(
			{"kubernetes": {"volume": {}, "volumeMount": {}}}
		);
		fields.recipe.deployOptions.voluming = _json_voluming;
		setFields({...fields});
	}
	
	function getVolume(i) {
		if (_json_voluming[i] && _json_voluming[i].kubernetes) {
			return _json_voluming[i].kubernetes;
		}
		if (fields.recipe.deployOptions.voluming[i] && fields.recipe.deployOptions.voluming[i].kubernetes) {
			return fields.recipe.deployOptions.voluming[i].kubernetes;
		}
		return null;
	}
	
	function deletePortEntry(i) {
		_json_ports.splice(i, 1);
		fields.recipe.deployOptions.ports = _json_ports;
		setFields({...fields});
	}
	
	function addPortEntry() {
		_json_ports.push({
			"name": "http",
			"target": 80,
			"isPublished": true,
			"published": 31080,
			"preserveClientIP": true
		});
		fields.recipe.deployOptions.ports = _json_ports;
		setFields({...fields});
	}
	
	function addEnvEntry() {
		fields.envs.push(_.merge({}, envModel));
		setFields({...fields});
	}
	
	function deleteEnvEntry(i) {
		fields.envs.splice(i, 1);
		setFields({...fields});
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			if (isSubscribed) {
				_json_liveness = starterFields.recipe.deployOptions.liveinessProbe;
				_json_readiness = starterFields.recipe.deployOptions.readinessProbe;
				_json_ports = starterFields.recipe.deployOptions.ports;
				_json_voluming = starterFields.recipe.deployOptions.voluming;
				_json_labels = starterFields.recipe.deployOptions.labels;
				_json_execCommands = starterFields.recipe.deployOptions.execCommands;
				_json_securityContext = starterFields.recipe.deployOptions.securityContext;
				let p = _.merge({}, starterFields);
				setFields({...p});
			}
		}
		
		onLoad().catch();
		return () => (isSubscribed = false);
	}, [starterFields]);
	
	return (
		<>
			<h5 className="font-weight-bold">{t("soajs:titles." + mode + "Recipe")}</h5>
			
			<Form>
				<Form.Group>
					<Form.Label>{t("soajs:fields.Name")}</Form.Label>
					<Form.Control
						id="name"
						size="sm"
						value={fields.name}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>{t("soajs:fields.Description")}</Form.Label>
					<Form.Control
						id="description"
						size="sm"
						as="textarea"
						rows={2}
						value={fields.description}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Row>
					<Col>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Type")}</Form.Label>
							<Form.Control
								id="type"
								size="sm"
								value={fields.type}
								onChange={handleFieldChange}
							/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Category")}</Form.Label>
							<Form.Control
								id="subtype"
								size="sm"
								value={fields.subtype}
								onChange={handleFieldChange}
							/>
						</Form.Group>
					</Col>
				</Row>
				<hr className="mt-1"/>
				<Row>
					<Col>
						<Accordion defaultActiveKey="0">
							<Card className="mb-3">
								<Card.Header className="pt-1 pb-0">
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
										<h6>Image Information</h6>
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey="1">
									<Card.Body className="pb-0">
										<Row>
											<Col>
												<Form.Group>
													<Form.Label>{t("soajs:fields.Prefix")}</Form.Label>
													<Form.Control
														id="recipe.deployOptions.image.prefix"
														size="sm"
														value={fields.recipe.deployOptions.image.prefix}
														onChange={handleFieldChange}
													/>
												</Form.Group>
											</Col>
											<Col>
												<Form.Group>
													<Form.Label>{t("soajs:fields.Name")}</Form.Label>
													<Form.Control
														id="recipe.deployOptions.image.name"
														size="sm"
														value={fields.recipe.deployOptions.image.name}
														onChange={handleFieldChange}
													/>
												</Form.Group>
											</Col>
											<Col>
												<Form.Group>
													<Form.Label>{t("soajs:fields.Tag")}</Form.Label>
													<Form.Control
														id="recipe.deployOptions.image.tag"
														size="sm"
														value={fields.recipe.deployOptions.image.tag}
														onChange={handleFieldChange}
													/>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col>
												<Form.Group>
													<Form.Label>{t("soajs:fields.Type")}</Form.Label>
													<Form.Control
														as="select"
														id="recipe.deployOptions.image.repositoryType"
														value={fields.recipe.deployOptions.image.repositoryType}
														onChange={handleFieldChange}
													>
														<option value={"public"}>Public</option>
														<option value={"private"}>Private</option>
													</Form.Control>
												</Form.Group>
											</Col>
											<Col>
												<Form.Group>
													<Form.Label>{t("soajs:fields.PullPolicy")}</Form.Label>
													<Form.Control
														as="select"
														id="recipe.deployOptions.image.pullPolicy"
														value={fields.recipe.deployOptions.image.pullPolicy}
														onChange={handleFieldChange}
													>
														<option value={"IfNotPresent"}>IfNotPresent</option>
														<option value={"Always"}>Always</option>
														<option value={"Never"}>Never</option>
													</Form.Control>
												</Form.Group>
											</Col>
											<Col>
												<Form.Group>
													<Form.Label>{t("soajs:fields.Shell")}</Form.Label>
													<Form.Control
														id="recipe.deployOptions.image.shell"
														size="sm"
														value={fields.recipe.deployOptions.image.shell}
														onChange={handleFieldChange}
													/>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col>
												<Form.Group>
													<Form.Label
														className="float-left">{t("soajs:fields.Override")}</Form.Label>
													<Form.Check
														className="float-right"
														id="recipe.deployOptions.image.override"
														size="sm"
														type="switch"
														checked={fields.recipe.deployOptions.image.override}
														onChange={handleFieldChange}
													/>
												</Form.Group>
											</Col>
											<Col>
												<Form.Group>
													<Form.Label
														className="float-left">{t("soajs:fields.Binary")}</Form.Label>
													<Form.Check
														className="float-right"
														id="recipe.deployOptions.image.binary"
														size="sm"
														type="switch"
														checked={fields.recipe.deployOptions.image.binary}
														onChange={handleFieldChange}
													/>
												</Form.Group>
											</Col>
										</Row>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</Col>
				</Row>
				<Row>
					<Col>
						<Accordion defaultActiveKey="0">
							<Card className="mb-3">
								<Card.Header className="pt-1 pb-0">
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
										Commands
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey="1">
									<Card.Body className="pb-0">
										<Form.Group>
											<Form.Label>{t("soajs:fields.ContainerCommand")}</Form.Label>
											<Form.Control
												id="recipe.buildOptions.cmd.deploy.command"
												size="sm"
												value={fields.recipe.buildOptions.cmd.deploy.command.join(', ')}
												onChange={handleFieldCommandChange}
											/>
										</Form.Group>
										<Form.Group>
											<Form.Label>{t("soajs:fields.CommandArguments")}</Form.Label>
											<Form.Control
												id="recipe.buildOptions.cmd.deploy.args"
												size="sm"
												as="textarea"
												rows={5}
												value={fields.recipe.buildOptions.cmd.deploy.args.join('\n')}
												onChange={handleFieldArgsChange}
											/>
											<Form.Text className="text-muted">
												Make sure each command is on a separate line.
											</Form.Text>
										</Form.Group>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</Col>
				</Row>
				<Row>
					<Col>
						<Accordion defaultActiveKey="0">
							<Card className="mb-3">
								<Card.Header className="pt-1 pb-0">
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
										Environment Variables
									</Accordion.Toggle>
									<AddButton
										className="float-right"
										onClick={addEnvEntry}
									/>
								</Card.Header>
								<Accordion.Collapse eventKey="1">
									<Card.Body className="pb-0">
										
										{fields.envs.map((rec, i) => (
											<Alert variant="secondary" key={i}>
												<Row>
													<Col><Row>
														<Col md={2} lg={2} xl={1}>{t("soajs:fields.Name")}</Col>
														<Col>
															<Form.Control
																id="name"
																size="sm"
																value={rec.name}
																onChange={(event) => handleFieldEnvChange(event, i)}
															/>
														</Col>
														<Col md={2} lg={1}>{t("soajs:fields.Type")}</Col>
														<Col md={3} lg={3} xl={2}>
															<Form.Control
																as="select"
																id="type"
																size="sm"
																value={rec.type}
																onChange={(event) => handleFieldEnvChange(event, i)}
															>
																<option value={"userInput"}>userInput</option>
																<option value={"computed"}>computed</option>
																<option value={"static"}>static</option>
																<option value={"secret"}>secret</option>
															</Form.Control>
														</Col>
													</Row></Col>
													<Col xs={1}>
														<DeleteButton
															className="float-right pl-1 pr-1 pt-0 pb-0"
															onClick={() => {
																deleteEnvEntry(i)
															}}
														/>
													</Col>
												</Row>
												<Row>
													<Col>
														<hr/>
														{rec.type === "static" &&
														<>
															<Form.Label>{t("soajs:fields.Value")}</Form.Label>
															<Form.Control
																id={"value"}
																size="sm"
																value={rec.value}
																onChange={(event) => handleFieldEnvChange(event, i)}
															/>
														</>
														}
														
														{rec.type === "computed" &&
														<>
															<Form.Label>{t("soajs:fields.Value")}</Form.Label>
															<Form.Control
																as="select"
																id={"value"}
																size="sm"
																value={rec.value}
																onChange={(event) => handleFieldEnvChange(event, i)}
															>
																{computeVaribales.map((rec, i) => (
																	<option key={i} disabled={rec.disabled}
																	        value={rec.l}>{rec.l}
																	</option>
																))}
															</Form.Control>
														</>
														}
														
														{rec.type === "userInput" &&
														<>
															<Form.Label>{t("soajs:fields.Label")}</Form.Label>
															<Form.Control
																id="label"
																size="sm"
																value={rec.label}
																onChange={(event) => handleFieldEnvChange(event, i)}
															/>
															<Form.Label>{t("soajs:fields.Default")}</Form.Label>
															<Form.Control
																id="default"
																size="sm"
																value={rec.default}
																onChange={(event) => handleFieldEnvChange(event, i)}
															/>
															<Form.Label>{t("soajs:fields.FieldMsg")}</Form.Label>
															<Form.Control
																id="fieldMsg"
																size="sm"
																value={rec.fieldMsg}
																onChange={(event) => handleFieldEnvChange(event, i)}
															/>
														</>
														}
														
														{rec.type === "secret" &&
														<>
															<Form.Label>{t("soajs:fields.Secret")}</Form.Label>
															<Form.Control
																id="secret"
																size="sm"
																value={rec.secret}
																onChange={(event) => handleFieldEnvChange(event, i)}
															/>
															<Form.Label>{t("soajs:fields.Key")}</Form.Label>
															<Form.Control
																id="key"
																size="sm"
																value={rec.key}
																onChange={(event) => handleFieldEnvChange(event, i)}
															/>
														</>
														}
													</Col>
												</Row>
											</Alert>
										))}
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</Col>
				</Row>
				<Row>
					<Col>
						<Accordion defaultActiveKey="0">
							<Card className="mb-3">
								<Card.Header className="pt-1 pb-0">
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
										Readiness Information
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey="1">
									<Card.Body className="pb-0">
										<Row style={{"height": "250px"}}>
											<Col>
												<JsonEditor
													modes={["code", "tree"]}
													mode="code"
													json={_json_readiness || fields.recipe.deployOptions.readinessProbe || null}
													onChange={readiness_onChange}
												/>
											</Col>
											<Col xs={4} className="small">
												<div>
													<Alert variant="dark" className="m-0 pb-0 pt-1"><h6>Example</h6>
													</Alert>
													<Alert variant="info" className="m-0 pb-0 pt-1">
													<pre className="small, text-muted">
														{JSON.stringify(_examples.readinessProbe, null, 2)}
													</pre>
													</Alert>
												</div>
											</Col>
										</Row>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</Col>
				</Row>
				<Row>
					<Col>
						<Accordion defaultActiveKey="0">
							<Card className="mb-3">
								<Card.Header className="pt-1 pb-0">
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
										Liveiness Information
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey="1">
									<Card.Body className="pb-0">
										<Row style={{"height": "250px"}}>
											<Col>
												<JsonEditor
													mode="code"
													modes={["code", "tree"]}
													json={_json_liveness || fields.recipe.deployOptions.liveinessProbe || null}
													onChange={liveness_onChange}
												/>
											</Col>
											<Col xs={4} className="small">
												<div>
													<Alert variant="dark" className="m-0 pb-0 pt-1"><h6>Example</h6>
													</Alert>
													<Alert variant="info" className="m-0 pb-0 pt-1">
													<pre className="small, text-muted">
														{JSON.stringify(_examples.livenessProbe, null, 2)}
													</pre>
													</Alert>
												</div>
											</Col>
										</Row>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</Col>
				</Row>
				<Row>
					<Col>
						<Accordion defaultActiveKey="0">
							<Card className="mb-3">
								<Card.Header className="pt-1 pb-0">
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
										Ports
									</Accordion.Toggle>
									<AddButton
										className="float-right"
										onClick={() => {
											addPortEntry();
										}}
									/>
								</Card.Header>
								<Accordion.Collapse eventKey="1">
									<Card.Body className="pb-0">
										{fields.recipe.deployOptions.ports.map((rec, i) => (
											<div key={i} className="p-1" style={{"backgroundColor": "#ebebeb"}}>
												<Card>
													<Card.Header className="p-0">
														<DeleteButton
															className="float-right pl-1 pr-1 pt-0 pb-0"
															onClick={() => {
																deletePortEntry(i)
															}}
														/>
													</Card.Header>
													<Card.Body className="pt-1 pb-1">
														<JsonEditor
															mode="code"
															modes={["code", "tree"]}
															json={_json_ports[i] || fields.recipe.deployOptions.ports[i] || null}
															onChange={(json) => port_onChange(json, i)}
														/>
													</Card.Body>
												</Card>
											</div>
										))}
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</Col>
				</Row>
				<Row>
					<Col>
						<Accordion defaultActiveKey="0">
							<Card className="mb-3">
								<Card.Header className="pt-1 pb-0">
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
										Volumes
									</Accordion.Toggle>
									<AddButton
										className="float-right"
										onClick={() => {
											addVolumeEntry();
										}}
									/>
								</Card.Header>
								<Accordion.Collapse eventKey="1">
									<Card.Body className="pb-0">
										{fields.recipe.deployOptions.voluming.map((rec, i) => (
											<div key={i} className="p-1" style={{"backgroundColor": "#ebebeb"}}>
												<Card>
													<Card.Header className="p-0">
														<DeleteButton
															className="float-right pl-1 pr-1 pt-0 pb-0"
															onClick={() => {
																deleteVolumeEntry(i)
															}}
														/>
													</Card.Header>
													<Card.Body className="pt-1 pb-1">
														<JsonEditor
															mode="code"
															modes={["code", "tree"]}
															json={getVolume(i)}
															onChange={(json) => voluming_onChange(json, i)}
														/>
													</Card.Body>
												</Card>
											</div>
										))}
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</Col>
				</Row>
				<Row>
					<Col>
						<Accordion defaultActiveKey="0">
							<Card className="mb-3">
								<Card.Header className="pt-1 pb-0">
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
										Security Context Information
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey="1">
									<Card.Body className="pb-0">
										<JsonEditor
											mode="code"
											modes={["code", "tree"]}
											json={_json_securityContext || fields.recipe.deployOptions.securityContext || null}
											onChange={securityContext_onChange}
										/>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</Col>
				</Row>
				<Row>
					<Col>
						<Accordion defaultActiveKey="0">
							<Card className="mb-3">
								<Card.Header className="pt-1 pb-0">
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
										Preset Pod Execute Commands
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey="1">
									<Card.Body className="pb-0">
										<JsonEditor
											mode="code"
											modes={["code", "tree"]}
											json={_json_execCommands || fields.recipe.deployOptions.execCommands || null}
											onChange={execCommands_onChange}
										/>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</Col>
				</Row>
				<Row>
					<Col>
						<Accordion defaultActiveKey="0">
							<Card className="mb-3">
								<Card.Header className="pt-1 pb-0">
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
										Additional Labels
									</Accordion.Toggle>
								</Card.Header>
								<Accordion.Collapse eventKey="1">
									<Card.Body className="pb-0">
										<JsonEditor
											mode="code"
											modes={["code", "tree"]}
											json={_json_labels || fields.recipe.deployOptions.labels || null}
											onChange={labels_onChange}
										/>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</Col>
				</Row>
				<Button
					className="float-right"
					variant="success"
					onClick={handleSubmit}
					disabled={!validateForm()}
				>
					{mode === "add" && t("soajs:buttons.Add")}
					{mode === "edit" && t("soajs:buttons.Update")}
				</Button>
				<span className="clearfix"></span>
			</Form>
		</>
	);
}