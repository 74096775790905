
import Template from "../Template/Template";

export default function Daemon() {
	
	return (
		<Template
			type={"daemon"}
		/>
	);
}