import {Button, Modal} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";
import {JsonEditor} from "../../../../../../../soajs/urac/components";

let _json = null;

export default function AddModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	
	const handleClose = () => {
		_json = null;
		setModalOpt({"show": false, "json": null, "mode": "code", "item": {}});
	};
	
	function handleSubmit() {
		handleAction(_json || modalOpt.json);
		handleClose();
	}
	
	function _json_onChange(json) {
		_json = json;
	}
	
	return (
		modalOpt &&
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="xl">
			<Modal.Header closeButton className="bg-secondary text-white">
				<Modal.Title>{modalOpt.item.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div style={{"height": "600px"}}>
					<JsonEditor
						mode={modalOpt.mode || "code"}
						modes={["tree", "code"]}
						json={_json || modalOpt.json || null}
						onChange={_json_onChange}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer className="bg-light">
				<Button variant="info" onClick={handleClose}>
					{t("soajs:buttons.Close")}
				</Button>
				<Button variant="success" onClick={handleSubmit}>
					{t("soajs:buttons.Add")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}