import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {Button, Card, CardDeck, Col, Form, Jumbotron} from "react-bootstrap";
import {BiSearchAlt} from "react-icons/bi";
import {useAppContext} from "../../../../soajs/libs/contextLib";
import AutoPagination from "../../../../lib/AutoPagination";

import {NotificationManager} from "../../../../soajs/urac/components";
import {MarketplaceService} from "../../../../services";
import _ from "lodash";

import DeleteButton from "../../../buttons/Delete";
import AddButton from "../../../buttons/Add";

const marketplaceService = MarketplaceService.getService();

async function getRecipes(currentPage, criteria, setPagination, setRecipes) {
	try {
		let c = {"limit": 10, "start": (currentPage - 1) * 10};
		if (criteria) {
			if (criteria.keywords && criteria.keywords !== "") {
				c.keywords = criteria.keywords;
			}
		}
		const _recipes = await marketplaceService.getRecipes(c);
		setPagination(
			{
				"totalItems": _recipes.count,
				"maxSize": 10,
				"itemsPerPage": _recipes.limit
			}
		);
		setRecipes(_recipes.records);
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function ConfigureModalRecipes({modalOpt, handleAction, handleClose}) {
	const {t} = useTranslation(["common", "soajs"]);
	const {ability} = useAppContext();
	const [fields, setFields] = useState({
		"recipes": []
	});
	const [recipesAllowed, setRecipesAllowed] = useState([]);
	const [recipes, setRecipes] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [criteria, setCriteria] = useState({
		"keywords": ""
	});
	const [pagination, setPagination] = useState({
		"totalItems": 1, "maxSize": 1, "itemsPerPage": 1
	});
	
	function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}
	
	async function reload(page) {
		await getRecipes(page, criteria, setPagination, setRecipes);
	}
	
	async function handleSearch(event) {
		event.preventDefault();
		await getRecipes(1, criteria, setPagination, setRecipes);
	}
	
	async function handleSubmit(event) {
		event.preventDefault();
		try {
			await marketplaceService.updateSettingsRecipes({
				"id": modalOpt.item._id,
				"recipes": fields.recipes,
				"subType": (modalOpt.item.configuration ? modalOpt.item.configuration.subType || null : null)
			});
			NotificationManager.success(t("soajs:messages.recipesItemSuccess"));
			handleAction()
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	function validateForm() {
		let valid = true;
		
		return (
			valid
		);
	}
	
	function removeRecipeFromAllow(recipe) {
		let obj = fields;
		_.pullAll(obj.recipes, [recipe._id]);
		setFields(obj);
		obj = recipesAllowed;
		_.pullAllBy(obj, [{"_id": recipe._id}], "_id");
		setRecipesAllowed([...obj])
	}
	
	function addRecipeToAllow(recipe) {
		if (fields.recipes.includes(recipe._id)) {
			return;
		}
		let obj = fields;
		obj.recipes.push(recipe._id);
		setFields(obj);
		obj = recipesAllowed;
		obj.push(recipe);
		setRecipesAllowed([...obj])
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				let _recipesAllowed = [];
				if (modalOpt.item &&
					modalOpt.item.settings &&
					modalOpt.item.settings.recipes) {
					setFields({
						"recipes": modalOpt.item.settings.recipes
					});
					if (modalOpt.item.settings.recipes.length > 0) {
						try {
							const _res = await marketplaceService.getRecipesByIds(modalOpt.item.settings.recipes);
							if (_res && Array.isArray(_res)) {
								_recipesAllowed = _res;
							}
						} catch (e) {
							NotificationManager.error(e.message);
						}
					}
				}
				
				await getRecipes(1, {"keywords": ""}, setPagination, setRecipes);
				if (isSubscribed) {
					setRecipesAllowed(_recipesAllowed)
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad().catch();
		return () => (isSubscribed = false);
	}, [modalOpt]);
	
	return (
		<>
			<Jumbotron className="p-3">
				<CardDeck>
					<Card>
						<Card.Body>
							<Form.Row>
								<Form.Label column="xs"
								            xs={3}>{t("soajs:buttons.Search")}</Form.Label>
								<Col xs={6}>
									<Form.Control
										id="keywords"
										size="sm"
										autoFocus
										value={criteria.keywords}
										onChange={handleCriteriaChange}
									/>
								</Col>
								<Col xs={3}>
									<Button className="float-right" variant="dark" size="sm"
									        onClick={handleSearch}>
										<BiSearchAlt/> {t("soajs:buttons.Search")}
									</Button>
								</Col>
							</Form.Row>
						</Card.Body>
					</Card>
				</CardDeck>
			</Jumbotron>
			<hr/>
			{t("soajs:titles.allowedRecipes")}
			<>
				{recipesAllowed.map((oo, index) => (
					<Card key={index} className="m-1 p-1 border-success" bg="light">
						<Card.Body className="p-2">
							{oo.name} - <span
							className="font-italic small text-danger">[{oo.type}/{oo.subtype}]</span>
							<span className="float-right small">
								V {oo.v}
								<DeleteButton
									className="ml-3"
									onClick={() => {
										removeRecipeFromAllow(oo);
									}}
								/>
							</span>
						</Card.Body>
					</Card>
				))}
			</>
			<hr/>
			{t("soajs:titles.listRecipes")}
			{recipes.map((oo, index) => (
				<Card key={index} className="m-1 p-1" bg="light">
					<Card.Body className="p-2">
						{oo.name} - <span
						className="font-italic small text-danger">[{oo.type}/{oo.subtype}]</span>
						<span className="float-right small">V {oo.v}
							<AddButton
								disabled={fields.recipes.includes(oo._id)}
								className="ml-3"
								onClick={() => {
									addRecipeToAllow(oo);
								}}
							/>
						</span>
					</Card.Body>
				</Card>
			))}
			{recipes.length > 0 ? (
				<>
					<div className="float-right mt-3">
						<AutoPagination
							currentPage={currentPage}
							totalItems={pagination.totalItems}
							itemsPerPage={pagination.itemsPerPage}
							maxSize={pagination.maxSize}
							onClick={(p) => {
								setCurrentPage(p);
								reload(p).catch();
							}}
						/>
					</div>
					<div className="clearfix"></div>
				</>
			) : null}
			
			<hr/>
			<div>
				<Button className="ml-1 float-right" variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Close")}
				</Button>
				
				{ability.can('market_item', 'settings_recipe') && (modalOpt.item.configuration ? modalOpt.item.configuration.subType || null : null) !== "soajs" &&
				<Button className="ml-1 float-right" variant="success"
				        disabled={!validateForm()}
				        onClick={(event) => handleSubmit(event, 3)}
				>
					{t("soajs:buttons.Save")} - {t("soajs:catalogs.recipes")}
				</Button>}
				{ability.can('market_item_soajs', 'settings_recipe') && (modalOpt.item.configuration ? modalOpt.item.configuration.subType || null : null) === "soajs" &&
				<Button className="ml-1 float-right" variant="success"
				        disabled={!validateForm()}
				        onClick={(event) => handleSubmit(event, 3)}
				>
					{t("soajs:buttons.Save")} - {t("soajs:catalogs.recipes")}
				</Button>}
			</div>
		</>
	);
}