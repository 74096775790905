import {
	Alert,
	Card,
	Button,
	Row,
	Col
} from "react-bootstrap";

import React from "react";

import Template from "../Template/Template";

const methodVariant = {
	"get": "primary",
	"post": "success",
	"delete": "danger",
	"put": "warning",
	"patch": "info",
	"head": "secondary",
	"other": "light"
};

function getMethodVariant(method) {
	if (method && methodVariant[method]) {
		return methodVariant[method];
	} else {
		return methodVariant["other"];
	}
}

function Details({version}) {
	let apis = {};
	if (version && version.apis) {
		for (let i = 0; i < version.apis.length; i++) {
			if (!version.apis[i].group) {
				version.apis[i].group = "No Group";
			}
			if (!apis[version.apis[i].group]) {
				apis[version.apis[i].group] = {};
			}
			if (!apis[version.apis[i].group][version.apis[i].m]) {
				apis[version.apis[i].group][version.apis[i].m] = []
			}
			apis[version.apis[i].group][version.apis[i].m].push(version.apis[i]);
		}
	}
	
	return (
		<>
			<hr/>
			{Object.keys(apis).map((group, index) => (
				<Card key={group + index} className={"mb-2"}>
					<Card.Header>
						<span className="font-weight-bold">{group}</span>
					</Card.Header>
					<Card.Body>
						{Object.keys(apis[group]).map((method, index) => (
							<div key={method + index}>
								{apis[group][method].map((api, index) => (
									<Alert key={index} variant={getMethodVariant(method)}>
										<Row>
											<Col>
												<Button size="sm" className="mr-2 pl-1 pr-1 pt-0 pb-0 float-left"
												        variant={getMethodVariant(method)}><span
													className="small">{method.toUpperCase()}</span></Button>
												<span className="small float-left">{api.v}</span>
												<span className="small float-right text-info">{api.l}</span>
											</Col>
										</Row>
									</Alert>
								))}
							</div>
						))}
					</Card.Body>
				</Card>
			))}
		</>
	)
}

export default function Api() {
	
	return (
		<Template
			type={"service"}
			Children={Details}
		/>
	);
}