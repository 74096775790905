import {Dropdown, DropdownButton} from "react-bootstrap";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import DeleteModal from "./modal/DeleteModal";
import RedeployModal from "./modal/RedeployModal";
import ConfigureModal from "./modal/ConfigureModal";

import {useAppContext} from "../../../../../soajs/libs/contextLib";
import {NotificationManager} from "../../../../../soajs/urac/components";
import {InfraService} from "../../../../../services";

const infraService = InfraService.getService();

export default function ServiceOperation({
	                                         version,
	                                         env,
	                                         itemName,
	                                         itemType,
	                                         itemSettings,
	                                         itemSrc,
	                                         deployed,
	                                         reload,
	                                         name,
	                                         kind,
	                                         active,
	                                         deployConfiguration,
	                                         inspect
                                         }) {
	const {t} = useTranslation(["common", "soajs"]);
	const {ability} = useAppContext();
	
	const [modalDeleteOpt, setModalDeleteOpt] = useState({"show": false, "item": null});
	const modalDeleteOptShow = (item) => setModalDeleteOpt({"show": true, "item": item});
	
	const [modalRedeployOpt, setModalRedeployOpt] = useState({"show": false, "item": null, "title": ""});
	const modalRedeployOptShow = (item) => setModalRedeployOpt({"show": true, "item": item, "title": item.name});
	
	const [modalDeployOpt, setModalDeployOpt] = useState({"show": false, "item": null, "title": ""});
	const modalDeployOptShow = (item) => setModalDeployOpt({"show": true, "item": item, "title": item.name});
	
	async function deleteItem(what) {
		if (kind && name && what === "deploy") {
			try {
				if (active) {
					await infraService.deleteItem(env, {"name": name, "mode": kind});
					NotificationManager.success(t("soajs:messages.deleteItem"));
					reload();
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		if (kind && name && what === "deploy_keepservice") {
			try {
				if (active) {
					await infraService.deleteItem(env, {"name": name, "mode": kind, "keepService": true});
					NotificationManager.success(t("soajs:messages.deleteItem"));
					reload();
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		if (what === "config") {
			// let data = {
			// 	"type": itemType,
			// 	"name": itemName,
			// 	"env": env
			// }
			// delete config
		}
	}
	
	async function restart() {
		if (kind && name) {
			try {
				if (active) {
					await infraService.restart(env, {"name": name, "mode": kind});
					NotificationManager.success(t("soajs:messages.restartItem"));
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
	}
	
	return (
		<>
			<DeleteModal
				modalOpt={modalDeleteOpt}
				setModalOpt={setModalDeleteOpt}
				handleAction={deleteItem}
			/>
			<RedeployModal
				modalOpt={modalRedeployOpt}
				setModalOpt={setModalRedeployOpt}
				handleAction={reload}
			/>
			<ConfigureModal
				modalOpt={modalDeployOpt}
				setModalOpt={setModalDeployOpt}
				handleAction={reload}
			/>
			<DropdownButton
				size="sm"
				id="dropdown-item-button"
				title="Service Operations"
				variant="secondary"
				className="float-left small">
				{deployed &&
				<>
					
					{ability.can('market_item', 'configure') &&
					<Dropdown.Item
						onClick={() => {
							modalDeployOptShow({
								"deployed": deployed,
								"itemSettings": itemSettings,
								"name": itemName,
								"type": itemType,
								"itemSrc": itemSrc,
								"availableSrc": {"branches": version.branches || null, "tags": version.tags || null},
								"deployConfiguration": deployConfiguration || null,
								"version": version.version,
								"env": env,
								"inspect": inspect || null,
								"kind": kind
							});
						}}
					>
						<small>Configure</small>
					</Dropdown.Item>}
					
					{ability.can('market_item', 'redeploy') &&
					<Dropdown.Item
						onClick={() => {
							modalRedeployOptShow({
								"name": itemName,
								"type": itemType,
								"itemSrc": itemSrc,
								"availableSrc": {"branches": version.branches || null, "tags": version.tags || null},
								"deployConfiguration": deployConfiguration || null,
								"version": version.version,
								"env": env,
								"inspect": inspect || null,
								"kind": kind
							});
						}}
					>
						<small>Redeploy</small>
					</Dropdown.Item>}
					
					{ability.can('infra_item', 'restart') &&
					<Dropdown.Item
						onClick={() => {
							restart().catch();
						}}
					>
						<small>Restart</small>
					</Dropdown.Item>}
					
					<Dropdown.Divider className="m-0"/>
					<Dropdown.Item
						onClick={() => {
							reload();
						}}
					>
						<small>Refresh</small>
					</Dropdown.Item>
					
					<Dropdown.Divider className="m-0"/>
					
					{/*{deployConfiguration && ability.can('market_item_configure', 'delete') &&*/}
					{/*<Dropdown.Item*/}
					{/*	onClick={() => {*/}
					{/*		modalDeleteOptShow({*/}
					{/*			"title": itemName + " v" + version.version + " configuration", "what": "config"*/}
					{/*		});*/}
					{/*	}}*/}
					{/*>*/}
					{/*	<small>Delete Configuration</small>*/}
					{/*</Dropdown.Item>*/}
					{/*}*/}
					
					{ability.can('infra_item', 'delete') &&
					<Dropdown.Item
						onClick={() => {
							modalDeleteOptShow({
								"title": itemName + " v" + version.version,
								"what": "deploy_keepservice"
							});
						}}
					>
						<small>Delete Deployment - without service</small>
					</Dropdown.Item>}
					{ability.can('infra_item', 'delete') &&
					<Dropdown.Item
						onClick={() => {
							modalDeleteOptShow({"title": itemName + " v" + version.version, "what": "deploy"});
						}}
					>
						<small>Delete Deployment - everything</small>
					</Dropdown.Item>}
				</>
				}
				{!deployed &&
				<>
					{ability.can('market_item', 'configure') &&
					<Dropdown.Item
						onClick={() => {
							modalDeployOptShow({
								"deployed": deployed,
								"itemSettings": itemSettings,
								"name": itemName,
								"type": itemType,
								"itemSrc": itemSrc,
								"availableSrc": {"branches": version.branches || null, "tags": version.tags || null},
								"deployConfiguration": deployConfiguration || null,
								"version": version.version,
								"env": env,
								"inspect": inspect || null,
								"kind": kind
							});
						}}
					>
						<small>Configure</small>
					</Dropdown.Item>}
					
					<Dropdown.Divider className="m-0"/>
					<Dropdown.Item
						onClick={() => {
							reload();
						}}
					>
						<small>Refresh</small>
					</Dropdown.Item>
					
					{/*<Dropdown.Divider className="m-0"/>*/}
					{/**/}
					{/*{deployConfiguration && ability.can('market_item_configure', 'delete') &&*/}
					{/*<Dropdown.Item*/}
					{/*	onClick={() => {*/}
					{/*		modalDeleteOptShow({*/}
					{/*			"title": itemName + " v" + version.version + " configuration", "what": "config"*/}
					{/*		});*/}
					{/*	}}*/}
					{/*>*/}
					{/*	<small>Delete Configuration</small>*/}
					{/*</Dropdown.Item>*/}
					{/*}*/}
				</>
				}
			</DropdownButton>
		</>
	);
}