import {Button, Modal, Form} from "react-bootstrap";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import {NotificationManager} from "../../../../soajs/urac/components";
import {ConsoleService} from "../../../../services";

const consoleService = ConsoleService.getService();

export default function DatabasesAddModal({modalOpt, setModalOpt, handleAction, environment, clusters}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, opts: null});
	const [fields, setFields] = useState({
		"name": "",
		"cluster": "",
		"prefix": "",
		"tenantSpecific": "false"
	});
	
	function validateForm() {
		return (
			fields.name.length > 0 &&
			fields.cluster.length > 0
		);
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setFields({
			...fields,
			[event.target.id]: value
		});
	}
	
	async function handleSubmit() {
		try {
			let data = {
				"env": environment.code,
				"name": fields.name,
				"cluster": fields.cluster,
				"prefix": fields.prefix,
				"tenantSpecific": fields.tenantSpecific === "true"
			};
			await consoleService.addDB(data);
			NotificationManager.success(t("soajs:messages.addDB"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.addDB")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Name")}</Form.Label>
						<Form.Control
							id="name"
							size="sm"
							value={fields.name}
							onChange={handleFieldChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Prefix")}</Form.Label>
						<Form.Control
							id="prefix"
							size="sm"
							value={fields.prefix}
							onChange={handleFieldChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Multitenant")}</Form.Label>
						<Form.Control
							as="select"
							id="tenantSpecific"
							value={fields.tenantSpecific}
							onChange={(event) => {
								handleFieldChange(event);
							}}
						>
							<option value="false">False</option>
							<option value="true">True</option>
						</Form.Control>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Cluster")}</Form.Label>
						<Form.Control
							as="select"
							id="cluster"
							value={fields.cluster}
							onChange={(event) => {
								handleFieldChange(event);
							}}
						>
							<option value="">---</option>
							{clusters.map((cluster, index) => (
								<option key={index} value={cluster.name}>{cluster.name}</option>
							))}
						</Form.Control>
					</Form.Group>
				</Form>
			
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button variant="success" onClick={handleSubmit}
				        disabled={!validateForm()}
				>
					{t("soajs:buttons.Add")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}