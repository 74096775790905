import {Button, Form, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {JsonEditor, NotificationManager} from "../../../../../soajs/urac/components";
import {MultitenantService} from "../../../../../services";

import deepSet from "../../../../../soajs/libs/deepSetLib";

const multitenantService = MultitenantService.getService();

const initFields = {
	"id": "",
	"appId": "",
	"key": "",
	"extKey": "",
	"label": "",
	"extKeyEnv": "",
	"expDate": null,
	"device": {},
	"geo": {}
};

export default function ConfigureExtKeyModal({modalOpt, setModalOpt, handleAction, environments}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, item: null});
	
	const [fields, setFields] = useState(initFields);
	
	function validateForm() {
		return (
			fields.label &&
			fields.label.length > 0 &&
			fields.extKeyEnv.length > 0
		);
	}
	
	function JsonEditor_onChange_device(json) {
		fields.device = json;
	}
	
	function JsonEditor_onChange_geo(json) {
		fields.geo = json;
	}
	
	function handleExpDateChange(m) {
		let obj = fields;
		if (m) {
			//deepSet(obj, "expDate", m.getFullYear() + "-" + (m.getMonth() + 1) + "-" + m.getDate());
			deepSet(obj, "expDate", m.toISOString());
		} else {
			deepSet(obj, "expDate", null);
		}
		setFields({...obj});
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		let obj = fields;
		deepSet(obj, event.target.id, value);
		setFields({...obj});
	}
	
	async function handleSubmit() {
		try {
			if (fields.expDate) {
				let m = new Date(fields.expDate);
				fields.expDate = m.toISOString();
			}
			await multitenantService.updateTenantConsoleApplicationExtKey(fields);
			NotificationManager.success(t("soajs:messages.tenantApplicationExtKey"));
			handleAction();
			handleClose();
		} catch
			(e) {
			NotificationManager.error(e.message);
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		if (isSubscribed && modalOpt && modalOpt.item) {
			setFields(Object.assign({}, initFields, modalOpt.item));
		}
		return () => (isSubscribed = false);
	}, [modalOpt]);
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.tenantApplicationExtKey")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Label")}</Form.Label>
						<Form.Control
							id="label"
							size="sm"
							value={fields.label}
							onChange={handleFieldChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Env")}</Form.Label>
						<Form.Control
							as="select"
							id="extKeyEnv"
							size="sm"
							value={fields.extKeyEnv}
							onChange={handleFieldChange}
						>
							<option value="">---</option>
							{environments.map((rec, index) => (
								<option key={index} value={rec.code}>{rec.code}</option>
							))}
						</Form.Control>
					</Form.Group>
					<Form.Group>
						<Form.Label className="mr-5">{t("soajs:fields.ExpDate")}</Form.Label>
						<DatePicker
							selected={(fields.expDate && fields.expDate !== "") ? new Date(fields.expDate) : null}
							onChange={handleExpDateChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Device")}</Form.Label><JsonEditor
						mode={"code"}
						json={fields.device || {}}
						onChange={JsonEditor_onChange_device}
					/>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Geo")}</Form.Label><JsonEditor
						mode={"code"}
						json={fields.geo || {}}
						onChange={JsonEditor_onChange_geo}
					/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button
					variant="success"
					onClick={handleSubmit}
					disabled={!validateForm()}
				>
					{t("soajs:buttons.Update")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}