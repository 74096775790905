import {Alert, Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {BiShareAlt} from "react-icons/bi";
import {useTranslation} from "react-i18next";
import {GoDiffAdded} from "react-icons/go";

import {NotificationManager} from "../../../../../soajs/urac/components";
import {ConsoleService} from "../../../../../services";
import DeleteButton from "../../../../buttons/Delete";
import AclButton from "../../../../buttons/Acl";
import {useAppContext} from "../../../../../soajs/libs/contextLib";

import ConfigureModal from "./ConfigureModal";
import DeleteModal from "./DeleteModal";
import AddModal from "./AddModal";
import UpdateModal from "./UpdateModal";

const consoleService = ConsoleService.getService();

async function onLoad(environment, setFields, isSubscribed) {
	try {
		const response = await consoleService.getResourceRegistry(environment.code);
		if (isSubscribed && response) {
			setFields(response);
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function Resource({environment, environments, reloadClusters}) {
	const {t} = useTranslation(["common", "soajs"]);
	const {ability} = useAppContext();
	const [fields, setFields] = useState([]);
	const [modalOpt, setModalOpt] = useState({"show": false, "item": null});
	const [deleteModalOpt, setDeleteModalOpt] = useState({"show": false, item: null});
	const handleDeleteShow = (item) => setDeleteModalOpt({"show": true, "item": item});
	const [addModalOpt, setAddModalOpt] = useState({"show": false});
	const handleAddShow = () => setAddModalOpt({"show": true});
	const [updateModalOpt, setUpdateModalOpt] = useState({"show": false, item: null});
	const handleUpdateShow = (item) => setUpdateModalOpt({"show": true, "item": item});
	
	function handleModalShow(item) {
		setModalOpt({"show": true, "item": item})
	}
	
	async function reLoad() {
		await onLoad(environment, setFields, true);
		reloadClusters();
	}
	
	async function plugUnplug(d, plugged) {
		try {
			await consoleService.unplugPlugResource({"id": d._id, "env": d.created, "plugged": plugged});
			if (plugged) {
				NotificationManager.success(t("soajs:messages.pluggedResource"));
			} else {
				NotificationManager.success(t("soajs:messages.unpluggedResource"));
			}
			await reLoad();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		environment && onLoad(environment, setFields, isSubscribed);
		return () => (isSubscribed = false);
	}, [environment]);
	
	return (
		environment &&
		<>
			<UpdateModal
				modalOpt={updateModalOpt}
				setModalOpt={setUpdateModalOpt}
				handleAction={reLoad}
				environments={environments}
				environment={environment}
			/>
			<AddModal
				modalOpt={addModalOpt}
				setModalOpt={setAddModalOpt}
				handleAction={reLoad}
				environments={environments}
				environment={environment}
			/>
			<DeleteModal
				modalOpt={deleteModalOpt}
				setModalOpt={setDeleteModalOpt}
				handleAction={reLoad}
			/>
			<ConfigureModal
				modalOpt={modalOpt}
				setModalOpt={setModalOpt}
				handleAfterSubmit={reLoad}
			/>
			<div>
				<h4 className="float-left">{t("soajs:titles.resourceRegistry")}</h4>
				{ability.can('registry_resource', 'add') &&
				<Button variant="success" className="float-right" size="sm"
				        onClick={() => {
					        handleAddShow();
				        }}
				>
					<GoDiffAdded/> {t("soajs:buttons.Add")}
				</Button>}
				<span className="clearfix"></span>
			</div>
			<hr/>
			{fields.length > 0 ?
				<>
					{fields.map((rec, i) => (
						<Alert key={i} variant="light" className="mt-4 mb-4" style={{"borderColor": "#c6c8ca"}}>
					<span className="float-left">
					<BiShareAlt
						className={rec.shared ? "text-success" : ""}
					/> <span className=" ml-2 font-weight-bold">{rec.name}</span>
					</span>
							{environment.code.toLowerCase() !== rec.created.toLowerCase() ?
								<div>
									<span className="float-right"> from: {rec.created}</span>
									<span className="clearfix"></span>
								</div>
								:
								<div>
									{ability.can('registry_resource', 'delete') &&
									<DeleteButton className="float-right ml-2"
									              onClick={() => {
										              handleDeleteShow(rec)
									              }}
									/>}
									<AclButton className="float-right ml-5"
									           onClick={() => {
										           handleModalShow(rec)
									           }}
									/>
									<Button variant="primary"
									        className="float-right ml-2"
									        size="sm"
									        disabled={!ability.can('registry_resource', 'edit')}
									        onClick={() => {
										        handleUpdateShow(rec)
									        }}
									>Configure</Button>
									{rec.plugged &&
									<Button variant="warning"
									        className="float-right ml-2"
									        size="sm"
									        disabled={!ability.can('registry_resource', 'edit')}
									        onClick={() => {
										        plugUnplug(rec, false);
									        }}
									>Unplug</Button>}
									{!rec.plugged &&
									<Button variant="success"
									        className="float-right ml-2"
									        size="sm"
									        disabled={!ability.can('registry_resource', 'edit')}
									        onClick={() => {
										        plugUnplug(rec, true);
									        }}
									>Plug</Button>}
									<span className="clearfix"></span>
								</div>
							}
						</Alert>
					))}
				</>
				:
				<Alert variant="secondary" className="mt-4 mb-4">
					{t("soajs:messages.empty")}
				</Alert>}
		</>
	)
};