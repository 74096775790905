import {Button, Modal, Form} from "react-bootstrap";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export default function ThrottlingAddModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, item: null});
	const [fields, setFields] = useState({
		"name": "",
		"type": 0,
		"window": 0,
		"limit": 0,
		"retries": 0,
		"delay": 0
	});
	
	function validateForm() {
		return (
			fields.name.length > 0 &&
			(fields.type === 0 || fields.type === 1) &&
			fields.window > 0 &&
			fields.limit > 0 &&
			fields.retries > 0 &&
			fields.delay > 0
		);
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		if (event.target.type === 'number' || event.target.id === "type") {
			value = parseInt(value);
		}
		setFields({
			...fields,
			[event.target.id]: value
		});
	}
	
	function handleSubmit() {
		handleAction(fields);
		handleClose();
	}
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.addThrottling")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Name")}</Form.Label>
						<Form.Control
							id="name"
							size="sm"
							value={fields.name}
							onChange={handleFieldChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Type")}</Form.Label>
						<Form.Control
							as="select"
							id="type"
							type="number"
							value={fields.type}
							onChange={handleFieldChange}
						>
							<option value="0">Per Tenant</option>
							<option value="1">Per Tenant & Per IP Address</option>
						</Form.Control>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Window")}</Form.Label>
						<Form.Control
							id="window"
							size="sm"
							type="number"
							min="1"
							value={fields.window}
							onChange={handleFieldChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Limit")}</Form.Label>
						<Form.Control
							id="limit"
							size="sm"
							type="number"
							min="1"
							value={fields.limit}
							onChange={handleFieldChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Retries")}</Form.Label>
						<Form.Control
							id="retries"
							size="sm"
							type="number"
							min="1"
							value={fields.retries}
							onChange={handleFieldChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Delay")}</Form.Label>
						<Form.Control
							id="delay"
							size="sm"
							type="number"
							min="1"
							value={fields.delay}
							onChange={handleFieldChange}
						/>
					</Form.Group>
				</Form>
			
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button variant="success" onClick={handleSubmit}
				        disabled={!validateForm()}
				>
					{t("soajs:buttons.Add")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}