import {useState} from "react";
import _ from "lodash";

import deepSet from "./deepSetLib";

export function useFormFields(initialState) {
	const [fields, setValues] = useState(initialState);
	
	return [
		fields,
		function (event) {
			let obj = fields;
			deepSet(obj, event.target.id, event.target.value);
			setValues({...obj});
		},
		function (newState) {
			let result = _.merge(fields, newState)
			setValues(result);
		}
	];
}