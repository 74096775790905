import React, {useEffect, useState} from "react";
import {Table, Button, Jumbotron} from "react-bootstrap";

import {GoDiffAdded} from "react-icons/go";
import {useTranslation} from "react-i18next";

import {NotificationManager} from "../../../../soajs/urac/components";
import {MultitenantService} from "../../../../services";
import {useAppContext} from "../../../../soajs/libs/contextLib";

import DeleteModal from "./DeleteModal"
import InfoModal from "./InfoModal/InfoModal";
import AddModal from "./AddModal";
import EditModal from "./EditModal";
import AclModal, {initializeACL} from "./AclModal/AclModal";

import DeleteButton from "../../../buttons/Delete";
import ViewButton from "../../../buttons/View";
import EditButton from "../../../buttons/Edit";
import AclButton from "../../../buttons/Acl";

import _config from "../../../../config"

const multitenantService = MultitenantService.getService();

async function onLoad(setFields, isSubscribed) {
	try {
		const product = await multitenantService.getMyConsoleProduct();
		if (isSubscribed) {
			setFields(product);
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function Packages() {
	const {ability} = useAppContext();
	const {t} = useTranslation(["common", "soajs", "organization"]);
	const [fields, setFields] = useState(null);
	
	const [modalInfoOpt, setModalInfoOpt] = useState({"show": false, opts: null, scope: null});
	const modalInfoOptShow = (pack, scope) => setModalInfoOpt({"show": true, opts: pack, scope: scope});
	
	const [modalDeleteOpt, setModalDeleteOpt] = useState({"show": false, opts: null});
	const modalDeleteOptShow = (opt) => setModalDeleteOpt({"show": true, opts: opt});
	
	const [modalEditOpt, setModalEditOpt] = useState({"show": false, opts: null});
	const modalEditOptShow = (opt) => setModalEditOpt({"show": true, opts: opt});
	
	const [modalAclOpt, setModalAclOpt] = useState({"show": false, opts: null, scope: null});
	const modalAclOptShow = (opt, scope) => setModalAclOpt({"show": true, opts: opt, scope: scope});
	
	const [modalAddOpt, setModalAddOpt] = useState({"show": false, opts: null});
	const modalAddOptShow = (opt) => setModalAddOpt({"show": true, opts: opt});
	
	async function reLoad() {
		await onLoad(setFields, true);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		onLoad(setFields, isSubscribed);
		return () => (isSubscribed = false);
	}, []);
	
	return (
		fields && (
			<>
				<AddModal
					modalOpt={modalAddOpt}
					setModalOpt={setModalAddOpt}
					handleAction={reLoad}
				/>
				<EditModal
					modalOpt={modalEditOpt}
					setModalOpt={setModalEditOpt}
					handleAction={reLoad}
				/>
				<DeleteModal
					modalOpt={modalDeleteOpt}
					setModalOpt={setModalDeleteOpt}
					handleAction={reLoad}
				/>
				<InfoModal
					modalOpt={modalInfoOpt}
					setModalOpt={setModalInfoOpt}
				/>
				<AclModal
					modalOpt={modalAclOpt}
					setModalOpt={setModalAclOpt}
					handleAction={reLoad}
				/>
				<Jumbotron className="p-4 mb-3">
					<span className="font-weight-bold">{fields.code}:</span> {fields.name}
					<hr/>
					{fields.description}
				</Jumbotron>
				<hr/>
				<div>
					<h5 className="float-left">{t("soajs:titles.packages")}</h5>
					{ability.can('console_package', 'add') &&
					<Button variant="success" className="float-right" size="sm"
					        onClick={() => modalAddOptShow({"_id": fields._id})}
					>
						<GoDiffAdded/> {t("soajs:buttons.Add")}
					</Button>}
				</div>
				<Table responsive striped hover size="sm">
					<thead className="text-light bg-dark">
					<tr>
						<th>{t("soajs:buttons.Actions")}</th>
						<th>{t("soajs:fields.Code")}</th>
						<th>{t("soajs:fields.Name")}</th>
						<th>{t("soajs:fields.Description")}</th>
					</tr>
					</thead>
					<tbody>
					{fields.packages.map((pack, index) => (
						<tr key={index} className="small">
							<td nowrap="true">
								<ViewButton
									className="mr-1"
									onClick={() => {
										if (!pack.acl) {
											pack.acl = {};
										}
										if (!pack.acl[_config.consoleEnvironment]) {
											pack.acl[_config.consoleEnvironment] = {};
										}
										modalInfoOptShow(pack, fields.scope);
									}}
								/>
								{ability.can('console_package', 'delete') &&
								<DeleteButton
									className="mr-1"
									onClick={() => {
										pack._id = fields._id;
										modalDeleteOptShow(pack);
									}}/>
								}
								{ability.can('console_package', 'edit') &&
								<EditButton
									className="mr-1"
									onClick={() => {
										pack._id = fields._id;
										modalEditOptShow(pack);
									}}/>
								}
								{ability.can('console_package', 'acl') &&
								<AclButton
									className="mr-1"
									onClick={() => {
										pack._id = fields._id;
										let convertedPack = initializeACL(pack, fields.scope);
										if (!convertedPack.acl) {
											convertedPack.acl = {};
										}
										if (!convertedPack.acl[_config.consoleEnvironment]) {
											convertedPack.acl[_config.consoleEnvironment] = {};
										}
										modalAclOptShow(convertedPack, fields.scope);
									}}/>
								}
							</td>
							<td>{pack.code}</td>
							<td nowrap="true">{pack.name}</td>
							<td>{pack.description}</td>
						</tr>
					))}
					</tbody>
				</Table>
			</>
		)
	);
}