import {Button, Form, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {NotificationManager} from "../../../../../soajs/urac/components";
import {MultitenantService} from "../../../../../services";
import deepSet from "../../../../../soajs/libs/deepSetLib";

const multitenantService = MultitenantService.getService();

export default function AddApplicationModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, item: null});
	
	const [packages, setPackages] = useState([]);
	const [products, setProducts] = useState([]);
	const [fields, setFields] = useState({
		"id": null,
		"productCode": "",
		"packageCode": "",
		"description": "",
		"_TTL": "168"
	});
	
	function validateForm() {
		return (
			fields.productCode.length > 0 &&
			fields.packageCode.length > 0 &&
			fields.description.length > 0
		);
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		let obj = fields;
		deepSet(obj, event.target.id, value);
		setFields({...obj});
		if (event.target.id === "productCode") {
			let p = products.filter((rec) => {
				return (value === rec.code);
			});
			if (p && p[0] && p[0].packages) {
				setPackages(p[0].packages);
			}
		}
	}
	
	async function handleSubmit() {
		try {
			fields.id = modalOpt.item.id;
			await multitenantService.addApplication(fields);
			NotificationManager.success(t("soajs:messages.tenantApplicationAdd"));
			handleAction();
			handleClose();
		} catch
			(e) {
			NotificationManager.error(e.message);
		}
	}
	
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				const response = await multitenantService.getProducts();
				if (isSubscribed && response) {
					setProducts(response);
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad().catch();
		return () => (isSubscribed = false);
	}, []);
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.tenantApplicationAdd")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label>{t("soajs:fields.ProductCode")}</Form.Label>
						<Form.Control
							as="select"
							id="productCode"
							size="sm"
							defaultValue={fields.productCode}
							onChange={handleFieldChange}
						>
							<option value="">---</option>
							{products.map((rec, index) => (
								<option key={index} value={rec.code}>{rec.code}</option>
							))}
						</Form.Control>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.PackageCode")}</Form.Label>
						<Form.Control
							as="select"
							id="packageCode"
							size="sm"
							defaultValue={fields.packageCode}
							onChange={handleFieldChange}
						>
							<option value="">---</option>
							{packages.map((rec, index) => (
								<option key={index} value={rec.code}>{rec.code}</option>
							))}
						</Form.Control>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.TTL")}</Form.Label>
						<Form.Control as="select" id="_TTL"
						              defaultValue={fields._TTL}
						              onChange={handleFieldChange}
						>
							<option value="6">6 hours</option>
							<option value="12">12 hours</option>
							<option value="24">24 hours</option>
							<option value="48">2 days</option>
							<option value="72">3 days</option>
							<option value="96">4 days</option>
							<option value="120">5 days</option>
							<option value="144">6 days</option>
							<option value="168">7 days</option>
						</Form.Control>
					</Form.Group>
					<Form.Group>
						<Form.Label>{t("soajs:fields.Description")}</Form.Label>
						<Form.Control
							id="description"
							size="sm"
							as="textarea"
							rows={3}
							value={fields.description}
							onChange={handleFieldChange}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button
					variant="success"
					onClick={handleSubmit}
					disabled={!validateForm()}
				>
					{t("soajs:buttons.Add")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}