import {Alert, Badge, Button, Col, Dropdown, DropdownButton, Form, Jumbotron, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";

import {NotificationManager} from "../../../../soajs/urac/components";
import {ConsoleService, SoamonitorService} from "../../../../services";
import AutoPagination from "../../../../lib/AutoPagination";
import {useTranslation} from "react-i18next";
import {BiSearchAlt} from "react-icons/bi";
import DatePicker from "react-datepicker";

const consoleService = ConsoleService.getService();
const soamonitorService = SoamonitorService.getService();
const methodVariant = {
	"get": "primary",
	"post": "success",
	"delete": "danger",
	"put": "warning",
	"patch": "info",
	"head": "secondary",
	"other": "light"
};

function getMethodVariant(method) {
	if (method && methodVariant[method]) {
		return methodVariant[method];
	} else {
		return methodVariant["other"];
	}
}

export default function ApiAnalytics() {
	const {t} = useTranslation(["common", "soajs"]);
	const [itemsApi, setItemsApi] = useState([]);
	const [itemsService, setItemsService] = useState([]);
	const [itemsUser, setItemsUser] = useState([]);
	const [itemsTenant, setItemsTenant] = useState([]);
	
	const [environments, setEnvironments] = useState([]);
	const [selectedEnv, setSelectedEnv] = useState(null);
	const [selectedMsg, setSelected] = useState("-- Select an environment --");
	
	const [criteria, setCriteria] = useState({
		"type": "api",
		"username": "",
		"tenantCode": "",
		"api": "",
		"service": "",
		"dateFrom": "",
		"dateTo": "",
		"year": {
			"from": 0,
			"to": 0
		},
		"month": {
			"from": 0,
			"to": 0
		},
		"day": {
			"from": 0,
			"to": 0
		}
	});
	
	const [currentPage, setCurrentPage] = useState(1);
	const [pagination, setPagination] = useState({
		"totalItems": 1, "maxSize": 1, "itemsPerPage": 1
	});
	
	async function reLoad(page, env) {
		try {
			let c = {"limit": 15, "start": (page - 1) * 15};
			if (criteria.type === "api") {
				if (criteria.api && criteria.api.length > 0) {
					c.filters = {
						"api": criteria.api
					};
				}
			}
			if (criteria.type === "user") {
				if (criteria.username && criteria.username.length > 0) {
					c.filters = {
						"username": criteria.username
					};
				}
			}
			if (criteria.type === "tenant") {
				if (criteria.tenantCode && criteria.tenantCode.length > 0) {
					c.filters = {
						"tenantCode": criteria.tenantCode
					};
				}
			}
			if (criteria.type === "service") {
				if (criteria.service && criteria.service.length > 0) {
					c.filters = {
						"service": criteria.service
					};
				}
			}
			if (criteria.dateFrom && criteria.dateFrom.length > 0) {
				if (!c.year) {
					c.year = {};
				}
				c.year.from = criteria.year.from;
				if (!c.month) {
					c.month = {};
				}
				c.month.from = criteria.month.from;
				if (!c.day) {
					c.day = {};
				}
				c.day.from = criteria.day.from;
			}
			if (criteria.dateTo && criteria.dateTo.length > 0) {
				if (!c.year) {
					c.year = {};
				}
				c.year.to = criteria.year.to;
				if (!c.month) {
					c.month = {};
				}
				c.month.to = criteria.month.to;
				if (!c.day) {
					c.day = {};
				}
				c.day.to = criteria.day.to;
			}
			const response = await soamonitorService.getMonitorAnalytics(criteria.type, c, env || selectedEnv);
			if (response) {
				setPagination(
					{
						"totalItems": response[criteria.type].count,
						"maxSize": 10,
						"itemsPerPage": response[criteria.type].limit
					}
				);
				if (response.api && response.api.items) {
					setItemsApi(response.api.items);
				} else {
					setItemsApi([]);
				}
				if (response.service && response.service.items) {
					setItemsService(response.service.items)
				} else {
					setItemsService([]);
				}
				if (response.user && response.user.items) {
					setItemsUser(response.user.items)
				} else {
					setItemsUser([]);
				}
				if (response.tenant && response.tenant.items) {
					setItemsTenant(response.tenant.items)
				} else {
					setItemsTenant([]);
				}
			}
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function handleSelectEnv(code) {
		setSelectedEnv(code);
		setSelected(code);
		await reLoad(1, code);
	}
	
	function handleFromDateChange(dp) {
		if (dp) {
			let y = dp.getFullYear();
			let m = dp.getMonth();
			let d = dp.getDate();
			
			criteria.dateFrom = dp.toISOString();
			criteria.year.from = y;
			criteria.month.from = m;
			criteria.day.from = d;
			setCriteria({...criteria});
		} else {
			criteria.dateFrom = "";
			criteria.year.from = 0;
			criteria.month.from = 0;
			criteria.day.from = 0;
			setCriteria({...criteria});
		}
	}
	
	function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}
	
	async function handleSearch(event) {
		event.preventDefault();
		setCurrentPage(1);
		await reLoad(1, null);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				const _environments = await consoleService.getEnvironments();
				if (_environments && isSubscribed) {
					setEnvironments(_environments);
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad().catch();
		return () => (isSubscribed = false);
	}, []);
	
	return (
		<>
			<Alert variant="info" className="mt-4 mb-2">
				<h6 className="float-left mr-3 mt-2">
					Environment:
				</h6>
				<DropdownButton
					size="sm"
					id="dropdown-item-button"
					title={selectedMsg}
					variant="light"
					className="small">
					{environments.map((item, index) => (
						<Dropdown.Item
							as="button"
							key={index}
							active={selectedEnv === item.code}
							onClick={() => {
								handleSelectEnv(item.code).catch();
							}}>
							<small>
								{item.code}
							</small>
						</Dropdown.Item>
					))}
				</DropdownButton>
				<span className="clearfix"></span>
			</Alert>
			
			<>
				<Jumbotron className="p-2 mb-1">
					<Form onSubmit={handleSearch}>
						<Row className="small">
							<Col md={3}>
								<Form.Control
									style={{
										"fontSize": "80%"
									}}
									as="select"
									id="type"
									defaultValue={criteria.type}
									onChange={handleCriteriaChange}
								>
									<option value="api">API</option>
									<option value="user">User</option>
									<option value="tenant">Tenant</option>
									<option value="service">Service</option>
								</Form.Control>
								<Form.Text className="text-muted">
									View by
								</Form.Text>
							</Col>
							<Col md={4}>
								{criteria.type === "api" &&
								<>
									<Form.Control
										style={{
											"fontSize": "80%"
										}}
										id="api"
										size="sm"
										value={criteria.api}
										onChange={handleCriteriaChange}
									/>
									<Form.Text className="text-muted">
										Filter by route
									</Form.Text>
								</>
								}
								{criteria.type === "user" &&
								<>
									<Form.Control
										style={{
											"fontSize": "80%"
										}}
										id="username"
										size="sm"
										value={criteria.username}
										onChange={handleCriteriaChange}
									/>
									<Form.Text className="text-muted">
										Filter by username
									</Form.Text>
								</>
								}
								{criteria.type === "service" &&
								<>
									<Form.Control
										style={{
											"fontSize": "80%"
										}}
										id="service"
										size="sm"
										value={criteria.service}
										onChange={handleCriteriaChange}
									/>
									<Form.Text className="text-muted">
										Filter by service
									</Form.Text>
								</>
								}
								{criteria.type === "tenant" &&
								<>
									<Form.Control
										style={{
											"fontSize": "80%"
										}}
										id="tenantCode"
										size="sm"
										value={criteria.tenantCode}
										onChange={handleCriteriaChange}
									/>
									<Form.Text className="text-muted">
										Filter by tenant
									</Form.Text>
								</>
								}
							</Col>
							<Col>
								<DatePicker
									selected={(criteria.dateFrom && criteria.dateFrom !== "") ? new Date(criteria.dateFrom) : null}
									onChange={handleFromDateChange}
								/>
								<Form.Text className="text-muted">
									Specific Date
								</Form.Text>
							</Col>
							<Col>
								<Button
									disabled={!selectedEnv}
									className="float-right"
									variant="dark"
									size="sm"
									onClick={handleSearch}>
									<BiSearchAlt/> {t("soajs:buttons.Search")}
								</Button>
							</Col>
						</Row>
					</Form>
				</Jumbotron>
				
				{itemsApi && itemsApi.length > 0 &&
				<Table responsive striped hover size="sm">
					<thead className="text-light bg-dark">
					<tr>
						<th>{t("soajs:fields.Api")}</th>
						<th>{t("soajs:fields.Service")}</th>
						<th className="text-center">{t("soajs:fields.Count")}</th>
						<th className="text-center">{t("soajs:fields.Max")}</th>
						<th className="text-center">{t("soajs:fields.Min")}</th>
						<th>{t("soajs:fields.Date")}</th>
					</tr>
					</thead>
					<tbody>
					{itemsApi.map((rec, i) => (
						<tr key={i}>
							<td>
								<Button size="sm" className="mr-2 pl-1 pr-1 pt-0 pb-0"
								        variant={getMethodVariant(rec.method.toLowerCase())}><span
									className="small">{rec.method.toUpperCase()}</span></Button>
								<small>{rec.api}</small>
							</td>
							<td><small>{rec.name} v{rec.version}</small></td>
							<td className="text-center small">{rec.count}</td>
							<td className="text-center small">{rec.max_time_total}</td>
							<td className="text-center small">{rec.min_time_total}</td>
							<td><small>{rec.year}-{rec.month + 1}-{rec.day}</small></td>
						</tr>
					))}
					</tbody>
				</Table>}
				
				{itemsService && itemsService.length > 0 &&
				<Table responsive striped hover size="sm">
					<thead className="text-light bg-dark">
					<tr>
						<th>{t("soajs:fields.Service")}</th>
						<th className="text-center">{t("soajs:fields.Count")}</th>
						<th className="text-center">{t("soajs:fields.Max")}</th>
						<th className="text-center">{t("soajs:fields.Min")}</th>
						<th>{t("soajs:fields.Date")}</th>
					</tr>
					</thead>
					<tbody>
					{itemsService.map((rec, i) => (
						<tr key={i}>
							<td><small>{rec.name} <Badge variant="secondary">v{rec.version}</Badge></small></td>
							<td className="text-center small">{rec.count}</td>
							<td className="text-center small">{rec.max_time_total}</td>
							<td className="text-center small">{rec.min_time_total}</td>
							<td><small>{rec.year}-{rec.month + 1}-{rec.day}</small></td>
						</tr>
					))}
					</tbody>
				</Table>}
				
				{itemsTenant && itemsTenant.length > 0 &&
				<Table responsive striped hover size="sm">
					<thead className="text-light bg-dark">
					<tr>
						<th>{t("soajs:fields.Tenant")}</th>
						<th className="text-center">{t("soajs:fields.Count")}</th>
						<th className="text-center">{t("soajs:fields.Max")}</th>
						<th className="text-center">{t("soajs:fields.Min")}</th>
						<th>{t("soajs:fields.Date")}</th>
					</tr>
					</thead>
					<tbody>
					{itemsTenant.map((rec, i) => (
						<tr key={i}>
							<td><small>{rec.tenant_id} [<span className="text-info">{rec.tenant_code}</span>]</small>
							</td>
							<td className="text-center small">{rec.count}</td>
							<td className="text-center small">{rec.max_time_total}</td>
							<td className="text-center small">{rec.min_time_total}</td>
							<td><small>{rec.year}-{rec.month + 1}-{rec.day}</small></td>
						</tr>
					))}
					</tbody>
				</Table>}
				
				{itemsUser && itemsUser.length > 0 &&
				<>
					<Table responsive striped hover size="sm">
						<thead className="text-light bg-dark">
						<tr>
							<th>{t("soajs:fields.ID")}</th>
							<th>{t("soajs:fields.Username")}</th>
							<th>{t("soajs:fields.Email")}</th>
							<th className="text-center">{t("soajs:fields.Count")}</th>
							<th className="text-center">{t("soajs:fields.Max")}</th>
							<th className="text-center">{t("soajs:fields.Min")}</th>
							<th>{t("soajs:fields.Date")}</th>
						</tr>
						</thead>
						<tbody>
						{itemsUser.map((rec, i) => (
							<tr key={i}>
								<td><small>{rec.urac_id}</small></td>
								<td><small>{rec.urac_username}</small></td>
								<td><small>{rec.urac_email}</small></td>
								<td className="text-center small">{rec.count}</td>
								<td className="text-center small">{rec.max_time_total}</td>
								<td className="text-center small">{rec.min_time_total}</td>
								<td><small>{rec.year}-{rec.month + 1}-{rec.day}</small></td>
							</tr>
						))}
						</tbody>
					</Table>
					
					<div className="float-right mt-3">
						<AutoPagination
							currentPage={currentPage}
							totalItems={pagination.totalItems}
							itemsPerPage={pagination.itemsPerPage}
							maxSize={pagination.maxSize}
							onClick={(p) => {
								setCurrentPage(p);
								reLoad(p, null).catch();
							}}
						/>
					</div>
					<div className="clearfix"></div>
				</>}
			</>
		</>
	);
}