import {Button, Modal, Form, Tabs, Tab, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {JsonEditor, NotificationManager} from "../../../../../soajs/urac/components";
import {ConsoleService} from "../../../../../services";

const consoleService = ConsoleService.getService();
let _json = {};

export default function UpdateModal({modalOpt, setModalOpt, handleAction, environments, environment}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, item: null});
	const [fields, setFields] = useState({
		"id": "",
		"name": "",
		"plugged": false,
		"shared": false,
		"type": "",
		"category": ""
	});
	const [sharedEnvs, setSharedEnvs] = useState({});
	
	function validateForm() {
		let valid = (
			fields.name.length > 0 &&
			fields.type.length > 0 &&
			fields.category.length > 0
		);
		return (
			valid
		);
	}
	
	function handleSharedEnvsChange(event) {
		let checked = false;
		if (event.target.hasOwnProperty("checked")) {
			checked = event.target.checked;
		}
		let obj = sharedEnvs;
		if (checked) {
			obj[event.target.id] = checked;
			setSharedEnvs({...obj});
		} else {
			delete obj[event.target.id];
			setSharedEnvs({...obj});
		}
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setFields({
			...fields,
			[event.target.id]: value
		});
	}
	
	async function handleSubmit() {
		try {
			let _sharedEnvs = {};
			let envs = Object.keys(sharedEnvs);
			for (let i = 0; i < envs.length; i++) {
				if (sharedEnvs[envs[i]]) {
					_sharedEnvs[envs[i]] = sharedEnvs[envs[i]];
				}
			}
			let data = {
				"name": fields.name,
				"plugged": fields.plugged,
				"shared": fields.shared,
				"sharedEnvs": _sharedEnvs,
				"type": fields.type,
				"category": fields.category
			};
			data.config = _json;
			
			await consoleService.updateResource(environment.code, fields.id, data);
			NotificationManager.success(t("soajs:messages.updateResource"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	function JsonEditor_onChange(json) {
		_json = json;
	}
	
	useEffect(() => {
		let isSubscribed = true;
		if (isSubscribed && modalOpt && modalOpt.item) {
			setFields(
				{
					"id": modalOpt.item._id,
					"name": modalOpt.item.name,
					"plugged": modalOpt.item.plugged,
					"shared": modalOpt.item.shared,
					"type": modalOpt.item.type,
					"category": modalOpt.item.category
				}
			);
			if (!modalOpt.item.sharedEnvs) {
				modalOpt.item.sharedEnvs = {};
			}
			let _sharedEns = {};
			for (let i = 0; i < environments.length; i++) {
				_sharedEns[environments[i].code] = !!modalOpt.item.sharedEnvs[environments[i].code];
			}
			setSharedEnvs({..._sharedEns});
			_json = modalOpt.item.config || {};
		}
		return () => (isSubscribed = false);
	}, [modalOpt, environments]);
	
	return (
		modalOpt && modalOpt.item ? (
			<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>{t("soajs:titles.resourceRegistryUpdate")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Tabs className="mb-3" defaultActiveKey="value" id="config">
							<Tab eventKey="value" title={t("soajs:fields.Configuration")}>
								<Form.Group>
									<Form.Label>{t("soajs:fields.Name")}</Form.Label>
									<Form.Control
										id="name"
										size="sm"
										value={fields.name}
										onChange={handleFieldChange}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label>{t("soajs:fields.Type")}</Form.Label>
									<Form.Control
										id="type"
										size="sm"
										value={fields.type}
										disabled
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label>{t("soajs:fields.Category")}</Form.Label>
									<Form.Control
										id="category"
										size="sm"
										value={fields.category}
										disabled
									/>
								</Form.Group>
								<Form.Group style={{"height": "400px"}}>
									<Form.Label>{t("soajs:fields.Value")}</Form.Label>
									<JsonEditor
										mode="code"
										json={_json}
										onChange={JsonEditor_onChange}
									/>
								</Form.Group>
							</Tab>
							<Tab eventKey="settings" title={t("soajs:fields.Settings")}>
								<Form.Group>
									<Form.Label
										className="float-left">{t("soajs:fields.PlugConfiguration")}</Form.Label>
									<Form.Check
										className="float-right"
										id="plugged"
										size="sm"
										type="switch"
										checked={fields.plugged}
										onChange={handleFieldChange}
									/>
									<span className="clearfix"></span>
								</Form.Group>
								<Form.Group>
									<Form.Label
										className="float-left">{t("soajs:fields.ShareConfiguration")}</Form.Label>
									<Form.Check
										className="float-right"
										id="shared"
										size="sm"
										type="switch"
										checked={fields.shared}
										onChange={handleFieldChange}
									/>
									<span className="clearfix"></span>
								</Form.Group>
								{fields.shared &&
								<Table responsive striped hover size="sm">
									<tbody>
									{environments.map((e, i) => (
										<tr key={i}>
											<td>
												{(!environment || environment.code !== e.code) &&
												<Form.Check
													id={e.code}
													size="sm"
													type="switch"
													checked={sharedEnvs[e.code]}
													onChange={handleSharedEnvsChange}
												/>
												}
											</td>
											<td>
												{e.code}
											</td>
											<td>
												{e.description}
											</td>
										</tr>
									))}
									</tbody>
								</Table>
								}
							</Tab>
						</Tabs>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="warning" onClick={handleClose}>
						{t("soajs:buttons.Cancel")}
					</Button>
					<Button variant="success" onClick={handleSubmit}
					        disabled={!validateForm()}
					>
						{t("soajs:buttons.Update")}
					</Button>
				</Modal.Footer>
			</Modal>
		) : null
	);
}