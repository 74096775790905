import {Alert, ListGroup, ListGroupItem} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import GearButton from "../../../buttons/Gear";
import {ConsoleService} from "../../../../services";
import {NotificationManager} from "../../../../soajs/urac/components";

import NamespaceModal from "./NamespaceModal";

const consoleService = ConsoleService.getService();

async function onLoad(setSettings, environment, isSubscribed) {
	try {
		const response = await consoleService.getRegistryDeployer(environment.code);
		if (response && isSubscribed) {
			setSettings(response);
		} else {
			setSettings(null);
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function Container({environment}) {
	const {t} = useTranslation(["common", "soajs"]);
	const [settings, setSettings] = useState(null);
	const [modalOpt, setModalOpt] = useState({"show": false});
	
	function handleModalShow() {
		setModalOpt({"show": true})
	}
	
	function reLoad() {
		onLoad(setSettings, environment, true).catch();
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		onLoad(setSettings, environment, isSubscribed).catch();
		return () => (isSubscribed = false);
	}, [environment]);
	
	return (
		settings &&
		<>
			<NamespaceModal
				modalOpt={modalOpt}
				setModalOpt={setModalOpt}
				handleAction={() => {
					reLoad();
				}}
				environment={environment}
				settings={settings}
			/>
			<hr/>
			<Alert variant="secondary">
				<ListGroup variant="flush">
					<ListGroupItem><span
						className="font-weight-bold">{t("soajs:fields.MasterNode")}:</span> {settings.container.kubernetes.configuration.url}
					</ListGroupItem>
					<ListGroupItem><span
						className="font-weight-bold">{t("soajs:fields.Namespace")}:</span> {settings.container.kubernetes.namespace}
						<GearButton className="float-right ml-3" onClick={() => {
							handleModalShow()
						}}/>
					</ListGroupItem>
				</ListGroup>
			</Alert>
		</>
	);
}