import {Button, Modal, Form, Card, Col} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import _ from "lodash";

import {NotificationManager} from "../../../../soajs/urac/components";
import {UracService} from "../../../../soajs/urac/services";
import {MultitenantService} from "../../../../services";

const uracService = UracService.getService();
const multitenantService = MultitenantService.getService();
const initFields = {
	"code": "",
	"name": "",
	"description": "",
	"config": {}
};

export default function AddModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, opts: null});
	const [fields, setFields] = useState(initFields);
	const [productFields, setProductFields] = useState({
		"code": "",
		"name": "",
		"description": "",
		"packages": []
	});
	
	function validateForm() {
		return (
			fields.code.length > 0 &&
			fields.name.length > 0 &&
			fields.description.length > 0
		);
	}
	
	function handleFieldChange(event) {
		setFields({
			...fields,
			[event.target.id]: event.target.value
		});
	}
	
	async function handleSubmit() {
		try {
			let packages = null;
			if (fields.config && fields.config.allowedPackages) {
				packages = [];
				for (let n in fields.config.allowedPackages) {
					packages.push({"product": n, "packages": fields.config.allowedPackages[n]})
				}
			}
			await uracService.addGroup(
				{
					"code": fields.code,
					"name": fields.name,
					"description": fields.description,
					"packages": packages
				});
			NotificationManager.success(t("soajs:messages.addGroup"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	function onChangePackage(checked, packCode, prodCode) {
		if (fields.config) {
			if (!fields.config.allowedPackages) {
				fields.config.allowedPackages = {};
			}
			if (checked) {
				if (!fields.config.allowedPackages[prodCode]) {
					fields.config.allowedPackages[prodCode] = [];
				}
				fields.config.allowedPackages[prodCode].push(packCode);
			} else {
				if (fields.config.allowedPackages[prodCode] && fields.config.allowedPackages[prodCode]) {
					_.pull(fields.config.allowedPackages[prodCode], packCode);
				}
			}
			setFields({...fields});
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				const product = await multitenantService.getMyConsoleProduct();
				if (isSubscribed) {
					let item = {};
					item.code = product.code;
					item.name = product.name;
					item.description = product.description;
					item.packages = [];
					for (let j = 0; j < product.packages.length; j++) {
						item.packages.push({
							"code": product.packages[j].code,
							"name": product.packages[j].name
						})
					}
					setProductFields(item);
					setFields(initFields);
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad();
		return () => (isSubscribed = false);
	}, []);
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.groupAdd")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={2}>{t("soajs:fields.Code")}</Form.Label>
							<Col sm={10}>
								<Form.Control
									id="code"
									size="sm"
									autoFocus
									value={fields.code}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={2}>{t("soajs:fields.Name")}</Form.Label>
							<Col sm={10}>
								<Form.Control
									id="name"
									size="sm"
									value={fields.name}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={2}>{t("soajs:fields.Description")}</Form.Label>
							<Col sm={10}>
								<Form.Control
									id="description"
									as="textarea"
									rows={3}
									size="sm"
									value={fields.description}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Card>
							<Card.Header>
								<div>
									{productFields.code} - {productFields.name}
								</div>
								<small className="font-italic">{productFields.description}</small>
							</Card.Header>
							<Card.Body>
								{productFields.packages.map((pack, index) => (
									<Form.Check key={index + 1} type="checkbox"
									            label={pack.code + " - " + pack.name}
									            onChange={(event) => {
										            onChangePackage(event.target.checked, pack.code, productFields.code)
									            }}
									/>
								))}
							</Card.Body>
						</Card>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button variant="success" onClick={handleSubmit}
				        disabled={!validateForm()}
				>
					{t("soajs:buttons.Add")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}