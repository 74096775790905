import {Button, Modal, Accordion, Card, Form, Col} from "react-bootstrap";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";

import {NotificationManager} from "../../../../soajs/urac/components";
import {UracService} from "../../../../soajs/urac/services";
import _ from "lodash";

const uracService = UracService.getService();
const fieldsInit = {
	"code": "",
	"name": "",
	"description": "",
	"id": "",
	"config": {}
};

export default function EditModal({modalOpt, setModalOpt, handleAction, product}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, opts: null});
	const [fields, setFields] = useState(fieldsInit);
	const [productFields, setProductFields] = useState([]);
	
	function validateForm() {
		return (
			fields.name.length > 0 &&
			fields.description.length > 0
		);
	}
	
	async function handleSubmit() {
		try {
			let packages = null;
			if (fields.config && fields.config.allowedPackages) {
				packages = [];
				for (let n in fields.config.allowedPackages) {
					packages.push({"product": n, "packages": fields.config.allowedPackages[n]})
				}
			}
			await uracService.editGroup(
				{
					"id": fields.id,
					"name": fields.name,
					"description": fields.description,
					"packages": packages
				});
			NotificationManager.success(t("soajs:messages.editGroup"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setFields({
			...fields,
			[event.target.id]: value
		});
	}
	
	function onChangePackage(checked, packCode, prodCode) {
		if (fields.config) {
			if (!fields.config.allowedPackages) {
				fields.config.allowedPackages = {};
			}
			if (checked) {
				if (!fields.config.allowedPackages[prodCode]) {
					fields.config.allowedPackages[prodCode] = [];
				}
				fields.config.allowedPackages[prodCode].push(packCode);
			} else {
				if (fields.config.allowedPackages[prodCode] && fields.config.allowedPackages[prodCode]) {
					_.pull(fields.config.allowedPackages[prodCode], packCode);
				}
			}
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				if (modalOpt.opts) {
					if (isSubscribed) {
						setFields({
							"code": modalOpt.opts.code,
							"name": modalOpt.opts.name,
							"description": modalOpt.opts.description,
							"id": modalOpt.opts._id,
							"config": _.merge({}, modalOpt.opts.config || {})
						});
						let item = {};
						item.code = product.code;
						item.name = product.name;
						item.description = product.description;
						item.packages = [];
						for (let j = 0; j < product.packages.length; j++) {
							item.packages.push({
								"code": product.packages[j].code,
								"name": product.packages[j].name,
								"selected": (
									modalOpt.opts && modalOpt.opts.config && modalOpt.opts.config.allowedPackages &&
									modalOpt.opts.config.allowedPackages[product.code] &&
									modalOpt.opts.config.allowedPackages[product.code].includes(product.packages[j].code)
								)
							})
						}
						if (isSubscribed) {
							setProductFields([item]);
						}
					}
				} else {
					if (isSubscribed) {
						setProductFields(null);
						setFields(fieldsInit);
					}
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad();
		return () => (isSubscribed = false);
	}, [modalOpt, product]);
	
	return (
		modalOpt.opts &&
		<Modal show={modalOpt.show} onHide={handleClose} animation={false}>
			<Modal.Header closeButton className="bg-secondary text-white">
				<Modal.Title>{t("soajs:titles.groupEdit")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				
				<Form>
					<Form.Group>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={2}>{t("organization:groups.code")}</Form.Label>
							<Col sm={10}>
								{fields.code}
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={2}>{t("organization:groups.name")}</Form.Label>
							<Col sm={10}>
								<Form.Control
									id="name"
									size="sm"
									autoFocus
									value={fields.name}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={2}>{t("organization:groups.description")}</Form.Label>
							<Col sm={10}>
								<Form.Control
									id="description"
									as="textarea"
									rows={3}
									size="sm"
									value={fields.description}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Accordion defaultActiveKey={1}>
							{productFields && productFields.map((item, index) => (
								<Card key={index + 1}>
									<Card.Header>
										<div>
											<Accordion.Toggle className="p-0" as={Button} variant="link"
											                  eventKey={index + 1}>
												{item.code} - {item.name}
											</Accordion.Toggle>
										</div>
										<small className="font-italic">{item.description}</small>
									</Card.Header>
									<Accordion.Collapse eventKey={index + 1}>
										<Card.Body>
											{item.packages.map((pack, index) => (
												<Form.Check key={index + 1} type="switch"
												            label={pack.code + " - " + pack.name}
												            defaultChecked={pack.selected}
												            id={pack.code}
												            onChange={(event) => {
													            onChangePackage(event.target.checked, pack.code, item.code)
												            }}
												/>
											))}
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							))}
						</Accordion>
					</Form.Group>
				</Form>
			
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button variant="success"
				        disabled={!validateForm()}
				        onClick={handleSubmit}
				>
					{t("soajs:buttons.Update")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}