import {Button, Card, Col, Form, Modal} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";
import {BiGitBranch} from "react-icons/bi";
import {AiFillGithub} from "react-icons/ai";
import {DiBitbucket} from "react-icons/di";

import {RepositoriesService} from "../../../../services";
import {NotificationManager} from "../../../../soajs/urac/components";
import {useAppContext} from "../../../../soajs/libs/contextLib";

const repositoriesService = RepositoriesService.getService();

function Provider({provider}) {
	let icon = (<BiGitBranch/>);
	if (provider === "github") {
		icon = (<AiFillGithub/>);
	} else if (provider === "bitbucket_enterprise") {
		icon = (<DiBitbucket/>);
	} else if (provider === "bitbucket") {
		icon = (<DiBitbucket/>);
	}
	return (icon);
}

export default function UpgradeModal({modalOpt, setModalOpt, modalFields, setModalFields, handleAfterSubmit}) {
	const {t} = useTranslation(["common", "soajs"]);
	const {ability} = useAppContext();
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setModalFields({
			...modalFields,
			[event.target.id]: value
		});
	}
	
	async function handleSubmit(event) {
		event.preventDefault();
		try {
			let data = {
				"id": modalFields.id,
				"username": modalFields.username,
				"token": modalFields.token,
				"oauthKey": modalFields.consumerKey,
				"oauthSecret": modalFields.consumerSecret
			}
			await repositoriesService.upgradeGitAccount(data);
			NotificationManager.success(t("soajs:development.accounts.upgradeMsg"));
			handleAfterSubmit();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	function validateForm() {
		let valid = true;
		
		valid = (valid && modalFields.token.length > 0);
		if (modalFields.provider === "bitbucket") {
			valid = (valid && modalFields.consumerSecret.length > 0);
			valid = (valid && modalFields.consumerKey.length > 0);
		}
		
		return (
			valid
		);
	}
	
	const handleClose = () => setModalOpt({"show": false, item: null});
	
	return (
		<>
			{modalFields &&
			<Modal size="lg" show={modalOpt.show} onHide={handleClose} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{t("soajs:development.accounts.upgrade")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card>
						<Card.Header>
							<Provider provider={modalFields.provider}/> <span
							className="font-weight-bold">{modalFields.label}</span> [{modalFields.username}]
						</Card.Header>
						<Card.Body>
							<Form onSubmit={(event) => {
								event.preventDefault();
							}}>
								<Form.Group>
									<Form.Row className="mb-2">
										<Form.Label column="sm"
										            sm={4}>{t("soajs:development.accounts.token")}</Form.Label>
										<Col sm={8}>
											<Form.Control
												id="token"
												size="sm"
												value={modalFields.token}
												onChange={handleFieldChange}
											/>
										</Col>
									</Form.Row>
									
									{modalFields.provider === "bitbucket" &&
									<Form.Row className="mb-2">
										<Form.Label column="sm"
										            sm={4}>{t("soajs:development.accounts.consumerKey")}</Form.Label>
										<Col sm={8}>
											<Form.Control
												id="consumerKey"
												size="sm"
												value={modalFields.consumerKey}
												onChange={handleFieldChange}
											/>
										</Col>
									</Form.Row>}
									
									{modalFields.provider === "bitbucket" &&
									<Form.Row className="mb-2">
										<Form.Label column="sm"
										            sm={4}>{t("soajs:development.accounts.consumerSecret")}</Form.Label>
										<Col sm={8}>
											<Form.Control
												id="consumerSecret"
												size="sm"
												value={modalFields.consumerSecret}
												onChange={handleFieldChange}
											/>
										</Col>
									</Form.Row>}
								</Form.Group>
							
							</Form>
						</Card.Body>
					</Card>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="warning" onClick={handleClose}>
						{t("soajs:buttons.Cancel")}
					</Button>
					
					{ability.can('repo_account', 'upgrade') &&
					<Button variant="success"
					        disabled={!validateForm()}
					        onClick={handleSubmit}
					>
						{t("soajs:buttons.Upgrade")}
					</Button>}
					
				</Modal.Footer>
			</Modal>
			}
		</>
	);
}