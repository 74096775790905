import React, {useState} from "react";
import Form from "react-bootstrap/Form";

import {useFormFields} from "../../libs/hooksLib";
import {LoaderButton} from "../../helpers";

import {NotificationManager} from '../components';
import {UracService} from "../services";
import {useTranslation} from "react-i18next";

const uracService = UracService.getService();

export default function ChangePassword() {
	const {t} = useTranslation(["common", "urac"]);
	const [fields, handleFieldChange] = useFormFields({
		password: "",
		newPassword: "",
		confirmNewPassword: ""
	});
	const [isLoading, setIsLoading] = useState(false);
	
	function validateForm() {
		return (
			fields.password.length > 0 &&
			fields.newPassword.length > 0 &&
			fields.newPassword === fields.confirmNewPassword
		);
	}
	
	async function handleSubmit(event) {
		event.preventDefault();
		
		setIsLoading(true);
		
		try {
			await uracService.changePassword(fields.password, fields.newPassword, fields.confirmNewPassword);
			NotificationManager.success(t("urac:msg.changePasswordSuccess"));
		} catch (e) {
			NotificationManager.error(e.message);
		}
		
		setIsLoading(false);
	}
	
	return (
		<div className="URAC">
			<Form onSubmit={handleSubmit}>
				<Form.Group controlId="password">
					<Form.Label>{t("urac:Existing Password")}</Form.Label>
					<Form.Control
						autoFocus
						type="password"
						value={fields.password}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Form.Group controlId="newPassword">
					<Form.Label>{t("urac:New Password")}</Form.Label>
					<Form.Control
						type="password"
						value={fields.newPassword}
						onChange={handleFieldChange}
					/>
				</Form.Group>
				<Form.Group controlId="confirmNewPassword">
					<Form.Label>{t("urac:Confirm Password")}</Form.Label>
					<Form.Control
						type="password"
						onChange={handleFieldChange}
						value={fields.confirmNewPassword}
					/>
				</Form.Group>
				<LoaderButton
					block
					type="submit"
					isLoading={isLoading}
					disabled={!validateForm()}
				>
					{t("urac:button.Change")}
				</LoaderButton>
			</Form>
		</div>
	);
}