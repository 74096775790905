import {Button, Modal} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";
import {NotificationManager} from "../../../../soajs/urac/components";
import {UracService} from "../../../../soajs/urac/services";

const uracService = UracService.getService();

export default function DeleteModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, opts: null});
	
	async function handleSubmit() {
		try {
			await uracService.deleteGroup(modalOpt.opts._id);
			NotificationManager.success(t("soajs:messages.deleteGroup"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false}>
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:buttons.Delete")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{t("soajs:messages.deleteQuestion")}</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button variant="danger" onClick={handleSubmit}>
					{t("soajs:buttons.Delete")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}