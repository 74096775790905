import axios from "axios";
import {response_catch, response_then} from "./commonResponse";

const SoaanalyticsService = (function () {
	let _service = null;
	
	function _getService() {
		if (!_service) {
			_service = this;
			return _service;
		}
		return _service;
	}
	
	function _getApis(criteria) {
		return axios.get("/console/apis/v2", {"params": criteria})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getDashboardApis(criteria) {
		return axios.get("/console/dashboard/apis/v2", {"params": criteria})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getDashboardServices(criteria) {
		return axios.get("/console/dashboard/services", {"params": criteria})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getAPIACLUsage(criteria) {
		return axios.get("/console/api/acl/usage", {"params": criteria})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getAPIACLUsageTenants(criteria) {
		return axios.get("/console/api/acl/usage/tenants", {"params": criteria})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	return {
		getService: _getService,
		getApis: _getApis,
		getDashboardApis: _getDashboardApis,
		getDashboardServices: _getDashboardServices,
		getAPIACLUsage: _getAPIACLUsage,
		getAPIACLUsageTenants: _getAPIACLUsageTenants
	}
})();
export default SoaanalyticsService;