import React, {useState} from "react";

import Manual from "./Manual";
import Container from "./Container";
import ConfigureModal from "./ConfigureModal";
import AclButton from "../../../buttons/Acl";
import {ListGroup, ListGroupItem} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import DeleteButton from "../../../buttons/Delete";
import {useAppContext} from "../../../../soajs/libs/contextLib";
import DeleteModal from "./DeleteModal";
import {useHistory} from "react-router-dom";


export default function Settings({environment}) {
	const {t} = useTranslation(["common", "soajs"]);
	const {ability} = useAppContext();
	const [modalOpt, setModalOpt] = useState({"show": false});
	const [deleteModalOpt, setDeleteModalOpt] = useState({"show": false, item: null});
	const handleDeleteShow = (item) => setDeleteModalOpt({"show": true, "item": item});
	
	const history = useHistory();
	
	async function reLoad() {
		history.push("/environments/manage");
	}
	
	function handleModalShow() {
		setModalOpt({"show": true})
	}
	
	return (
		environment &&
		<>
			<DeleteModal
				modalOpt={deleteModalOpt}
				setModalOpt={setDeleteModalOpt}
				handleAction={reLoad}
			/>
			<ConfigureModal
				environment={environment}
				modalOpt={modalOpt}
				setModalOpt={setModalOpt}
			/>
			<ListGroup variant="flush">
				<ListGroupItem><span
					className="font-weight-bold">{t("soajs:fields.Code")}:</span> {environment.code}
					{!environment.locked && ability.can('env', 'delete') &&
					<DeleteButton
						className="float-right ml-3" onClick={() => {
						handleDeleteShow(environment);
					}}
					/>}
					<AclButton className="float-right ml-3" onClick={() => {
						handleModalShow();
					}}/>
				</ListGroupItem>
				<ListGroupItem><span
					className="font-weight-bold">{t("soajs:fields.Type")}:</span> {environment.deployer.type}
				</ListGroupItem>
				<ListGroupItem><span
					className="font-weight-bold">{t("soajs:fields.Description")}:</span> {environment.description}
				</ListGroupItem>
			</ListGroup>
			
			{environment.deployer.type === "manual" &&
			<div>
				<Manual
					environment={environment}
				/>
			</div>
			}
			{environment.deployer.type === "container" &&
			<div>
				<Container
					environment={environment}
				/>
			</div>
			}
		</>
	);
}