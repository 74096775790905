import React from "react";
import {Container} from "react-bootstrap";

import Routes from "./Routes";
import Navigation from "./Navigation";

export default function Catalogs() {
	return (
		<>
			<Navigation/>
			<div className="bg-dark pt-1"></div>
			<div className="main-area">
				<Container className="mt-3 mb-3">
					<Routes/>
				</Container>
			</div>
		</>
	);
}