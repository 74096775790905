import {Alert, Badge, Button, Col, Row} from "react-bootstrap";

import {AiOutlineInfoCircle} from "react-icons/ai";
import React, {useState} from "react";
import JsonModal from "./modals/JsonModal";

import _ from "lodash";

import DeleteModal from "./modals/DeleteModal";
import EditModal from "./modals/EditModal";

import DeleteButton from "../../../../../buttons/Delete";
import EditButton from "../../../../../buttons/Edit";

export default function PV({items, deleteItem, editItem}) {
	const [modalOpt, setModalOpt] = useState({"show": false, "json": {}, "mode": "view", "title": ""});
	const modalOptShow = (json, title) => setModalOpt({
		"show": true,
		"json": json,
		"mode": "view",
		"title": title || ""
	});
	const [modalDeleteOpt, setModalDeleteOpt] = useState({"show": false, "item": {}});
	const modalDeleteOptShow = (item) => setModalDeleteOpt({"show": true, "item": item});
	
	const [modalEditOpt, setModalEditOpt] = useState({"show": false, "item": {}, "json": null});
	const modalEditOptShow = (item, json) => setModalEditOpt({"show": true, "item": item, "json": json});
	
	return (
		<>
			<JsonModal
				modalOpt={modalOpt}
				setModalOpt={setModalOpt}
			/>
			{deleteItem &&
			<DeleteModal
				modalOpt={modalDeleteOpt}
				setModalOpt={setModalDeleteOpt}
				handleAction={
					(name) => {
						deleteItem("PV", name);
					}
				}
			/>}
			{editItem &&
			<EditModal
				modalOpt={modalEditOpt}
				setModalOpt={setModalEditOpt}
				handleAction={
					(name, body) => {
						body.kind = "PersistentVolume";
						editItem(body.kind, body.metadata.name, body)
					}
				}
			/>}
			
			{items.map((rec, i) => (
				<Alert key={i} variant="info" className="p-1 m-1">
					<Row>
						<Col>
							<Badge variant="light" className="float-left mr-1 small mt-1">PV</Badge>
							<span className="small">{rec.metadata.name}</span>
						</Col>
						<Col xs={"auto"}>
							<span className="small float-left mr-2">{rec.metadata.creationTimestamp}</span>
							<Button size="sm" variant="light" className="float-right pt-0 pb-1 pl-1 pr-1 ml-2">
								<AiOutlineInfoCircle
									className="text-primary"
									onClick={() => {
										modalOptShow(rec, rec.metadata.name);
									}}
								/>
							</Button>
							{editItem &&
							<EditButton
								className="float-right pt-0 pb-1 pl-1 pr-1 ml-2"
								onClick={() => {
									modalEditOptShow({
										"title": rec.metadata.name,
										"name": rec.metadata.name
									}, _.merge({}, rec));
								}}
							/>
							}
							{deleteItem &&
							<DeleteButton
								className="float-right pt-0 pb-1 pl-1 pr-1 ml-2"
								onClick={() => {
									modalDeleteOptShow({"title": rec.metadata.name, "name": rec.metadata.name});
								}}
							/>
							}
						</Col>
					</Row>
				</Alert>
			))}
		</>
	);
}