import {
	Accordion,
	Alert,
	Badge,
	Button,
	Col,
	Form,
	Jumbotron,
	Row,
	useAccordionToggle
} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {BiSearchAlt} from "react-icons/bi";
import {useTranslation} from "react-i18next";

import {BsLockFill} from "react-icons/bs";

import AutoPagination from "../../../../lib/AutoPagination";
import {NotificationManager} from "../../../../soajs/urac/components";
import {SoaanalyticsService} from "../../../../services";
import {FcCollapse, FcExpand} from "react-icons/fc";

const initCriteria = {
	"type": "service",
	"includeSOAJS": false,
	"serviceName": "",
	"serviceGroup": "",
	"serviceVersion": "",
	"route": "",
	"tenant": ""
};

const methodVariant = {
	"get": "primary",
	"post": "success",
	"delete": "danger",
	"put": "warning",
	"patch": "info",
	"head": "secondary",
	"other": "light"
};
const soaanalyticsService = SoaanalyticsService.getService();
const tenantLimit = 10;

function getMethodVariant(method) {
	if (method && methodVariant[method]) {
		return methodVariant[method];
	} else {
		return methodVariant["other"];
	}
}

async function onLoad(setFields, setPagination, currentPage, isSubscribed, criteria) {
	try {
		let c = {
			"limit": 6,
			"start": (currentPage - 1) * 6,
			"type": criteria.type,
			"includeSOAJS": criteria.includeSOAJS,
			"keywords": {
				"serviceName": criteria.serviceName,
				"serviceGroup": criteria.serviceGroup,
				"serviceVersion": criteria.serviceVersion,
				"route": criteria.route
			}
		};
		const response = await soaanalyticsService.getApis(c);
		if (isSubscribed) {
			if (response && response.records) {
				setFields(response.records);
				setPagination(
					{
						"totalItems": response.count,
						"maxSize": 10,
						"itemsPerPage": response.limit
					}
				);
			}
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

function CustomToggle({children, eventKey, handleClick}) {
	const decoratedOnClick = useAccordionToggle(eventKey, () => {
		handleClick();
	});
	return (
		<div className="float-right" type="button" onClick={decoratedOnClick}>
			{children}
		</div>
	);
}

export default function ApiAcl() {
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [criteria, setCriteria] = useState(initCriteria);
	const [pagination, setPagination] = useState({
		"totalItems": 1, "maxSize": 1, "itemsPerPage": 1
	});
	
	const [selectedAPI, setSelectedAPI] = useState(null);
	const [usage, setUsage] = useState([]);
	const [usageTenants, setUsageTenants] = useState({
		"code": "",
		"count": 0,
		"tenants": []
	});
	const [activeKey, setActiveKey] = useState(0);
	
	async function reLoad(page) {
		await onLoad(setFields, setPagination, page || currentPage, true, criteria)
	}
	
	function handleCriteriaChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setCriteria({
			...criteria,
			[event.target.id]: value
		});
	}
	
	async function getACLUsageTenants(code) {
		try {
			let data = {
				"productCode": code,
				"limit": tenantLimit,
				"start": 0
			};
			if (criteria.tenant.length > 0) {
				data.keyword = criteria.tenant;
			}
			let response = await soaanalyticsService.getAPIACLUsageTenants(data);
			if (response && response.records) {
				setUsageTenants({
					"code": code,
					"count": response.count,
					"tenants": response.records
				});
			}
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function getACLUsage(api) {
		try {
			setSelectedAPI({
				"service": api.serviceName,
				"version": api.v,
				"method": api.method,
				"api": api.route,
				"group": api.group
			});
			let response = await soaanalyticsService.getAPIACLUsage({
				"service": api.serviceName,
				"version": api.v,
				"method": api.method,
				"api": api.route
			});
			if (response) {
				setUsage(response);
			}
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function handleSearch(event) {
		event.preventDefault();
		setCurrentPage(1);
		await reLoad(1);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		onLoad(setFields, setPagination, 1, isSubscribed, initCriteria).catch();
		return () => (isSubscribed = false);
	}, []);
	
	return (
		<>
			<Jumbotron className="p-3">
				<Form onSubmit={handleSearch}>
					<Row className="small">
						<Col xs={4}>
							<Form.Control
								placeholder={t("soajs:fields.ServiceName")}
								id="serviceName"
								size="sm"
								autoFocus
								value={criteria.serviceName}
								onChange={handleCriteriaChange}
							/>
						</Col>
						<Col xs={4}>
							<Form.Control
								placeholder={t("soajs:fields.ServiceVersion")}
								id="serviceVersion"
								size="sm"
								autoFocus
								value={criteria.serviceVersion}
								onChange={handleCriteriaChange}
							/>
						</Col>
						<Col xs={4}>
							<Form.Control
								placeholder={t("soajs:fields.ServiceGroup")}
								id="serviceGroup"
								size="sm"
								autoFocus
								value={criteria.serviceGroup}
								onChange={handleCriteriaChange}
							/>
						</Col>
					</Row>
					<Row className="small mt-4">
						<Col xs={6} md={7}>
							<Form.Control
								placeholder={t("soajs:fields.Route")}
								id="route"
								size="sm"
								autoFocus
								value={criteria.route}
								onChange={handleCriteriaChange}
							/>
						</Col>
						<Col xs={3}>
							<Form.Check
								id="includeSOAJS"
								label="Include SOAJS APIs"
								defaultChecked={criteria.includeSOAJS}
								onChange={handleCriteriaChange}
							/>
						</Col>
						<Col xs={3} md={2}>
							<Button className="float-right" variant="dark" size="sm" onClick={handleSearch}>
								<BiSearchAlt/> {t("soajs:buttons.Search")}
							</Button>
						</Col>
					</Row>
				</Form>
			</Jumbotron>
			{fields.length > 0 ? (
				<>
					{fields.map((api, index) => (
						
						<Alert className="m-1 p-2" key={index} variant={getMethodVariant(api.method)}>
							<Row>
								<Col>
									<Button size="sm" className="mr-2 pl-1 pr-1 pt-0 pb-0 float-left"
									        variant={getMethodVariant(api.method)}><span
										className="small">{api.method ? api.method.toUpperCase() : ""}</span></Button>
									<span className="small float-left font-weight-bold mr-1">{api.serviceName}</span>
									<span
										className="small float-left mr-2 font-weight-bold text-success"> v{api.v} </span>
									<span className="small float-left">{api.route}</span>
									<Button
										disabled={!api.route}
										variant="light"
										className="float-right ml-2 small pl-1 pr-1 pt-0 pb-0"
										onClick={() => {
											getACLUsage(api).catch();
										}}
									>
										<BsLockFill
											className="small"
										/>
									</Button>
									<span className="small float-right text-info">{api.group}</span>
								</Col>
							</Row>
						</Alert>
					))}
					<div className="float-right mt-3">
						<AutoPagination
							currentPage={currentPage}
							totalItems={pagination.totalItems}
							itemsPerPage={pagination.itemsPerPage}
							maxSize={pagination.maxSize}
							onClick={(p) => {
								setCurrentPage(p);
								reLoad(p);
							}}
						/>
					</div>
					<div className="clearfix"></div>
					<hr/>
				</>
			) : null}
			
			{selectedAPI &&
			<Alert className="m-1 p-2" variant={getMethodVariant(selectedAPI.method)}>
				<Row>
					<Col>
						<Button size="sm" className="mr-2 pl-1 pr-1 pt-0 pb-0 float-left"
						        variant={getMethodVariant(selectedAPI.method)}><span
							className="small">{selectedAPI.method ? selectedAPI.method.toUpperCase() : ""}</span></Button>
						<span className="small float-left font-weight-bold mr-1">{selectedAPI.service}</span>
						<span
							className="small float-left mr-2 font-weight-bold text-success"> v{selectedAPI.version} </span>
						<span className="small float-left">{selectedAPI.api}</span>
						<span className="small float-right text-info">{selectedAPI.group}</span>
					</Col>
				</Row>
			</Alert>
			}
			<Accordion activeKey={activeKey}>
				{usage.map((rec, i) => (
					<Alert key={i} variant="light" className="mt-2 mb-2 small" style={{"borderColor": "#c6c8ca"}}>
						<Row>
							<Col>
								<b>{rec.name}</b><Badge variant="info ml-3">{rec.code}</Badge>
								<CustomToggle
									eventKey={i + 1}
									handleClick={() => {
										if (activeKey === (i + 1)) {
											setActiveKey(null);
										} else {
											setActiveKey(i + 1);
										}
									}}
								>
									{activeKey === (i + 1) ? <FcExpand/> :
										<FcCollapse/>}
								</CustomToggle>
							
							</Col>
						</Row>
						<Accordion.Collapse eventKey={i + 1}>
							<>
								<hr/>
								<div className="p-2">
									{rec.found_in_scope && rec.found_in_scope.envs.length > 0 &&
									<Alert key={i} variant="secondary" className="p-1">
										Scope usage
										<span className="float-right">
										Environments:
										<span className=" info ml-2">
											{rec.found_in_scope.envs.join(", ").toUpperCase()}
										</span>
										</span>
									</Alert>
									}
									{rec.found_in_package && rec.found_in_package.packages.length > 0 &&
									<div>
										<b>Package usage</b>
										{rec.found_in_package.packages.map((pack, i) => (
											<Alert key={i} variant="info" className="p-1 m-1">
												{pack}
												<span className="float-right">
													{rec.found_in_package.envs &&
													rec.found_in_package.envs[pack] &&
													<>
														Environments:
														<span className="info ml-2">
															{rec.found_in_package.envs[pack].join(", ").toUpperCase()}
														</span>
													</>
													}
												</span>
											</Alert>
										))}
									</div>
									}
									<hr/>
									<Jumbotron className="p-3 mb-2">
										<b>Tenants usage</b> click on search to filter and fetch usage
										<Row className="mt-2">
											<Col>
												<Form onSubmit={handleSearch}>
													<Row>
														<Col xs={9} md={10}>
															<Form.Control
																id="tenant"
																size="sm"
																autoFocus
																value={criteria.tenant}
																onChange={handleCriteriaChange}
															/>
														</Col>
														<Col xs={3} md={2}>
															<Button
																className="float-right"
																variant="dark"
																size="sm"
																onClick={() => {
																	getACLUsageTenants(rec.code).catch();
																}}>
																<BiSearchAlt/> {t("soajs:buttons.Search")}
															</Button>
														</Col>
													</Row>
												</Form>
											</Col>
										</Row>
										{usageTenants.code === rec.code && usageTenants.tenants &&
										<Row>
											<Col>
												<Alert key={i} className=" mt-2 p-1 m-0">
													Used by <Badge
													variant="secondary">{usageTenants.count}</Badge> tenant(s)
													{usageTenants.count > tenantLimit &&
													<span>, max {tenantLimit} are listed below use search to filter</span>}
												</Alert>
											</Col>
										</Row>
										}
									</Jumbotron>
									{usageTenants.code === rec.code && usageTenants.tenants && usageTenants.tenants.length > 0 &&
									<div>
										{usageTenants.tenants.map((ten, i) => (
											<Alert key={i} variant="light" className="p-2 m-1 border-secondary">
												<b>{ten.name}</b>
												<span className="float-right">
													<Badge variant="secondary">{ten.code}</Badge>
												</span>
												<hr/>
												{ten.applications.map((app, i) => (
													<Alert key={i} variant="info" className="p-1 m-1 small">
														{app.package}
														<span className="float-right">
															Environment:
															<span className=" info ml-2">
																{app.key.env || "All environments"}
															</span>
														</span>
														<hr/>
														<span style={{"wordBreak": "break-all"}}>
															{app.key.extKey}
														</span>
													</Alert>
												))}
											</Alert>
										))}
									</div>
									}
								</div>
							</>
						</Accordion.Collapse>
					</Alert>
				))}
			</Accordion>
		</>
	);
}