import React, { Suspense, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Ability } from '@casl/ability';

import { AiOutlineCopyrightCircle } from "react-icons/ai";

import 'bootstrap/dist/css/bootstrap.min.css';

import Routes from "./Routes.js";
import './App.css';

import { NotificationContainer, NotificationManager } from './soajs/urac/components';
import { AppContext } from "./soajs/libs/contextLib";

import './soajs/libs/Interceptors.js';
import './soajs/urac/components/Notifications/notifications.css';
import './soajs/urac/urac.css';

import { LocalStorageService, OauthService, UracService, AbilitiesService } from "./soajs/urac/services";

import Navigation from "./Navigation.js"

const localStorageService = LocalStorageService.getService();
const oauthService = OauthService.getService();
const uracService = UracService.getService();
const abilitiesService = AbilitiesService.getService();
const ability = new Ability();

const Loader = () => (
	<div className="App">
		<div>loading...</div>
	</div>
);

function App() {
	const history = useHistory();

	const [isAuthenticating, setIsAuthenticating] = useState(true);
	const [isAuthenticated, userHasAuthenticated] = useState(false);
	const [firstName, setFirstName] = useState();

	useEffect(() => {
		async function onLoad() {
			const access_token = localStorageService.getAccessToken();
			if (access_token) {
				try {
					await uracService.getUser();
					await abilitiesService.createUserAbilities(ability);
					setFirstName(localStorageService.getFirstname());
					setIsAuthenticating(false);
					userHasAuthenticated(true);
				} catch (e) {
					setIsAuthenticating(false);
					NotificationManager.info(e.message);
				}
			} else {
				setIsAuthenticating(false);
			}
		}

		onLoad();
	}, []);

	async function handleLogout() {
		userHasAuthenticated(false);
		try {
			await oauthService.logout();
		} catch (e) {
			NotificationManager.error(e.message);
		}
		localStorageService.clearToken();
		localStorageService.clearUser();
		history.push("/sign-in");
	}

	return (
		!isAuthenticating && (
			<Suspense fallback={<Loader />}>
				<div className="pt-1" style={{ "backgroundColor": "#DE962E" }}></div>
				<div className="App">
					<Navigation
						isAuthenticated={isAuthenticated}
						firstName={firstName}
						handleLogout={handleLogout}
					/>
					<div className="bg-secondary pt-1"></div>
					<AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, setFirstName, ability }}>
						<NotificationContainer />
						<Routes />
					</AppContext.Provider>
				</div>
				<div className="bg-secondary p-1">

				</div>
				<div className="bg-dark p-2 text-white text-center">
					<small>
						<a className="text-white" href="https://www.soajs.org/">
							<AiOutlineCopyrightCircle /> copyright SOAJS</a> an <a className="text-white" href="https://lfaidata.foundation/projects/">LFAI project</a>
					</small>
				</div>
			</Suspense>
		)
	)
}

export default App;
