import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {
	Form,
	Jumbotron, Modal,
	Tab, Tabs
} from "react-bootstrap";


import ConfigureModalGroups from "./ConfigureModalGroups";
import {NotificationManager} from "../../../../soajs/urac/components";
import {ConsoleService} from "../../../../services";

const consoleService = ConsoleService.getService();

async function onLoad(setSettings, environment, isSubscribed) {
	try {
		const response = await consoleService.getEnvironmentSettings(environment.code);
		if (response && response.settings && isSubscribed) {
			setSettings(response.settings);
		} else {
			setSettings(null);
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function ConfigureModal({modalOpt, setModalOpt, environment}) {
	const {t} = useTranslation(["common", "soajs"]);
	const [settings, setSettings] = useState(null);
	
	const handleClose = () => setModalOpt({"show": false});
	
	async function reLoad() {
		await onLoad(setSettings, environment, true)
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		onLoad(setSettings, environment, isSubscribed).catch();
		return () => (isSubscribed = false);
	}, [environment]);
	
	return (
		<>
			{environment &&
			<Modal size="lg" show={modalOpt.show} onHide={handleClose} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{t("soajs:titles.environmentConfiguration")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					
					<Jumbotron className="p-4">
						<span
							className="font-weight-bold">{environment.code}</span> - {environment.deployer.type}
						<hr/>
						{environment.description}
					</Jumbotron>
					<Form onSubmit={(event) => {
						event.preventDefault();
					}}>
						<Tabs className="mb-3" defaultActiveKey="acl" id="config">
							<Tab eventKey="acl" title={t("soajs:fields.Groups")}>
								<ConfigureModalGroups
									settings={settings}
									setSettings={setSettings}
									handleAction={() => {
										reLoad();
									}}
									handleClose={handleClose}
									code={environment.code}
								/>
							</Tab>
						</Tabs>
					</Form>
				</Modal.Body>
			</Modal>
			}
		</>
	);
}