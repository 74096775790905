import {
	Form,
	Col
} from "react-bootstrap";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {LoaderButton} from "../../../../soajs/helpers";
import {NotificationManager} from "../../../../soajs/urac/components";
import {RepositoriesService} from "../../../../services";
import {useHistory} from "react-router-dom";
import {useAppContext} from "../../../../soajs/libs/contextLib";

const repositoriesService = RepositoriesService.getService();

const _GITHUB_ = "1";
const _BITBUCKET_ = "2";
const _BITBUCKET_ENT_ = "3";
const _PERSONAL_ = "1";
const _ORGANIZATION_ = "2";
const _PUBLIC_ = "1";
const _PRIVATE_ = "2";

export default function AddAccount({rootPath}) {
	const [fields, setFields] = useState({
		"label": "",
		"accountType": _PERSONAL_,
		"username": "",
		"email": "",
		"token": "",
		"consumerKey": "",
		"consumerSecret": ""
	});
	const {t} = useTranslation(["common", "soajs"]);
	const {ability} = useAppContext();
	const [fieldProvider, setFieldProvider] = useState(_GITHUB_);
	const [fieldAccessType, setFieldAccessType] = useState(_PUBLIC_);
	const [fieldDomain, setFieldDomain] = useState("github.com");
	
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	
	function onChangeProvider(event) {
		setFieldProvider(event.target.value)
		if (event.target.value === _GITHUB_) {
			setFieldDomain("github.com");
		} else if (event.target.value === _BITBUCKET_) {
			setFieldDomain("bitbucket.org");
		} else {
			setFieldDomain("");
		}
	}
	
	function onChangeDomain(event) {
		setFieldDomain(event.target.value)
	}
	
	function onChangeAccessType(event) {
		setFieldAccessType(event.target.value)
	}
	
	async function handleSubmit(event) {
		event.preventDefault();
		setIsLoading(true);
		let data = {
			"label": fields.label,
			"domain": fieldDomain,
			"username": fields.username,
			"email": fields.email,
			"token": fields.token,
			"oauthKey": fields.consumerKey,
			"oauthSecret": fields.consumerSecret
		};
		if (fields.accountType === _PERSONAL_) {
			data.type = "personal";
		} else if (fields.accountType === _ORGANIZATION_) {
			data.type = "organization";
		}
		
		if (fieldAccessType === _PUBLIC_) {
			data.access = "public";
		} else if (fieldAccessType === _PRIVATE_) {
			data.access = "private";
		}
		
		if (fieldProvider === _GITHUB_) {
			data.provider = "github";
		} else if (fieldProvider === _BITBUCKET_) {
			data.provider = "bitbucket";
			data.username = data.email;
		} else if (fieldProvider === _BITBUCKET_ENT_) {
			data.provider = "bitbucket_enterprise";
		}
		
		try {
			await repositoriesService.addGitAccount(data);
			NotificationManager.success(t("soajs:development.accounts.addMsg"));
			history.push(rootPath);
		} catch (e) {
			NotificationManager.error(e.message);
		}
		setIsLoading(false);
	}
	
	function validateForm() {
		let valid = true;
		if (fieldProvider === _GITHUB_ || fieldProvider === _BITBUCKET_ENT_) {
			valid = (valid && fields.username.length > 0);
		}
		if (fieldProvider === _BITBUCKET_) {
			valid = (valid && fields.email.length > 0);
		}
		if (fieldAccessType === _PRIVATE_) {
			valid = (valid && fields.token.length > 0);
			if (fieldProvider === _BITBUCKET_) {
				valid = (valid && fields.consumerSecret.length > 0);
				valid = (valid && fields.consumerKey.length > 0);
			}
		}
		return (
			fields.label.length > 0 &&
			fieldDomain.length > 0 &&
			valid
		);
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setFields({
			...fields,
			[event.target.id]: value
		});
	}
	
	return (
		<>
			<div className="clearfix mb-3">
				<h3 className="float-left">{t("soajs:development.accounts.addTitle")}</h3>
			</div>
			<Form onSubmit={handleSubmit}>
				<Form.Group>
					<Form.Row className="mb-2">
						<Form.Label column="sm" sm={3}>{t("soajs:development.accounts.provider")}</Form.Label>
						<Col sm={9}>
							<Form.Control
								as="select"
								id="provider"
								size="sm"
								value={fieldProvider}
								onChange={onChangeProvider}
							>
								<option value={_GITHUB_}>Github</option>
								<option value={_BITBUCKET_}>Bitbucket</option>
								<option value={_BITBUCKET_ENT_}>Bitbucket Enterprise</option>
							</Form.Control>
						</Col>
					</Form.Row>
					<Form.Row className="mb-2">
						<Form.Label column="sm" sm={3}>{t("soajs:development.accounts.accountType")}</Form.Label>
						<Col sm={9}>
							<Form.Control
								as="select"
								id="accountType"
								size="sm"
								value={fields.accountType}
								onChange={handleFieldChange}
							>
								<option value={_PERSONAL_}>Personal Account</option>
								<option value={_ORGANIZATION_}>Organization Account</option>
							</Form.Control>
						</Col>
					</Form.Row>
					<Form.Row className="mb-2">
						<Form.Label column="sm" sm={3}>{t("soajs:development.accounts.accessType")}</Form.Label>
						<Col sm={9}>
							<Form.Control
								as="select"
								id="accessType"
								size="sm"
								value={fieldAccessType}
								onChange={onChangeAccessType}
							>
								<option value={_PUBLIC_}>Only Public Repositories</option>
								<option value={_PRIVATE_}>Both Public and Private Repositories</option>
							</Form.Control>
						</Col>
					</Form.Row>
					<hr/>
					
					<Form.Row className="mb-2">
						<Form.Label column="sm" sm={3}>{t("soajs:development.accounts.label")}</Form.Label>
						<Col sm={9}>
							<Form.Control
								id="label"
								size="sm"
								value={fields.label}
								onChange={handleFieldChange}
							/>
						</Col>
					</Form.Row>
					<Form.Row className="mb-2">
						<Form.Label column="sm" sm={3}>{t("soajs:development.accounts.domain")}</Form.Label>
						<Col sm={9}>
							<Form.Control
								disabled={(fieldProvider === _BITBUCKET_ || fieldProvider === _GITHUB_)}
								id="domain"
								size="sm"
								value={fieldDomain}
								onChange={onChangeDomain}
							/>
						</Col>
					</Form.Row>
					
					<hr/>
					{(fieldProvider === _GITHUB_ || fieldProvider === _BITBUCKET_ENT_) &&
					<Form.Row className="mb-2">
						<Form.Label column="sm" sm={3}>{t("soajs:development.accounts.username")}</Form.Label>
						<Col sm={9}>
							<Form.Control
								id="username"
								size="sm"
								value={fields.username}
								onChange={handleFieldChange}
							/>
						</Col>
					</Form.Row>}
					
					{fieldProvider === _BITBUCKET_ &&
					<Form.Row className="mb-2">
						<Form.Label column="sm" sm={3}>{t("soajs:development.accounts.email")}</Form.Label>
						<Col sm={9}>
							<Form.Control
								id="email"
								size="sm"
								type="email"
								value={fields.email}
								onChange={handleFieldChange}
							/>
						</Col>
					</Form.Row>}
					
					{fieldAccessType === _PRIVATE_ &&
					<Form.Row className="mb-2">
						<Form.Label column="sm" sm={3}>{t("soajs:development.accounts.token")}</Form.Label>
						<Col sm={9}>
							<Form.Control
								id="token"
								size="sm"
								value={fields.token}
								onChange={handleFieldChange}
							/>
						</Col>
					</Form.Row>}
					
					{fieldProvider === _BITBUCKET_ && fieldAccessType === _PRIVATE_ &&
					<Form.Row className="mb-2">
						<Form.Label column="sm" sm={3}>{t("soajs:development.accounts.consumerKey")}</Form.Label>
						<Col sm={9}>
							<Form.Control
								id="consumerKey"
								size="sm"
								value={fields.consumerKey}
								onChange={handleFieldChange}
							/>
						</Col>
					</Form.Row>}
					
					{fieldProvider === _BITBUCKET_ && fieldAccessType === _PRIVATE_ &&
					<Form.Row className="mb-2">
						<Form.Label column="sm" sm={3}>{t("soajs:development.accounts.consumerSecret")}</Form.Label>
						<Col sm={9}>
							<Form.Control
								id="consumerSecret"
								size="sm"
								value={fields.consumerSecret}
								onChange={handleFieldChange}
							/>
						</Col>
					</Form.Row>}
					
					<hr/>
				</Form.Group>
				
				{ability.can('repo_account', 'add') &&
				<LoaderButton
					className="mb-3"
					block
					type="submit"
					variant="success"
					isLoading={isLoading}
					disabled={!validateForm()}
				>
					{t("soajs:buttons.Add")}
				</LoaderButton>}
				
			</Form>
		</>
	);
}