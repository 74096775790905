import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {useParams} from "react-router-dom";

import Routes from "./Routes";
import Navigation from "./Navigation";
import {NotificationManager} from "../../soajs/urac/components";
import {ConsoleService} from "../../services";

const consoleService = ConsoleService.getService();

async function reloadClusters(env, setClusters, isSubscribed) {
	try {
		if (env) {
			const _clusters = await consoleService.getResourceRegistry(env, "cluster");
			if (isSubscribed && _clusters) {
				setClusters(_clusters);
			}
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function Environments() {
	const [environments, setEnvironments] = useState([]);
	const [environment, setEnvironment] = useState(null);
	const [clusters, setClusters] = useState([]);
	const {env} = useParams();
	
	async function reload() {
		await reloadClusters(env, setClusters, true);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				await reloadClusters(env, setClusters, isSubscribed);
				const _environments = await consoleService.getEnvironments();
				if (_environments && isSubscribed) {
					setEnvironments(_environments);
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad().catch();
		return () => (isSubscribed = false);
	}, [env]);
	
	return (
		<>
			<Navigation
				environment={environment}
				environments={environments}
				setEnvironment={setEnvironment}
			/>
			<div className="bg-dark pt-1"></div>
			<div className="main-area">
				<Container className="mt-3 mb-3">
					<Routes
						environment={environment}
						environments={environments}
						clusters={clusters}
						reloadClusters={reload}
					/>
				</Container>
			</div>
		</>
	);
}