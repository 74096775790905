import {Card, Alert, Form, ListGroup, ListGroupItem} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {NotificationManager} from "../../../../soajs/urac/components";
import {ConsoleService, InfraService} from "../../../../services";
import {LoaderButton} from "../../../../soajs/helpers";
import {useAppContext} from "../../../../soajs/libs/contextLib";
import {useHistory} from "react-router-dom";

const infraService = InfraService.getService();
const consoleService = ConsoleService.getService();

export default function Add() {
	const {ability} = useAppContext();
	const {t} = useTranslation(["common", "soajs"]);
	const [isLoading, setIsLoading] = useState(false);
	const [fields, setFields] = useState({
		"code": "",
		"type": "manual",
		"description": "",
		"ip": "127.0.0.1",
		"port": "10000",
		"cluster": null,
		"namespace": ""
	});
	const [accounts, setAccounts] = useState([]);
	const history = useHistory();
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		setFields({
			...fields,
			[event.target.id]: value
		});
	}
	
	async function handleSubmit(event) {
		event.preventDefault();
		setIsLoading(true);
		try {
			let data = {
				"code": fields.code,
				"description": fields.description,
				"settings": {
					"type": fields.type
				}
			};
			if (fields.type === "manual") {
				data.settings.port = parseInt(fields.port);
			}
			if (fields.type === "kubernetes") {
				data.settings.namespace = fields.namespace;
				data.settings.id = accounts[fields.cluster]._id;
			}
			await consoleService.addEnvironment(data);
			history.push("/environments/manage/" + fields.code.toUpperCase() + "/settings");
		} catch (e) {
			NotificationManager.error(e.message);
		}
		setIsLoading(false);
	}
	
	function validateForm() {
		let valid = (fields.code.length > 0 &&
			fields.type.length > 4 &&
			fields.description.length > 0);
		if (fields.type === "manual") {
			
			valid = (valid && fields.ip.length > 0 && fields.port > 0);
		}
		if (fields.type === "kubernetes") {
			valid = (valid && fields.cluster >= 0 && fields.namespace.length > 0);
		}
		return (
			valid
		);
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		async function onLoad() {
			try {
				const response = await infraService.getInfraAccounts();
				if (response && isSubscribed) {
					setAccounts(response);
				}
			} catch (e) {
				NotificationManager.error(e.message);
			}
		}
		
		onLoad().catch();
		return () => (isSubscribed = false);
	}, []);
	
	return (
		<>
			<h5>Add an environment</h5>
			<Card bg="light" style={{minWidth: '22rem'}}>
				<Card.Body>
					<Form onSubmit={handleSubmit}>
						<Form.Row className="mb-2">
							<Form.Label>Environment Type</Form.Label>
							<Form.Control as="select" id="type"
							              defaultValue={fields.type}
							              onChange={handleFieldChange}
							>
								<option value="manual">Manual deployment</option>
								<option value="kubernetes">Containerized development</option>
							</Form.Control>
						</Form.Row>
						<Alert variant="info" className="small mt-3 mb-3">
							{fields.type === "manual" &&
							<>Manual: Build, manage, operate and test APIs on a local machine with complete awareness
								and
								interconnect mesh among microservices</>
							}
							{fields.type === "kubernetes" &&
							<>Container: Build, manage, operate and test APIs on a containerized cluster as
								infrastructure enabling
								kubernetes deployment & orchestration.</>
							}
						</Alert>
						<hr/>
						<Form.Row className="mb-2">
							<Form.Label>{t("soajs:fields.Code")}</Form.Label>
							<Form.Control
								id="code"
								value={fields.code}
								onChange={handleFieldChange}
							/>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label>{t("soajs:fields.Description")}</Form.Label>
							<Form.Control
								id="description"
								as="textarea"
								rows={3}
								value={fields.description}
								onChange={handleFieldChange}
							/>
						</Form.Row>
						<hr/>
						{fields.type === "manual" &&
						<>
							<Form.Row className="mb-2">
								<Form.Label>Machine IP Address</Form.Label>
								<Form.Control
									id="ip"
									value={fields.ip}
									disabled={true}
								/>
							</Form.Row>
							<Form.Row className="mb-2">
								<Form.Label>Gateway port</Form.Label>
								<Form.Control
									id="port"
									type="number"
									value={fields.port}
									onChange={handleFieldChange}
								/>
							</Form.Row>
						</>
						}
						{fields.type === "kubernetes" &&
						<Form.Row className="mb-2">
							<Form.Label>Select a kubernetes cluster</Form.Label>
							<Form.Control as="select" id="cluster"
							              defaultValue={fields.cluster}
							              onChange={handleFieldChange}
							>
								<option value={null}>--- Select an infrastructure ---</option>
								{accounts.map((acc, index) => (
									<option key={index} value={index}>{acc.label}</option>
								))}
							</Form.Control>
						</Form.Row>
						}
						{fields.type === "kubernetes" && fields.cluster !== null &&
						<Alert variant="info" className="small mt-3 mb-3">
							<ListGroup variant="flush">
								<ListGroupItem><span
									className="font-weight-bold">{t("soajs:fields.MasterNode")}:</span> {accounts[fields.cluster].configuration.url}
								</ListGroupItem>
								<ListGroupItem><span
									className="font-weight-bold">{t("soajs:fields.Port")}:</span> {accounts[fields.cluster].configuration.port}
								</ListGroupItem>
								{accounts[fields.cluster].deployments && accounts[fields.cluster].deployments.length > 0 &&
								<ListGroupItem><span
									className="font-weight-bold">{t("soajs:fields.Environments")}:</span>
									{accounts[fields.cluster].deployments.map((env, index) => (
										<span key={index}>
											[ {env.env} ]
										</span>
									))}
								</ListGroupItem>
								}
							</ListGroup>
						</Alert>
						}
						{fields.type === "kubernetes" && fields.cluster &&
						
						<Form.Row className="mb-2">
							<Form.Label>{t("soajs:fields.Namespace")}</Form.Label>
							<Form.Control
								id="namespace"
								value={fields.namespace}
								onChange={handleFieldChange}
							/>
						</Form.Row>
						}
						<hr className="mt-4"/>
						{ability.can('env', 'add') &&
						<LoaderButton
							block
							size="lg"
							type="submit"
							isLoading={isLoading}
							disabled={!validateForm()}
						>
							{t("soajs:buttons.Add")}
						</LoaderButton>
						}
					</Form>
				</Card.Body>
			</Card>
		</>
	);
}