import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
	Button,
	Accordion,
	Card,
	useAccordionToggle,
	ListGroup,
	ListGroupItem,
	CardDeck,
	Jumbotron, Col, Row
} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {GoDiffAdded} from "react-icons/go";
import {FcCollapse, FcExpand} from "react-icons/fc";
import {AiFillGithub} from "react-icons/ai";
import {DiBitbucket} from "react-icons/di";
import {BiGitBranch} from "react-icons/bi";

import {NotificationManager} from "../../../../soajs/urac/components";
import {RepositoriesService} from "../../../../services";
import {useAppContext} from "../../../../soajs/libs/contextLib";

import UpgradeModal from "./UpgradeModal";
import DeleteModal from "./DeleteModal";

import DeleteButton from "../../../buttons/Delete";
import SynchroniseButton from "../../../buttons/Synchronise";
import UpgradeButton from "../../../buttons/Upgrade";

const repositoriesService = RepositoriesService.getService();

function Organizations({metadata, t}) {
	let show = false;
	if (metadata && metadata.organizations && Array.isArray(metadata.organizations) && metadata.organizations.length > 0) {
		show = true;
	}
	return (
		show &&
		<Jumbotron className="p-3 mt-3 bg-light">
			<h6 className="mb-4 font-weight-bold">{t("soajs:development.accounts.organizations")}</h6>
			<CardDeck>
				{metadata.organizations.map((_O, _I) => (
					<Card key={_I} className="text-center text-white" bg="secondary"
					      style={{"minWidth": '21rem', "maxWidth": '21rem'}}>
						<Card.Body className="p-2">{_O}</Card.Body>
					</Card>))}
			</CardDeck>
		</Jumbotron>
	);
}

function Provider({provider}) {
	let icon = (<BiGitBranch/>);
	if (provider === "github") {
		icon = (<AiFillGithub/>);
	} else if (provider === "bitbucket_enterprise") {
		icon = (<DiBitbucket/>);
	} else if (provider === "bitbucket") {
		icon = (<DiBitbucket/>);
	}
	return (icon);
}

function CustomToggle({children, eventKey, handleClick}) {
	const decoratedOnClick = useAccordionToggle(eventKey, () => {
		handleClick();
	});
	return (
		<div className="float-left" type="button" onClick={decoratedOnClick}>
			{children}
		</div>
	);
}

async function onLoad(setFields, isSubscribed) {
	try {
		const gitAccounts = await repositoriesService.getGitAccounts();
		if (isSubscribed) {
			setFields(gitAccounts);
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function Accounts({rootPath}) {
	const {t} = useTranslation(["common", "soajs"]);
	const [fields, setFields] = useState([]);
	const [activeKey, setActiveKey] = useState(1);
	const {ability} = useAppContext();
	const [modalFields, setModalFields] = useState({
		"id": "",
		"provider": "",
		"label": "",
		"username": "",
		"token": "",
		"consumerKey": "",
		"consumerSecret": ""
	});
	
	const [deleteModalOpt, setDeleteModalOpt] = useState({"show": false, opts: null});
	const handleDeleteShow = (opt) => setDeleteModalOpt({"show": true, opts: opt});
	
	const [modalOpt, setModalOpt] = useState({"show": false, "item": null});
	const handleModalShow = (item) => {
		setModalFields({
			"id": item._id,
			"provider": item.provider,
			"label": item.label,
			"username": item.owner,
			"token": "",
			"consumerKey": "",
			"consumerSecret": ""
		})
		setModalOpt({"show": true, "item": item})
	};
	
	async function syncAccount(id) {
		try {
			await repositoriesService.syncGitAccount(id);
			NotificationManager.success(t("soajs:development.accounts.syncMsg"));
			await onLoad(setFields, true)
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function reload() {
		await onLoad(setFields, true)
	}
	
	useEffect(() => {
		let isSubscribed = true;
		onLoad(setFields, isSubscribed);
		return () => (isSubscribed = false);
	}, []);
	
	return (
		<>
			<DeleteModal
				modalOpt={deleteModalOpt}
				setModalOpt={setDeleteModalOpt}
				handleAction={reload}
			/>
			<UpgradeModal
				modalOpt={modalOpt}
				setModalOpt={setModalOpt}
				setModalFields={setModalFields}
				modalFields={modalFields}
				handleAfterSubmit={reload}
			/>
			
			<div className="clearfix float-none mb-3">
				<h3 className="float-left">{t("soajs:development.accounts.title")}</h3>
				
				{ability.can('repo_account', 'add') &&
				<Link to={rootPath + '/add'}>
					<Button variant="success" className="float-right" size="sm">
						<GoDiffAdded/> {t("soajs:buttons.Add")}
					</Button>
				</Link>}
			
			</div>
			<Accordion activeKey={activeKey}>
				{fields.map((item, index) => (
					<Card key={index + 1}>
						<Card.Header>
							<Row>
								<Col xs={12} md={8}>
							<CustomToggle
								as={Card.Header}
								eventKey={index + 1}
								handleClick={() => {
									if (activeKey === (index + 1)) {
										setActiveKey(null);
									} else {
										setActiveKey(index + 1);
									}
								}}
							>
								{activeKey === (index + 1) ? <FcExpand className="mr-1"/> :
									<FcCollapse className="mr-1"/>} <Provider
								provider={item.provider}/> <span
								className="font-weight-bold">{item.label}</span> [{item.owner}]
							</CustomToggle>
								</Col>
								<Col xs={12} md={4}>
							{ability.can('repo_account', 'delete') &&
							<DeleteButton
								className="float-right ml-1"
								onClick={() => {
									handleDeleteShow({"id": item._id});
								}}
							/>
							}
							
							{ability.can('repo_account', 'synchronize') &&
							<SynchroniseButton
								className="float-right ml-1"
								onClick={() => {
									syncAccount(item._id)
								}}
							/>
							}
							
							{ability.can('repo_account', 'upgrade') &&  item.access === "public" &&
							<UpgradeButton
								className="float-right ml-1"
								onClick={() => {
									handleModalShow(item)
								}}
							/>
							}
								</Col>
							</Row>
						</Card.Header>
						<Accordion.Collapse eventKey={index + 1}>
							<Card.Body>
								<ListGroup variant="flush">
									<ListGroupItem><span
										className="font-weight-bold">{t("soajs:development.accounts.accountType")}:</span> {item.accountType}
									</ListGroupItem>
									<ListGroupItem><span
										className="font-weight-bold">{t("soajs:development.accounts.domain")}:</span> {item.domain}
									</ListGroupItem>
									<ListGroupItem><span
										className="font-weight-bold">{t("soajs:development.accounts.accessType")}:</span> {item.access}
									</ListGroupItem>
								</ListGroup>
								<Organizations metadata={item.metadata} t={t}/>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				))}
				{fields.length === 0 ? (
					<span>{t("soajs:development.accounts.zeroMsg")}</span>
				) : null}
			</Accordion>
		</>
	);
}