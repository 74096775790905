import axios from "axios";
import Config from "../config.js";
import {response_catch, response_then} from "./commonResponse";

const SoamonitorService = (function () {
	let _service = null;
	
	function _getService() {
		if (!_service) {
			_service = this;
			return _service;
		}
		return _service;
	}
	
	function _getMonitorItems(criteria, env) {
		let url = "/soamonitor/monitor/items";
		let params = {"params": criteria};
		if (env.toLowerCase() !== Config.consoleEnvironment) {
			params.params.proxyRoute = url;
			url = "/soajs/proxy";
			params.headers = {
				"__env": env.toLowerCase()
			};
		}
		return axios.get(url, params)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getMonitorAnalytics(type, criteria, env) {
		let url = "/soamonitor/monitor/analytics/" + type;
		let params = {"params": criteria};
		if (env.toLowerCase() !== Config.consoleEnvironment) {
			params.params.proxyRoute = url;
			url = "/soajs/proxy";
			params.headers = {
				"__env": env.toLowerCase()
			};
		}
		return axios.get(url, params)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	return {
		getService: _getService,
		getMonitorItems: _getMonitorItems,
		getMonitorAnalytics: _getMonitorAnalytics
	}
})();
export default SoamonitorService;