
import Template from "../Template/Template";


export default function SoajsFrontend() {
	
	return (
		<Template
			type={"static"}
			subType={"soajs"}
		/>
	);
}