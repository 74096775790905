import axios from "axios";
import {response_catch, response_then} from "./commonResponse";

const MarketplaceService = (function () {
	let _service = null;
	
	function _getService() {
		if (!_service) {
			_service = this;
			return _service;
		}
		return _service;
	}
	
	function _getItem(name, type) {
		return axios.get("/marketplace/item/type?name=" + name + "&type=" + type)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getItems(criteria) {
		let url = "/marketplace/items";
		if (criteria) {
			if (criteria.subType && criteria.subType === "soajs") {
				url = "/marketplace/soajs/items";
			}
			if (criteria.type) {
				url = url + "?type=" + criteria.type;
			}
			if (criteria.limit) {
				url = url + "&limit=" + criteria.limit;
			}
			if (criteria.start) {
				url = url + "&start=" + criteria.start;
			}
			if (criteria.keywords) {
				url = url + "&keywords=" + criteria.keywords;
			}
		}
		return axios.get(url)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getRecipes(criteria) {
		let url = "/marketplace/recipes";
		if (criteria) {
			url = url + "?count=true";
			if (criteria.limit) {
				url = url + "&limit=" + criteria.limit;
			}
			if (criteria.start) {
				url = url + "&start=" + criteria.start;
			}
			if (criteria.keywords) {
				url = url + "&keywords=" + criteria.keywords;
			}
		}
		return axios.get(url)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getRecipesByIds(ids) {
		
		return axios.get("/marketplace/recipes/ids", {"params": {"ids": ids}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateSettingsGroups(fields) {
		let url = "/marketplace/item/acl";
		if (fields.subType === "soajs") {
			url = "/marketplace/soajs/item/acl";
		}
		let data = {
			"id": fields.id,
			"type": fields.type,
			"groups": fields.groups
		};
		return axios.put(url, data)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateSettingsRecipes(fields) {
		let url = "/marketplace/item/recipes";
		if (fields.subType === "soajs") {
			url = "/marketplace/soajs/item/recipes";
		}
		let data = {
			"id": fields.id,
			"recipes": fields.recipes
		};
		return axios.put(url, data)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateSettingsEnvironments(fields) {
		let url = "/marketplace/item/environments";
		if (fields.subType === "soajs") {
			url = "/marketplace/soajs/item/environments";
		}
		let data = {
			"id": fields.id,
			"type": fields.type,
			"environments": fields.environments
		};
		return axios.put(url, data)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _itemMaintenance(fields) {
		let url = "/marketplace/item/maintenance";
		let qs = {
			"name": fields.name,
			"env": fields.env,
			"type": fields.type,
			"version": fields.version,
			"operation": fields.operation
		};
		let data = {
			"port": fields.port
		};
		return axios.put(url, data, {"params": qs})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addRecipe(fields) {
		let url = "/marketplace/recipe";
		let data = {
			"catalog": fields
		};
		return axios.post(url, data)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _updateRecipe(id, fields) {
		let url = "/marketplace/recipe?id=" + id;
		let data = {
			"catalog": fields
		};
		return axios.put(url, data)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _redeploy(fields) {
		let url = "/marketplace/item/deploy/redeploy";
		let options = {
			"params": {
				"name": fields.name,
				"type": fields.type,
				"env": fields.env,
				"version": fields.version
			}
		};
		
		let data = {};
		if (fields.imageTag) {
			data.image = {
				"tag": fields.imageTag
			};
		}
		if (fields.branch) {
			data.src = {
				"from": {
					"branch": fields.branch
				}
			};
			if (fields.commit) {
				data.src.from.commit = fields.commit;
			}
		} else if (fields.tag) {
			data.src = {
				"from": {
					"tag": fields.tag
				}
			};
		}
		return axios.put(url, data, options)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteConfigure() {
	
	}
	
	function _configure(params, data) {
		let url = "/marketplace/item/deploy/configure";
		let options = {
			"params": params
		};
		
		return axios.put(url, data, options)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _build(params, data) {
		let url = "/marketplace/item/deploy/build";
		let options = {
			"params": params
		};
		
		return axios.put(url, data, options)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addResource(fields) {
		return axios.put("/marketplace/item/resource", {"item": fields})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteItem(fields) {
		return axios.delete("/marketplace/item", {"params": {"type": fields.type, "name": fields.name}})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	return {
		getService: _getService,
		getItems: _getItems,
		getItem: _getItem,
		getRecipes: _getRecipes,
		getRecipesByIds: _getRecipesByIds,
		
		updateSettingsGroups: _updateSettingsGroups,
		updateSettingsRecipes: _updateSettingsRecipes,
		updateSettingsEnvironments: _updateSettingsEnvironments,
		
		itemMaintenance: _itemMaintenance,
		
		addRecipe: _addRecipe,
		updateRecipe: _updateRecipe,
		
		redeploy: _redeploy,
		deleteConfigure: _deleteConfigure,
		configure: _configure,
		build: _build,
		
		addResource: _addResource,
		deleteItem: _deleteItem
	}
})();
export default MarketplaceService;