import {Pagination} from "react-bootstrap";
import React from "react";

function AutoPagination({currentPage, totalItems, maxSize, itemsPerPage, onClick, className}) {
	className = className || "";
	currentPage = currentPage || 1
	totalItems = totalItems || 1;
	itemsPerPage = itemsPerPage || 1;
	maxSize = maxSize || 10;
	let numberOfPages = 1;
	if (totalItems > itemsPerPage) {
		numberOfPages = Math.ceil(totalItems / itemsPerPage);
	}
	if (currentPage > numberOfPages) {
		currentPage = numberOfPages;
	}
	let pages = [];
	if (numberOfPages < maxSize) {
		maxSize = numberOfPages;
	}
	let index = currentPage - Math.floor(maxSize / 2);
	if (index + maxSize > numberOfPages) {
		index = numberOfPages - maxSize;
	}
	if (index < 0) {
		index = 0;
	}
	for (let i = 1; i <= maxSize; i++) {
		pages.push((i + index));
	}
	
	function clicked(p) {
		if (onClick) {
			onClick(p);
		}
	}
	
	return (
		<Pagination size="sm" className={className}>
			<Pagination.First onClick={(e) => {
				e.preventDefault();
				clicked(1)
			}} disabled={currentPage === 1}/>
			<Pagination.Prev onClick={(e) => {
				e.preventDefault();
				clicked(currentPage - 1)
			}} disabled={currentPage === 1}/>
			{pages[0] > 1 &&
			<Pagination.Ellipsis disabled={true}/>}
			{pages.map((_o, _i) => (
				<Pagination.Item onClick={(e) => {
					e.preventDefault();
					clicked(_o)
				}} active={currentPage === _o} key={_i}>{_o}</Pagination.Item>
			))}
			{pages[maxSize - 1] < numberOfPages &&
			<Pagination.Ellipsis disabled={true}/>}
			<Pagination.Next onClick={(e) => {
				e.preventDefault();
				clicked(currentPage + 1)
			}} disabled={currentPage === numberOfPages}/>
			<Pagination.Last onClick={(e) => {
				e.preventDefault();
				clicked(numberOfPages)
			}} disabled={currentPage === numberOfPages}/>
		</Pagination>
	);
}

export default AutoPagination;