import {Alert, Button, Form, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {NotificationManager} from "../../../../../soajs/urac/components";
import {MultitenantService} from "../../../../../services";
import deepSet from "../../../../../soajs/libs/deepSetLib";

const multitenantService = MultitenantService.getService();
const initFields = {
	"id": "",
	"type": 2,
	"oauthType": "urac",
	"disabled": 0,
	"pin": {},
	"secret": ""
};
export default function OauthModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, item: null});
	
	const [fields, setFields] = useState(initFields);
	
	function validateForm() {
		return (
			fields.disabled === 1 ||
			(fields.disabled === 0 && fields.secret.length > 0)
		);
	}
	
	function handleFieldChange(event) {
		let value = event.target.value;
		if (event.target.hasOwnProperty("checked")) {
			value = event.target.checked;
		}
		if (event.target.type === 'number' || event.target.id === "type") {
			value = parseInt(value);
		}
		if (event.target.id === "disabled") {
			if (value) {
				value = 0;
			} else {
				value = 1;
			}
		}
		let obj = fields;
		deepSet(obj, event.target.id, value);
		setFields({...obj});
	}
	
	async function handleSubmit() {
		try {
			let data = {}
			if (fields.disabled === 1) {
				data = {
					"id": fields.id,
					"oauthType": "off",
					"secret": fields.secret
				}
			} else {
				data = {
					"id": fields.id,
					"type": fields.type,
					"oauthType": fields.oauthType,
					"secret": fields.secret
				};
			}
			await multitenantService.updateTenantOauth(data);
			NotificationManager.success(t("soajs:messages.tenantOauth"));
			handleAction();
			handleClose();
		} catch
			(e) {
			NotificationManager.error(e.message);
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		if (isSubscribed && modalOpt && modalOpt.item) {
			setFields(Object.assign({}, initFields, modalOpt.item));
		}
		return () => (isSubscribed = false);
	}, [modalOpt]);
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.tenantOauth")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant="warning">
					<p>Be advised that when turning ON and OFF or modifying the oAuth Security of a tenant, all the keys
						configuration for all the applications belonging to this tenant will be modified based on the
						option you select. <a
							href="https://soajsorg.atlassian.net/wiki/spaces/DSBRD/pages/61979922/Multitenancy#Multitenancy-oauth"
						>Learn More</a></p>
				</Alert>
				<hr/>
				{modalOpt.item &&
				<Form>
					<Form.Group>
						<Form.Check
							id="disabled"
							type="switch"
							label="Turn ON oAuth"
							checked={(fields.disabled === 0)}
							onChange={handleFieldChange}
						/>
					</Form.Group>
					{fields.disabled === 0 &&
					<>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Type")}</Form.Label>
							<Form.Control
								as="select"
								id="type"
								value={fields.type}
								onChange={handleFieldChange}
							>
								<option value="2">oAuth 2.0</option>
								<option value="0">oAuth 0</option>
							</Form.Control>
						</Form.Group>
						<Form.Group>
							<Form.Label>{t("soajs:fields.Secret")}</Form.Label>
							<Form.Control
								id="secret"
								size="sm"
								value={fields.secret}
								onChange={handleFieldChange}
							/>
						</Form.Group>
						{fields.type === 2 &&
						<>
							<Form.Group>
								<Form.Label>{t("soajs:fields.OauthType")}</Form.Label>
								<Form.Control
									as="select"
									id="oauthType"
									value={fields.oauthType}
									onChange={handleFieldChange}
								>
									<option value="urac">URAC - Complete user management</option>
									<option value="oauth">Integration - Basic user management</option>
								</Form.Control>
							</Form.Group>
						</>}
					</>}
				</Form>}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				
				{modalOpt.item &&
				<Button
					variant="success"
					onClick={handleSubmit}
					disabled={!validateForm()}
				>
					{t("soajs:buttons.Update")}
				</Button>}
			</Modal.Footer>
		</Modal>
	);
}