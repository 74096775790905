import {Alert} from "react-bootstrap";
import React from "react";

export default function Rbac({environment}) {
	
	return (
		
		<Alert variant="info" className="mt-4 mb-4">
			RBAC: coming soon
		</Alert>
	
	);
}