import {Alert, Badge, Button, Col, Row} from "react-bootstrap";

import {AiOutlineInfoCircle} from "react-icons/ai";
import {FaWindowMaximize} from "react-icons/fa";
import {GiGears} from "react-icons/gi";
import {VscGraph} from "react-icons/vsc";
import React, {useState} from "react";

import JsonModal from "./modals/JsonModal";
import LogModal from "./modals/LogModal";
import ExecModal from "./modals/ExecModal";
import MetricsModal from "./modals/MetricsModal";
import {useAppContext} from "../../../../../../soajs/libs/contextLib";
import EditButton from "../../../../../buttons/Edit";
import _ from "lodash";
import DeleteButton from "../../../../../buttons/Delete";
import DeleteModal from "./modals/DeleteModal";
import EditModal from "./modals/EditModal";

function getLastValidStatus(status) {
	let lastValidStatus = {
		"t": null,
		"l": "-"
	};
	//if (status.phase === "Running") {
	if (status.conditions && Array.isArray(status.conditions)) {
		for (let i = 0; i < status.conditions.length; i++) {
			let oneCond = status.conditions[i];
			if (oneCond.status === "True") {
				if (lastValidStatus.t) {
					let d_set = new Date(lastValidStatus.t);
					let d_new = new Date(oneCond.lastTransitionTime);
					if (d_new > d_set) {
						lastValidStatus = {
							"t": oneCond.lastTransitionTime,
							"l": oneCond.type
						};
					}
				} else {
					lastValidStatus = {
						"t": oneCond.lastTransitionTime,
						"l": oneCond.type
					};
				}
			}
		}
	}
	//}
	return lastValidStatus.l;
}

function getStatusVariant(status) {
	if (status === "Running") {
		return "success";
	} else if (status === "Succeeded") {
		return "info";
	} else {
		return "danger"
	}
}

export default function Pods({items, env, deleteItem, editItem}) {
	const {ability} = useAppContext();
	const [modalOpt, setModalOpt] = useState({"show": false, "json": {}, "mode": "view", "title": ""});
	const modalOptShow = (json, title) => setModalOpt({
		"show": true,
		"json": json,
		"mode": "view",
		"title": title || ""
	});
	const [logModalOpt, setLogModalOpt] = useState({"show": false, "data": {}, "title": ""});
	const logModalOptShow = (data, title) => setLogModalOpt({
		"show": true,
		"data": data,
		"title": title || ""
	});
	const [execModalOpt, setExecModalOpt] = useState({"show": false, "data": {}, "title": ""});
	const execModalOptShow = (data, title) => setExecModalOpt({
		"show": true,
		"data": data,
		"title": title || ""
	});
	const [metricsModalOpt, setMetricsModalOpt] = useState({"show": false, "data": "", "title": ""});
	const metricsModalOptShow = (data, title) => setMetricsModalOpt({
		"show": true,
		"data": data,
		"title": title || ""
	});
	const [modalDeleteOpt, setModalDeleteOpt] = useState({"show": false, "item": {}});
	const modalDeleteOptShow = (item) => setModalDeleteOpt({"show": true, "item": item});
	
	const [modalEditOpt, setModalEditOpt] = useState({"show": false, "item": {}, "json": null});
	const modalEditOptShow = (item, json) => setModalEditOpt({"show": true, "item": item, "json": json});
	
	return (
		<>
			<JsonModal
				modalOpt={modalOpt}
				setModalOpt={setModalOpt}
			/>
			<LogModal
				modalOpt={logModalOpt}
				setModalOpt={setLogModalOpt}
			/>
			<ExecModal
				modalOpt={execModalOpt}
				setModalOpt={setExecModalOpt}
			/>
			<MetricsModal
				modalOpt={metricsModalOpt}
				setModalOpt={setMetricsModalOpt}
			/>
			
			{deleteItem &&
			<DeleteModal
				modalOpt={modalDeleteOpt}
				setModalOpt={setModalDeleteOpt}
				handleAction={
					(name) => {
						deleteItem("Pod", name);
					}
				}
			/>}
			{editItem &&
			<EditModal
				modalOpt={modalEditOpt}
				setModalOpt={setModalEditOpt}
				handleAction={
					(name, body) => {
						body.kind = "Pod";
						editItem(body.kind, body.metadata.name, body)
					}
				}
			/>}
			
			{items.map((rec, i) => (
				<Alert key={i} variant="info" className="p-1 m-1">
					<Row>
						<Col xs={"auto"}>
							<Badge variant="primary" className="float-left mr-1 small mt-1">pod</Badge>
							<span className="small">{rec.metadata.name}</span>
						</Col>
						<Col xs={12} lg={4}>
							<span className="small mt-1">{rec.metadata.creationTimestamp}</span>
							<Badge variant={getStatusVariant(rec.status.phase)}
							       className="small mt-1 ml-1">{rec.status.phase}</Badge>
							<Badge variant="light"
							       className=" small mt-1 ml-1">{getLastValidStatus(rec.status)}</Badge>
						</Col>
					</Row>
					<Row className="mt-1">
						<Col>
							<Button size="sm" variant="light" className="float-right pt-0 pb-1 pl-1 pr-1">
								<AiOutlineInfoCircle
									className="text-primary"
									onClick={() => {
										modalOptShow(rec, rec.metadata.name);
									}}
								/>
							</Button>
							
							{ability.can('infra_pod', 'log') &&
							<Button size="sm" variant="light" className="float-right pt-0 pb-1 pl-1 pr-1 mr-2">
								<FaWindowMaximize
									className="text-secondary"
									onClick={() => {
										logModalOptShow({"env": env, "name": rec.metadata.name}, rec.metadata.name);
									}}
								/>
							</Button>}
							
							{ability.can('infra_pod', 'exec') &&
							<Button size="sm" variant="light" className="float-right pt-0 pb-1 pl-1 pr-1 mr-2">
								<GiGears
									className="text-danger"
									onClick={() => {
										execModalOptShow({"env": env, "name": rec.metadata.name}, rec.metadata.name);
									}}
								/>
							</Button>}
							
							<Button size="sm" variant="light" className="float-right pt-0 pb-1 pl-1 pr-1 mr-2 ml-2">
								<VscGraph
									className="text-info"
									onClick={() => {
										metricsModalOptShow("", rec.metadata.name);
									}}
								/>
							</Button>
							{editItem &&
							<EditButton
								className="float-right pt-0 pb-1 pl-1 pr-1 ml-2"
								onClick={() => {
									modalEditOptShow({
										"title": rec.metadata.name,
										"name": rec.metadata.name
									}, _.merge({}, rec));
								}}
							/>
							}
							{deleteItem &&
							<DeleteButton
								className="float-right pt-0 pb-1 pl-1 pr-1 ml-2"
								onClick={() => {
									modalDeleteOptShow({"title": rec.metadata.name, "name": rec.metadata.name});
								}}
							/>
							}
						</Col>
					</Row>
				</Alert>
			))}
		</>
	);
}