import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import React from "react";
import {LinkContainer} from "react-router-bootstrap";
import {useTranslation} from "react-i18next";

function Navigation() {
	const {t} = useTranslation(["common", "soajs"]);
	const rootPath = "/catalogs";
	return (
		<Navbar collapseOnSelect expand="md" className="pt-0 pb-0" bg="secondary" variant="light">
			<span></span>
			<Navbar.Toggle aria-controls="catalog-navbar-nav"/>
			<Navbar.Collapse id="catalog-navbar-nav" className="justify-content-end">
				<Nav activeKey={window.location.pathname}>
					{/*<LinkContainer to={rootPath + "/application"}>*/}
					{/*	<NavDropdown.Item><small>{t("soajs:menu.Application")}</small></NavDropdown.Item>*/}
					{/*</LinkContainer>*/}
					{/*<LinkContainer to={rootPath + "/event"}>*/}
					{/*	<NavDropdown.Item><small>{t("soajs:menu.Event")}</small></NavDropdown.Item>*/}
					{/*</LinkContainer>*/}
					{/*<LinkContainer to={rootPath + "/topic"}>*/}
					{/*	<NavDropdown.Item><small>{t("soajs:menu.Topic")}</small></NavDropdown.Item>*/}
					{/*</LinkContainer>*/}
					<LinkContainer to={rootPath + "/api"}>
						<NavDropdown.Item><small>{t("soajs:menu.API")}</small></NavDropdown.Item>
					</LinkContainer>
					<LinkContainer to={rootPath + "/daemon"}>
						<NavDropdown.Item><small>{t("soajs:menu.Daemon")}</small></NavDropdown.Item>
					</LinkContainer>
					<LinkContainer to={rootPath + "/frontend"}>
						<NavDropdown.Item><small>{t("soajs:menu.Frontend")}</small></NavDropdown.Item>
					</LinkContainer>
					<LinkContainer to={rootPath + "/custom"}>
						<NavDropdown.Item><small>{t("soajs:menu.Custom")}</small></NavDropdown.Item>
					</LinkContainer>
					<LinkContainer to={rootPath + "/resource"}>
						<NavDropdown.Item><small>{t("soajs:menu.Resource")}</small></NavDropdown.Item>
					</LinkContainer>
					
					<LinkContainer to={rootPath + "/git/repositories"}>
						<NavDropdown.Item><small>{t("soajs:menu.GitRepositories")}</small></NavDropdown.Item>
					</LinkContainer>
					
					<NavDropdown className="small" alignRight title={t("soajs:menu.Deployment")}
					             id="collasible-nav1-dropdown">
						<LinkContainer to={rootPath + "/config"}>
							<NavDropdown.Item><small>{t("soajs:menu.Config")}</small></NavDropdown.Item>
						</LinkContainer>
						<LinkContainer to={rootPath + "/recipe"}>
							<NavDropdown.Item><small>{t("soajs:menu.Recipe")}</small></NavDropdown.Item>
						</LinkContainer>
					</NavDropdown>
					
					<NavDropdown className="small" alignRight title={t("soajs:menu.SOAJS")}
					             id="collasible-nav1-dropdown">
						<LinkContainer to={rootPath + "/soajs/api"}>
							<NavDropdown.Item><small>{t("soajs:menu.API")}</small></NavDropdown.Item>
						</LinkContainer>
						<LinkContainer to={rootPath + "/soajs/frontend"}>
							<NavDropdown.Item><small>{t("soajs:menu.Frontend")}</small></NavDropdown.Item>
						</LinkContainer>
					</NavDropdown>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}

export default Navigation