import {Button, Modal, Form, Col} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";


import {JsonEditor, NotificationManager} from "../../../../soajs/urac/components";
import {InfraService} from "../../../../services";

const infraService = InfraService.getService();

export default function UpdateModal({modalOpt, setModalOpt, handleAction}) {
	const {t} = useTranslation(["common", "soajs"]);
	const handleClose = () => setModalOpt({"show": false, opts: null});
	const [fields, setFields] = useState({
		"id": "",
		"label": "",
		"description": "",
		"url": "",
		"port": 0,
		"token": "",
		"type": "secret",
		"ca": null
	});
	let _ca = null;
	
	function validateForm() {
		return (
			fields.label.length > 0 &&
			fields.description.length > 4 &&
			fields.token.length > 0 &&
			fields.url.length > 0 &&
			fields.port > 0
		);
	}
	
	function handleFieldChange(event) {
		setFields({
			...fields,
			[event.target.id]: event.target.value
		});
	}
	
	async function handleSubmit() {
		try {
			fields.port = parseInt(fields.port);
			fields.ca = _ca || fields.ca;
			await infraService.updateInfraAccount(fields);
			NotificationManager.success(t("soajs:messages.updateInfraAccount"));
			handleAction();
			handleClose();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	function JsonEditor_onChange(json) {
		if (json) {
			_ca = json;
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		if (isSubscribed && modalOpt && modalOpt.item) {
			setFields({
				"id": modalOpt.item._id,
				"label": modalOpt.item.label,
				"description": modalOpt.item.description,
				"url": modalOpt.item.configuration.url,
				"port": modalOpt.item.configuration.port,
				"token": "",
				"type": "secret",
				"ca": null
			});
		}
		return () => (isSubscribed = false);
	}, [modalOpt]);
	
	return (
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>{t("soajs:titles.clusterUpdate")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Label")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="label"
									size="sm"
									value={fields.label}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Description")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="description"
									size="sm"
									as="textarea"
									rows={3}
									value={fields.description}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Url")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="url"
									size="sm"
									value={fields.url}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Port")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="port"
									size="sm"
									type="number"
									value={fields.port}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Token")}</Form.Label>
							<Col sm={9}>
								<Form.Control
									id="token"
									size="sm"
									as="textarea"
									rows={3}
									value={fields.token}
									onChange={handleFieldChange}
								/>
							</Col>
						</Form.Row>
						<Form.Row className="mb-2">
							<Form.Label column="sm" sm={3}>{t("soajs:fields.Certificate")}</Form.Label>
							<Col sm={9}>
								<JsonEditor
									mode="code"
									json={fields.ca}
									onChange={JsonEditor_onChange}
								/>
							</Col>
						</Form.Row>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="warning" onClick={handleClose}>
					{t("soajs:buttons.Cancel")}
				</Button>
				<Button variant="success" onClick={handleSubmit}
				        disabled={!validateForm()}
				>
					{t("soajs:buttons.Update")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}