import {Button, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {InfraService} from "../../../../../../../services";
import {NotificationManager} from "../../../../../../../soajs/urac/components";

const infraService = InfraService.getService();

export default function LogModal({modalOpt, setModalOpt}) {
	const {t} = useTranslation(["common", "soajs"]);
	const [logResponse, setLogResponse] = useState("");
	
	const handleClose = () => setModalOpt({"show": false, "data": {}, "mode": "preview", "title": ""});
	
	async function onLoad(isSubscribed, env, name) {
		try {
			let response = await infraService.podLog(env, {
				"name": name
			});
			if (isSubscribed && response) {
				setLogResponse(response);
			}
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function getLog() {
		if (modalOpt && modalOpt.data && modalOpt.data.env && modalOpt.data.name) {
			await onLoad(true, modalOpt.data.env, modalOpt.data.name);
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		
		if (modalOpt && modalOpt.data && modalOpt.data.env && modalOpt.data.name) {
			onLoad(isSubscribed, modalOpt.data.env, modalOpt.data.name).catch();
		}
		return (isSubscribed) => (isSubscribed = false);
	}, [modalOpt]);
	
	return (
		modalOpt &&
		<Modal show={modalOpt.show} onHide={handleClose} animation={false} size="xl">
			<Modal.Header closeButton className="bg-secondary text-white">
				<Modal.Title>{modalOpt.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div
					className="small"
					style={
						{
							"backgroundColor": "black",
							"color": "white",
							"minHeight": 300,
							"maxHeight": 600,
							"overflow": "auto"
						}
					}
				>
					<pre><code className="text-light">{logResponse}</code></pre>
				</div>
			</Modal.Body>
			<Modal.Footer className="bg-light">
				<Button variant="success" onClick={getLog}>
					{t("soajs:buttons.Refresh")}
				</Button>
				<Button variant="info" onClick={handleClose}>
					{t("soajs:buttons.Close")}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}