import {Alert, Badge, Button, Col, Form, Row} from "react-bootstrap";
import React from "react";

import APIs from "../../../../../availableAbilities";
import {BsUnlockFill} from "react-icons/bs";

const methodVariant = {
	"get": "primary",
	"post": "success",
	"delete": "danger",
	"put": "warning",
	"patch": "info",
	"head": "secondary",
	"other": "light"
};

function getMethodVariant(method) {
	if (method && methodVariant[method]) {
		return methodVariant[method];
	} else {
		return methodVariant["other"];
	}
}

function DisplayAbility({
	                        method,
	                        service,
	                        api,
	                        version,
	                        name,
	                        apiAbility,
	                        index,
	                        addAbility,
	                        removeAbility,
	                        env,
	                        abilityPath
                        }) {
	let restricted = (service && service[version].apisPermission === "restricted");
	let available = false;
	if (restricted) {
		if (service && service[version] && service[version][method] && service[version][method].apis && service[version][method].apis[api]) {
			available = true;
		}
	} else if (service) {
		available = true;
	}
	
	return (
		apiAbility &&
		<div>
			{available ?
				<Alert variant={getMethodVariant(method)}>
					<Row>
						<Col className="font-weight-bold small">
							<Form.Check type="switch"
							            id={name + "/" + method + "/" + index + "_" + abilityPath}
							            checked={available}
							            label={apiAbility.M}
							            onChange={() => removeAbility(name, method, version, api, env)}
							/>
							{apiAbility.isPublic &&
							<span className="font-weight-bold text-info small"><BsUnlockFill
								className="mb-1"/> Public</span>
							}
						</Col>
						<Col className="small" lg={2}>
							<span className="font-weight-bold small">Available</span>
						</Col>
						<Col>
							<Button size="sm" className="mr-2 float-left p-1"
							        variant={getMethodVariant(method)}><small>{method.toUpperCase()}</small></Button>
							<span className="float-left small mt-1"><Badge variant="info"
							                                               className="mr-1">{name}</Badge>{api}</span>
						</Col>
					</Row>
				</Alert>
				:
				<Alert variant="light" className="border-dark">
					<Row>
						<Col className="font-weight-bold small">
							<Form.Check type="switch"
							            id={name + "/" + method + "/" + index + "_" + abilityPath}
							            checked={available}
							            label={apiAbility.M}
							            onChange={() => addAbility(name, method, version, api, env, apiAbility.isPublic)}
							/>
							{apiAbility.isPublic &&
							<span className="font-weight-bold text-info small"><BsUnlockFill
								className="mb-1"/> Public</span>
							}
						</Col>
						<Col className="small" lg={2}>
							<span className="font-weight-bold small">Not available</span>
						</Col>
						<Col>
							<Button size="sm" className="mr-2 float-left p-1"
							        variant={getMethodVariant(method)}><small>{method.toUpperCase()}</small></Button>
							<span className="float-left small mt-1"><Badge variant="info"
							                                               className="mr-1">{name}</Badge>{api}</span>
						</Col>
					</Row>
				</Alert>
			}
		</div>
	);
}

export default function Granular({service, name, addAbility, removeAbility, env, abilityPath}) {
	return (
		service ?
			Object.keys(service).map((version, index) => (
				<div key={index}>
					{APIs.versions[name] === version ?
						<div>
							{Object.keys(methodVariant).map((method, index) => (
								APIs[abilityPath][name] && APIs[abilityPath][name][method] &&
								Object.keys(APIs[abilityPath][name][method]).map((api, index) => (
									<DisplayAbility
										key={index}
										method={method}
										service={service}
										api={api}
										version={version}
										name={name}
										env={env}
										apiAbility={APIs[abilityPath][name][method][api]}
										index={index}
										addAbility={addAbility}
										removeAbility={removeAbility}
										abilityPath={abilityPath}
									/>
								))
							))}
						</div>
						:
						<span> wrong version for {name} @ {version}</span>
					}
				</div>
			))
			:
			<div>
				{Object.keys(methodVariant).map((method, index) => (
					APIs[abilityPath][name] && APIs[abilityPath][name][method] &&
					Object.keys(APIs[abilityPath][name][method]).map((api, index) => (
						<DisplayAbility
							key={index}
							method={method}
							service={service}
							api={api}
							env={env}
							version={APIs.versions[name]}
							name={name}
							apiAbility={APIs[abilityPath][name][method][api]}
							index={index}
							addAbility={addAbility}
							removeAbility={removeAbility}
							abilityPath={abilityPath}
						/>
					))
				))}
			</div>
	);
}