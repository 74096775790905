import {Alert, Badge, Button, Col, Row} from "react-bootstrap";

import {AiOutlineInfoCircle} from "react-icons/ai";
import React, {useState} from "react";
import JsonModal from "./modals/JsonModal";

export default function Hpas({items}) {
	const [modalOpt, setModalOpt] = useState({"show": false, "json": {}, "mode": "view", "title": ""});
	const modalOptShow = (json, title) => setModalOpt({
		"show": true,
		"json": json,
		"mode": "view",
		"title": title || ""
	});
	
	return (
		<>
			<JsonModal
				modalOpt={modalOpt}
				setModalOpt={setModalOpt}
			/>
			
			{items.map((rec, i) => (
				<Alert key={i} variant="info" className="p-1 m-1">
					<Row>
						<Col>
							<Badge variant="danger" className="float-left mr-1 small mt-1">hpa</Badge>
							<span className="small">{rec.metadata.name}</span>
						</Col>
						<Col xs={"auto"}>
							<span className="small float-left mr-2">{rec.metadata.creationTimestamp}</span>
							<Button size="sm" variant="light" className="float-right pt-0 pb-1 pl-1 pr-1">
								<AiOutlineInfoCircle
									className="text-primary"
									onClick={() => {
										modalOptShow(rec, rec.metadata.name);
									}}
								/>
							</Button>
						</Col>
					</Row>
				</Alert>
			))}
		</>
	);
}