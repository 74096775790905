import {Alert, Button, Badge} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {GoDiffAdded} from "react-icons/go";

import AddModal from "./AddModal";

import {InfraService} from "../../../../services";
import {NotificationManager} from "../../../../soajs/urac/components";
import {useAppContext} from "../../../../soajs/libs/contextLib";

import DeleteButton from "../../../buttons/Delete";

const infraService = InfraService.getService();

async function onLoad(setFields, isSubscribed) {
	try {
		const response = await infraService.getCDTokens();
		if (isSubscribed && response) {
			setFields(response);
		}
	} catch (e) {
		NotificationManager.error(e.message);
	}
}

export default function Tokens() {
	const {t} = useTranslation(["common", "soajs"]);
	const {ability} = useAppContext();
	const [fields, setFields] = useState([]);
	
	const [addModalOpt, setAddModalOpt] = useState({"show": false});
	const handleAddShow = () => setAddModalOpt({"show": true});
	
	async function reLoad() {
		await onLoad(setFields, true);
	}
	
	async function deleteToken(rec) {
		try {
			await infraService.deleteCDToken(rec.token);
			NotificationManager.success(t("soajs:messages.deleteToken"));
			await reLoad();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	async function updateToken(rec, status) {
		try {
			await infraService.updateCDToken(rec.token, status);
			NotificationManager.success(t("soajs:messages.updateToken"));
			await reLoad();
		} catch (e) {
			NotificationManager.error(e.message);
		}
	}
	
	useEffect(() => {
		let isSubscribed = true;
		onLoad(setFields, isSubscribed).catch();
		return () => (isSubscribed = false);
	}, []);
	
	return (
		<>
			<AddModal
				modalOpt={addModalOpt}
				setModalOpt={setAddModalOpt}
				handleAction={reLoad}
			/>
			<div>
				<h4 className="float-left">{t("soajs:titles.CDToken")}</h4>
				{ability.can('registry_custom', 'add') &&
				<Button variant="success" className="float-right" size="sm"
				        onClick={() => {
					        handleAddShow();
				        }}
				>
					<GoDiffAdded/> {t("soajs:buttons.Add")}
				</Button>}
				<span className="clearfix"></span>
			</div>
			<hr/>
			{fields.map((rec, i) => (
				<Alert key={i} variant="light" className="mt-2 mb-2" style={{"borderColor": "#c6c8ca"}}>
					{ability.can('infra_cd_token', 'delete') &&
					<DeleteButton
						className="float-left mr-2"
						onClick={() => {
							deleteToken(rec).catch();
						}}
					/>
					}
					{rec.status === "active" &&
					<Button variant="warning"
					        className="float-left mr-2"
					        size="sm"
					        disabled={!ability.can('infra_cd_token', 'update')}
					        onClick={() => {
						        updateToken(rec, "inactive").catch();
					        }}
					>Deactivate</Button>}
					{rec.status === "inactive" &&
					<Button variant="success"
					        className="float-left mr-2"
					        size="sm"
					        disabled={!ability.can('infra_cd_token', 'update')}
					        onClick={() => {
						        updateToken(rec, "active").catch();
					        }}
					>Activate</Button>}
					{rec.label &&
					<Badge variant="dark" className=" float-right mt-2 ml-2">
						{rec.label}
					</Badge>
					}
					<small style={{
						"wordBreak": "break-all"
					}}>
						<span className="small float-left mt-2">
						{rec.token}
						</span>
					</small>
					<span className="clearfix"></span>
				</Alert>
			))}
		</>
	);
}