import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";
import {FiEdit} from "react-icons/fi";

export default function Edit({...props}) {
	const {t} = useTranslation(["common", "soajs"]);
	return (
		<OverlayTrigger
			placement="top"
			overlay={
				<Tooltip id={`tooltip-top}`}>
					{t("soajs:buttons.Edit")}
				</Tooltip>
			}
		>
			<Button size="sm" variant="primary" {...props}>
				<FiEdit/>
			</Button>
		</OverlayTrigger>
	);
}