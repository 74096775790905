import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";

import {AuthenticatedRoute} from "../../soajs/helpers";

import Users from "./containers/Users/Users";
import Groups from "./containers/Groups/Groups";
import Packages from "./containers/Packages/Packages";
import Configuration from "./containers/Configuration/Configuration";

import Integration from "./containers/Integration/Integration";

import CDConfiguration from "./containers/ContinousDelivery/Configuration";
import Tokens from "./containers/ContinousDelivery/Tokens";

import Notifications from "./containers/Notifications/Notifications";
import Infrastructure from "./containers/Infrastructure/Infrastructure";

import AddAccount from "../Development/containers/Accounts/AddAccount";
import Accounts from "../Development/containers/Accounts/Accounts";

const rootPath = "/settings";
const defaultPath = "/infrastructure";

export default function Routes() {
	return (
		<Switch>
			<AuthenticatedRoute path={rootPath + "/users"}>
				<Users/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/groups"}>
				<Groups/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/packages"}>
				<Packages/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/configuration"}>
				<Configuration/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/infrastructure"}>
				<Infrastructure/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/notifications"}>
				<Notifications/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/integration"}>
				<Integration/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/cd/configuration"}>
				<CDConfiguration/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/tokens"}>
				<Tokens/>
			</AuthenticatedRoute>
			
			<AuthenticatedRoute path={rootPath + "/git/accounts/add"}>
				<AddAccount
					rootPath={rootPath + "/git/accounts"}
				/>
			</AuthenticatedRoute>
			<AuthenticatedRoute path={rootPath + "/git/accounts"}>
				<Accounts
					rootPath={rootPath + "/git/accounts"}
				/>
			</AuthenticatedRoute>
			<Route
				render={() => {
					return (<Redirect to={rootPath + defaultPath}/>)
				}}/>
		</Switch>
	)
}