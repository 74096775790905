import axios from "axios";
import {response_catch, response_then} from "./commonResponse";

const RepositoriesService = (function () {
	let _service = null;
	
	function _getService() {
		if (!_service) {
			_service = this;
			return _service;
		}
		return _service;
	}
	
	function _getGitBranch(fields) {
		return axios.get("/repositories/git/branch", {"params": fields})
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getGitAccount(id) {
		return axios.get("/repositories/git/account?id=" + id)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getGitAccounts() {
		return axios.get("/repositories/git/accounts")
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getRepositories(criteria) {
		return axios.post("/repositories/git/repos", criteria)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _addGitAccount(fields) {
		return axios.post("/repositories/git/account", fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteGitAccount(id) {
		return axios.delete("/repositories/git/account?id=" + id)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _syncGitAccount(id) {
		return axios.put("/repositories/git/sync/account?id=" + id)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _upgradeGitAccount(fields) {
		return axios.put("/repositories/git/account?id=" + fields.id, fields)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _deleteRepository(fields) {
		let url = "/repositories/git/repo";
		url = url + "?id=" + fields.id;
		url = url + "&owner=" + fields.owner;
		url = url + "&provider=" + fields.provider;
		
		return axios.delete(url)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _turnOnRepository(fields) {
		let url = "/repositories/git/repo/activate";
		url = url + "?id=" + fields.id;
		url = url + "&owner=" + fields.owner;
		url = url + "&provider=" + fields.provider;
		
		return axios.put(url)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _turnOffRepository(fields) {
		let url = "/repositories/git/repo/deactivate";
		url = url + "?id=" + fields.id;
		url = url + "&owner=" + fields.owner;
		url = url + "&provider=" + fields.provider;
		
		return axios.put(url)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _syncRepository(fields) {
		let url = "/repositories/git/sync/repository";
		url = url + "?id=" + fields.id;
		url = url + "&owner=" + fields.owner;
		url = url + "&provider=" + fields.provider;
		
		return axios.put(url)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _turnOnBranch(fields) {
		let url = "/repositories/git/branch/activate";
		url = url + "?id=" + fields.id;
		url = url + "&owner=" + fields.owner;
		url = url + "&provider=" + fields.provider;
		url = url + "&branch=" + fields.branch;
		
		return axios.put(url)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _turnOffBranch(fields) {
		let url = "/repositories/git/branch/deactivate";
		url = url + "?id=" + fields.id;
		url = url + "&owner=" + fields.owner;
		url = url + "&provider=" + fields.provider;
		url = url + "&branch=" + fields.branch;
		
		return axios.put(url)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _syncBranch(fields) {
		let url = "/repositories/git/sync/branch";
		url = url + "?id=" + fields.id;
		url = url + "&owner=" + fields.owner;
		url = url + "&provider=" + fields.provider;
		url = url + "&branch=" + fields.branch;
		
		return axios.put(url)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _turnOnTag(fields) {
		let url = "/repositories/git/tag/activate";
		url = url + "?id=" + fields.id;
		url = url + "&owner=" + fields.owner;
		url = url + "&provider=" + fields.provider;
		url = url + "&tag=" + fields.tag;
		
		return axios.put(url)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _turnOffTag(fields) {
		let url = "/repositories/git/tag/deactivate";
		url = url + "?id=" + fields.id;
		url = url + "&owner=" + fields.owner;
		url = url + "&provider=" + fields.provider;
		url = url + "&tag=" + fields.tag;
		
		return axios.put(url)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getTag(fields) {
		return axios.get("/repositories/git/tag?id=" + fields.id + "&tag=" + fields.tag)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	function _getTags(fields) {
		return axios.get("/repositories/git/tags?id=" + fields.id + "&size=" + fields.count)
			.then(res => {
				return response_then(res);
			})
			.catch((error) => {
				return response_catch(error);
			});
	}
	
	return {
		getService: _getService,
		
		getGitBranch: _getGitBranch,
		
		getGitAccount: _getGitAccount,
		getGitAccounts: _getGitAccounts,
		getRepositories: _getRepositories,
		addGitAccount: _addGitAccount,
		deleteGitAccount: _deleteGitAccount,
		syncGitAccount: _syncGitAccount,
		upgradeGitAccount: _upgradeGitAccount,
		
		deleteRepository: _deleteRepository,
		turnOnRepository: _turnOnRepository,
		turnOffRepository: _turnOffRepository,
		syncRepository: _syncRepository,
		turnOnBranch: _turnOnBranch,
		turnOffBranch: _turnOffBranch,
		syncBranch: _syncBranch,
		turnOnTag: _turnOnTag,
		turnOffTag: _turnOffTag,
		getTag: _getTag,
		getTags: _getTags
	}
})();
export default RepositoriesService;